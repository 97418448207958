import React, { useState, useEffect } from "react";

const CircleLabel = ({ color, label }) => {
  return (
    <div className="circle-label-container">
      <div className="circle-label-circle" style={{ background: color }}></div>
      <p className="circle-label-label">{label}</p>
    </div>
  );
};

export default CircleLabel;
