import * as jobTypes from "../types/jobTypes";
import StatusMessage from "../models/statusMessage";

const initialState = {
  jobs: [],
  pendingJobs: [],
  scheduledJobs: [],
  completedJobs: [],
  inProgressJobs: [],
  loading: false,
  job: {},
  summary: {},
  newJob: "",
  token: "",
  statusMessages: [],
};

const jobReducer = (state = initialState, action) => {
  switch (action.type) {
    case jobTypes.GET_JOB:
      return {
        ...state,
        loading: false,
        job: action.payload || state.job,
        statusMessages: [
          ...state.statusMessages,
          new StatusMessage({ subject: jobTypes.GET_JOB, action }),
        ],
      };

    case jobTypes.GET_JOB_SUMMARY:
      return {
        ...state,
        loading: false,
        // job: action.payload || state.job,
        summary: action.payload?.data || state.summary,
        statusMessages: [
          ...state.statusMessages,
          new StatusMessage({ subject: jobTypes.GET_JOB_SUMMARY, action }),
        ],
      };

    case jobTypes.CLEAR_NEW_JOB:
      return {
        ...state,
        loading: false,
        newJob: "",
      };

    case jobTypes.ADD_JOB:
      return {
        ...state,
        loading: false,
        newJob: action.payload?.data?.data || state.newJob,
        statusMessages: [
          ...state.statusMessages,
          new StatusMessage({ subject: jobTypes.ADD_JOB, action }),
        ],
      };

    case jobTypes.UPDATE_JOB:
      return {
        ...state,
        loading: false,
        newJob: action.payload?.data || state.newJob,
        statusMessages: [
          ...state.statusMessages,
          new StatusMessage({ subject: jobTypes.UPDATE_JOB, action }),
        ],
      };

    case jobTypes.SCHEDULE_JOB:
      return {
        ...state,
        loading: false,
        statusMessages: [
          ...state.statusMessages,
          new StatusMessage({ subject: jobTypes.SCHEDULE_JOB, action }),
        ],
      };

    case jobTypes.RE_SCHEDULE_JOB:
      return {
        ...state,
        loading: false,
        statusMessages: [
          ...state.statusMessages,
          new StatusMessage({ subject: jobTypes.RE_SCHEDULE_JOB, action }),
        ],
      };

    case jobTypes.GET_ALL_JOBS:
      return {
        ...state,
        loading: false,
        jobs: action.payload?.data?.data || state.jobs,
        statusMessages: [
          ...state.statusMessages,
          new StatusMessage({ subject: jobTypes.GET_ALL_JOBS, action }),
        ],
      };

    case jobTypes.GET_PENDING_JOBS:
      return {
        ...state,
        loading: false,
        pendingJobs: action.payload.data || state.pendingJobs,
        statusMessages: [
          ...state.statusMessages,
          new StatusMessage({ subject: jobTypes.GET_PENDING_JOBS, action }),
        ],
      };

    case jobTypes.GET_SCHEDULED_JOBS:
      return {
        ...state,
        loading: false,
        scheduledJobs: action.payload.data || state.scheduledJobs,
        statusMessages: [
          ...state.statusMessages,
          new StatusMessage({ subject: jobTypes.GET_SCHEDULED_JOBS, action }),
        ],
      };

    case jobTypes.GET_JOBS_IN_PROGRESS:
      return {
        ...state,
        loading: false,
        completedJobs: action.payload.data || state.inProgressJobs,
        statusMessages: [
          ...state.statusMessages,
          new StatusMessage({ subject: jobTypes.GET_JOBS_IN_PROGRESS, action }),
        ],
      };

    case jobTypes.GET_COMPLETED_JOBS:
      return {
        ...state,
        loading: false,
        inProgressJobs: action.payload.data || state.inProgressJobs,
        statusMessages: [
          ...state.statusMessages,
          new StatusMessage({ subject: jobTypes.GET_COMPLETED_JOBS, action }),
        ],
      };

    case jobTypes.DELETE_JOB:
      return {
        ...state,
        loading: false,
        statusMessages: [
          ...state.statusMessages,
          new StatusMessage({ subject: jobTypes.DELETE_JOB, action }),
        ],
      };

    case jobTypes.LOADING_JOBS:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};

export default jobReducer;
