import React, { useState, useEffect } from "react";

import Button from "../Button";
import { RiCalendarTodoFill } from "react-icons/ri";
import { Calendar } from "react-multi-date-picker";
import { Select } from "antd";
import { Colors } from "../../config";
import styles from "./style.module.css";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import moment from "moment";

const { Option } = Select;

const CustomDateBtn = ({ onSubmit, onClear, noMargin, fromLeft, offset }) => {
  const [dateDropdownOpen, setDateDropdownOpen] = useState(false);
  const [customDateChosen, setCustomDateChosen] = useState("");

  const [showCalendar, setShowCalendar] = useState(false);
  const [confirmedDate, setConfirmedDate] = useState({});
  const [values, setValues] = useState([]);
  const usePicker = true;

  const handleConfirm = () => {
    const isValid = values?.length && values.length === 2;
    if (!isValid) return;
    setShowCalendar(false);

    const payload = {
      start: new Date(values[0].format()),
      end: new Date(values[1].format()),
      startReadable: values[0].format("DD MMM, YYYY"),
      endReadable: values[1].format("DD MMM, YYYY"),
      filter: (d) => {
        let target = moment(d);
        let start = moment(new Date(values[0].format()));
        let finish = moment(new Date(values[1].format()));
        return target.isBetween(start, finish, "days", "[]");
      },
    };

    setConfirmedDate(payload);
    if (onSubmit) {
      onSubmit(payload);
    }
  };

  const handleClear = () => {
    setConfirmedDate({});
    setValues([]);
    if (onClear) {
      onClear();
    }
  };

  const toggleCalendar = () => {
    console.log("Toggling");
    setShowCalendar(!showCalendar);
  };

  // Auto close
  const handleClickOutside = () => {
    if (showCalendar) {
      toggleCalendar();
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClickOutside}>
      <div
        className={styles.container}
        style={noMargin ? {} : { marginBottom: "12.6px", marginLeft: "10px" }}
      >
        {usePicker ? (
          <>
            <div className={styles.iconWrapper} onClick={toggleCalendar}>
              <RiCalendarTodoFill size={25} color={Colors.darkGreen} />
            </div>
            <div className={styles.textContainer}>
              <p onClick={() => setShowCalendar(!showCalendar)}>
                {confirmedDate?.startReadable ? (
                  <>
                    {confirmedDate.startReadable} <span> to </span>
                    {confirmedDate.endReadable}
                  </>
                ) : (
                  "Custom Date"
                )}
              </p>
              <div
                className={styles.calendarContainer}
                style={
                  fromLeft
                    ? {
                        left: offset || "-55px",
                      }
                    : { right: offset || "-55px" }
                }
              >
                {showCalendar ? (
                  <Calendar
                    maxDate={new Date()}
                    className="green custom-date-btn"
                    style={{
                      backgroundColor: "#006C3A",
                    }}
                    numberOfMonths={2}
                    disableMonthPicker
                    disableYearPicker
                    value={values}
                    onChange={setValues}
                    range
                  >
                    <button
                      onClick={handleConfirm}
                      className={styles.confirmBtn}
                      style={{
                        background:
                          values?.length && values.length === 2
                            ? "#3D9970"
                            : Colors.borderGray,
                        color:
                          values?.length && values.length === 2
                            ? Colors.white
                            : Colors.darkBackground,
                      }}
                    >
                      Confirm
                    </button>

                    <button
                      onClick={handleClear}
                      className={styles.confirmBtn}
                      style={{
                        background: `${Colors.actionRed}40`,
                        color: Colors.actionRed,
                      }}
                    >
                      Clear
                    </button>
                  </Calendar>
                ) : null}
              </div>
            </div>
          </>
        ) : (
          <div className="dashboard-home-dispatch-button">
            <Button
              color={Colors.white}
              onClick={() => setDateDropdownOpen(!dateDropdownOpen)}
            >
              <RiCalendarTodoFill size={25} color={Colors.darkGreen} />
              <p className="dashboard-home-dispatch-button-text">Custom Date</p>
            </Button>
            <div className="dashboard-home-select-container">
              <Select
                // showSearch
                open={dateDropdownOpen}
                style={{ width: "100%" }}
                // placeholder={}
                optionFilterProp="Status"
                onChange={(val) => {
                  setCustomDateChosen(val);
                  setDateDropdownOpen(!dateDropdownOpen);
                }}
                value={customDateChosen}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                <Option value="Daily">Daily</Option>
                <Option value="Weekly">Weekly</Option>
                <Option value="Monthly">Monthly</Option>
                <Option value="6 Months">6 Months</Option>
                <Option value="Yearly">Yearly</Option>
                <Option value="Custom">Custom</Option>
              </Select>
            </div>
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default CustomDateBtn;
