import React, { useState, useEffect } from "react";
import { Button as AntDBtn, Modal, Spin, notification } from "antd";
import Colors from "../../../config/Colors";
import makeID from "../../../redux/utils/makeID";
import { useSelector, useDispatch } from "react-redux";
import { updateSP } from "./../../../redux/actions/spActions";
import { Select } from "antd";
import * as spActions from "./../../../redux/actions/spActions";

const { Option } = Select;
const AssignSpToDiv = ({ visible, setVisible, sp }) => {
  const divisionsInState = useSelector((state) => state.division.divisions);
  const loading = useSelector((state) => state.sp.loading);
  const spStatusMessages = useSelector((state) => state.sp.statusMessages);
  const spsLoading = useSelector((state) => state.sp.loading);
  const [statusID, setStatusID] = useState("");
  const dispatch = useDispatch();

  const defaultDiv = "None Chosen";
  const [division, setDivision] = useState(defaultDiv);
  const handleAction = () => {
    if (division === defaultDiv) {
      notification.warn({
        message: "Invalid Input!",
        description: "Please choose a division!",
        onClick: () => {},
        duration: 2,
      });
      return;
    }

    const actualDiv = divisionsInState?.find((div) => div.name === division);

    const updateObj = {
      org: {
        ...(sp?.org || {}),
        division: actualDiv._id,
      },
      isVoucherSP: true,
    };
    const newStatusID = makeID(8);
    setStatusID(newStatusID);
    dispatch(spActions.updateSP(sp._id, updateObj, newStatusID));
    console.log("Chosen division is ", division);
    // if (onAction) onAction();
  };

  useEffect(() => {
    if (statusID) {
      const result = spStatusMessages.find((it) => it.statusID === statusID);
      if (result?.success) {
        const successMessage =
          "Service Provider has successfully been assigned to a division";
        notification.success({
          message: "Update",
          description: successMessage,
          onClick: () => {},
          duration: 2,
          onClose: () => {
            dispatch(spActions.getSp(sp?._id));
          },
        });
        setStatusID("");
        setVisible(false);
        //   goBackToSps();
        return;
      } else {
        const failMessage =
          "Failed to assign a division to service provider. Please try again";
        notification.error({
          message: "Update",
          description: failMessage,
          onClick: () => {},
          duration: 2,
        });
        setStatusID("");

        return;
      }
    }
  }, [spStatusMessages]);

  return (
    <Modal
      visible={visible}
      title={
        <b style={{ color: Colors.textGray }}>
          ASSIGN DIVISION TO {sp?.full_name}
        </b>
      }
      onOk={handleAction}
      onCancel={setVisible}
      footer={[
        <AntDBtn
          key="cancel"
          type="primary"
          danger
          onClick={handleAction}
          color={Colors.actionRed}
          style={{}}
        >
          Assign
        </AntDBtn>,
        <AntDBtn
          key="logout"
          type="primary"
          onClick={() => setVisible(false)}
          className="logout-modal-no"
        >
          Cancel
        </AntDBtn>,
      ]}
    >
      <Spin spinning={loading}>
        <div style={{ marginBottom: "30px" }}>
          <p style={{ color: Colors.darkText }}>Choose a Division</p>
          <Select
            showSearch
            style={{ width: "100%" }}
            // placeholder={}
            optionFilterProp="Status"
            onChange={(val) => setDivision(val)}
            value={division}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {divisionsInState?.map((div) => (
              <Option value={div.name}>{div.name}</Option>
            ))}
          </Select>
        </div>
      </Spin>
    </Modal>
  );
};

export default AssignSpToDiv;
