import React, { useState, useEffect } from 'react';
import { Button as AntDBtn, Modal, Input, notification, Spin } from 'antd';
import Colors from '../../../config/Colors';
import Checkbox from '../../../components/Checkbox';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import {
  createVoucherProgram,
  getVouchersOverview,
} from '../../../redux/actions/voucherActions';
import makeID from '../../../redux/utils/makeID';

const validDivisions = ['Nakawa', 'Makindye', 'Central', 'Kawempe', 'Rubaga'];

const AddVoucherProgramModal = ({ visible, setVisible }) => {
  // Redux
  const dispatch = useDispatch();
  const voucherStatusMessages = useSelector(
    (state) => state.voucher.statusMessages
  );
  const adminInState = useSelector((state) => state.admin.id);
  const loading = useSelector((state) => state.voucher.loading);
  const divisionsInState = useSelector((state) => state.division.divisions);
  const [createStatus, setCreateStatus] = useState('');

  const [startDate, setStartDate] = useState(false);
  const [endDate, setEndDate] = useState(false);
  const [programName, setProgramName] = useState('');
  const [maxVouchersPerDivision, setMaxVouchersPerDivision] = useState('');
  const [defaultVoucherValidity, setDefaultVoucherValidity] = useState('');
  const [defaultVoucherValue, setDefaultVoucherValue] = useState('');
  const [kccaPercentage, setKccaPercentage] = useState('');
  const [beneficiaryAmount, setBeneficiaryAmount] = useState('');
  const [divisions, setDivisions] = useState([]);

  const handleAddProgram = () => {
    if (
      !programName ||
      !divisions?.length ||
      !startDate ||
      !endDate ||
      !maxVouchersPerDivision ||
      !defaultVoucherValidity ||
      !programName ||
      !kccaPercentage
    ) {
      notification.warn({
        message: 'Incomplete Input!',
        description: `Please provide all details`,
        onClick: () => {},
        duration: 2,
      });
      return;
    }
    console.log('divisionsInState', divisionsInState);
    const payload = {
      divisions: divisions.map((d) => ({
        divisionName: divisionsInState.find((div) => div._id === d)?.name,
        divisionID: d,
        maxVouchers: parseInt(maxVouchersPerDivision),
      })),
      startDate,
      endDate,
      maxVouchersPerDivision: parseInt(maxVouchersPerDivision),
      defaultVoucherValidity: parseInt(defaultVoucherValidity),
      defaultVoucherValue: parseInt(defaultVoucherValue),
      createdBy: adminInState,
      programName,
      kccaPercentage,
      beneficiaryAmount: parseInt(
        defaultVoucherValue -
          (Number(kccaPercentage) / 100) * defaultVoucherValue
      ),
    };

    const createStatusID = makeID(8);
    setCreateStatus(createStatusID);
    dispatch(createVoucherProgram(payload, createStatusID));
  };

  const handleAddDiv = (d) => {
    if (divisions.includes(d)) {
      setDivisions(divisions.filter((el) => el !== d));
    } else {
      setDivisions([...divisions, d]);
    }
  };

  useEffect(() => {
    if (!createStatus) return;

    const result = voucherStatusMessages.find(
      (it) => it.statusID === createStatus
    );

    if (result?.success) {
      const successMessage = 'Voucher Program created successfully';
      notification.success({
        message: 'Update',
        description: successMessage,
        onClose: () => {
          dispatch(getVouchersOverview());
          setVisible(false);
        },
        duration: 2,
      });
    } else {
      const errorMessage = result?.error || 'An unknown error occurred';
      notification.warn({
        message: 'An Error Occurred',
        description: errorMessage,
        onClick: () => {},
        duration: 2,
      });
    }

    setCreateStatus('');
  }, [voucherStatusMessages]);

  useEffect(() => {
    if (!kccaPercentage || !defaultVoucherValue) {
      setBeneficiaryAmount(0);
    } else {
      setBeneficiaryAmount(
        parseInt(
          defaultVoucherValue -
            (Number(kccaPercentage) / 100) * defaultVoucherValue
        )
      );
    }
  }, [kccaPercentage, defaultVoucherValue]);

  return (
    <Modal
      visible={visible}
      title={<b style={{ color: Colors.textGray }}>ADD VOUCHER PROGRAM</b>}
      onOk={handleAddProgram}
      className='add-vp-container'
      onCancel={() => setVisible(false)}
      footer={[
        <AntDBtn key='cancel' onClick={() => setVisible(false)}>
          Cancel
        </AntDBtn>,
        <AntDBtn
          key='add'
          type='primary'
          onClick={handleAddProgram}
          className='logout-modal-no'
        >
          Add
        </AntDBtn>,
      ]}
    >
      <Spin spinning={loading}>
        <div>
          <div className='add-vp-fields'>
            <p className='add-vp-key'>Program Name:</p>
            <Input
              type='text'
              className='add-vp-val'
              value={programName}
              onChange={(val) => setProgramName(val.target.value)}
              placeholder='Enter Value'
            />
          </div>

          <div className='add-vp-fields'>
            <p className='add-vp-key'>Program Start Date:</p>
            <Input
              className='add-vp-val'
              type='date'
              value={startDate}
              onChange={(val) => setStartDate(val.target.value)}
              placeholder='Enter Value'
            />
          </div>

          <div className='add-vp-fields'>
            <p className='add-vp-key'>Program End Date:</p>
            <Input
              className='add-vp-val'
              type='date'
              value={endDate}
              onChange={(val) => setEndDate(val.target.value)}
              placeholder='Enter Value'
            />
          </div>

          <div className='add-vp-fields'>
            <p className='add-vp-key'>Active / Participating Divisions:</p>
            <div className='add-vp-val'>
              {divisionsInState.map((d) => (
                <Checkbox
                  key={d._id}
                  checked={divisions.includes(d._id)}
                  onCheck={() => handleAddDiv(d._id)}
                >
                  <p style={{ color: Colors.textGray, margin: '0px' }}>
                    {d.name}
                  </p>
                </Checkbox>
              ))}
            </div>
          </div>

          <div className='add-vp-fields'>
            <p className='add-vp-key'>Default Voucher Validity (days):</p>
            <Input
              className='add-vp-val'
              type='number'
              value={defaultVoucherValidity}
              onChange={(val) => setDefaultVoucherValidity(val.target.value)}
              placeholder='Enter Value'
            />
          </div>

          <div className='add-vp-fields'>
            <p className='add-vp-key'>Default Voucher Value (UGX):</p>
            <Input
              className='add-vp-val'
              type='number'
              value={defaultVoucherValue}
              onChange={(val) => setDefaultVoucherValue(val.target.value)}
              placeholder='Enter Value'
            />
          </div>

          <div className='add-vp-fields'>
            <p className='add-vp-key'>KCCA Percentage:</p>
            <Input
              className='add-vp-val'
              type='number'
              value={kccaPercentage}
              onChange={(val) => setKccaPercentage(val.target.value)}
              placeholder='Enter Value'
            />
          </div>

          <div className='add-vp-fields'>
            <p className='add-vp-key'>Beneficiary to pay:</p>
            <Input
              className='add-vp-val'
              type='number'
              value={beneficiaryAmount}
              disabled
              onChange={(val) => setBeneficiaryAmount(val.target.value)}
              placeholder='Enter KCCA Percentage'
            />
          </div>

          <div className='add-vp-fields'>
            <p className='add-vp-key'>Total Number of Vouchers per Division:</p>
            <Input
              className='add-vp-val'
              type='number'
              value={maxVouchersPerDivision}
              onChange={(val) => setMaxVouchersPerDivision(val.target.value)}
              placeholder='Enter Value'
            />
          </div>
        </div>
      </Spin>
    </Modal>
  );
};

export default AddVoucherProgramModal;
