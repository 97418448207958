import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Modal, Spin, Select, notification } from "antd";
import CustomButton from "../../../components/Button";
import { Colors } from "../../../config";
import * as utils from "../../../redux/utils/componentUtils";
import * as voucherActions from "../../../redux/actions/voucherActions";
const { Option } = Select;
const ApproveAllVouchers = ({ isDO }) => {
  // State
  const [visible, setVisible] = useState(false);

  // Redux
  const dispatch = useDispatch();
  const spsInState = useSelector((state) => state.sp.sps);
  const loading = useSelector((state) => state.voucher.loading);
  const [scanning, setScanning] = useState(false);
  const vouchersInState = useSelector((state) => state.voucher.vouchers);
  const voucherStatusMessages = useSelector(
    (state) => state.voucher.statusMessages
  );
  const vouchersLoading = useSelector((state) => state.voucher.loading);
  const officer = useSelector((state) => state.admin.officer);
  const divisions = useSelector((state) => state.division.divisions);
  //   const officerDivision = "";
  const vouchersOverviewInState = useSelector(
    (state) => state.voucher.overview
  );

  const defaultSelectedProgram = "Choose a Program";
  const [selectedProgram, setSelectedProgram] = useState(
    defaultSelectedProgram
  );
  const [validVouchers, setValidVouchers] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  const scanVouchers = () => {
    const officerDivision = divisions.find(
      (d) => d._id === officer?.divisionID
    );

    // 1. Get all the vouchers for this division / cats / program
    //  - Must match the program
    //  - Status must be 1
    setScanning(true);
    const validVouchers = vouchersInState?.filter(
      (v) =>
        v.voucherProgram === selectedProgram &&
        v.status === 1 &&
        v.validForDivision === officerDivision?.name
    );

    if (validVouchers.length) {
      setErrorMessage(
        `Found ${validVouchers.length} valid vouchers. Please press start to begin`
      );
      // setFoundVouchers(validVouchers.length);
      setValidVouchers(validVouchers);
    } else {
      setErrorMessage(`Did not find any valid vouchers for ${selectedProgram}`);
      setValidVouchers([]);
    }

    setScanning(false);
  };

  const startDispatch = () => {
    const doID = officer._id;
    const doDivision = officer.divisionID;
    const sps = spsInState?.filter((it) => it.org?.division === doDivision);

    if (!validVouchers?.length) return;
    validVouchers.forEach(async (voucher, index, arr) => {
      const isLast = index === arr.length - 1;
      // set up data payload
      const sp = sps?.filter((sp) => sp.type === voucher.spType)[0];
      console.log(sp?._id);

      const payload = {
        _id: voucher._id,
        division: voucher.validForDivision,
        data: {
          status: 6, // This is the completed status
          approved: {
            status: 1,
            time: new Date(),
            approvedBy: doID,
          },
          dispatched: {
            status: 3,
            time: new Date(),
            dispatchedBy: doID,
            spID: sp?._id,
          },
          jobApproved: {
            status: 6,
            time: new Date(),
          },
          jobStatus: {
            status: 1,
            completedOn: new Date(),
          },
          // jobID: "",
        },
      };

      dispatch(voucherActions.updateVoucher(payload));

      if (isLast) {
        setVisible(false);
        notification.success({
          message: "Update",
          description: "All valid vouchers have been completed.",
          onClick: () => {},
          duration: 2,
        });
      }
      // console.log(payload);
    });
  };

  useEffect(() => {
    dispatch(voucherActions.getVouchersOverview());
  }, []);
  return (
    <>
      <CustomButton
        color={Colors.darkGreen}
        disabled={!isDO}
        onClick={() => setVisible(true)}
      >
        <span style={{ color: Colors.white }}>Approve All Vouchers</span>
      </CustomButton>

      <Modal
        visible={visible}
        title={<b style={{ color: Colors.textGray }}>APPROVE ALL VOUCHERS</b>}
        onOk={!errorMessage ? scanVouchers : startDispatch}
        onCancel={() => setVisible(false)}
        footer={[
          <Button
            key="activate"
            type="primary"
            danger
            disabled={
              !selectedProgram || selectedProgram === defaultSelectedProgram
            }
            onClick={!errorMessage ? scanVouchers : startDispatch}
            color={Colors.actionRed}
            style={{}}
          >
            {errorMessage ? "Start" : "Scan"}
          </Button>,
          <Button
            key="logout"
            type="primary"
            onClick={() => setVisible(false)}
            className="logout-modal-no"
          >
            Cancel
          </Button>,
        ]}
      >
        <Spin spinning={loading || scanning}>
          <div style={{ marginBottom: "30px" }}>
            {errorMessage ? (
              <p style={{ color: Colors.darkText }}>{errorMessage}</p>
            ) : (
              <div>
                <p style={{ color: "red", fontSize: 20, fontWeight: "bold" }}>
                  Warning! This will mark all jobs that have been marked as
                  approved as completed.
                </p>
                <p> This is what will happen</p>
                <ul>
                  <li>
                    It will search for all vouchers that you have approved for a
                    particular voucher program
                  </li>
                  <li>It will "dispatch" these to the respective SP</li>
                  <li>It will mark the job as completed by the SP</li>
                  <li>It will mark the job as approved by the CATs / VHT</li>
                  <li>It will mark the job as approved YOU</li>
                  <li>
                    It will mark the job as COMPLETED and ready for payment
                  </li>
                </ul>
                <br />
                <p style={{ color: "red" }}>
                  If you are okay with this, please select a voucher program and
                  click the "Scan Vouchers" button to begin.
                </p>
                <br />

                <div
                  className="form-group"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    // justifyContent: "space-between",
                  }}
                >
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    // placeholder={}
                    optionFilterProp="Status"
                    onChange={(val) => setSelectedProgram(val)}
                    value={selectedProgram}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {vouchersOverviewInState?.length
                      ? vouchersOverviewInState.map((v) => (
                          <Option value={v.programName} key={v.programName}>
                            {v.programName}
                          </Option>
                        ))
                      : null}
                  </Select>
                </div>
              </div>
            )}
          </div>
        </Spin>
      </Modal>
    </>
  );
};

export default ApproveAllVouchers;
