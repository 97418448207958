import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import CustomDateBtn from '../../components/CustomDateBtn';

// Tabs
import OverView from './Tabs/Overview';
import CatsVHT from './Tabs/CatsVHT';
import Settings from './Tabs/Settings';
import Payments from './Tabs/Payments';
import AllVouchers from './Tabs/AllVouchers';
import ServiceProviders from './Tabs/ServiceProviders';

const Vouchers = ({ history, isDO }) => {
  const vouchersInState = useSelector((state) => state.voucher.vouchers);
  const vouchersOverviewInState = useSelector(
    (state) => state.voucher.overview
  );
  const activeProgramInState = useSelector(
    (state) => state.voucher.activeProgram
  );
  const [activeProgram, setActiveProgram] = useState(activeProgramInState);

  const [activeTab, setActiveTab] = useState(1);
  const [emptierCount, setEmptierCount] = useState(0);
  const [gulperCount, setGulperCount] = useState(0);

  const changeTab = (tab) => {
    setActiveTab(tab.number);
  };

  const tabs = {
    1: {
      name: 'OVERVIEW',
      number: 1,
      component: <OverView spCounts={{ emptierCount, gulperCount }} />,
    },
    2: {
      name: 'VOUCHERS',
      number: 2,
      component: <AllVouchers />,
    },
    3: {
      name: 'CATS/VHTS',
      number: 3,
      component: <CatsVHT />,
    },
    4: {
      name: 'SERVICE PROVIDERS',
      number: 4,
      component: <ServiceProviders />,
    },
    5: {
      name: 'PAYMENTS',
      number: 5,
      component: <Payments />,
    },

    // Only visible to admins
    ...(!isDO && {
      6: {
        name: 'SETTINGS',
        number: 6,
        component: <Settings />,
      },
    }),
  };

  useEffect(() => {
    const isValid =
      Array.isArray(vouchersOverviewInState) && vouchersOverviewInState?.length;
    if (!isValid) return;

    if (!activeProgram) return;
    const completedVouchers = vouchersInState.filter(
      (voucher) =>
        voucher.status > 3 &&
        voucher.status !== 7 &&
        voucher.jobStatus >= 1 &&
        voucher.voucherProgram === activeProgram.programName
    );

    const emptierCompleted = completedVouchers.filter(
      (v) => v?.spType === 'Emptier'
    );
    const gulperCompleted = completedVouchers.filter(
      (v) => v?.spType === 'Gulper'
    );

    setEmptierCount(emptierCompleted?.length || 0);
    setGulperCount(gulperCompleted?.length || 0);
  }, [vouchersInState, vouchersOverviewInState, activeProgramInState]);

  useEffect(() => {
    if (!activeProgramInState) return;
    setActiveProgram(activeProgramInState);
    const completedVouchers = vouchersInState.filter(
      (voucher) =>
        voucher.status > 3 &&
        voucher.status !== 7 &&
        voucher.jobStatus >= 1 &&
        voucher.voucherProgram === activeProgramInState.programName
    );

    const emptierCompleted = completedVouchers.filter(
      (v) => v?.spType === 'Emptier'
    );
    const gulperCompleted = completedVouchers.filter(
      (v) => v?.spType === 'Gulper'
    );

    setEmptierCount(emptierCompleted?.length || 0);
    setGulperCount(gulperCompleted?.length || 0);
  }, [activeProgramInState]);

  return (
    <div className='dashboard-vouchers'>
      <div className='dashboard-vouchers-top'>
        <div className='dashboard-vouchers-tabs'>
          {Object.keys(tabs).map((tabNum) => (
            <p
              className={`dashboard-vouchers-tab ${
                activeTab === tabs[tabNum].number
                  ? 'dashboard-vouchers-tab-active'
                  : ''
              }`}
              onClick={() => changeTab(tabs[tabNum])}
            >
              {tabs[tabNum].name}
            </p>
          ))}
        </div>

        <div className='dashboard-vouchers-dispatch-button'>
          <CustomDateBtn />
        </div>
      </div>

      {React.cloneElement(tabs[activeTab].component, { history, isDO })}
    </div>
  );
};

export default Vouchers;
