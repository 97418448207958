import React, { useState, useEffect, useRef } from 'react';
import Button from './../../components/Button';
import { AiOutlineSearch } from 'react-icons/ai';
import { FiChevronRight } from 'react-icons/fi';
import { Select, Table, Space } from 'antd';
import { CSVLink } from 'react-csv';

import { Colors } from './../../config';
import * as utils from './../../redux/utils/componentUtils';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import * as customerActions from './../../redux/actions/customerActions';
import makeID from './../../redux/utils/makeID';
import configureForCSV from './../../redux/utils/configureForCSV';

import moment from 'moment';

const { Option } = Select;
const { Column } = Table;

const Customers = ({ isDO }) => {
  const csvDownloaderRef = useRef(null);
  const [csvDataForDownload, setCsvDataForDownload] = useState([]);

  // Redux
  const dispatch = useDispatch();
  const customerStatusMessages = useSelector(
    (state) => state.customer.statusMessages
  );
  const customersInState = useSelector((state) => state.customer.customers);
  const [getCustomerStatus, setGetCustomerStatus] = useState('');
  const loading = useSelector((state) => state.customer.loading);

  const dataSource = customersInState;

  const [facilityType, setFacilityType] = useState('All');
  const [filteredData, setFilteredData] = useState(dataSource);
  const [searchText, setSearchText] = useState('');

  const getCustomers = () => {
    const statusID = makeID(8);
    setGetCustomerStatus(statusID);
    dispatch(customerActions.getAllCustomers(statusID));
  };

  const configureEntitiesForCSV = async () => {
    const configFields = [
      { delete: '_id' },
      { delete: 'updatedAt' },
      { delete: 'locations' },
      { delete: 'language' },
      { delete: '__v' },
      { delete: 'customer_salt' },
      { delete: 'customer_hash' },
      { delete: 'device_id' },
      { delete: 'email' },

      {
        new: 'Date Of Registration',
        old: 'date_of_registration',
        mutation: (val) => moment(val).format('DD/MM/YYYY, hh:mm a'),
      },
      { new: 'Full Name', old: 'full_name' },
      { old: 'phone_number', new: 'Phone Number' },

      { old: 'customer_id', new: 'Customer Id' },

      { old: 'facility_type', new: 'Facility Type' },
      {
        new: 'Address',
        mutation: (val) => val.location.address,
        useParent: true,
      },
      {
        new: 'Division',
        mutation: (val) => val.location.division,
        useParent: true,
      },
      {
        new: 'Parish',
        mutation: (val) => val.location.parish,
        useParent: true,
      },
      {
        new: 'Village',
        mutation: (val) => val.location.village,
        useParent: true,
      },
      { delete: 'location' },
    ];

    await configureForCSV(customersInState, configFields)
      .then((res) => setCsvDataForDownload(res))
      .then(() => csvDownloaderRef.current.click());
  };

  const handleSearch = (text) => {
    const result = utils.search(
      text,
      dataSource,
      ['full_name', 'phone_number', 'address', 'facility_type', 'division'],
      'customer_id'
    );

    setFilteredData(result);
  };

  useEffect(() => {
    if (searchText.length) {
      handleSearch(searchText);
    } else {
      const customersReversed = dataSource.reverse();

      setFilteredData(customersReversed);
    }
  }, [searchText]);

  useEffect(() => {
    getCustomers();
  }, []);

  useEffect(() => {
    if (!getCustomerStatus) return;
    const result = customerStatusMessages.find(
      (it) => it.statusID === getCustomerStatus
    );
    setFilteredData(dataSource);
  }, [customerStatusMessages]);

  return (
    <div className='customers'>
      <div className='customers-top'>
        <h3>CUSTOMERS</h3>
      </div>
      <div className='customers-bottom'>
        <div className='customers-search-row'>
          <div className='customers-select-container'>
            <p className='customers-select-label'>Type of facility</p>
            <Select
              showSearch
              style={{ width: '100%' }}
              // placeholder={}
              optionFilterProp='Status'
              onChange={(val) => setFacilityType(val)}
              value={facilityType}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value='Lined Pit Latrine'>Lined Pit Latrine</Option>
              <Option value='Unlined Pit Latrine'>Unlined Pit Latrine</Option>
              <Option value='Septic Tank'>Septic Tank</Option>
              <Option value='Flush Toilet'>Flush Toilet</Option>
              <Option value='Composting Toilet'>Composting Toilet</Option>
              <Option value='Others'>Others</Option>
            </Select>
          </div>

          <div className='customer-search-container'>
            <div className='customer-search-input-wrapper'>
              <input
                placeholder='Search full name, phone number'
                value={searchText}
                onChange={(e) => setSearchText(utils.getInputVal(e))}
              />
            </div>
            <div className='customer-search-icon'>
              <AiOutlineSearch color={Colors.textGray} size={20} />
            </div>
          </div>
        </div>

        <div style={{ marginBottom: '1rem' }}>
          <Button color={Colors.darkGreen} onClick={configureEntitiesForCSV}>
            <span style={{ color: Colors.white }}>Export to CSV</span>
          </Button>
        </div>

        <div style={{ display: 'none' }}>
          <CSVLink
            filename={`WEYONJE CUSTOMERS SUMMARY(${moment().format(
              'DD-MM-YYYY'
            )}).csv`}
            hidden
            data={csvDataForDownload}
          >
            <span ref={csvDownloaderRef}>Downloader</span>
          </CSVLink>
        </div>

        <div className='customers-table'>
          <Table
            rowKey={(record) => record._id}
            loading={loading}
            dataSource={filteredData}
            key={Math.random()}
            pagination={{
              defaultPageSize: 5,
              showSizeChanger: true,
              pageSizeOptions: ['5', '8', '10', '20', '30'],
            }}
          >
            <Column
              title={<b>Customer Source</b>}
              key={Math.random()}
              onFilter={(val, record) => {
                let text = '';
                const isVoucherCustomer = record.isVoucherCustomer;
                const isFromApp = record.locations.length;
                const isFromCallCenter =
                  !!record.location && !!record.facility_type;
                const isFromDriver = !!record.location;
                text = record.source
                  ? record.source
                  : isVoucherCustomer
                  ? 'Voucher job'
                  : isFromApp
                  ? 'App customer'
                  : isFromCallCenter
                  ? 'Call center job'
                  : isFromDriver
                  ? 'SP app job'
                  : 'Source is unknown';

                if (text === val) return record;
              }}
              filters={[
                { text: 'Voucher job', value: 'Voucher job' },
                { text: 'App customer', value: 'App customer' },
                { text: 'Call center job', value: 'Call center job' },
                { text: 'SP app job', value: 'SP app job' },
                { text: 'Source is unknown', value: 'Source is unknown' },
              ]}
              render={(data, record) => {
                let text = '';
                const isVoucherCustomer = record.isVoucherCustomer;
                const isFromApp = record.locations.length;
                const isFromCallCenter =
                  !!record.location && !!record.facility_type;
                const isFromDriver = !!record.location;
                text = record.source
                  ? record.source
                  : isVoucherCustomer
                  ? 'Voucher job'
                  : isFromApp
                  ? 'App customer'
                  : isFromCallCenter
                  ? 'Call center job'
                  : isFromDriver
                  ? 'SP app job'
                  : 'Source is unknown';

                return text;
              }}
            />

            <Column
              title={<b>Phone Number</b>}
              dataIndex='phone_number'
              key={Math.random()}
              render={(data, record) => {
                let phone_number = record?.phone_number;
                if (phone_number) {
                  phone_number = phone_number.replace('+256', '0');
                } else {
                  phone_number = 'None provided';
                }

                return phone_number;
              }}
            />

            <Column
              title={<b>Customer Name</b>}
              dataIndex='full_name'
              key={Math.random()}
            />
            <Column
              title={<b>Customer Address</b>}
              render={(data, record) => {
                let address = record?.location?.address || 'None Provided';
                return address;
              }}
              key={Math.random()}
            />
            <Column
              title={<b>Division</b>}
              render={(data, record) => {
                let division =
                  record?.location?.division ||
                  record?.division ||
                  'None Provided';
                return division;
              }}
              onFilter={
                !isDO
                  ? (val, record) => {
                      let division =
                        record?.location?.division ||
                        record?.division ||
                        'None Provided';

                      if (val.toLowerCase() === division.toLowerCase())
                        return record;
                    }
                  : null
              }
              filters={
                !isDO
                  ? [
                      { text: 'Nakawa', value: 'Nakawa' },
                      { text: 'Central', value: 'Central' },
                      { text: 'Makindye', value: 'Makindye' },
                      { text: 'Rubaga', value: 'Rubaga' },
                      { text: 'Kawempe', value: 'Kawempe' },
                    ]
                  : null
              }
              key={Math.random()}
            />
            <Column
              title={<b>INFORMAL SETTLEMENT</b>}
              render={(data, record) => {
                let division =
                  record?.location?.informal_settlement?.name || 'N/A';
                return division;
              }}
              key={Math.random()}
            />

            <Column
              title={<b>Facility Type</b>}
              key={Math.random()}
              onFilter={(val, record) => {
                if (record.facility_type === val) return record;
                if (!record.facility_type && val === 'None Yet') return record;
              }}
              filters={[
                { text: 'Pit Latrine', value: 'Pit Latrine' },
                {
                  text: 'Lined pit latrine with slab',
                  value: 'Lined pit latrine with slab',
                },
                {
                  text: 'Unlined pit latrine with slab',
                  value: 'Unlined pit latrine with slab',
                },
                {
                  text: 'Flush toilet connected to pit latrine',
                  value: 'Flush toilet connected to pit latrine',
                },
                {
                  text: 'Flush toilet connected to septic tank',
                  value: 'Flush toilet connected to septic tank',
                },
                { text: 'Lined', value: 'Lined' },
                { text: 'Unlined', value: 'Unlined' },
                { text: 'Composting toilet', value: 'Composting toilet' },
                { text: "I don't know", value: "I don't know" },
                { text: 'None Yet', value: 'None Yet' },
              ]}
              render={(text, record) => (
                <Space size='middle' key={Math.random()}>
                  <div className='customer-table-end'>
                    <div className='customer-table-end-label'>
                      {record.facility_type || 'None Yet'}
                    </div>
                    <div className='customer-table-end-icon'>
                      <FiChevronRight size={20} color={Colors.textGray} />
                    </div>
                  </div>
                </Space>
              )}
            />
          </Table>
        </div>
      </div>
    </div>
  );
};

export default Customers;
