import React, { useState, useEffect } from "react";
import Button from "../../../components/Button";
import { FiChevronRight } from "react-icons/fi";
import { Table, Space } from "antd";

import { Colors } from "../../../config";
import * as utils from "../../../redux/utils/componentUtils";
import { CSVLink } from "react-csv";
import AddSpModal from "./../VoucherModals/AddSpModal";
import SPTable from "./../../ServiceProviders";

// Redux
import { useSelector, useDispatch } from "react-redux";
import * as spActions from "../../../redux/actions/spActions";
import makeID from "../../../redux/utils/makeID";
import moment from "moment";

const { Column } = Table;

const CATsVHTs = ({ history, isDO }) => {
  return (
    <React.Fragment>
      <SPTable history={history} isDO={isDO} />
    </React.Fragment>
  );
};

export default CATsVHTs;

/**
 {
    "_id": "5fdb49ef605eef74a93465e7",
    "no_of_jobs": 1,
    "no_of_dumps_new": 0,
    "no_of_dumps_old": 0,
    "ent_id": 300,
    "full_name": "MUHUMUZA ABDALLAH VUNI ",
    "division": "Other",
    "phone_number": "0705734660",
    "type": "Gulper",
    "last_seen": "2020-12-17T18:13:23.272Z"
}
 */
