import React, { useState, useEffect } from "react";
import { Button as AntDBtn, Modal, Spin } from "antd";
import Colors from "../../../config/Colors";

const DeleteModal = ({
  visible,
  setVisible,
  onDelete,
  deleteText,
  loading,
}) => {
  // const loading = true;
  const handleDelete = () => {
    if (onDelete) onDelete();
  };

  return (
    <Modal
      visible={visible}
      title={<b style={{ color: Colors.textGray }}>DELETE {deleteText}</b>}
      onOk={handleDelete}
      onCancel={setVisible}
      footer={[
        <AntDBtn key="logout" onClick={setVisible} className="logout-modal-yes">
          Cancel
        </AntDBtn>,
        <AntDBtn
          key="cancel"
          type="primary"
          onClick={onDelete}
          color={Colors.actionRed}
          className="logout-modal-no"
        >
          Delete
        </AntDBtn>,
      ]}
    >
      <Spin spinning={loading}>
        <div style={{ marginBottom: "30px" }}>
          <p style={{ color: Colors.darkText }}>
            Are you sure you want to delete {deleteText}? This action is
            irreversible
          </p>
        </div>
      </Spin>
    </Modal>
  );
};

export default DeleteModal;
