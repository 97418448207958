import logo from "./logo.svg";
// import "./App.css";
import "antd/dist/antd.css";
import "react-multi-date-picker/styles/colors/green.css";
import "./styles/main.scss";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import DashboardLayout from "./views/DashboardLayout";
import Login from "./views/Login";
// Redux
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import returnStoreAndPersistor from "./redux";

// Persist the redux store
const { store, persistor } = returnStoreAndPersistor();

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <div className="App">
            <Switch>
              <Route
                exact
                path={"/"}
                render={(props) => <Login {...props} />}
              />

              <Route
                exact
                path={"/dashboard/:tab"}
                render={(props) => {
                  return <DashboardLayout {...props} />;
                }}
              />
            </Switch>
          </div>
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
