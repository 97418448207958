import React, { useState, useEffect } from "react";
import { Button as AntDBtn, Modal, Spin } from "antd";
import Colors from "../../../config/Colors";

const Activate = ({
  visible,
  setVisible,
  onActivate,
  deactivationTxt,
  loading,
}) => {
  const handleActivate = () => {
    if (onActivate) onActivate();
  };

  return (
    <Modal
      visible={visible}
      title={<b style={{ color: Colors.textGray }}>ACTIVATE CAT/VHT</b>}
      onOk={handleActivate}
      onCancel={setVisible}
      footer={[
        <AntDBtn
          key="cancel"
          type="primary"
          danger
          onClick={onActivate}
          color={Colors.actionRed}
          style={{}}
        >
          Activate
        </AntDBtn>,
        <AntDBtn
          key="logout"
          type="primary"
          onClick={setVisible}
          className="logout-modal-no"
        >
          Cancel
        </AntDBtn>,
      ]}
    >
      <Spin spinning={loading}>
        <div style={{ marginBottom: "30px" }}>
          <p style={{ color: Colors.darkText }}>
            Activating the account means the CAT/VHT will be able to use the
            CATS App.
          </p>
        </div>
      </Spin>
    </Modal>
  );
};

export default Activate;
