import React, { useState, useEffect } from "react";
import { Button as AntDBtn, Modal, Spin } from "antd";
import Colors from "../../../config/Colors";

const UpdateVPModal = ({
  visible,
  setVisible,
  onAction,
  loading,
  title,
  actionBtnTxt,
  actionTxt,
}) => {
  const handleAction = () => {
    if (onAction) onAction();
  };

  return (
    <Modal
      visible={visible}
      title={<b style={{ color: Colors.textGray }}>{title}</b>}
      onOk={handleAction}
      onCancel={setVisible}
      footer={[
        <AntDBtn
          key="cancel"
          type="primary"
          danger
          onClick={onAction}
          color={Colors.actionRed}
          style={{}}
        >
          {actionBtnTxt}
        </AntDBtn>,
        <AntDBtn
          key="logout"
          type="primary"
          onClick={() => setVisible(false)}
          className="logout-modal-no"
        >
          Cancel
        </AntDBtn>,
      ]}
    >
      <Spin spinning={loading}>
        <div style={{ marginBottom: "30px" }}>
          <p style={{ color: Colors.darkText }}>{actionTxt}</p>
        </div>
      </Spin>
    </Modal>
  );
};

export default UpdateVPModal;
