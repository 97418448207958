import React, { useState, useEffect } from "react";
import { Doughnut } from "react-chartjs-2";

const VoucherChart = ({ dataset, colors, size, title, icon }) => {
  const chartColors = dataset.map((item) => item.color);
  const options = {
    cutout: "75%",

    plugins: {
      legend: {
        display: false,
      },
    },
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
  };

  const data = {
    labels: dataset.map((item) => item.label),
    datasets: [
      {
        data: dataset.map((item) => item.number),
        backgroundColor: chartColors,
        hoverBackgroundColor: chartColors,
        // circumference: size,
      },
    ],
  };
  return (
    <div className="voucher-chart-container">
      <div className="voucher-chart-flex">
        <div className="voucher-chart-left">
          <div className="voucher-chart-title">
            {icon ? icon : ""}
            <h2>{title}</h2>
          </div>
          <style>{`
        .voucher-chart-left-canvas-container canvas {
            width: ${size}px !important;
            height: ${size}px !important;
          }

        }
      `}</style>

          <div className="voucher-chart-left-canvas-container">
            {options ? (
              <Doughnut
                data={data}
                options={options}
                width={size}
                height={size}
              />
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="voucher-chart-right">
          {dataset.map((item) => (
            <div className="voucher-chart-label">
              <div
                className="voucher-chart-label-left"
                style={{ backgroundColor: item.color }}
              >
                <h3>{item.number}</h3>
              </div>
              <div
                className="voucher-chart-label-right"
                style={{ backgroundColor: `${item.color}33` }}
              >
                {item.labelAlt ? item.labelAlt : item.label}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default VoucherChart;
