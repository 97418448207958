import React, { useState, useEffect } from 'react';
import CustomInput from './../../components/CustomInput';
import {
  AiOutlineMail,
  AiOutlineEye,
  AiOutlineEyeInvisible,
} from 'react-icons/ai';

import { Colors } from '../../config';
import TouchableOpacity from './../../components/TouchableOpacity';
import Button from './../../components/Button';
import Checkbox from './../../components/Checkbox';
import PageAddresses from '../../config/PageAddresses';
import { notification } from 'antd';
import LoginTimeoutModal from './LoginTimeoutModal';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import * as adminActions from './../../redux/actions/adminActions';
import * as divisionActions from './../../redux/actions/divisionActions';
import makeID from './../../redux/utils/makeID';

const LoginBasic = ({ history, switchTab }) => {
  // Redux
  const dispatch = useDispatch();
  const adminStatusMessages = useSelector(
    (state) => state.admin.statusMessages
  );
  const [statusID, setStatusID] = useState('');
  const loading = useSelector((state) => state.admin.loading);

  // State
  const [email_address, setEmail_address] = useState('');
  const [password, setPassword] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [isDO, setIsDO] = useState(false);
  const [loginTimeout, setLoginTimeout] = useState(null);
  const [showLoginTimeout, setShowLoginTimeout] = useState(false);

  const startLogin = () => {
    const loginObject = {
      email_address,
      password,
      ...(isDO && { isDO }),
    };

    const newStatusID = makeID(8);
    setLoginTimeout(
      setTimeout(() => {
        setStatusID('');
        dispatch(adminActions.clearLogin());
        setShowLoginTimeout(true);
      }, 15000)
    );

    setStatusID(newStatusID);
    dispatch(adminActions.loginAdmin(loginObject, newStatusID));
  };
  const loginUser = (e) => {
    e.preventDefault();
    startLogin();
  };

  const redoLogin = () => {
    setShowLoginTimeout(false);
    startLogin();
  };

  const showLoginApproval = () => {
    dispatch(divisionActions.getAllDivisions());
    notification.success({
      message: 'Success!',
      description: "You've logged in successfully",
      onClick: () => {},
      onClose: () => {},
      duration: 2,
    });
    if (loginTimeout) clearTimeout(loginTimeout);
  };

  const showLoginFailure = (msg) => {
    const defaultMsg = 'Login Failed, Please try again';
    if (msg && msg.includes('An unknown error occurred')) msg = defaultMsg;
    notification.error({
      message: 'Oops!',
      description: msg || defaultMsg,
      onClick: () => {},
      duration: 2,
    });
  };

  const switchActiveTab = () => switchTab(2);
  useEffect(() => {
    const result = adminStatusMessages.find((it) => it.statusID === statusID);

    if (!result) return;

    if (result?.success) {
      showLoginApproval();
    } else {
      showLoginFailure(result.error || result.message);
    }

    setStatusID('');
  }, [adminStatusMessages]);

  return (
    <React.Fragment>
      <LoginTimeoutModal
        visible={showLoginTimeout}
        setVisible={setShowLoginTimeout}
        redoLogin={redoLogin}
      />
      <form onSubmit={loginUser}>
        <CustomInput
          placeHolder='Email Address'
          type='email'
          // style={{ width: "8rem" }}
          required={true}
          value={email_address}
          receiveOutput={setEmail_address}
          iconRight={<AiOutlineMail size={20} color={Colors.textGray} />}
        />

        <CustomInput
          placeHolder='Password'
          type={!passwordVisible ? 'password' : 'text'}
          // style={{ width: "8rem" }}
          required={true}
          value={password}
          receiveOutput={setPassword}
          iconRight={
            <TouchableOpacity
              clicked={() => setPasswordVisible(!passwordVisible)}
            >
              {passwordVisible ? (
                <AiOutlineEye size={20} color={Colors.textGray} />
              ) : (
                <AiOutlineEyeInvisible size={20} color={Colors.textGray} />
              )}
            </TouchableOpacity>
          }
        />

        <Button
          color={email_address && password ? Colors.darkGreen : Colors.hrGray}
          disabled={email_address && password ? false : true}
          type='submit'
          loading={loading}
        >
          <span style={{ color: Colors.white }}>Login</span>
        </Button>
        <TouchableOpacity clicked={switchActiveTab}>
          <div className='login-clickable-text'>Forgot Password?</div>
        </TouchableOpacity>

        <Checkbox checked={isDO} onCheck={() => setIsDO(!isDO)}>
          <p style={{ color: Colors.textGray, margin: '0px' }}>
            I am a Division Officer / Region User
          </p>
        </Checkbox>
      </form>
    </React.Fragment>
  );
};

export default LoginBasic;
