import * as dumpTypes from "../types/dumpTypes";
import { dumpEndpoints } from "../endpoints";
import * as fetchFunctions from "../utils/fetchFunctions";
import * as fetchFunctionsLegacy from "../utils/fetchFunctionsLegacy";

export const getAllDumps = (statusID) => async (dispatch, getState) => {
  dispatch({
    type: dumpTypes.LOADING_DUMPS,
  });

  const { token } = getState().admin;

  const result = await fetchFunctionsLegacy.getData(
    dumpEndpoints.getAll,
    token
  );

  dispatch({
    type: dumpTypes.GET_ALL_DUMPS,
    payload: result,
    statusID,
  });
};
