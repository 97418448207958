import React, { useState, useEffect } from "react";
import CustomInput from "./../../components/CustomInput";
import WeyonLogo from "./../../assets/logos/weyonje-logo.png";
import {
  AiOutlineMail,
  AiOutlineEye,
  AiOutlineEyeInvisible,
} from "react-icons/ai";

import { Colors } from "../../config";
import TouchableOpacity from "./../../components/TouchableOpacity";
import Button from "./../../components/Button";
import Checkbox from "./../../components/Checkbox";
import LoginBasic from "./LoginBasic";
import LoginForgotPassword from "./LoginForgotPassword";
import PageAddresses from "../../config/PageAddresses";

// Redux
import { useSelector, useDispatch } from "react-redux";

const Login = ({ history }) => {
  const userIsLoggedIn = !!useSelector((state) => state.admin.token);
  const tabs = {
    1: <LoginBasic />,
    2: <LoginForgotPassword />,
  };

  const [activeTab, setActiveTab] = useState(tabs[1]);

  const changeActiveTab = (num) => {
    setActiveTab(tabs[num]);
  };

  const goHome = () => {
    history.push(PageAddresses.dashboard.home.path);
  };

  useEffect(() => {
    if (userIsLoggedIn) {
      goHome();
    }
  }, [userIsLoggedIn]);

  return (
    <div className="login-page">
      <div className="login-form-container">
        <img src={WeyonLogo} className="login-logo" />
        {React.cloneElement(activeTab, {
          switchTab: changeActiveTab,
          history: history,
        })}
      </div>
    </div>
  );
};

export default Login;
