import * as spTypes from "../types/spTypes";
import StatusMessage from "../models/statusMessage";

const initialState = {
  sps: [],
  loading: false,
  sp: {},
  token: "",
  statusMessages: [],
};

const spReducer = (state = initialState, action) => {
  switch (action.type) {
    case spTypes.GET_SP:
      return {
        ...state,
        loading: false,
        sp: action.payload?.data || state.sp,
        statusMessages: [
          ...state.statusMessages,
          new StatusMessage({ subject: spTypes.GET_SP, action }),
        ],
      };

    case spTypes.PUT_SP_IN_STATE:
      return {
        ...state,
        loading: false,
        sp: action.payload || state.sp,
      };

    case spTypes.ADD_SP:
      return {
        ...state,
        loading: false,
        sp: action.payload?.data?.data || state.sp,
        statusMessages: [
          ...state.statusMessages,
          new StatusMessage({ subject: spTypes.ADD_SP, action }),
        ],
      };

    case spTypes.UPDATE_SP:
      return {
        ...state,
        loading: false,
        sp: action.payload?.data?.data || state.sp,
        statusMessages: [
          ...state.statusMessages,
          new StatusMessage({ subject: spTypes.UPDATE_SP, action }),
        ],
      };

    case spTypes.DELETE_SP:
      return {
        ...state,
        loading: false,
        statusMessages: [
          ...state.statusMessages,
          new StatusMessage({ subject: spTypes.DELETE_SP, action }),
        ],
      };

    case spTypes.GET_NEAREST_SP:
      return {
        ...state,
        loading: false,
        sp: action.payload?.data?.data || state.sp,
        statusMessages: [
          ...state.statusMessages,
          new StatusMessage({ subject: spTypes.GET_NEAREST_SP, action }),
        ],
      };

    case spTypes.GET_ALL_SPS:
      return {
        ...state,
        loading: false,
        sps: action.payload?.data?.data || state.sps,
        statusMessages: [
          ...state.statusMessages,
          new StatusMessage({ subject: spTypes.GET_ALL_SPS, action }),
        ],
      };

    case spTypes.LOADING_SPS:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};

export default spReducer;
