import * as divisionTypes from '../types/divisionTypes';
import StatusMessage from '../models/statusMessage';

const initialState = {
  divisions: [],
  loading: false,
  division: {},
  statusMessages: [],
};

const divisionReducer = (state = initialState, action) => {
  switch (action.type) {
    case divisionTypes.GET_DIVISION:
      return {
        ...state,
        loading: false,
        division: action.payload || state.division,
        statusMessages: [
          ...state.statusMessages,
          new StatusMessage({ subject: divisionTypes.GET_DIVISION, action }),
        ],
      };

    case divisionTypes.PUT_DIVISION_IN_STATE:
      return {
        ...state,
        loading: false,
        division: action.payload || state.division,
      };

    case divisionTypes.ADD_DIVISION:
      return {
        ...state,
        loading: false,
        statusMessages: [
          ...state.statusMessages,
          new StatusMessage({
            subject: divisionTypes.ADD_DIVISION,
            action,
          }),
        ],
      };

    case divisionTypes.GET_ALL_DIVISIONS:
      return {
        ...state,
        loading: false,
        divisions: action.payload?.data || state.divisions,
        statusMessages: [
          ...state.statusMessages,
          new StatusMessage({
            subject: divisionTypes.GET_ALL_DIVISIONS,
            action,
          }),
        ],
      };

    case divisionTypes.LOADING_DIVISIONS:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};

export default divisionReducer;
