import * as customerTypes from "../types/customerTypes";
import StatusMessage from "../models/statusMessage";

const initialState = {
  customers: [],
  loading: false,
  customer: {},
  token: "",
  statusMessages: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case customerTypes.GET_CUSTOMER:
      return {
        ...state,
        loading: false,
        customer: action.payload || state.customer,
        statusMessages: [
          ...state.statusMessages,
          new StatusMessage({ subject: customerTypes.GET_CUSTOMER, action }),
        ],
      };

    case customerTypes.ADD_CUSTOMER:
      return {
        ...state,
        loading: false,
        customer: action.payload || state.customer,
        statusMessages: [
          ...state.statusMessages,
          new StatusMessage({ subject: customerTypes.ADD_CUSTOMER, action }),
        ],
      };

    case customerTypes.GET_ALL_CUSTOMERS:
      return {
        ...state,
        loading: false,
        customers: action.payload?.data
          ? action.payload.data.reverse()
          : state.customers,
        statusMessages: [
          ...state.statusMessages,
          new StatusMessage({
            subject: customerTypes.GET_ALL_CUSTOMERS,
            action,
          }),
        ],
      };

    case customerTypes.LOADING_CUSTOMERS:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}
