import React, { useState, useEffect } from 'react';
import { Colors } from '../../../config';
import Button from './../../../components/Button';
import { HiOutlineTicket } from 'react-icons/hi';
import { Table, Space, notification } from 'antd';
import UpdateVPModal from './../VoucherModals/UpdateVPModal';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import * as voucherActions from '../../../redux/actions/voucherActions';
import AddVoucherProgramModal from './../VoucherModals/AddVoucherProgramModal';
import AddDivisionModal from './../VoucherModals/AddDivisionModal';
import makeID from '../../../redux/utils/makeID';
import moment from 'moment';

const { Column } = Table;
const defaultLabels = [
  {
    name: 'Approved',
    number: 0,
    color: Colors.darkGreen,
  },
  {
    name: 'Declined',
    number: 0,
    color: Colors.actionRed,
  },
  {
    name: 'Pending',
    number: 0,
    color: Colors.hrGray,
  },
  {
    name: 'Redeemed',
    number: 0,
    color: Colors.yellow,
  },
];

const Settings = () => {
  // State
  const [labels, setLabels] = useState(defaultLabels);
  const [showAddProgram, setShowAddProgram] = useState(false);
  const [showAddDivision, setShowAddDivision] = useState(false);
  const [showRecountModal, setShowRecountModal] = useState(false);
  const [showUpdateVPModal, setShowUpdateVPModal] = useState(false);
  const [isDeclineVP, setIsDeclineVP] = useState(false);
  const [updateID, setUpdateID] = useState('');
  const [recountID, setRecountID] = useState('');
  const userRole = useSelector((state) => state.admin.role);
  const [selectedVP, setSelectedVP] = useState({});
  const canApprove = userRole === 'finance';
  const activeProgramInState = useSelector(
    (state) => state.voucher.activeProgram
  );
  const [activeProgram, setActiveProgram] = useState(activeProgramInState);

  // Redux
  const dispatch = useDispatch();
  const vouchersInState = useSelector((state) => state.voucher.vouchers);
  const vouchersLoading = useSelector((state) => state.voucher.loading);
  const voucherStatusMessages = useSelector(
    (state) => state.voucher.statusMessages
  );
  const vouchersOverviewInState = useSelector(
    (state) => state.voucher.overview
  );
  const loading = useSelector((state) => state.voucher.loading);

  const handleDeclineVoucherProgram = () => {
    const payload = {
      approved: 2,
      active: false,
    };
    const statusID = makeID(8);
    setUpdateID(statusID);

    dispatch(
      voucherActions.updateVoucherProgram(payload, selectedVP._id, statusID)
    );
  };

  const handleApproveVoucherProgram = () => {
    const payload = {
      approved: 1,
      active: false,
    };
    const statusID = makeID(8);
    setUpdateID(statusID);
    setIsDeclineVP(false);
    dispatch(
      voucherActions.updateVoucherProgram(payload, selectedVP._id, statusID)
    );
  };

  const handleStartRecount = () => {
    const statusID = makeID(8);
    setRecountID(statusID);
    dispatch(voucherActions.recountVouchers(statusID));
  };

  useEffect(() => {
    const isValid =
      Array.isArray(vouchersOverviewInState) && vouchersOverviewInState?.length;
    if (!isValid) return;
    // let declined = 0;
    // let approved = 0;
    // let redeemed = 0;
    // let pending = 0;

    let approved = activeProgram?.approvedVouchers;
    let declined =
      vouchersInState.filter(
        (v) => v.status === 2 && activeProgram.programName === v.voucherProgram
      )?.length || 0;
    let pending =
      vouchersInState.filter(
        (v) => v.status === 0 && activeProgram.programName === v.voucherProgram
      )?.length || 0;

    let redeemed =
      vouchersInState.filter(
        (v) =>
          (v.status === 10 && activeProgram.programName === v.voucherProgram) ||
          (v.status === 6 && activeProgram.programName === v.voucherProgram)
      )?.length || 0;

    const newLabels = [
      {
        name: 'Approved',
        number: approved,
        color: Colors.darkGreen,
      },
      {
        name: 'Declined',
        number: declined,
        color: Colors.actionRed,
      },
      {
        name: 'Pending',
        number: pending,
        color: Colors.hrGray,
      },
      {
        name: 'Redeemed',
        number: redeemed,
        color: Colors.yellow,
      },
    ];

    setLabels(newLabels);
  }, [vouchersOverviewInState]);
  useEffect(() => {
    dispatch(voucherActions.getVouchersOverview());
  }, []);

  useEffect(() => {
    if (!updateID && !recountID) return;
    const result = updateID
      ? voucherStatusMessages.find((it) => it.statusID === updateID)
      : voucherStatusMessages.find((it) => it.statusID === recountID);

    if (result?.success) {
      notification.success({
        message: 'Update',
        description: updateID
          ? 'Voucher Program updated successfully.'
          : 'Vouchers have been recounted',
        duration: 2,
        onClose: () => {
          dispatch(voucherActions.getVouchersOverview());
        },
      });
      setShowRecountModal(false);
      setShowUpdateVPModal(false);
    } else {
      notification.error({
        message: 'Update',
        description: updateID
          ? 'Failed to update Voucher Program. Please try again.'
          : 'Failed to recount vouchers. Please try again',
        onClose: () => {
          dispatch(voucherActions.getVouchersOverview());
        },
        duration: 2,
      });
    }

    setUpdateID('');
    setRecountID('');
  }, [voucherStatusMessages]);

  useEffect(() => {
    if (!activeProgramInState) return;
    setActiveProgram(activeProgramInState);
  }, [activeProgramInState]);

  return (
    <>
      <UpdateVPModal
        visible={showRecountModal}
        loading={vouchersLoading}
        setVisible={setShowRecountModal}
        onAction={handleStartRecount}
        actionBtnTxt={'Recount'}
        actionTxt={
          'Confirm that you want to recount ALL vouchers currently in the system. This will reset the counters to zero then do a fresh count to populate the counters with accurate information.'
        }
        title={'Recount Vouchers'}
      />

      <UpdateVPModal
        visible={showUpdateVPModal}
        loading={vouchersLoading}
        setVisible={setShowUpdateVPModal}
        onAction={
          isDeclineVP
            ? handleDeclineVoucherProgram
            : handleApproveVoucherProgram
        }
        actionBtnTxt={isDeclineVP ? 'Decline' : 'Approve'}
        actionTxt={
          isDeclineVP
            ? 'Confirm that you want to decline this program'
            : 'Confirm that you want to approve this program'
        }
        title={
          isDeclineVP ? 'Decline Voucher Program' : 'Approve Voucher Program'
        }
      />
      <AddVoucherProgramModal
        visible={showAddProgram}
        setVisible={setShowAddProgram}
      />
      <AddDivisionModal
        visible={showAddDivision}
        setVisible={setShowAddDivision}
      />
      <div className='voucher-settings'>
        <div className='voucher-settings-card'>
          <div className='voucher-settings-top-top'>
            <Button
              color={Colors.darkGreen}
              onClick={() => setShowAddProgram(true)}
            >
              <span className='bleach'>Add Voucher Program</span>
            </Button>
            <Button
              color={Colors.darkGreen}
              style={{ marginLeft: '10px' }}
              onClick={() => setShowAddDivision(true)}
            >
              <span className='bleach'>Add Division / Region Program</span>
            </Button>
            <Button
              color={Colors.actionRed}
              style={{ marginLeft: '10px' }}
              onClick={() => setShowRecountModal(true)}
            >
              <span className='bleach'>Recount Vouchers</span>
            </Button>
          </div>
          <div className='voucher-settings-top-bottom'>
            <div className='voucher-settings-top-bottom-title'>
              <HiOutlineTicket size={35} /> <h2>Voucher Programs</h2>
            </div>
            <div className='voucher-settings-top-bottom-labels'></div>
          </div>
        </div>

        <div className='voucher-settings-card'>
          <div className='voucher-settings-labels'>
            {labels.map((item) => (
              <div className='voucher-chart-label'>
                <div
                  className='voucher-chart-label-left'
                  style={{ backgroundColor: item.color }}
                >
                  <h3>{item.number}</h3>
                </div>
                <div
                  className='voucher-chart-label-right'
                  style={{ backgroundColor: `${item.color}33` }}
                >
                  {item.name}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className='voucher-settings-card'>
          <div style={{ marginBottom: '1rem' }}>
            <Button color={Colors.darkGreen}>
              <span style={{ color: Colors.white }}>Export to CSV</span>
            </Button>
          </div>

          <div className='sps-table vouchers-table'>
            <Table
              rowKey={(record) => record._id}
              loading={loading}
              dataSource={vouchersOverviewInState}
              key={Math.random()}
              pagination={{
                defaultPageSize: 5,
                showSizeChanger: true,
                pageSizeOptions: ['5', '8', '10', '20', '30'],
              }}
            >
              <Column
                title={<b>PROGRAM NAME</b>}
                dataIndex='programName'
                key={Math.random()}
                width={'160px'}
              />

              <Column
                title={<b>START DATE</b>}
                dataIndex='startDate'
                key={Math.random()}
                width={'160px'}
                render={(val) => moment(val).format('DD/MM/YYYY')}
              />

              <Column
                title={<b>END DATE</b>}
                dataIndex='endDate'
                key={Math.random()}
                width={'160px'}
                render={(val) => moment(val).format('DD/MM/YYYY')}
              />

              <Column
                title={<b>PARTICIPATING DIVISIONS</b>}
                dataIndex='divisions'
                key={Math.random()}
                width={'160px'}
                render={(val) => val.map((it) => it.divisionName).join(', ')}
              />

              <Column
                title={<b>VOUCHER VALUE</b>}
                dataIndex='defaultVoucherValue'
                key={Math.random()}
                width={'160px'}
                render={(val) => `UGX ${val}`}
              />

              <Column
                title={<b>VOUCHERS PER DIVISION</b>}
                dataIndex='maxVouchersPerDivision'
                key={Math.random()}
                width={'160px'}
              />

              <Column
                title={<b>VOUCHER VALIDITY</b>}
                dataIndex='defaultVoucherValidity'
                key={Math.random()}
                width={'160px'}
              />

              <Column
                title={<b>PROGRAM VALUE</b>}
                dataIndex='totalPeriodVouchersValue'
                key={Math.random()}
                width={'160px'}
              />

              <Column
                title={<b>STATUS</b>}
                dataIndex='approved'
                key={Math.random()}
                width={'160px'}
                render={(val) => (val === 1 ? 'Yes' : 'No')}
              />

              <Column
                title={<b>ACTION</b>}
                key={Math.random()}
                width={'160px'}
                render={(text, record) => (
                  <Space size='middle' key={Math.random()}>
                    {!record.approved && canApprove ? (
                      <div style={{ display: 'flex' }}>
                        <Button
                          color={Colors.darkGreen}
                          onClick={() => {
                            setIsDeclineVP(false);
                            setShowUpdateVPModal(true);
                            setSelectedVP(record);
                          }}
                        >
                          <p
                            style={{
                              color: Colors.white,
                              margin: '0px',
                              minWidth: '100px',
                            }}
                          >
                            Approve
                          </p>
                        </Button>
                        <Button
                          color={Colors.actionRed}
                          onClick={() => {
                            setIsDeclineVP(true);
                            setShowUpdateVPModal(true);
                            setSelectedVP(record);
                          }}
                        >
                          <p
                            style={{
                              color: Colors.white,
                              margin: '0px',
                              minWidth: '100px',
                            }}
                          >
                            Decline
                          </p>
                        </Button>
                      </div>
                    ) : (
                      <Button
                        color={Colors.darkGreen}
                        onClick={() => {}}
                        disabled
                      >
                        <p
                          style={{
                            color: Colors.white,
                            margin: '0px',
                            minWidth: '100px',
                          }}
                        >
                          {record.approved
                            ? 'Already Approved'
                            : 'Awaiting Finance Approval'}
                        </p>
                      </Button>
                    )}
                  </Space>
                )}
              />
            </Table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Settings;
