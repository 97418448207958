import React, { useState, useEffect } from "react";
import {
  Button as AntDBtn,
  Modal,
  Spin,
  Select,
  Input,
  notification,
} from "antd";
import Colors from "../../../config/Colors";

// Redux
import { useSelector, useDispatch } from "react-redux";
import * as driverActions from "../../../redux/actions/driverActions";
import * as voucherActions from "../../../redux/actions/voucherActions";
import * as spActions from "../../../redux/actions/spActions";
import * as jobActions from "../../../redux/actions/jobActions";
import makeID from "../../../redux/utils/makeID";

const { Option } = Select;

const ScheduleVoucherModal = ({ vouchers, visible, setVisible, voucher }) => {
  // State
  const [scheduleTime, setScheduleTime] = useState("");
  const defaultServiceProvider = "Available Service Providers";
  const [serviceProviderName, setServiceProviderName] = useState(
    defaultServiceProvider
  );
  const [scheduleStatus, setScheduleStatus] = useState("");
  const [serviceProvider, setServiceProvider] = useState("");

  // Redux
  const adminID = useSelector((state) => state.admin.id);
  const dispatch = useDispatch();
  const availableDrivers = useSelector(
    (state) => state.driver.availableDrivers
  );
  const spsLoading = useSelector((state) => state.sp.loading);
  const catsLoading = useSelector((state) => state.cats.loading);
  const driverLoading = useSelector((state) => state.driver.loading);
  const vouchersInState = useSelector((state) => state.voucher.vouchers);
  const jobStatusMessages = useSelector((state) => state.jobs.statusMessages);
  const spsInState = useSelector((state) => state.sp.sps);
  const actualVouchers = vouchers
    ? vouchersInState.filter((it) => {
        if (vouchers.includes(it._id)) return it;
      })
    : null;
  const loading = spsLoading || catsLoading || driverLoading;
  const handleAvailableSp = (spID) => {
    const chosenSP = spsInState.find((dr) => dr._id == spID);
    setServiceProviderName(chosenSP.full_name);
    setServiceProvider(chosenSP);
  };

  const handleScheduleVouchers = () => {
    const scheduleStatusOne = makeID(8);

    let data = [];

    if (actualVouchers?.length) {
      actualVouchers.forEach((vch) => {
        const jobData = {
          full_name: vch?.beneficiary,
          phone_number: vch?.beneficiaryNumber,
          address: vch?.address,
          facility_type: vch?.facilityType,
          long: vch?.lng,
          lat: vch?.lat,
          id: adminID,
          auto_dispatch: false,
          isVoucherJob: true,
        };

        data.push({ jobData, voucherID: vch._id });
      });
    } else if (voucher) {
      data = [
        {
          jobData: {
            full_name: voucher?.beneficiary,
            phone_number: voucher?.beneficiaryNumber,
            address: voucher?.address,
            facility_type: voucher?.facilityType,
            long: voucher?.lng,
            lat: voucher?.lat,
            id: adminID,
            auto_dispatch: false,
            isVoucherJob: true,
          },
          voucherID: voucher._id,
        },
      ];
    }

    setScheduleStatus(scheduleStatusOne);
    dispatch(
      jobActions.scheduleJobs(
        serviceProvider?._id,
        data,
        scheduleTime,
        scheduleStatusOne
      )
    );
  };

  useEffect(() => {
    if (!scheduleStatus) return;

    const result = jobStatusMessages.find(
      (it) => it.statusID === scheduleStatus
    );
    const message = result?.error || result?.message;

    if (result?.success) {
      notification.success({
        message: "Update",
        description: "Your voucher has successfully been scheduled",
        onClick: () => {},
        duration: 2,
        onClose: () => {
          setVisible(false);
          dispatch(voucherActions.getAllVouchers());
        },
      });
    } else {
      notification.error({
        message: "Update",
        description: message,
        onClick: () => {},
        duration: 2,
      });
    }
    setScheduleStatus("");
  }, [jobStatusMessages]);

  useEffect(() => {
    if (!visible) return;

    dispatch(spActions.getAllSps());
    dispatch(driverActions.getAvailableDrivers());
  }, [visible]);

  return (
    <Modal
      visible={visible}
      title={
        <b style={{ color: Colors.textGray }}>
          SCHEDULE {vouchers ? "VOUCHERS" : "VOUCHER"}
        </b>
      }
      onOk={handleScheduleVouchers}
      onCancel={() => setVisible(false)}
      footer={[
        <AntDBtn key="cancel" onClick={() => setVisible(false)}>
          Cancel
        </AntDBtn>,
        <AntDBtn
          key="schedule"
          type="primary"
          onClick={handleScheduleVouchers}
          className="logout-modal-no"
        >
          Schedule
        </AntDBtn>,
      ]}
    >
      <div className="dp-input-container">
        <Spin spinning={loading}>
          <Input
            type="date"
            value={scheduleTime}
            onChange={(val) => setScheduleTime(val.target.value)}
            placeholder="Choose a date"
            style={{ marginBottom: 20 }}
          />

          <Select
            showSearch
            filterOption={(input, option) =>
              option.children
                .join(" ")
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            style={{ width: "100%" }}
            className="dp-input-container-select"
            onChange={handleAvailableSp}
            value={serviceProviderName}
            size="large"
          >
            {spsInState.map((val) => (
              <Option value={val?._id} key={val?._id}>
                {val?.full_name} ({val?.type})
              </Option>
            ))}
          </Select>
        </Spin>

        {vouchers ? (
          <ul style={{ marginTop: "20px", marginLeft: "20px" }}>
            {actualVouchers.map((vch) => (
              <li style={{ listStyleType: "circle", marginBottom: "10px" }}>
                Voucher ID: {vch.voucherReadableID} for beneficiary:{" "}
                {vch.beneficiary}
                <br />
                From CAT: {vch.generatedBy}
              </li>
            ))}
          </ul>
        ) : null}
      </div>
    </Modal>
  );
};

export default ScheduleVoucherModal;
