import searchArray from "./searchArray";
export const getInputVal = (e) => {
  return e.target.value;
};

export const getSum = (...args) => {
  let total = 0;
  args.forEach((num) => (total = Number(total) + Number(num)));

  return total;
};

export const insertCommas = (num) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const search = searchArray;
