import React, { useState } from "react";
import { Colors } from "../../../config";
import { Popover, Button as AntDBtn } from "antd";
import Button from "../../../components/Button";
import ReDispatchPopup from "./../../Jobs/ReDispatchPopup";
import { BiBlock } from "react-icons/bi";
import ScheduleVoucherModal from "./ScheduleVoucherModal";

const ScheduleVoucher = ({
  vouchersList,
  voucher,
  useAntD,
  disabled,
  useDispatch,
  status,
}) => {
  // State
  const [warnScheduleVisible, setWarnScheduleVisible] = useState(false);
  const [showReDispatchModal, setShowReDispatchModal] = useState(false);
  const [scheduleWarning, setScheduleWarning] = useState(null);
  const [showScheduleModal, setShowScheduleModal] = useState(false);
  const isDisabled = disabled || (!voucher && !vouchersList?.length);

  const handleScheduleVoucher = () => {
    if (isDisabled) {
      setWarnScheduleVisible(true);
      return;
    }
    if (useDispatch) {
      setShowReDispatchModal(true);
    } else {
      setShowScheduleModal(true);
    }
  };

  return (
    <div
      onMouseLeave={() => {
        if (warnScheduleVisible) setWarnScheduleVisible(false);
        if (scheduleWarning) clearTimeout(scheduleWarning);
      }}
      onMouseEnter={() => {
        if (!isDisabled) return;
        setScheduleWarning(
          setTimeout(() => {
            setWarnScheduleVisible(true);
          }, 300)
        );
      }}
    >
      {voucher ? (
        <ReDispatchPopup
          voucherDetails={voucher}
          showPopup={showReDispatchModal}
          hidePopup={() => setShowReDispatchModal(false)}
        />
      ) : null}
      {showScheduleModal ? (
        <ScheduleVoucherModal
          visible={showScheduleModal}
          setVisible={setShowScheduleModal}
          vouchers={vouchersList}
          voucher={voucher}
        />
      ) : null}
      <Popover
        content={
          status ? (
            <span>
              Cannot perform action because the status of the voucher is{" "}
              {status}
            </span>
          ) : (
            <span>
              Please select <b>at least one</b> voucher to schedule
            </span>
          )
        }
        title={
          <div style={{ display: "flex", alignItems: "center" }}>
            <BiBlock
              size={20}
              color={Colors.actionRed}
              style={{ marginRight: "7px" }}
            />
            <h4 style={{ margin: "0px" }}>Not Allowed</h4>
          </div>
        }
        visible={warnScheduleVisible}
      >
        {useAntD ? (
          <AntDBtn
            type={"primary"}
            danger={useDispatch}
            ghost={useDispatch}
            onClick={handleScheduleVoucher}
            disabled={isDisabled}
            className="voucher-list-btn"
          >
            {useDispatch ? "Dispatch Now" : "Schedule Voucher Job"}
            {vouchersList?.length ? (
              <div className="voucher-list-icon">{vouchersList.length}</div>
            ) : null}
          </AntDBtn>
        ) : (
          <Button
            color={Colors.blue}
            onClick={handleScheduleVoucher}
            disabled={isDisabled}
            extraClassName="voucher-list-btn"
          >
            <span style={{ color: Colors.white }}>
              {useDispatch ? "Dispatch Now" : "Schedule Voucher Job"}
              {vouchersList?.length ? (
                <div className="voucher-list-icon">{vouchersList.length}</div>
              ) : null}
            </span>
          </Button>
        )}
      </Popover>
    </div>
  );
};

export default ScheduleVoucher;
