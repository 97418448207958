// CRUD Operations
export const CREATE_JOB = "CREATE_JOB";
export const UPDATE_JOB = "UPDATE_JOB";
export const DELETE_JOB = "DELETE_JOB";
export const GET_JOB = "GET_JOB";
export const GET_ALL_JOBS = "GET_ALL_JOBS";
export const GET_JOBS_IN_PROGRESS = "GET_JOBS_IN_PROGRESS";
export const GET_PENDING_JOBS = "GET_PENDING_JOBS";
export const GET_SCHEDULED_JOBS = "GET_SCHEDULED_JOBS";
export const GET_COMPLETED_JOBS = "GET_COMPLETED_JOBS";

export const ADD_JOB = "ADD_JOB";
export const CLEAR_NEW_JOB = "CLEAR_NEW_JOB";
export const SCHEDULE_JOB = "SCHEDULE_JOB";
export const RE_SCHEDULE_JOB = "SCHEDULE_JOB";
export const GET_JOB_SUMMARY = "GET_JOB_SUMMARY";

// Misc
export const LOADING_JOBS = "LOADING_JOBS";
