import * as dumpTypes from "../types/dumpTypes";
import StatusMessage from "../models/statusMessage";

const initialState = {
  dumps: [],
  loading: false,
  dump: {},
  token: "",
  statusMessages: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case dumpTypes.GET_DUMP:
      return {
        ...state,
        loading: false,
        dump: action.payload || state.dump,
        statusMessages: [
          ...state.statusMessages,
          new StatusMessage({ subject: dumpTypes.GET_DUMP, action }),
        ],
      };

    case dumpTypes.ADD_DUMP:
      return {
        ...state,
        loading: false,
        dump: action.payload || state.dump,
        statusMessages: [
          ...state.statusMessages,
          new StatusMessage({ subject: dumpTypes.ADD_DUMP, action }),
        ],
      };

    case dumpTypes.GET_ALL_DUMPS:
      return {
        ...state,
        loading: false,
        dumps: action.payload?.data || state.dumps,
        statusMessages: [
          ...state.statusMessages,
          new StatusMessage({
            subject: dumpTypes.GET_ALL_DUMPS,
            action,
          }),
        ],
      };

    case dumpTypes.LOADING_DUMPS:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}
