import React, { useState, useEffect } from "react";
import { Select, Input, notification } from "antd";
const { Option } = Select;
const VoucherField = ({
  fieldName,
  voucher,
  readableName,
  onEdit,
  editing,
  selectOptions,
  inputType,
  placeholder,
  required,
}) => {
  const [value, setValue] = useState("");
  const [selectValue, setSelectValue] = useState("");

  const handleValChange = (val) => {
    setValue(val || voucher[fieldName]);
    onEdit(
      {
        [fieldName]: val || voucher[fieldName],
      },
      required
    );
  };

  const handleSelectChange = (val) => {
    setSelectValue(val || voucher[fieldName]);
    onEdit(
      {
        [fieldName]: val || voucher[fieldName],
      },
      required
    );
  };

  useEffect(() => {
    setValue(voucher[fieldName]);
    setSelectValue(voucher[fieldName]);
  }, []);

  return editing && !selectOptions ? (
    <div className="sv-bottom-detail-pair">
      <p className="sv-bottom-detail-left ">{readableName}:</p>

      <p className="sv-bottom-detail-right ">
        <Input
          type={inputType || "text"}
          value={value}
          onChange={(val) => handleValChange(val.target.value)}
          placeholder={placeholder}
          style={{ marginBottom: 20 }}
        />
      </p>
    </div>
  ) : editing && selectOptions ? (
    <div className="sv-bottom-detail-pair">
      <p className="sv-bottom-detail-left ">{readableName}:</p>

      <p className="sv-bottom-detail-right ">
        <Select
          showSearch
          filterOption={(input, option) =>
            option.children
              .join(" ")
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
          }
          style={{ width: "100%" }}
          className="dp-input-container-select"
          onChange={handleSelectChange}
          value={selectValue}
          size="large"
        >
          {selectOptions.map((val) => (
            <Option value={val} key={val}>
              {val}
            </Option>
          ))}
        </Select>
      </p>
    </div>
  ) : (
    <div className="sv-bottom-detail-pair">
      <p className="sv-bottom-detail-left ">{readableName}:</p>
      <p className="sv-bottom-detail-right ">{voucher[fieldName]}</p>
    </div>
  );
};

export default VoucherField;
