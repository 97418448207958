import React, { useState, useEffect } from "react";
import { Button as AntDBtn, Modal, Input, notification } from "antd";
import Colors from "../../../config/Colors";

// Redux
import { useSelector, useDispatch } from "react-redux";
import * as voucherActions from "../../../redux/actions/voucherActions";
import makeID from "../../../redux/utils/makeID";
import { insertCommas } from "../../../redux/utils/componentUtils";
import moment from "moment";

const ApproveModal = ({ visible, setVisible, voucher, onAction }) => {
  // Redux
  const dispatch = useDispatch();

  const doID = useSelector((state) => state.admin.officer?._id);
  const vouchersOverviewInState = useSelector(
    (state) => state.voucher.overview
  );
  const activeProgramInState = useSelector(
    (state) => state.voucher.activeProgram
  );
  const [activeProgram, setActiveProgram] = useState(activeProgramInState);

  const [expiryDate, setExpiryDate] = useState("");
  const [expiryDateAlt, setExpiryDateAlt] = useState("");
  const [voucherValue, setVoucherValue] = useState("");

  const handleExpiryDate = (val, altVal) => {
    setExpiryDate(val);
    setExpiryDateAlt(altVal);
  };

  const handleApproveConfirmed = () => {
    if (!expiryDate || !voucherValue) {
      notification.warn({
        message: "Invalid Input!",
        description: "Please give an expiry date and a voucher value",
        onClick: () => {},
        duration: 2,
      });
      return;
    }

    if (!doID) {
      notification.warn({
        message: "Invalid User!",
        description: "You are not a District Officer.",
        onClick: () => {},
        duration: 2,
      });
      return;
    }

    const actionDetails = {
      doID,
      expiryDate,
      status: 1,
      // reasonForDenial: declineReason,
      // spID,
      // jobID,
      // reasonForDeactivation,
      // catsRemarks,
      // officerRemarks,
    };

    onAction(actionDetails, voucher._id);
  };

  useEffect(() => {
    if (!vouchersOverviewInState?.length) return;

    if (!activeProgram) return;
    const actualExpiryDate = moment().add(
      activeProgram.defaultVoucherValidity,
      "days"
    );

    const actualValue = activeProgram.defaultVoucherValue;
    setExpiryDateAlt(actualExpiryDate.format("DD/MM/YYYY"));
    setVoucherValue(actualValue);
    setExpiryDate(new Date(actualExpiryDate));
  }, [visible]);

  useEffect(() => {
    if (!activeProgramInState) return;
    setActiveProgram(activeProgramInState);
  }, [activeProgramInState]);
  return (
    <Modal
      visible={visible}
      title={<b style={{ color: Colors.textGray }}>APPROVE VOUCHER REQUEST</b>}
      onOk={handleApproveConfirmed}
      onCancel={setVisible}
      footer={[
        <AntDBtn key="logout" onClick={setVisible} className="logout-modal-yes">
          Cancel
        </AntDBtn>,
        <AntDBtn
          key="cancel"
          type="primary"
          onClick={handleApproveConfirmed}
          color={Colors.darkGreen}
          className="logout-modal-no"
        >
          Approve
        </AntDBtn>,
      ]}
    >
      <div style={{ marginBottom: "30px" }}>
        <p>
          Approve Voucher for {voucher.beneficiary} worth{" "}
          {insertCommas(voucherValue)} until {expiryDateAlt}.
        </p>
      </div>
    </Modal>
  );
};

export default ApproveModal;
