import React, { useState, useEffect } from "react";
import Button from "./../../components/Button";
import { AiOutlineSearch } from "react-icons/ai";
import { FiChevronRight } from "react-icons/fi";
import { Select, Table, Space } from "antd";
import { RiCalendarTodoFill } from "react-icons/ri";
import CustomDateBtn from "../../components/CustomDateBtn";

import { Colors } from "./../../config";
import * as utils from "./../../redux/utils/componentUtils";
import defaultData from "./defaultData";
import TouchableOpacity from "./../../components/TouchableOpacity";

const { Option } = Select;
const { Column } = Table;

const ServiceProviders = () => {
  const today = new Date();
  const dataSource = defaultData.jobs;
  const [activeTab, setActiveTab] = useState(1);
  const [showDropdown, setShowDropdown] = useState(false);
  const [status, setStatus] = useState("All");
  const [facilityType, setFacilityType] = useState("All");
  const [filteredData, setFilteredData] = useState(dataSource);
  const [searchText, setSearchText] = useState("");

  const changeTab = (tab) => {
    setActiveTab(tab.number);
  };

  const handleSearch = (text) => {
    const result = utils.search(
      text,
      dataSource,
      [
        "fullName",
        "phoneNumber",
        "address",
        "facilityType",
        "spPhoneNumber",
        "spName",
        "status",
      ],
      "dispatchID"
    );

    setFilteredData(result);
  };

  useEffect(() => {
    if (searchText.length) {
      handleSearch(searchText);
    } else {
      setFilteredData(dataSource);
    }
  }, [searchText]);

  return (
    <div className="dps">
      <div className="dps-top">
        <h3>DISPATCHERS</h3>
      </div>
      <div className="dps-bottom">
        <div className="dps-search-row">
          <div className="dps-select-container">
            <p className="dps-select-label">Dumping Location</p>
            <Select
              showSearch
              style={{ width: "100%" }}
              // placeholder={}
              optionFilterProp="Status"
              onChange={(val) => setFacilityType(val)}
              value={facilityType}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value="Bugolobi">Bugolobi</Option>
              <Option value="Lubigi">Lubigi</Option>
            </Select>
          </div>

          <div className="dps-select-container">
            <p className="dps-select-label">Type of Service Provider</p>
            <Select
              showSearch
              style={{ width: "100%" }}
              // placeholder={}
              optionFilterProp="Status"
              onChange={(val) => setFacilityType(val)}
              value={facilityType}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value="Gulper">Gulper</Option>
              <Option value="Emptier">Emptier</Option>
              <Option value="Others">Others</Option>
            </Select>
          </div>

          <div className="dashboard-home-dispatch-button">
            <CustomDateBtn noMargin />
          </div>
        </div>

        <div style={{ marginBottom: "1rem" }} className="dps-export-row">
          <Button color={Colors.darkGreen}>
            <span style={{ color: Colors.white }}>Export to CSV</span>
          </Button>

          <div className="dps-search-container">
            <div className="dps-search-input-wrapper">
              <input
                placeholder="Search full name, phone number, sp type"
                value={searchText}
                onChange={(e) => setSearchText(utils.getInputVal(e))}
              />
            </div>
            <div className="dps-search-icon">
              <AiOutlineSearch color={Colors.textGray} size={20} />
            </div>
          </div>
        </div>

        <div className="dps-table">
          <Table
            rowKey={(record) => record._id}
            // searchable={true}
            // searchableProps={{ fuzzySearch: true }}
            dataSource={filteredData}
            key={Math.random()}
            pagination={{
              defaultPageSize: 5,
              showSizeChanger: true,
              pageSizeOptions: ["5", "8", "10", "20", "30"],
            }}
          >
            <Column
              title={<b>CUSTOMER Name</b>}
              dataIndex="fullName"
              key={Math.random()}
            />
            <Column
              title={<b>CUSTOMER Number</b>}
              dataIndex="phoneNumber"
              key={Math.random()}
            />

            <Column
              title={<b>location</b>}
              dataIndex="address"
              key={Math.random()}
            />

            <Column
              title={<b>Facility Type</b>}
              dataIndex="facilityType"
              key={Math.random()}
            />

            <Column
              title={<b>sp Name</b>}
              dataIndex="spName"
              key={Math.random()}
            />

            <Column
              title={<b>sp Number</b>}
              dataIndex="spPhoneNumber"
              key={Math.random()}
            />

            <Column
              title={<b>status</b>}
              dataIndex="status"
              key={Math.random()}
            />
          </Table>
        </div>
      </div>
    </div>
  );
};

export default ServiceProviders;
