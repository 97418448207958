import React, { useState, useEffect } from "react";

const MapJobMarker = ({ icon, text, color }) => {
  return (
    <div className="map-job-marker">
      <img src={icon} />
      <p>{text}</p>
      {/* <p style={{ color }}>{text}</p> */}
    </div>
  );
};

export default MapJobMarker;
