import * as divisionTypes from '../types/divisionTypes';
import { divisionEndpoints } from '../endpoints';
import * as fetchFunctions from '../utils/fetchFunctions';

export const getAllDivisions = (statusID) => async (dispatch, getState) => {
  dispatch({
    type: divisionTypes.LOADING_DIVISIONS,
  });

  const { token } = getState().admin;

  const result = await fetchFunctions.getData(divisionEndpoints.getAll, token);
  dispatch({
    type: divisionTypes.GET_ALL_DIVISIONS,
    payload: result,
    statusID,
  });
};

export const addDivision = (data, statusID) => async (dispatch, getState) => {
  try {
    dispatch({
      type: divisionTypes.LOADING_DIVISIONS,
    });

    const { token } = getState().admin;

    const result = await fetchFunctions.postData(
      divisionEndpoints.add,
      data,
      token
    );
    dispatch({
      type: divisionTypes.ADD_DIVISION,
      payload: result,
      statusID,
    });
  } catch (error) {
    console.error('Error while creating voucher program', error);
    dispatch({
      type: divisionTypes.ADD_DIVISION,
      payload: { success: 'False', message: 'Something went wrong.' },
      statusID,
    });
  }
};
