import React, { useState, useEffect } from "react";
import { Modal, Button } from "antd";

// Redux
import * as adminActions from "./../../redux/actions/adminActions";
import { useSelector, useDispatch } from "react-redux";
import { Colors, Addresses } from "../../config";

const LogoutModal = ({ visible, setVisible, history }) => {
  const dispatch = useDispatch();

  const handleClose = () => {
    setVisible(false);
  };

  const handleLogout = () => {
    dispatch(adminActions.logoutAdmin());
    history.push(Addresses.onboarding.login.path);
  };
  return (
    <Modal
      visible={visible}
      title={<b style={{ color: Colors.textGray }}>Logout</b>}
      onOk={handleLogout}
      onCancel={handleClose}
      footer={[
        <Button
          key="cancel"
          type="primary"
          onClick={handleClose}
          color={Colors.darkGreen}
          className="logout-modal-no"
        >
          No
        </Button>,
        <Button
          key="logout"
          onClick={handleLogout}
          className="logout-modal-yes"
        >
          Yes
        </Button>,
      ]}
    >
      <p>Do you want to logout now?</p>
    </Modal>
  );
};

export default LogoutModal;
