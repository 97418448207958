import React from "react";
import Fade from "react-reveal/Fade";
const Modal = ({ children }) => {
  return (
    <Fade duration={600}>
      <div className="app-modal">{children}</div>
    </Fade>
  );
};

export default Modal;
