export default (base, prefix, apiVersion) => {
  const defaultEndpoint = "sp";
  return {
    get: `${base}/${prefix}/${defaultEndpoint}`,
    getAll: `${base}/${prefix}/${defaultEndpoint}`,
    getAvailable: `${base}/${prefix}/${defaultEndpoint}/available`,
    delete: `${base}/${prefix}/${defaultEndpoint}`,
    add: `${base}/${prefix}/${defaultEndpoint}`,
  };
};
