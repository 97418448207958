export default {
  textGray: "#808483",
  darkText: "#666666",
  actionRed: "#ef3123",
  inputGray: "#fbfbfb",
  disabledGray: "#d5d8dd",
  borderGray: "#e0e0e0",
  hrGray: "#979797",
  white: "#ffffff",
  black: "#000000",
  borderGray: "#e2e1e1",
  yellow: "#ffdd03",
  darkGreen: "#006c3a",
  backgroundGray: "#f5f5f7",
  hoverGreen: "#f5f9f8",
  darkMaroon: "#760e1a",
  blue: "#458de1",
  darkBackground: "#2f2f2f",
  palestGreen: "#f2f8f6",
  statCardGreen: "#E5F0EB",
  statPurple: "#A877D4",
  statCardPurple: "#F9EFFF",
  statCardBlue: "#EAF9FF",
  weyonLightBlue: "#4ED6FF",
  mapSelected: "#5D4D62",
  barChartGreen: "#80D100",
  transparentGreen: "#006c3a4D",
  gold: "#F2D400",
};
