import React, { useState, useEffect, useRef } from "react";
import Button from "./../../components/Button";
import { AiOutlineSearch } from "react-icons/ai";
import { Select, Table, Space } from "antd";
import { RiCalendarTodoFill } from "react-icons/ri";
import { Colors } from "./../../config";
import * as utils from "./../../redux/utils/componentUtils";
import moment from "moment";
import { CSVLink } from "react-csv";
import JobModal from "./JobModal";
import ReDispatchPopup from "./ReDispatchPopup";
import ScheduleJobModal from "./ScheduleJobModal";
import configureForCSV from "./../../redux/utils/configureForCSV";
import CustomDateBtn from "../../components/CustomDateBtn";
import ExportToCSVModal from "../../components/ExportToCSVModal";

// Redux
import { useSelector, useDispatch } from "react-redux";
import * as jobActions from "./../../redux/actions/jobActions";
import makeID from "./../../redux/utils/makeID";

const { Option } = Select;
const { Column } = Table;

const allFacilityTypes = [
  "Lined",
  "Unlined",
  "Lined pit latrine with slab",
  "Unlined pit latrine without slab",
  "Flush toilet connected to septic tank",
  "Unlined pit latrine with slab",
  "Composting toilet",
  "I don't know",
];

const Jobs = ({ history, isDO }) => {
  const csvDownloaderRef = useRef(null);
  const [csvDataForDownload, setCsvDataForDownload] = useState([]);
  // Redux
  const dispatch = useDispatch();
  const jobStatusMessages = useSelector((state) => state.jobs.statusMessages);
  const jobsInState = useSelector((state) => state.jobs.jobs);
  const jobsSummaryInState = useSelector((state) => state.jobs.summary);
  const [getJobsStatus, setGetJobsStatus] = useState("");
  const userIsLoggedIn = useSelector((state) => state.admin.token);
  const loading = useSelector((state) => state.jobs.loading);

  const getJobs = () => {
    const statusID = makeID(8);

    setGetJobsStatus(statusID);
    dispatch(jobActions.getJobsSummary());
    dispatch(jobActions.getAllJobs(statusID));
  };

  // State
  const today = new Date();
  // const dataSource = defaultData.jobs;
  const dataSource = jobsInState;
  const [activeTab, setActiveTab] = useState(1);
  const [showDropdown, setShowDropdown] = useState(false);
  const [status, setStatus] = useState("All");
  const [facilityType, setFacilityType] = useState("All");
  const [filteredData, setFilteredData] = useState(dataSource);
  const [searchText, setSearchText] = useState("");
  const [currentJob, setCurrentJob] = useState("");
  const [showJobModal, setShowJobModal] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [showReDispatchModal, setShowReDispatchModal] = useState(false);
  const [showScheduleModal, setShowScheduleModal] = useState(false);
  const changeTab = (tab) => {
    setActiveTab(tab.number);
  };

  const jobStatuses = {
    0: "Scheduled",
    1: "In Progress",
    2: "Declined",
    3: "Completed",
  };

  const tabs = {
    1: {
      name: "TOTAL JOBS",
      number: 1,
      stat: jobsSummaryInState?.total || 0,
    },
    2: {
      name: "IN PROGRESS JOBS",
      number: 2,
      stat: jobsSummaryInState?.inProgress || 0,
    },
    3: {
      name: "PENDING JOBS",
      number: jobsSummaryInState?.pendingJobs || 0,
      stat: "49",
    },
    4: {
      name: "COMPLETED JOBS",
      number: 4,
      stat: jobsSummaryInState?.completedJobs || 0,
    },
    5: {
      name: "SCHEDULED JOBS",
      number: 5,
      stat: jobsSummaryInState?.scheduledJobs || 0,
    },
  };

  const handleSearch = (text, data) => {
    const result = utils.search(
      text,
      data,
      [
        "full_name",
        "location",
        "division",
        "parish",
        // "status",
        "finishTime",
      ],
      "job_id"
    );

    setFilteredData(result);
  };

  const csvHeaders = [
    { label: "Job id", key: "job_id" },
    { label: "Customer Name", key: "full_name" },
    { label: "Location", key: "location" },
    { label: "Division", key: "division" },
    { label: "Parish", key: "parish" },
    { label: "Status", key: "status" },
    { label: "Finish Time", key: "finish_time" },
  ];

  const configureEntitiesForCSV = async () => {
    let jobData = jobsInState.sort(
      (a, b) => a.date_of_registration > b.date_of_registration
    );
    const configFields = [
      { delete: "_id" },
      { delete: "villages" },
      { delete: "source" },

      {
        new: "Date Of Job",
        old: "date_of_registration",
        mutation: (val) => moment(val).format("DD/MM/YYYY, hh:mm a"),
      },
      { new: "Job ID", old: "job_id" },
      {
        new: "Source",
        old: "initiated_by",
      },
      { new: "Status", old: "status", mutation: (val) => jobStatuses[val] },
      { new: "Customer Name", old: "full_name" },
      { old: "phone_number", new: "Customer Phone Number" },
      {
        old: "isVoucherJob",
        new: "Is This From A Voucher?",
        mutation: (val) => (val ? "Yes" : "No"),
      },
      { old: "referral_code", new: "Referral Code" },
      { old: "facility_type", new: "Facility Type" },
      {
        new: "Address",
        old: "location",
      },
      {
        new: "Start Time",
        mutation: (val) =>
          val.start_time
            ? moment(val.start_time).format("DD/MM/YYYY, hh:mm a")
            : "Not Started",
        useParent: true,
      },
      { delete: "start_time" },
      {
        new: "Finish Time",
        mutation: (val) =>
          val.finish_time
            ? moment(val.finish_time).format("DD/MM/YYYY, hh:mm a")
            : "Not Finished",
        useParent: true,
      },
      { delete: "finish_time" },
      {
        new: "Parish",
        old: "parish",
      },
      {
        new: "Division",
        old: "division",
      },
      {
        new: "Income Received",
        old: "income_received",
        mutation: (val) => val || "None Yet",
      },
      {
        old: "service_provider",
        new: "Service Provider (SP)",
      },
      {
        old: "service_provider_number",
        new: "SP Phone Number",
      },
      {
        new: "Latitude",
        mutation: (val) => val.start_point_lat || val.lat || "None Provided",
        useParent: true,
      },
      {
        new: "Longitude",
        mutation: (val) => val.start_point_long || val.long || "None Provided",
        useParent: true,
      },
      { delete: "lat" },
      { delete: "long" },
      { delete: "start_point_lat" },
      { delete: "start_point_long" },
    ];

    await configureForCSV(jobData, configFields)
      .then((res) => setCsvDataForDownload(res))
      .then(() => csvDownloaderRef.current.click());
  };

  const getSource = (record) => {
    let val = "None Given";
    if (record.source?.from) {
      val = record.source?.from;
    } else if (record?.source) {
      val = record?.source;
    } else if (record.referral_code !== "No referral code used") {
      val = "CAT's";
    } else if (record.isVoucherJob) {
      val = "CAT's";
    }
    return val;
  };

  useEffect(() => {
    let data = dataSource.sort(
      (a, b) => a.date_of_registration > b.date_of_registration
    );

    // Handle Status Filters
    if (status !== "All") {
      const matchingStatus = Object.entries(jobStatuses).find(
        (st) => st[1] === status
      )[0];

      data = data.filter((j) => `${j.status}` === `${matchingStatus}`);
    }

    if (facilityType !== "All") {
      data = data.filter((j) => `${j.facility_type}` === `${facilityType}`);
    }

    // Handle Search
    if (searchText.length) {
      handleSearch(searchText, data);
    } else {
      setFilteredData(data);
    }
  }, [searchText, status, facilityType]);

  useEffect(() => {
    getJobs();
  }, []);

  useEffect(() => {
    if (!getJobsStatus) return;
    const result = jobStatusMessages.find(
      (it) => it.statusID === getJobsStatus
    );
    setFilteredData(dataSource);
  }, [jobStatusMessages]);

  const handleRedoDispatch = () => {
    setShowJobModal(false);
    setShowReDispatchModal(true);
  };

  const handleReschedule = () => {
    setShowJobModal(false);
    setShowScheduleModal(true);
  };

  return (
    <div className="jobs">
      <JobModal
        visible={showJobModal}
        setVisible={setShowJobModal}
        job={currentJob}
        history={history}
        handleRedoDispatch={handleRedoDispatch}
        handleReschedule={handleReschedule}
      />
      <ScheduleJobModal
        job={currentJob}
        visible={showScheduleModal}
        setVisible={setShowScheduleModal}
      />
      <ReDispatchPopup
        jobDetails={currentJob}
        showPopup={showReDispatchModal}
        hidePopup={() => setShowReDispatchModal(false)}
      />
      <div className="jobs-top">
        <h3>JOBS</h3>
      </div>
      <div className="jobs-bottom">
        <div className="jobs-select-row">
          <div className="jobs-select-container">
            <p className="jobs-select-label">Status</p>
            <Select
              showSearch
              style={{ width: "100%" }}
              // placeholder={}
              optionFilterProp="Status"
              onChange={(val) => setStatus(val)}
              value={status}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value="All">All</Option>
              {Object.values(jobStatuses).map((opt) => (
                <Option value={opt} key={opt}>
                  {opt}
                </Option>
              ))}
            </Select>
          </div>

          <div className="jobs-select-container">
            <p className="jobs-select-label">Facility Type</p>
            <Select
              showSearch
              style={{ width: "100%" }}
              // placeholder={}
              optionFilterProp="Status"
              onChange={(val) => setFacilityType(val)}
              value={facilityType}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value="All">All</Option>
              {Object.values(allFacilityTypes).map((opt) => (
                <Option value={opt} key={opt}>
                  {opt}
                </Option>
              ))}
            </Select>
          </div>

          <div className="jobs-select-container">
            <p className="jobs-select-label">Filter</p>
            <CustomDateBtn />
          </div>
        </div>

        <div className="jobs-tabs">
          {Object.keys(tabs).map((tabNum) => (
            <p
              className={`jobs-tab ${
                activeTab === tabs[tabNum].number ? "jobs-tab-active" : ""
              }`}
              onClick={() => changeTab(tabs[tabNum])}
            >
              {tabs[tabNum].name}: {tabs[tabNum].stat}
            </p>
          ))}
        </div>

        <div className="jobs-search-row">
          <div style={{ marginBottom: "1rem" }}>
            <Button color={Colors.darkGreen} onClick={configureEntitiesForCSV}>
              <span style={{ color: Colors.white }}>Export to CSV</span>
            </Button>
            {/* <ExportToCSVModal/> */}
          </div>

          <div style={{ display: "none" }}>
            <CSVLink
              filename={`WEYONJE JOBS SUMMARY(${moment().format(
                "DD-MM-YYYY"
              )}).csv`}
              hidden
              data={csvDataForDownload}
            >
              <span ref={csvDownloaderRef}>Downloader</span>
            </CSVLink>
          </div>

          <div className="job-search-container">
            <div className="job-search-input-wrapper">
              <input
                placeholder="Search customer name, location, service provider"
                value={searchText}
                onChange={(e) => setSearchText(utils.getInputVal(e))}
              />
            </div>
            <div className="job-search-icon">
              <AiOutlineSearch color={Colors.textGray} size={20} />
            </div>
          </div>
        </div>

        <div className="jobs-table">
          <Table
            rowKey={(record) => record._id}
            loading={loading}
            rowClassName={(record, index) =>
              record.status === 0 || record.status === 1 ? "warning-row" : ""
            }
            // searchable={true}
            // searchableProps={{ fuzzySearch: true }}
            dataSource={filteredData}
            key={Math.random()}
            pagination={{
              defaultPageSize: 5,
              showSizeChanger: true,
              pageSizeOptions: ["5", "8", "10", "20", "30"],
              onChange: (pgNum) => setCurrentPage(pgNum),
              current: currentPage,
            }}
          >
            <Column
              title={<b>Job id</b>}
              dataIndex="job_id"
              key={Math.random()}
            />

            <Column
              title={<b>Job Type</b>}
              dataIndex="source"
              key={Math.random()}
              render={(value, record) => getSource(record)}
            />

            <Column
              title={<b>Customer Name</b>}
              dataIndex="full_name"
              key={Math.random()}
            />
            <Column
              title={<b>Customer Number</b>}
              dataIndex="phone_number"
              key={Math.random()}
              render={(data, record) => {
                let phone_number = record?.phone_number;
                if (phone_number) {
                  phone_number = phone_number.replace("+256", "0");
                } else {
                  phone_number = "None provided";
                }

                return phone_number;
              }}
            />
            <Column
              title={<b>Facility Type </b>}
              dataIndex="facility_type"
              key={Math.random()}
            />

            <Column
              title={<b>Service Provider </b>}
              dataIndex="service_provider"
              key={Math.random()}
            />

            <Column
              title={<b>Service Provider Number</b>}
              dataIndex="service_provider_number"
              key={Math.random()}
            />

            <Column
              title={<b>Location</b>}
              dataIndex="location"
              key={Math.random()}
            />
            <Column
              title={<b>Division</b>}
              dataIndex="division"
              key={Math.random()}
            />
            <Column
              title={<b>Parish</b>}
              dataIndex="parish"
              key={Math.random()}
            />

            <Column
              title={<b>Source</b>}
              dataIndex="source"
              key={Math.random()}
              render={(value, record) => {
                let val = "None Given";
                if (record.source?.from) {
                  val = record.source?.from;
                } else if (record?.source) {
                  val = record?.source;
                } else if (record.referral_code !== "No referral code used") {
                  val = "CAT's";
                } else if (record.isVoucherJob) {
                  val = "CAT's";
                }
                return val;
              }}
            />

            <Column
              title={<b>REFERRAL CODE</b>}
              dataIndex="referral_code"
              key={Math.random()}
              render={(value, record) => {
                return value === "No referral code used" ? "N/A" : value;
              }}
            />

            <Column
              title={<b>Status</b>}
              dataIndex="status"
              key={Math.random()}
              render={(value, record) => jobStatuses[value]}
            />

            <Column
              title={<b>Finish Time</b>}
              dataIndex="finish_time"
              render={(data) => {
                return moment(data).format("DD/MM/YYYY HH:mm");
              }}
            />

            <Column
              title={<b>Action</b>}
              key={Math.random()}
              render={(record, index) => {
                return (
                  <Space
                    size="middle"
                    key={Math.random()}
                    style={{ minWidth: "100px" }}
                  >
                    <Button
                      color={
                        record.status === 0 || record.status === 1
                          ? Colors.actionRed
                          : Colors.darkGreen
                      }
                      onClick={() => {
                        console.log("Record is ", record);
                        setCurrentJob(record);
                        setShowJobModal(true);
                      }}
                    >
                      <p
                        style={{
                          color: Colors.white,
                          margin: "0px",
                          minWidth: "100px",
                        }}
                      >
                        View
                      </p>
                    </Button>
                  </Space>
                );
              }}
            />
          </Table>
        </div>
      </div>
    </div>
  );
};

export default Jobs;
