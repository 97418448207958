// CRUD Operations
export const GET_VOUCHER = "GET_VOUCHER";
export const PUT_VOUCHER_IN_STATE = "PUT_VOUCHER_IN_STATE";
export const GET_ALL_VOUCHERS = "GET_ALL_VOUCHERS";
export const GET_VOUCHERS_OVERVIEW = "GET_VOUCHERS_OVERVIEW";
export const APPROVE_VOUCHER = "APPROVE_VOUCHER";
export const APPROVE_VOUCHER_FOR_PAYMENT = "APPROVE_VOUCHER_FOR_PAYMENT";
export const DENY_VOUCHER = "DENY_VOUCHER";
export const DELETE_VOUCHER = "DELETE_VOUCHER";
export const EDIT_VOUCHER = "EDIT_VOUCHER";
export const DEACTIVATE_VOUCHER = "DEACTIVATE_VOUCHER";
export const CREATE_VOUCHER_PROGRAM = "CREATE_VOUCHER_PROGRAM";
export const UPDATE_VOUCHER_PROGRAM = "UPDATE_VOUCHER_PROGRAM";
export const RECOUNT_VOUCHERS = "RECOUNT_VOUCHERS";
export const UPDATE_VOUCHER = "UPDATE_VOUCHER";
export const SET_ACTIVE_PROGRAM = "SET_ACTIVE_PROGRAM";

// Misc
export const LOADING_VOUCHERS = "LOADING_VOUCHERS";
