import * as catsTypes from "../types/catsTypes";
import StatusMessage from "../models/statusMessage";

const initialState = {
  cats: [],
  loading: false,
  cat: "",

  statusMessages: [],
  totalNumberOfCatsFromCentral: 0,
  totalNumberOfCatsFromKawempe: 0,
  totalNumberOfCatsFromMakindye: 0,
  totalNumberOfCatsFromNakawa: 0,
  totalNumberOfCatsFromRubaga: 0,
  totalNumberOfHouseholdsMappedInNakawa: 0,
  totalNumberOfHouseholdsMappedInCentral: 0,
  totalNumberOfHouseholdsMappedInKawempe: 0,
  totalNumberOfHouseholdsMappedInMakindye: 0,
  totalNumberOfHouseholdsMappedInRubaga: 0,
};

const catsReducer = (state = initialState, action) => {
  switch (action.type) {
    case catsTypes.GET_CATS:
      return {
        ...state,
        loading: false,
        cat: action.payload?.data || state.cat,
        statusMessages: [
          ...state.statusMessages,
          new StatusMessage({ subject: catsTypes.GET_CATS, action }),
        ],
      };

    case catsTypes.ADD_CATS:
      return {
        ...state,
        loading: false,
        cat: action.payload || state.cat,
        statusMessages: [
          ...state.statusMessages,
          new StatusMessage({ subject: catsTypes.ADD_CATS, action }),
        ],
      };

    case catsTypes.DEACTIVATE_CATS:
      return {
        ...state,
        loading: false,
        statusMessages: [
          ...state.statusMessages,
          new StatusMessage({ subject: catsTypes.DEACTIVATE_CATS, action }),
        ],
      };

    case catsTypes.ACTIVATE_CATS:
      return {
        ...state,
        loading: false,
        statusMessages: [
          ...state.statusMessages,
          new StatusMessage({ subject: catsTypes.ACTIVATE_CATS, action }),
        ],
      };

    case catsTypes.DELETE_CATS:
      return {
        ...state,
        loading: false,
        statusMessages: [
          ...state.statusMessages,
          new StatusMessage({ subject: catsTypes.DELETE_CATS, action }),
        ],
      };

    case catsTypes.PUT_CATS_IN_STATE:
      return {
        ...state,
        loading: false,
        cat: action.payload || state.cat,
      };

    case catsTypes.GET_ALL_CATS:
      return {
        ...state,
        loading: false,
        cats: action.payload?.data || state.cats,
        totalNumberOfCatsFromCentral:
          action.payload?.totalNumberOfCatsFromCentral ||
          state.totalNumberOfCatsFromCentral,
        totalNumberOfCatsFromKawempe:
          action.payload?.totalNumberOfCatsFromKawempe ||
          state.totalNumberOfCatsFromKawempe,
        totalNumberOfCatsFromMakindye:
          action.payload?.totalNumberOfCatsFromMakindye ||
          state.totalNumberOfCatsFromMakindye,
        totalNumberOfCatsFromNakawa:
          action.payload?.totalNumberOfCatsFromNakawa ||
          state.totalNumberOfCatsFromNakawa,
        totalNumberOfCatsFromRubaga:
          action.payload?.totalNumberOfCatsFromRubaga ||
          state.totalNumberOfCatsFromRubaga,
        totalNumberOfHouseholdsMappedInNakawa:
          action.payload?.totalNumberOfHouseholdsMappedInNakawa ||
          state.totalNumberOfHouseholdsMappedInNakawa,
        totalNumberOfHouseholdsMappedInCentral:
          action.payload?.totalNumberOfHouseholdsMappedInCentral ||
          state.totalNumberOfHouseholdsMappedInCentral,
        totalNumberOfHouseholdsMappedInKawempe:
          action.payload?.totalNumberOfHouseholdsMappedInKawempe ||
          state.totalNumberOfHouseholdsMappedInKawempe,
        totalNumberOfHouseholdsMappedInMakindye:
          action.payload?.totalNumberOfHouseholdsMappedInMakindye ||
          state.totalNumberOfHouseholdsMappedInMakindye,
        totalNumberOfHouseholdsMappedInRubaga:
          action.payload?.totalNumberOfHouseholdsMappedInRubaga ||
          state.totalNumberOfHouseholdsMappedInRubaga,
        statusMessages: [
          ...state.statusMessages,
          new StatusMessage({ subject: catsTypes.GET_ALL_CATS, action }),
        ],
      };

    case catsTypes.LOADING_CATS:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};

export default catsReducer;
