import React, { useState, useEffect } from 'react';
import Button from './../../components/Button';
import { AiOutlineSearch } from 'react-icons/ai';
import { FiChevronRight } from 'react-icons/fi';
import { Select, Table, Space, Button as AntDBtn } from 'antd';
import { RiCalendarTodoFill } from 'react-icons/ri';
import queryString from 'query-string';

import { Colors } from './../../config';
import * as utils from './../../redux/utils/componentUtils';
import defaultData from './defaultData';
import TouchableOpacity from './../../components/TouchableOpacity';
import { CSVLink } from 'react-csv';
import AddSpModal from './../Vouchers/VoucherModals/AddSpModal';
import DeleteSPModal from './DeleteSPModal';
import SingleSp from './SingleSp';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import * as spActions from './../../redux/actions/spActions';
import * as divisionActions from './../../redux/actions/divisionActions';
import makeID from './../../redux/utils/makeID';
import moment from 'moment';
import { useHistory } from 'react-router';

const { Option } = Select;
const { Column } = Table;

const ServiceProviders = ({ isDO }) => {
  const history = useHistory();

  // Redux
  const isAdmin = !isDO;
  const doDivision = useSelector((state) => state.admin.officer?.divisionID);
  const dispatch = useDispatch();
  const spStatusMessages = useSelector((state) => state.sp.statusMessages);
  const spsInState = useSelector((state) => state.sp.sps);
  const spInState = useSelector((state) => state.sp.sp);
  const [getSpStatus, setGetSpStatus] = useState('');
  const [spToDelete, setSpToDelete] = useState(false);
  const [showAddSpModal, setShowAddSpModal] = useState(false);
  const [showDeleteSPModal, setShowDeleteSPModal] = useState(false);
  const [viewSP, setViewSP] = useState(false);
  const loading = useSelector((state) => state.sp.loading);

  const getSps = () => {
    const statusID = makeID(8);
    setGetSpStatus(statusID);
    dispatch(spActions.getAllSps(statusID));
  };

  const handlePromptDelete = (sp) => {
    setSpToDelete(sp);
    setShowDeleteSPModal(true);
  };

  const handleView = (sp) => {
    dispatch(spActions.putSpInState(sp));
    const pathName = history.location.pathname;
    history.push(`${pathName}?sp=${sp._id}`);
    // navigateToSingleSp(sp._id);
  };

  const dataSource = isDO
    ? spsInState?.filter((it) => it.org?.division === doDivision)
    : spsInState;

  const [facilityType, setFacilityType] = useState('All');
  const [filteredData, setFilteredData] = useState(dataSource);
  const [searchText, setSearchText] = useState('');

  const handleSearch = (text) => {
    const result = utils.search(
      text,
      dataSource,
      [
        'full_name',
        'phone_number',
        'no_of_dumps_new',
        'no_of_jobs',
        'type',
        'last_seen',
      ],
      'ent_id'
    );

    setFilteredData(result);
  };

  const csvHeaders = [
    { label: 'SP ID', key: 'ent_id' },
    { label: 'SP Full Name', key: 'full_name' },
    { label: 'Phone Number', key: 'phone_number' },
    { label: 'No Of Dumps', key: 'no_of_dumps_new' },
    { label: 'No Of Jobs', key: 'no_of_jobs' },
    { label: 'SP Type', key: 'type' },
    { label: 'Last seen', key: 'last_seen' },
  ];

  const navigateToSingleSp = () => {
    const query = queryString.parse(history.location.search) || {};
    const spID = query.sp;

    // Get the SP details
    console.log('Getting SP details!');
    if (!spInState) {
      console.log('Fetching sp now...');
      dispatch(spActions.getSp(spID));
    }
    setViewSP(true);
  };

  useEffect(() => {
    if (searchText.length) {
      handleSearch(searchText);
    } else {
      setFilteredData(dataSource);
    }
  }, [searchText]);

  useEffect(() => {
    const query = queryString.parse(history.location.search) || {};
    const spID = query.sp;

    if (spID) {
      navigateToSingleSp();
    } else {
      getSps();
      dispatch(divisionActions.getAllDivisions());
      if (viewSP) {
        setViewSP(false);
      }
    }
  }, []);

  useEffect(() => {
    const query = queryString.parse(history.location.search) || {};
    const spID = query.sp;

    if (spID) {
      navigateToSingleSp();
    } else {
      if (viewSP) {
        setViewSP(false);
      }
    }
  }, [history?.location]);

  useEffect(() => {
    if (!spsInState.length) return;

    const orgGuys = spsInState.filter((sp) => sp.org);
  }, [spsInState]);

  useEffect(() => {
    if (!getSpStatus) return;
    const result = spStatusMessages.find((it) => it.statusID === getSpStatus);
    setFilteredData(dataSource);
  }, [spStatusMessages]);

  const handleLastSeen = (val) => moment(new Date(val)).fromNow();

  return (
    <>
      <DeleteSPModal
        visible={showDeleteSPModal}
        setVisible={setShowDeleteSPModal}
        loading={loading}
        deleteText={spToDelete?.full_name}
        spToDelete={spToDelete}
      />
      {viewSP ? (
        <SingleSp isDO={isDO} history={history} onDelete={handlePromptDelete} />
      ) : (
        <>
          <AddSpModal visible={showAddSpModal} setVisible={setShowAddSpModal} />
          <div className='sps'>
            <div className='sps-top'>
              <h3>SERVICE PROVIDERS</h3>
            </div>
            <div className='sps-bottom'>
              <div className='sps-search-row'>
                <div className='sps-select-container'>
                  <p className='sps-select-label'>Type of Service Provider</p>
                  <Select
                    showSearch
                    style={{ width: '100%' }}
                    // placeholder={}
                    optionFilterProp='Status'
                    onChange={(val) => setFacilityType(val)}
                    value={facilityType}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <Option value='Gulper'>Gulper</Option>
                    <Option value='Emptier'>Emptier</Option>
                    <Option value='Others'>Others</Option>
                  </Select>
                </div>

                <div className='sp-search-container'>
                  <div className='sp-search-input-wrapper'>
                    <input
                      placeholder='Search full name, phone number, sp type'
                      value={searchText}
                      onChange={(e) => setSearchText(utils.getInputVal(e))}
                    />
                  </div>
                  <div className='sp-search-icon'>
                    <AiOutlineSearch color={Colors.textGray} size={20} />
                  </div>
                </div>
              </div>

              <div style={{ marginBottom: '1rem', display: 'flex' }}>
                <Button color={Colors.darkGreen}>
                  <CSVLink
                    filename={'WEYONJE_SERVICE_PROVIDERS.csv'}
                    data={filteredData}
                    headers={csvHeaders}
                  >
                    <span style={{ color: Colors.white }}>Export to CSV</span>
                  </CSVLink>
                </Button>

                <div style={{ marginLeft: '40px' }}>
                  <Button
                    color={Colors.darkGreen}
                    onClick={() => setShowAddSpModal(true)}
                  >
                    <span style={{ color: Colors.white }}>
                      Add Service Provider
                    </span>
                  </Button>
                </div>
              </div>

              <div className='sps-table'>
                <Table
                  rowKey={(record) => record._id}
                  loading={loading}
                  // searchable={true}
                  // searchableProps={{ fuzzySearch: true }}
                  dataSource={filteredData}
                  key={Math.random()}
                  pagination={{
                    defaultPageSize: 5,
                    showSizeChanger: true,
                    pageSizeOptions: ['5', '8', '10', '20', '30'],
                  }}
                >
                  <Column
                    title={<b>SP id</b>}
                    dataIndex='ent_id'
                    key={Math.random()}
                  />

                  <Column
                    title={<b>Can Do Vouchers</b>}
                    dataIndex='isVoucherSP'
                    key={Math.random()}
                    render={(text, record) => {
                      const dateAsText = handleLastSeen(record.last_seen);
                      return (
                        <Space size='middle' key={Math.random()}>
                          {record?.isVoucherSP ? 'Yes' : 'No'}
                        </Space>
                      );
                    }}
                  />

                  <Column
                    title={<b>sp full Name</b>}
                    dataIndex='full_name'
                    key={Math.random()}
                  />
                  <Column
                    title={<b>Phone Number</b>}
                    dataIndex='phone_number'
                    key={Math.random()}
                  />
                  <Column
                    title={<b>NO of dumps</b>}
                    dataIndex='no_of_dumps_new'
                    key={Math.random()}
                  />
                  <Column
                    title={<b>no of jobs</b>}
                    dataIndex='no_of_jobs'
                    key={Math.random()}
                  />

                  <Column
                    title={<b>sp type</b>}
                    dataIndex='type'
                    key={Math.random()}
                  />

                  <Column
                    title={<b>Last seen</b>}
                    key={Math.random()}
                    render={(text, record) => {
                      const dateAsText = handleLastSeen(record.last_seen);
                      return (
                        <Space size='middle' key={Math.random()}>
                          <div className='sp-table-end'>
                            <div className='sp-table-end-label'>
                              {dateAsText}
                            </div>
                            <div className='sp-table-end-icon'>
                              <FiChevronRight
                                size={20}
                                color={Colors.textGray}
                              />
                            </div>
                          </div>
                        </Space>
                      );
                    }}
                  />

                  <Column
                    title={<b>ACTION</b>}
                    key={Math.random()}
                    width={'160px'}
                    render={(record) => (
                      <Space
                        size='middle'
                        key={Math.random()}
                        style={{ minWidth: '100px' }}
                      >
                        {isAdmin && (
                          <AntDBtn
                            danger
                            primary
                            onClick={() => handlePromptDelete(record)}
                          >
                            Delete
                          </AntDBtn>
                        )}
                        <AntDBtn
                          type='primary'
                          onClick={() => handleView(record)}
                        >
                          View {isAdmin && '/ Edit'}
                        </AntDBtn>
                      </Space>
                    )}
                  />
                </Table>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ServiceProviders;
