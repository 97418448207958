import * as voucherTypes from '../types/voucherTypes';
import { voucherEndpoints } from '../endpoints';
import * as fetchFunctions from '../utils/fetchFunctions';

export const getAllVouchers = (statusID) => async (dispatch, getState) => {
  dispatch({
    type: voucherTypes.LOADING_VOUCHERS,
  });

  const { token } = getState().admin;
  const officerDetails = getState().admin.officer;

  let endpoint = voucherEndpoints.getAll;

  if (officerDetails?._id) {
    endpoint = `${voucherEndpoints.getAll}/${officerDetails?._id}`;
  }
  const result = await fetchFunctions.getData(endpoint, token);
  dispatch({
    type: voucherTypes.GET_ALL_VOUCHERS,
    payload: result,
    statusID,
  });
};

export const getVouchersOverview = (statusID) => async (dispatch, getState) => {
  dispatch({
    type: voucherTypes.LOADING_VOUCHERS,
  });

  const { token } = getState().admin;

  const result = await fetchFunctions.getData(
    voucherEndpoints.getOverviewData,
    token
  );

  dispatch({
    type: voucherTypes.GET_VOUCHERS_OVERVIEW,
    payload: result,
    statusID,
  });
};

export const putVoucherInState = (voucher) => async (dispatch, getState) => {
  dispatch({
    type: voucherTypes.PUT_VOUCHER_IN_STATE,
    payload: voucher,
  });
};

export const setActiveProgram = (program) => async (dispatch, getState) => {
  dispatch({
    type: voucherTypes.SET_ACTIVE_PROGRAM,
    payload: program,
  });
};

export const getVoucher =
  (voucherID, statusID) => async (dispatch, getState) => {
    dispatch({
      type: voucherTypes.LOADING_VOUCHERS,
    });

    const { token } = getState().admin;

    const result = await fetchFunctions.getData(
      `${voucherEndpoints.get}/${voucherID}`,
      token
    );

    dispatch({
      type: voucherTypes.GET_VOUCHER,
      payload: result,
      statusID,
    });
  };

export const approveVoucher =
  (voucherID, voucherDetails, statusID) => async (dispatch, getState) => {
    dispatch({
      type: voucherTypes.LOADING_VOUCHERS,
    });

    const { token } = getState().admin;

    const result = await fetchFunctions.patchData(
      voucherEndpoints.actionVoucher(voucherID),
      voucherDetails,
      token
    );

    dispatch({
      type: voucherTypes.APPROVE_VOUCHER,
      payload: result,
      statusID,
    });
  };

export const denyVoucher =
  (voucherID, voucherDetails, statusID) => async (dispatch, getState) => {
    dispatch({
      type: voucherTypes.LOADING_VOUCHERS,
    });

    const { token } = getState().admin;

    const result = await fetchFunctions.patchData(
      voucherEndpoints.actionVoucher(voucherID),
      voucherDetails,
      token
    );

    dispatch({
      type: voucherTypes.DENY_VOUCHER,
      payload: result,
      statusID,
    });
  };

export const deleteVoucher =
  (voucherID, statusID) => async (dispatch, getState) => {
    dispatch({
      type: voucherTypes.LOADING_VOUCHERS,
    });

    const { token } = getState().admin;

    const result = await fetchFunctions.deleteData(
      voucherEndpoints.deleteVoucher(voucherID),
      token
    );

    dispatch({
      type: voucherTypes.DELETE_VOUCHER,
      payload: result,
      statusID,
    });
  };

export const editVoucher =
  (voucherID, voucherDetails, statusID) => async (dispatch, getState) => {
    dispatch({
      type: voucherTypes.LOADING_VOUCHERS,
    });

    const { token } = getState().admin;

    const result = await fetchFunctions.patchData(
      voucherEndpoints.editVoucher(voucherID),
      voucherDetails,
      token
    );

    dispatch({
      type: voucherTypes.EDIT_VOUCHER,
      payload: result,
      statusID,
    });
  };

export const deactivateVoucher =
  (voucherID, updateData, statusID) => async (dispatch, getState) => {
    dispatch({
      type: voucherTypes.LOADING_VOUCHERS,
    });

    const { token, id } = getState().admin;

    const result = await fetchFunctions.postData(
      voucherEndpoints.deactivateVoucher(voucherID),
      { status: 7, doID: id },
      token
    );

    dispatch({
      type: voucherTypes.DEACTIVATE_VOUCHER,
      payload: result,
      statusID,
    });
  };

export const updateVoucher =
  (updateData, statusID) => async (dispatch, getState) => {
    dispatch({
      type: voucherTypes.LOADING_VOUCHERS,
    });

    const { token } = getState().admin;

    const result = await fetchFunctions.patchData(
      voucherEndpoints.updateVoucher,
      updateData,
      token
    );

    dispatch({
      type: voucherTypes.UPDATE_VOUCHER,
      payload: result,
      statusID,
    });
  };

export const approveVoucherForPayment =
  (voucherID, voucherDetails, statusID) => async (dispatch, getState) => {
    dispatch({
      type: voucherTypes.LOADING_VOUCHERS,
    });

    const { token } = getState().admin;

    const result = await fetchFunctions.patchData(
      voucherEndpoints.actionVoucher(voucherID),
      voucherDetails,
      token
    );

    dispatch({
      type: voucherTypes.APPROVE_VOUCHER_FOR_PAYMENT,
      payload: result,
      statusID,
    });
  };

export const createVoucherProgram =
  (programDetails, statusID) => async (dispatch, getState) => {
    try {
      dispatch({
        type: voucherTypes.LOADING_VOUCHERS,
      });

      const { token } = getState().admin;

      const result = await fetchFunctions.postData(
        voucherEndpoints.createVoucherProgram,
        programDetails,
        token
      );

      dispatch({
        type: voucherTypes.CREATE_VOUCHER_PROGRAM,
        payload: result,
        statusID,
      });
    } catch (error) {
      console.error('Error while creating voucher program', error);
      dispatch({
        type: voucherTypes.CREATE_VOUCHER_PROGRAM,
        payload: { success: 'False', message: 'Something went wrong.' },
        statusID,
      });
    }
  };

export const updateVoucherProgram =
  (updateDetails, programID, statusID) => async (dispatch, getState) => {
    try {
      dispatch({
        type: voucherTypes.LOADING_VOUCHERS,
      });

      const { token } = getState().admin;

      const result = await fetchFunctions.patchData(
        voucherEndpoints.updateVoucherProgram(programID),
        updateDetails,
        token
      );

      dispatch({
        type: voucherTypes.UPDATE_VOUCHER_PROGRAM,
        payload: result,
        statusID,
      });
    } catch (error) {
      console.error('Error while updating voucher program', error);
      dispatch({
        type: voucherTypes.UPDATE_VOUCHER_PROGRAM,
        payload: { success: 'False', message: 'Something went wrong.' },
        statusID,
      });
    }
  };

export const recountVouchers = (statusID) => async (dispatch, getState) => {
  dispatch({
    type: voucherTypes.LOADING_VOUCHERS,
  });

  const { token } = getState().admin;

  const result = await fetchFunctions.getData(
    voucherEndpoints.recountVouchers,
    token
  );

  dispatch({
    type: voucherTypes.RECOUNT_VOUCHERS,
    payload: result,
    statusID,
  });
};

export const approveSinglePayment =
  (voucherID, statusID) => async (dispatch, getState) => {
    try {
      const updateData = {
        paid: true,
        status: 10,
        _id: voucherID,
      };

      console.log('Updating voucher', voucherID);

      dispatch({
        type: voucherTypes.LOADING_VOUCHERS,
      });

      const { token } = getState().admin;

      const result = await fetchFunctions.patchData(
        voucherEndpoints.updateVoucher,
        updateData,
        token
      );

      console.log('Results are', result);

      dispatch({
        type: voucherTypes.UPDATE_VOUCHER,
        payload: result,
        statusID,
      });
    } catch (error) {
      console.log('Error while updating voucher');
      dispatch({
        type: voucherTypes.UPDATE_VOUCHER,
        payload: { success: 'False', message: 'Something went wrong.' },
        statusID,
      });
    }
  };

/**
 *  const {
    doID,
    expiryDate,
    status,
    reasonForDenial,
    spID,
    jobID,
    reasonForDeactivation,
    catsRemarks,
    officerRemarks,
  } = req.body;
 */
