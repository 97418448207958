import * as notificationTypes from "../types/notificationTypes";
import { notificationEndpoints } from "../endpoints";
import * as fetchFunctions from "../utils/fetchFunctions";
import * as fetchFunctionsLegacy from "../utils/fetchFunctionsLegacy";

export const sendSPNotification =
  (notificationData, statusID) => async (dispatch, getState) => {
    dispatch({
      type: notificationTypes.LOADING_NOTIFICATIONS,
    });

    const { token } = getState().admin;

    const result = await fetchFunctionsLegacy.postData(
      notificationEndpoints.sendSp,
      notificationData,
      token
    );
    dispatch({
      type: notificationTypes.SEND_SP_NOTIFICATION,
      payload: result,
      statusID,
    });
  };
