import React, { useState, useEffect } from 'react';
import {
  Button as AntDBtn,
  Modal,
  Input,
  Switch,
  Select,
  notification,
  Spin,
} from 'antd';
import Colors from '../../../config/Colors';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import * as catsActions from '../../../redux/actions/catsActions';
import makeID from '../../../redux/utils/makeID';

const { Option } = Select;

const validDivisions = ['Nakawa', 'Makindye', 'Central', 'Kawempe', 'Rubaga'];

const AddCATModal = ({ visible, setVisible }) => {
  // Redux
  const dispatch = useDispatch();
  const catStatusMessages = useSelector((state) => state.cats.statusMessages);
  const loading = useSelector((state) => state.cats.loading);
  const [createStatus, setCreateStatus] = useState('');
  const divisionsInState = useSelector((state) => state.division.divisions);

  // State
  const [isActive, setIsActive] = useState(false);
  const [fullName, setFullName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const defaultDivision = 'Choose a division';
  const [division, setDivision] = useState(defaultDivision);

  const handleSwitch = (e) => {
    setIsActive(e);
  };

  const handleAddCats = () => {
    if (!fullName || !phoneNumber || !division) {
      notification.warn({
        message: 'Incomplete Input!',
        description: `Please provide all details`,
        onClick: () => {},
        duration: 2,
      });
      return;
    }

    const catObj = {
      phone_number: phoneNumber,
      full_name: fullName,
      division: division,
      isApprovedByDO: isActive,
    };

    const createStatusID = makeID(8);
    setCreateStatus(createStatusID);

    dispatch(catsActions.addCats(catObj, createStatusID));
  };

  useEffect(() => {
    if (!createStatus) return;

    const result = catStatusMessages.find((it) => it.statusID === createStatus);

    if (result?.success) {
      const successMessage = 'CATS/VHT saved';
      notification.success({
        message: 'Update',
        description: successMessage,
        onClose: () => {
          dispatch(catsActions.getAllCats());
        },
        duration: 2,
      });
    } else {
      const errorMessage = result?.error || 'An unknown error occurred';
      notification.warn({
        message: 'An Error Occurred',
        description: errorMessage,
        onClick: () => {},
        duration: 2,
      });
    }

    setCreateStatus('');
  }, [catStatusMessages]);

  return (
    <Modal
      visible={visible}
      title={<b style={{ color: Colors.textGray }}>ADD CAT/VHT</b>}
      onOk={handleAddCats}
      onCancel={() => setVisible(false)}
      footer={[
        <AntDBtn key='logout' onClick={() => setVisible(false)}>
          Cancel
        </AntDBtn>,
        <AntDBtn
          key='logout'
          type='primary'
          onClick={handleAddCats}
          className='logout-modal-no'
        >
          Add
        </AntDBtn>,
      ]}
    >
      <Spin spinning={loading}>
        <>
          <div style={{ marginBottom: '30px' }}>
            <p style={{ color: Colors.darkText }}>Full Name:</p>
            <Input
              type='text'
              value={fullName}
              onChange={(val) => setFullName(val.target.value)}
              placeholder='Enter Value'
            />
          </div>

          <div style={{ marginBottom: '30px' }}>
            <p style={{ color: Colors.darkText }}>Phone number:</p>
            <Input
              type='text'
              value={phoneNumber}
              onChange={(val) => setPhoneNumber(val.target.value)}
              placeholder='Enter Value'
            />
          </div>

          <div style={{ marginBottom: '30px' }}>
            <p style={{ color: Colors.darkText }}>Division:</p>
            <Select
              style={{ width: '100%' }}
              onChange={(val) => setDivision(val)}
              value={division}
            >
              {divisionsInState
                ?.filter((division) => division.name !== 'Test')
                ?.map((opt) => (
                  <Option value={opt.name} key={opt._id}>
                    {opt.name}
                  </Option>
                ))}
            </Select>
          </div>

          <div style={{ marginBottom: '30px' }}>
            <div>
              <p
                style={{
                  color: Colors.darkText,
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: '5px',
                }}
              >
                <span>Active:</span>{' '}
                <Switch onChange={handleSwitch} checked={isActive} />
              </p>
            </div>

            <small>If inactive, they will not be able to login</small>
          </div>
        </>
      </Spin>
    </Modal>
  );
};

export default AddCATModal;
