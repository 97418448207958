import React, { useState, useEffect } from "react";
import CustomInput from "./../../components/CustomInput";
import WeyonLogo from "./../../assets/logos/weyonje-logo.png";
import {
  AiOutlineMail,
  AiOutlineEye,
  AiOutlineEyeInvisible,
} from "react-icons/ai";

import { Colors } from "../../config";
import TouchableOpacity from "./../../components/TouchableOpacity";
import Button from "./../../components/Button";
import Checkbox from "./../../components/Checkbox";
import PageAddresses from "../../config/PageAddresses";

const LoginForgotPassword = ({ history, switchTab }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [isDivOfficer, setIsDivOfficer] = useState(false);

  const goHome = (e) => {
    e.preventDefault();

    history.push(PageAddresses.dashboard.home.path);
  };

  const switchActiveTab = () => switchTab(1);

  return (
    <React.Fragment>
      <form className="login-forgot-password" onSubmit={goHome}>
        <h3 className="login-forgot-password-title">FORGOT YOUR PASSWORD?</h3>
        <p className="login-forgot-password-instruction">
          Enter the email address associated with your account
        </p>
        <CustomInput
          placeHolder="Email Address"
          type="email"
          // style={{ width: "8rem" }}
          required={true}
          value={email}
          receiveOutput={setEmail}
          iconRight={<AiOutlineMail size={20} color={Colors.textGray} />}
        />

        <Button
          color={email ? Colors.darkGreen : Colors.hrGray}
          //   onClick={goHome}
          type="submit"
        >
          <span style={{ color: Colors.white }}>Reset Password</span>
        </Button>
        <TouchableOpacity clicked={switchActiveTab}>
          <div className="login-clickable-text">Login/Signup</div>
        </TouchableOpacity>

        <Checkbox
          checked={isDivOfficer}
          onCheck={() => setIsDivOfficer(!isDivOfficer)}
        >
          <p style={{ color: Colors.textGray, margin: "0px" }}>
            I am a Division Officer
          </p>
        </Checkbox>
      </form>
    </React.Fragment>
  );
};

export default LoginForgotPassword;
