import * as voucherTypes from "../types/voucherTypes";
import StatusMessage from "../models/statusMessage";

const initialState = {
  vouchers: [],
  loading: false,
  voucher: "",
  overview: "",
  activeProgram: "",
  statusMessages: [],
};

const voucherReducer = (state = initialState, action) => {
  switch (action.type) {
    case voucherTypes.GET_VOUCHER:
      return {
        ...state,
        loading: false,
        voucher: action.payload?.data?.length
          ? action.payload.data[0]
          : state.voucher,
        statusMessages: [
          ...state.statusMessages,
          new StatusMessage({ subject: voucherTypes.GET_VOUCHER, action }),
        ],
      };

    case voucherTypes.PUT_VOUCHER_IN_STATE:
      return {
        ...state,
        loading: false,
        voucher: action.payload || state.voucher,
      };

    case voucherTypes.GET_ALL_VOUCHERS:
      return {
        ...state,
        loading: false,
        vouchers: action.payload?.data || state.vouchers,
        statusMessages: [
          ...state.statusMessages,
          new StatusMessage({ subject: voucherTypes.GET_ALL_VOUCHERS, action }),
        ],
      };

    case voucherTypes.APPROVE_VOUCHER:
      return {
        ...state,
        loading: false,
        statusMessages: [
          ...state.statusMessages,
          new StatusMessage({ subject: voucherTypes.APPROVE_VOUCHER, action }),
        ],
      };

    case voucherTypes.APPROVE_VOUCHER_FOR_PAYMENT:
      return {
        ...state,
        loading: false,
        statusMessages: [
          ...state.statusMessages,
          new StatusMessage({
            subject: voucherTypes.APPROVE_VOUCHER_FOR_PAYMENT,
            action,
          }),
        ],
      };

    case voucherTypes.EDIT_VOUCHER:
      return {
        ...state,
        loading: false,
        statusMessages: [
          ...state.statusMessages,
          new StatusMessage({ subject: voucherTypes.EDIT_VOUCHER, action }),
        ],
      };
    //

    case voucherTypes.UPDATE_VOUCHER_PROGRAM:
      return {
        ...state,
        loading: false,
        statusMessages: [
          ...state.statusMessages,
          new StatusMessage({
            subject: voucherTypes.UPDATE_VOUCHER_PROGRAM,
            action,
          }),
        ],
      };

    case voucherTypes.UPDATE_VOUCHER:
      return {
        ...state,
        loading: false,
        statusMessages: [
          ...state.statusMessages,
          new StatusMessage({
            subject: voucherTypes.UPDATE_VOUCHER,
            action,
          }),
        ],
      };

    case voucherTypes.CREATE_VOUCHER_PROGRAM:
      return {
        ...state,
        loading: false,
        statusMessages: [
          ...state.statusMessages,
          new StatusMessage({
            subject: voucherTypes.CREATE_VOUCHER_PROGRAM,
            action,
          }),
        ],
      };

    case voucherTypes.DEACTIVATE_VOUCHER:
      return {
        ...state,
        loading: false,
        statusMessages: [
          ...state.statusMessages,
          new StatusMessage({
            subject: voucherTypes.DEACTIVATE_VOUCHER,
            action,
          }),
        ],
      };

    case voucherTypes.DENY_VOUCHER:
      return {
        ...state,
        loading: false,
        statusMessages: [
          ...state.statusMessages,
          new StatusMessage({ subject: voucherTypes.DENY_VOUCHER, action }),
        ],
      };

    case voucherTypes.DELETE_VOUCHER:
      return {
        ...state,
        loading: false,
        statusMessages: [
          ...state.statusMessages,
          new StatusMessage({ subject: voucherTypes.DELETE_VOUCHER, action }),
        ],
      };

    case voucherTypes.RECOUNT_VOUCHERS:
      return {
        ...state,
        loading: false,
        overview: action.payload?.data || state.overview,
        activeProgram: action.payload?.data?.length
          ? action.payload?.data?.find((v) => v.isActive === true) ||
            action.payload?.data.sort(
              (a, b) => new Date(b.endDate) - new Date(a.endDate)
            )[0]
          : state.activeProgram,
        statusMessages: [
          ...state.statusMessages,
          new StatusMessage({
            subject: voucherTypes.RECOUNT_VOUCHERS,
            action,
          }),
        ],
      };

    case voucherTypes.GET_VOUCHERS_OVERVIEW:
      return {
        ...state,
        loading: false,
        overview: action.payload?.data || state.overview,
        activeProgram: action.payload?.data?.length
          ? action.payload?.data?.find((v) => v.isActive === true) ||
            action.payload?.data.sort(
              (a, b) => new Date(b.endDate) - new Date(a.endDate)
            )[0]
          : state.activeProgram,
        statusMessages: [
          ...state.statusMessages,
          new StatusMessage({
            subject: voucherTypes.GET_VOUCHERS_OVERVIEW,
            action,
          }),
        ],
      };

    case voucherTypes.SET_ACTIVE_PROGRAM:
      return {
        ...state,
        activeProgram: action.payload,
      };

    case voucherTypes.LOADING_VOUCHERS:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};

export default voucherReducer;
