import React, { useState, useEffect } from "react";
import { Colors } from "../../../../config";
import BottomBars from "../Components/BottomBars";
import BottomBarsHorizontalStack from "./../Components/BottomBarsHorizontalStack";
import CircleLabel from "../Components/CircleLabel";
import { Spin } from "antd";

const BottomDumping = ({ dataLeft, dataRight, leftLoading, rightLoading }) => {
  const tension = 0.3;
  const borderRadius = 10;
  const tripsData = {
    name: "OVERVIEW",
    number: 1,
    background: Colors.yellow,
    gradColor: "rgba(235, 241, 226, #)",
    lineColor: Colors.darkGreen,
    chartTitle: "Dumping Volume at Sewage plants",
    statCardColor: Colors.statCardGreen,
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Lubigi",
        backgroundColor: Colors.yellow,
        borderColor: Colors.yellow,
        data: dataLeft.lubigi || [2, 10, 5, 2, 20, 30, 45, 10, 12, 2, 3, 18],
        tension,
        borderRadius,
        borderDash: [],
        borderDashOffset: 0.0,
      },

      {
        label: "Bugolobi",
        backgroundColor: Colors.darkGreen,
        borderColor: Colors.darkGreen,
        data: dataLeft.bugolobi || [
          41, 22, 18, 1, 15, 18, 13, 25, 12, 23, 26, 17,
        ],
        tension,
        borderRadius,
        borderDash: [],
        borderDashOffset: 0.0,
      },
    ],
  };

  const plantsData = {
    name: "OVERVIEW",
    number: 1,
    background: Colors.yellow,
    gradColor: "rgba(235, 241, 226, #)",
    lineColor: Colors.darkGreen,
    chartTitle: "Trend of dumping trips by service provider type",
    statCardColor: Colors.statCardGreen,
    labels: ["Others", "Rubaga", "Nakawa", "Makindye", "Kawempe", "Central"],
    datasets: [
      {
        label: "Bugolobi",
        backgroundColor: Colors.weyonLightBlue,
        borderColor: Colors.weyonLightBlue,
        data: dataRight?.bugolobi || [55, 23, 44, 12, 18, 32],
        tension,
        borderRadius,
        borderDash: [],
        borderDashOffset: 0.0,
      },
      {
        label: "Lubigi",
        backgroundColor: Colors.yellow,
        borderColor: Colors.yellow,
        data: dataRight?.lubigi || [20, 18, 12, 31, 40, 22],
        tension,
        borderRadius,
        borderDash: [],
        borderDashOffset: 0.0,
      },
    ],
  };
  return (
    <div className="bottom-overview-container">
      <div className="bottom-overview-left">
        <Spin spinning={leftLoading}>
          <BottomBars
            title={tripsData.chartTitle}
            labels={tripsData.labels}
            datasets={tripsData.datasets}
            gradColor={tripsData.gradColor}
            lineColor={tripsData.lineColor}
            stacked
          />
        </Spin>
        <div className="bottom-bars-labels">
          {tripsData.datasets.map((it) => (
            <CircleLabel
              label={it.label}
              color={it.backgroundColor}
              key={it.label}
            />
          ))}
        </div>
      </div>
      <div className="bottom-overview-right">
        <Spin spinning={rightLoading}>
          <BottomBarsHorizontalStack
            title={plantsData.chartTitle}
            labels={plantsData.labels}
            datasets={plantsData.datasets}
            gradColor={plantsData.gradColor}
            lineColor={plantsData.lineColor}
            small
            barThickness={20}
          />
        </Spin>
        <div className="bottom-bars-labels">
          {plantsData.datasets.map((it) => (
            <CircleLabel
              label={it.label}
              color={it.backgroundColor}
              key={it.label}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default BottomDumping;
