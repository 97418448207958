export default {
  onboarding: {
    login: {
      path: "/",
    },
  },
  dashboard: {
    home: {
      path: "/dashboard/home",
    },
    liveTracking: {
      path: "/dashboard/live-tracking",
    },
    jobs: {
      path: "/dashboard/jobs",
    },
    serviceProviders: {
      path: "/dashboard/service-providers",
    },
    customers: {
      path: "/dashboard/customers",
    },
    dumps: {
      path: "/dashboard/dumps",
    },
    dispatchers: {
      path: "/dashboard/dispatchers",
    },
    // Single Voucher
    voucher: {
      path: "/dashboard/voucher",
    },
    // Vouchers overview
    vouchers: {
      path: "/dashboard/vouchers",
    },
  },
};
