export default (BASE_URL, API_PREFIX, API_VERSION) => {
  const apiToken =
    "2ab2394589ec7669a24880ede64fe112e969eeb7a62e42ba655c51a2ca0fe3b6";
  const defaultEndpoint = "dispatch";
  const base = "https://api.pushy.me/pushes/";

  return {
    sendSp: `${BASE_URL}/${API_PREFIX}/${defaultEndpoint}`,
    checkStatus: (id) => base + id + `?api_key=${apiToken}`,
  };
};
