// CRUD Operations
export const CREATE_ADMIN = "CREATE_ADMIN";
export const UPDATE_ADMIN = "UPDATE_ADMIN";
export const DELETE_ADMIN = "DELETE_ADMIN";
export const GET_ADMIN = "GET_ADMIN";
export const GET_ALL_ADMINS = "GET_ALL_ADMINS";
export const LOGIN_ADMIN = "LOGIN_ADMIN";
export const LOGOUT_ADMIN = "LOGOUT_ADMIN";
export const ADD_ADMIN = "ADD_ADMIN";
export const REQUEST_OTP = "REQUEST_OTP";
export const VERIFY_OTP = "VERIFY_OTP";

// Misc
export const LOADING_ADMINS = "LOADING_ADMINS";
