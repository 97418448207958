import * as adminTypes from '../types/adminTypes';
import { authEndpoints } from '../endpoints';
import * as fetchFunctions from '../utils/fetchFunctions';
import * as fetchFunctionsLegacy from '../utils/fetchFunctionsLegacy';

export const createAdmin =
  (userInfo, statusID) => async (dispatch, getState) => {
    dispatch({
      type: adminTypes.LOADING_ADMINS,
    });

    const creationResult = await fetchFunctions.postData(
      authEndpoints.add,
      userInfo
    );

    dispatch({
      type: adminTypes.ADD_ADMIN,
      payload: creationResult,
      statusID,
    });
  };

export const requestOTP =
  (phoneNumber, statusID) => async (dispatch, getState) => {
    dispatch({
      type: adminTypes.LOADING_ADMINS,
    });

    const address = `${authEndpoints.getOTP}/+${phoneNumber}`;

    const result = await fetchFunctions.getData(address);

    dispatch({
      type: adminTypes.REQUEST_OTP,
      payload: result,
      statusID,
    });
  };

export const verifyOTP =
  (code, phoneNumber, statusID) => async (dispatch, getState) => {
    dispatch({
      type: adminTypes.LOADING_ADMINS,
    });

    const address = `${authEndpoints.verifyOTP}/+${phoneNumber}/${code}`;

    const result = await fetchFunctions.getData(address);

    dispatch({
      type: adminTypes.VERIFY_OTP,
      payload: result,
      statusID,
    });
  };

export const loginAdmin =
  (userInfo, statusID) => async (dispatch, getState) => {
    dispatch({
      type: adminTypes.LOADING_ADMINS,
    });

    const result = await fetchFunctionsLegacy.postFormDataTxt(
      authEndpoints.login,
      userInfo
    );
    dispatch({
      type: adminTypes.LOGIN_ADMIN,
      payload: result,
      statusID,
      isDO: userInfo.isDO,
    });
  };

export const logoutAdmin = () => async (dispatch, getState) => {
  dispatch({
    type: adminTypes.LOGOUT_ADMIN,
  });
};

export const clearLogin = () => async (dispatch, getState) => {
  dispatch({
    type: adminTypes.LOGOUT_ADMIN,
  });
};
