import React, { useState, useEffect } from "react";
import { Button as AntDBtn, Modal, Spin, notification } from "antd";
import Colors from "./../../config/Colors";
// Redux
import { useSelector, useDispatch } from "react-redux";
import * as spActions from "./../../redux/actions/spActions";
import makeID from "./../../redux/utils/makeID";

const DeleteSPModal = ({
  visible,
  setVisible,
  deleteText,
  loading,
  spToDelete,
}) => {
  const dispatch = useDispatch();
  const spStatusMessages = useSelector((state) => state.sp.statusMessages);
  const [deleteSpStatus, setDeleteSpStatus] = useState("");

  // const loading = true;
  const handleDelete = () => {
    // notification.error({
    //   message: "Update",
    //   description: `Cannot delete ${deleteText}. Please try again.`,
    //   onClick: () => {},
    //   duration: 2,
    // });
    // return;
    const statusID = makeID(8);
    setDeleteSpStatus(statusID);
    dispatch(spActions.deleteSP(spToDelete?._id, statusID));
  };

  useEffect(() => {
    if (!deleteSpStatus) return;

    const result = spStatusMessages.find(
      (it) => it.statusID === deleteSpStatus
    );
    const message = result?.error || result?.message;

    if (result?.success) {
      notification.success({
        message: "Update",
        description: `Successfully deleted ${deleteText}.`,
        onClick: () => {},
        duration: 2,
        onClose: () => {
          dispatch(spActions.getAllSps());
        },
      });
    } else {
      notification.error({
        message: "Update",
        description: `Failed to delete ${deleteText}. Please try again.`,
        onClick: () => {},
        duration: 2,
      });
    }
    setDeleteSpStatus("");
  }, [spStatusMessages]);

  return (
    <Modal
      visible={visible}
      title={<b style={{ color: Colors.textGray }}>DELETE {deleteText}</b>}
      onOk={handleDelete}
      onCancel={() => setVisible(false)}
      footer={[
        <AntDBtn
          key="logout"
          onClick={() => setVisible(false)}
          className="logout-modal-yes"
        >
          Cancel
        </AntDBtn>,
        <AntDBtn
          key="cancel"
          type="primary"
          onClick={handleDelete}
          color={Colors.actionRed}
          className="logout-modal-no"
        >
          Delete
        </AntDBtn>,
      ]}
    >
      <Spin spinning={loading}>
        <div style={{ marginBottom: "30px" }}>
          <p style={{ color: Colors.darkText }}>
            Are you sure you want to delete {deleteText}? This action is
            irreversible
          </p>
        </div>
      </Spin>
    </Modal>
  );
};

export default DeleteSPModal;
