import React, { useState, useEffect } from "react";
import { Bar, Line } from "react-chartjs-2";
import { Colors } from "../../../../config";
import CircleLabel from "./CircleLabel";

const Chart = ({
  title,
  labels,
  datasets,
  gradColor,
  lineColor,
  small,
  barThickness,
}) => {
  const [useLineChart, setUseLineChart] = useState(true);
  const chartIconSize = 25;

  // const data = {
  //   labels,
  //   datasets,
  // };
  const height = 50;
  const width = 100;

  const data = (canvas) => {
    const ctx = canvas.getContext("2d");

    let gradient;
    if (gradColor) {
      gradient = ctx.createLinearGradient(0, 0, 0, 400);
      gradient.addColorStop(0, gradColor.replace("#", 1));
      gradient.addColorStop(1, gradColor.replace("#", 0));
    }

    let gradientStroke;
    if (lineColor) {
      gradientStroke = ctx.createLinearGradient(500, 0, 100, 0);
      gradientStroke.addColorStop(0, lineColor || Colors.actionRed);
      gradientStroke.addColorStop(1, lineColor || Colors.actionRed);
    }

    let obj =
      datasets.length === 1
        ? {
            labels,
            datasets: [
              {
                ...datasets[0],
                backgroundColor: lineColor,
                fill: "start",
                borderColor: gradientStroke,
                pointBorderColor: gradientStroke,
                pointBackgroundColor: gradientStroke,
                pointHoverBackgroundColor: gradientStroke,
                pointHoverBorderColor: gradientStroke,
              },
            ],
          }
        : { labels, datasets };

    return obj;
  };

  const options = {
    maintainAspectRatio: false,
    indexAxis: "y",
    plugins: {
      legend: {
        display: false,
      },
      barThickness: barThickness || 73,
      barPercentage: 0.1,
      categorySpacing: 10,
    },
    scales: {
      // categorySpacing: 10,
      xAxes: {
        barThickness: barThickness || 73,
        barPercentage: 0.1,
        categorySpacing: 10,
        stacked: true,
        grid: {
          color: "rgba(0, 0, 0, 0)",
          borderDash: [3, 4],
          borderDashOffset: 0.0,
        },
      },

      yAxes: {
        barThickness: barThickness || 73,
        barPercentage: 0.1,
        categorySpacing: 10,
        stacked: true,
        grid: {
          color: "rgba(0, 0, 0, 0)",
          borderDash: [8, 8],
          borderDashOffset: 0.0,
        },
      },
    },
  };

  const size = "100%";

  return (
    <div className="dashboard-chart">
      <h3>{title}</h3>
      <div className="dashboard-chart-icons"></div>
      <div className="dashboard-chart-container">
        <style>{`
        .dashboard-chart-wrapper canvas {
            width: ${size}px !important;
            
          }

        }
      `}</style>
        <div
          className={
            small ? "dashboard-chart-wrapper-small" : "dashboard-chart-wrapper"
          }
        >
          <Bar data={data} width={width} height={height} options={options} />
        </div>
      </div>
    </div>
  );
};

export default Chart;
