import * as customerTypes from '../types/customerTypes';
import { customerEndpoints, divisionEndpoints } from '../endpoints';
import * as fetchFunctions from '../utils/fetchFunctions';
import * as fetchFunctionsLegacy from '../utils/fetchFunctionsLegacy';

export const getAllCustomers = (statusID) => async (dispatch, getState) => {
  dispatch({
    type: customerTypes.LOADING_CUSTOMERS,
  });

  const { token, officer } = getState().admin;

  let result = await fetchFunctions.getData(customerEndpoints.getAll, token);

  // Only return the jobs for a particular division if a DO is logged in
  if (officer?.divisionID) {
    const allDivisionsResult = await fetchFunctions.getData(
      divisionEndpoints.getAll,
      token
    );

    const allDivisions = allDivisionsResult?.data || [];
    const matchingDivision = allDivisions.find(
      (d) => d._id === officer?.divisionID
    )?.name;

    const filteredCustomers = result?.data?.filter(
      (c) =>
        `${
          c?.location?.division || c?.division || 'None Provided'
        }`.toLowerCase() === `${matchingDivision}`.toLowerCase()
    );

    result.data = filteredCustomers;
  }

  dispatch({
    type: customerTypes.GET_ALL_CUSTOMERS,
    payload: result,
    statusID,
  });
};
