import React, { useState, useEffect } from "react";
import { Button as AntDBtn, Modal, Input } from "antd";
import Colors from "../../../config/Colors";
import Button from "../../../components/Button";
import OutlineInput from "../../../components/OutlineInput";
import TouchableOpacity from "../../../components/TouchableOpacity";
import { CgChevronDown, CgChevronUp } from "react-icons/cg";
import ApproveModal from "./ApproveModal";
import DeclineModal from "./DeclineModal";

const ApproveDeclineDropdown = ({ voucher, onApprove, onDecline, onView }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showDeclineModal, setShowDeclineModal] = useState(false);
  const [expiryDate, setExpiryDate] = useState("");
  const [expiryDateAlt, setExpiryDateAlt] = useState("");

  const handleApproveClicked = () => {
    setShowApproveModal(true);
    setShowDropdown(false);
  };
  const handleDeclineClicked = () => {
    setShowDeclineModal(true);
    setShowDropdown(false);
  };

  const handleViewChosen = () => {
    onView(voucher);
  };

  const closeModal = () => {
    setShowDeclineModal(false);
    setShowApproveModal(false);
  };

  return (
    <>
      <ApproveModal
        visible={showApproveModal}
        setVisible={closeModal}
        voucher={voucher}
        onAction={onApprove}
      />
      <DeclineModal
        visible={showDeclineModal}
        setVisible={closeModal}
        voucher={voucher}
        onAction={onDecline}
      />
      <div className="ad-container">
        <Button
          color={Colors.darkGreen}
          onClick={() => setShowDropdown(!showDropdown)}
        >
          <p
            style={{
              color: Colors.white,
              margin: "0px",
              minWidth: "80px",
            }}
          >
            Approve/Decline{" "}
          </p>
          {showDropdown ? (
            <CgChevronUp size={20} color={Colors.white} />
          ) : (
            <CgChevronDown size={20} color={Colors.white} />
          )}
        </Button>

        {showDropdown ? (
          <div className="ad-dropdown">
            <TouchableOpacity
              clicked={handleApproveClicked}
              altClass="ad-dropdown-item-br"
            >
              APPROVE
            </TouchableOpacity>
            <TouchableOpacity
              clicked={handleDeclineClicked}
              altClass="ad-dropdown-item"
            >
              DECLINE
            </TouchableOpacity>
            <TouchableOpacity
              clicked={handleViewChosen}
              altClass="ad-dropdown-item"
            >
              VIEW
            </TouchableOpacity>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default ApproveDeclineDropdown;
