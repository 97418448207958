import React, { useState, useEffect } from "react";
import { FiChevronRight } from "react-icons/fi";
import { Colors } from "../../config";

const JobCard = ({ job, onHover, onClick, activeJob, currentColor }) => {
  return (
    <div
      className="job-card"
      onMouseEnter={() => onHover(job)}
      style={{
        background:
          activeJob?._id === job?._id ? `${currentColor}1A` : Colors.white,
      }}
    >
      <div className="job-card-left">
        <div className="job-card-pair">
          <div className="job-card-key">Job ID:</div>
          <div className="job-card-value">{job.job_id}</div>
        </div>

        <div className="job-card-pair">
          <div className="job-card-key">Customer Name:</div>
          <div className="job-card-value">{job.full_name}</div>
        </div>

        <div className="job-card-pair">
          <div className="job-card-key">Service Provider:</div>
          <div className="job-card-value">
            {job?.entreprenuer?.full_name || "None Yet"}
          </div>
        </div>

        <div className="job-card-pair">
          <div className="job-card-key">Customer Number:</div>
          <div className="job-card-value">
            {job?.phone_number || "None Yet"}
          </div>
        </div>

        <div className="job-card-pair">
          <div className="job-card-key">Location:</div>
          <div className="job-card-value">{job.address || "None provided"}</div>
        </div>

        <div className="job-card-pair">
          <div className="job-card-key">Division:</div>
          <div className="job-card-value">
            {job.division || "None provided"}
          </div>
        </div>

        <div className="job-card-pair">
          <div className="job-card-key">Source:</div>
          <div className="job-card-value">
            {job?.source?.from || "None provided"}
          </div>
        </div>

        {/* <div className="job-card-pair">
          <div className="job-card-key">Amount Received:</div>
          <div className="job-card-value">{job.income_received}</div>
        </div> */}
      </div>
      <div className="job-card-right">
        <FiChevronRight size={20} color={Colors.darkGreen} />
      </div>
    </div>
  );
};

export default JobCard;
