import React from "react";
import { Button as AntDBtn, Modal, Spin } from "antd";
import Colors from "./../../config/Colors";

const LoginTimeoutModal = ({ visible, setVisible, loading, redoLogin }) => {
  return (
    <Modal
      visible={visible}
      title={<b style={{ color: Colors.textGray }}>LOGIN TIMED OUT</b>}
      onOk={redoLogin}
      onCancel={() => setVisible(false)}
      footer={[
        <AntDBtn
          key="logout"
          onClick={() => setVisible(false)}
          className="logout-modal-yes"
        >
          Close
        </AntDBtn>,
        <AntDBtn
          key="cancel"
          type="primary"
          onClick={redoLogin}
          color={Colors.actionRed}
          className="logout-modal-no"
        >
          Retry
        </AntDBtn>,
      ]}
    >
      <div style={{ marginBottom: "30px" }}>
        <p style={{ color: Colors.darkText }}>
          Login timed out. Please check your internet connection and try again.
        </p>
      </div>
    </Modal>
  );
};

export default LoginTimeoutModal;
