import React, { useState, useEffect } from "react";
import { Colors } from "./../../config";
import {
  FiPieChart,
  FiSend,
  FiUsers,
  FiChevronDown,
  FiChevronUp,
} from "react-icons/fi";
import { BiTargetLock, BiRecycle } from "react-icons/bi";
import { BsBriefcase } from "react-icons/bs";
import { HiOutlineTicket } from "react-icons/hi";
import { FaUserCircle } from "react-icons/fa";
import { BsBell } from "react-icons/bs";
import { CustomIcon, CustomIconTypes } from "./../../components/CustomIcon";
import TouchableOpacity from "../../components/TouchableOpacity";

// Tabs
import Customers from "./../Customers";
import Dashboard from "./../Dashboard";
import Dispatchers from "./../Dispatchers";
import Dumps from "./../Dumps";
import Jobs from "./../Jobs";
import LiveTracking from "./../LiveTracking";
import ServiceProviders from "./../ServiceProviders";
import Vouchers from "./../Vouchers";

// Misc
import LogoutModal from "./LogoutModal";

// Paths
import PageAddresses from "../../config/PageAddresses";

// Redux
import { useSelector } from "react-redux";
const defaultTabIconSize = 20;
const tabList = {
  1: {
    name: "Dashboard",
    icon: <FiPieChart size={defaultTabIconSize} />,
    number: 1,
    address: PageAddresses.dashboard.home.path,
    component: <Dashboard />,
  },
  2: {
    name: "Live Tracking",
    icon: <BiTargetLock size={defaultTabIconSize} />,
    number: 2,
    address: PageAddresses.dashboard.liveTracking.path,
    component: <LiveTracking />,
  },
  3: {
    name: "Jobs",
    icon: <BsBriefcase size={defaultTabIconSize} />,
    number: 3,
    address: PageAddresses.dashboard.jobs.path,
    component: <Jobs />,
  },
  4: {
    name: "Service Providers",
    icon: <CustomIcon size={1.3} type={CustomIconTypes.radio} />,
    number: 4,
    address: PageAddresses.dashboard.serviceProviders.path,
    component: <ServiceProviders />,
  },
  5: {
    name: "Customers",
    icon: <FiUsers size={defaultTabIconSize} />,
    number: 5,
    address: PageAddresses.dashboard.customers.path,
    component: <Customers />,
  },
  6: {
    name: "Dumps",
    icon: <BiRecycle size={defaultTabIconSize} />,
    number: 6,
    address: PageAddresses.dashboard.dumps.path,
    component: <Dumps />,
  },
  7: {
    name: "Dispatchers",
    icon: <FiSend size={defaultTabIconSize} />,
    number: 7,
    address: PageAddresses.dashboard.dispatchers.path,
    component: <Dispatchers />,
  },
  8: {
    name: "Vouchers",
    icon: <HiOutlineTicket size={defaultTabIconSize} />,
    number: 8,
    address: PageAddresses.dashboard.vouchers.path,
    component: <Vouchers />,
  },
};
const tabListAsArray = Object.keys(tabList).map((key) => tabList[key]);
const validAddresses = Object.keys(tabList).map((num) => tabList[num].address);
const DashboardLayout = ({ history, location }) => {
  // Redux
  const userName = useSelector((state) => state.admin.name);
  const isDO = !!useSelector((state) => state.admin.officer?._id);

  // State
  const [activeTab, setActiveTab] = useState(1);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);

  const changeActiveTab = (tab) => {
    setActiveTab(tab.number);
    history.push(tab.address);
  };

  const handleLogout = () => {
    setShowDropdown(false);
    setShowLogoutModal(true);
  };

  useEffect(() => {
    // Navigate only to valid addresses, else go home
    const newLocation = location.pathname;
    const isValid = validAddresses.includes(newLocation);

    if (!isValid) {
      // Go home
      history.push(tabList[1].address);
      return;
    }

    // Find the tab that matches the address
    let matchingTab;
    Object.keys(tabList).forEach((key) => {
      if (matchingTab) return;
      if (tabList[key].address === newLocation) {
        matchingTab = tabList[key];
        return;
      }
    });

    setActiveTab(matchingTab.number);
  }, [location]);

  return (
    <div className="dashboard">
      <LogoutModal
        visible={showLogoutModal}
        setVisible={setShowLogoutModal}
        history={history}
      />
      {/* Dashboard-top-navbar */}
      <div className="dashboard-top">
        <div className="dashboard-top-left">
          <h4 className="dashboard-title">WEYONJE</h4>
          <span className="dashboard-title-dropdown">
            <FiChevronDown size={22} color={Colors.textGray} />
          </span>
        </div>

        <div className="dashboard-top-right">
          <BsBell size={22} color={Colors.textGray} />

          <FaUserCircle
            size={22}
            color={Colors.textGray}
            style={{ marginLeft: "10px" }}
          />

          <p>{userName}</p>

          <div className="dashboard-top-user-info">
            <TouchableOpacity
              clicked={() => setShowDropdown(!showDropdown)}
              altClass="dashboard-top-user-info-touchable"
            >
              {showDropdown ? (
                <FiChevronUp size={22} color={Colors.textGray} />
              ) : (
                <FiChevronDown size={22} color={Colors.textGray} />
              )}
            </TouchableOpacity>

            {showDropdown ? (
              <div className="dashboard-top-user-info-dropdown">
                <TouchableOpacity
                  clicked={() => {}}
                  altClass="dashboard-top-user-info-dropdown-item-br"
                >
                  Account Settings
                </TouchableOpacity>
                <TouchableOpacity
                  clicked={handleLogout}
                  altClass="dashboard-top-user-info-dropdown-item"
                >
                  Logout
                </TouchableOpacity>
              </div>
            ) : null}
          </div>
        </div>
      </div>

      {/* Dashboard lower content */}
      <div className="dashboard-lower-content">
        {/* Tabs */}
        <div className="dashboard-tabs-container">
          {tabListAsArray.map((tab) => {
            return (
              <div
                className={`dashboard-tab ${
                  activeTab === tab.number ? "dashboard-tab-active" : ""
                }`}
                onClick={() => changeActiveTab(tab)}
              >
                {React.cloneElement(tab.icon, {
                  color:
                    activeTab === tab.number
                      ? Colors.darkGreen
                      : Colors.textGray,
                })}
                <p>{tab.name}</p>
              </div>
            );
          })}
        </div>

        {/* Content */}
        <div className="dashboard-content-container">
          {React.cloneElement(tabList[activeTab].component, {
            history,
            location,
            isDO,
          })}
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
