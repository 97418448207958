import React, { useState, useEffect } from "react";
import { Button as AntDBtn, Modal, Input, Select, notification } from "antd";
import Colors from "../../../config/Colors";

// Redux
import { useSelector, useDispatch } from "react-redux";
import * as voucherActions from "../../../redux/actions/voucherActions";
import makeID from "../../../redux/utils/makeID";

const { Option } = Select;

const DeclineModal = ({ visible, setVisible, voucher, onAction }) => {
  // Redux
  const dispatch = useDispatch();
  const doID = useSelector((state) => state.admin.officer?._id);

  // Status
  const defaultDecline = "Choose a reason";

  const [declineReason, setDeclineReason] = useState(defaultDecline);
  const [declineReasonAlt, setDeclineReasonAlt] = useState(defaultDecline);
  const declineOptions = [
    "Overdue",
    "Not paid",
    "Voucher is double entry",
    "Beneficiary is not eligible",
    "Already used voucher",
    "Voucher not in division",
    "Other",
  ];

  const handleDeclineReason = (val) => {
    setDeclineReasonAlt(val);
    if (val === "Other") {
      setDeclineReason("");
    } else {
      setDeclineReason(val);
    }
  };

  const handleDeclineConfirmed = () => {
    const otherReasonChosen = declineReasonAlt === "Other";

    if (
      declineReasonAlt === defaultDecline ||
      (otherReasonChosen && !declineReason)
    ) {
      const noOtherChosen =
        "Please choose a reason for declining this voucher. If you cannot see a relevant one, choose other.";
      const otherChosen =
        "Please provide a reason why you want to decline this voucher.";

      notification.warn({
        message: "Invalid Input!",
        description: otherReasonChosen ? otherChosen : noOtherChosen,
        onClick: () => {},
        duration: 2,
      });
      return;
    }

    if (!doID) {
      notification.warn({
        message: "Invalid User!",
        description: "You are not a District Officer.",
        onClick: () => {},
        duration: 2,
      });
      return;
    }

    const actionDetails = {
      doID,
      // expiryDate,
      status: 2,
      reasonForDenial: declineReason,
      // spID,
      // jobID,
      // reasonForDeactivation,
      // catsRemarks,
      // officerRemarks,
    };

    onAction(actionDetails, voucher?._id);
  };

  return (
    <Modal
      visible={visible}
      title={<b style={{ color: Colors.textGray }}>DECLINE VOUCHER REQUEST</b>}
      onOk={handleDeclineConfirmed}
      onCancel={setVisible}
      footer={[
        <AntDBtn key="logout" onClick={setVisible} className="logout-modal-yes">
          Cancel
        </AntDBtn>,
        <AntDBtn
          key="cancel"
          type="primary"
          danger
          onClick={handleDeclineConfirmed}
          className="logout-modal-no"
        >
          Decline
        </AntDBtn>,
      ]}
    >
      <div style={{ marginBottom: "30px" }}>
        <p style={{ color: Colors.darkText }}>Reason for Decline:</p>
        <Select
          style={{ width: "100%" }}
          onChange={(val) => handleDeclineReason(val)}
          value={declineReasonAlt}
        >
          {declineOptions.map((opt) => (
            <Option value={opt} key={opt}>
              {opt}
            </Option>
          ))}
        </Select>
      </div>

      {declineReasonAlt === "Other" ? (
        <div style={{ marginBottom: "30px" }}>
          <p style={{ color: Colors.darkText }}>"Other" Reason for Decline</p>
          <Input
            placeholder=""
            type="text"
            value={declineReason}
            onChange={(val) => {
              setDeclineReason(val.target.value);
            }}
          />
        </div>
      ) : null}
    </Modal>
  );
};

export default DeclineModal;
