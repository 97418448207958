import React, { useState, useEffect } from "react";
import { Colors } from "./../../config";
import queryString from "query-string";
import { Button, notification } from "antd";
import moment from "moment";
import DeleteModal from "./VoucherModals/DeleteModal";
import DeactivateModal from "./VoucherModals/DeactivateModal";
import ActivateModal from "./VoucherModals/ActivateModal";

// Redux
import { useSelector, useDispatch } from "react-redux";
import * as catsActions from "./../../redux/actions/catsActions";
import makeID from "./../../redux/utils/makeID";

const SingleCats = ({ history }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeactivateModal, setShowDeactivateModal] = useState(false);
  const [showActivateModal, setShowActivateModal] = useState(false);
  const [deactivateStatus, setDeactivateStatus] = useState("");
  const [activateStatus, setActivateStatus] = useState("");
  const [deleteStatus, setDeleteStatus] = useState("");

  // Redux
  const dispatch = useDispatch();
  const catsInState = useSelector((state) => state.cats.cat);
  const catsStatusMessages = useSelector((state) => state.cats.statusMessages);
  const loading = useSelector((state) => state.cats.loading);

  const calculateApprovedOn = (dt) => {
    const dateIsInvalid = `${new Date(dt)}` === "Invalid Date";

    return dateIsInvalid ? dt : moment(dt).format("DD MMMM YYYY hh:mm a");
  };

  useEffect(() => {
    const query = queryString.parse(history.location.search) || {};
    const catsID = query.catsID;
    const actualPath = history.location.pathname;

    if (!catsInState) {
      // This endpoint is not yet created

      dispatch(catsActions.getCats(catsID));
    }
  }, []);

  const handleDelete = () => {
    const statusID = makeID(8);
    setDeleteStatus(statusID);
    dispatch(catsActions.deleteCats(catsInState?._id, statusID));
  };
  const handleDeactivate = () => {
    const statusID = makeID(8);
    setDeactivateStatus(statusID);
    dispatch(catsActions.deactivateCats(catsInState?._id, statusID));
  };

  const handleActivate = () => {
    const statusID = makeID(8);
    setActivateStatus(statusID);
    dispatch(catsActions.activateCats(catsInState?._id, statusID));
  };

  useEffect(() => {
    if (!deleteStatus && !deactivateStatus && !activateStatus) return;

    let notificationTxt = "";
    let success = false;
    let isDelete = false;

    if (activateStatus) {
      const result = catsStatusMessages.find(
        (it) => it.statusID === activateStatus
      );
      if (result?.success) {
        success = true;
        notificationTxt = "CATS has been activated";
      } else {
        success = false;
        notificationTxt = "Failed to activate CATS. Please try again";
      }
      setActivateStatus("");
    }

    if (deleteStatus) {
      isDelete = true;
      const result = catsStatusMessages.find(
        (it) => it.statusID === deleteStatus
      );
      if (result?.success) {
        success = true;
        notificationTxt = "CATS has been deleted";
      } else {
        success = false;
        notificationTxt = "Failed to delete CATS. Please try again";
      }
      setDeleteStatus("");
    }

    if (deactivateStatus) {
      const result = catsStatusMessages.find(
        (it) => it.statusID === deactivateStatus
      );
      if (result?.success) {
        success = true;
        notificationTxt = "CATS has been deactivated";
      } else {
        success = false;
        notificationTxt = "Failed to deactivate CATS. Please try again";
      }

      setDeactivateStatus("");
    }

    if (success) {
      notification.success({
        message: "Update",
        description: notificationTxt,
        onClick: () => {},
        duration: 2,
        onClose: () => {
          setShowDeactivateModal(false);
          setShowDeleteModal(false);
          setShowActivateModal(false);
          if (isDelete) {
            history.push(history.location.pathname);
          } else {
            dispatch(catsActions.getCats(catsInState?._id));
          }
        },
      });
    } else {
      notification.error({
        message: "Update",
        description: notificationTxt,
        onClick: () => {},
        duration: 2,
      });
    }
  }, [catsStatusMessages]);

  return catsInState?._id ? (
    <>
      <DeactivateModal
        visible={showDeactivateModal}
        setVisible={() => setShowDeactivateModal(!showDeactivateModal)}
        onDeactivate={handleDeactivate}
        deactivationTxt={`CAT ${catsInState?.full_name}`}
        loading={loading}
      />
      <ActivateModal
        visible={showActivateModal}
        setVisible={() => setShowActivateModal(!showActivateModal)}
        onActivate={handleActivate}
        activationTxt={`CAT ${catsInState?.full_name}`}
        loading={loading}
      />
      <DeleteModal
        visible={showDeleteModal}
        setVisible={() => setShowDeleteModal(!showDeleteModal)}
        onDelete={handleDelete}
        deleteText={`CAT ${catsInState?.full_name}`}
        loading={loading}
      />

      <div className="single-voucher-container">
        <div className="single-vouchers-top">
          <div className="single-vouchers-left">
            <h3 style={{ color: Colors.darkGreen }}>
              <u>
                <b>CATS/VHTS</b>
              </u>
            </h3>
            <span style={{ color: Colors.textGray }}>{">"}</span>
            <h3 style={{ color: Colors.textGray, marginLeft: "6px" }}>
              {catsInState?.full_name}
            </h3>
          </div>
          <div className="single-vouchers-right">
            <Button
              // loading={loading}

              style={{
                background: Colors.darkGreen,
                borderColor: Colors.darkGreen,
              }}
              key="1"
              type="danger"
              onClick={() => {}}
            >
              Edit
            </Button>

            <Button
              key="2"
              danger
              onClick={
                !catsInState?.isApprovedByDO
                  ? () => setShowActivateModal(true)
                  : () => setShowDeactivateModal(true)
              }
              disabled={false}
            >
              {!catsInState?.isApprovedByDO ? "Activate" : "Deactivate"}
            </Button>

            <Button
              key="3"
              type="danger"
              onClick={() => setShowDeleteModal(true)}
            >
              Delete
            </Button>
          </div>
        </div>

        <div className="single-vouchers-bottom">
          <div className="single-vouchers-bottom-left">
            <div className="sv-bottom-col ">
              <div className="sv-detail-container">
                <h4 className="sv-bottom-title">CATS/VHT NAME</h4>
                <p>{catsInState?.full_name}</p>
              </div>

              <div className="sv-detail-container">
                <h4 className="sv-bottom-title"> CAT/VHT ID:</h4>
                <p> {catsInState?.cats_id}</p>
              </div>

              <div className="sv-detail-container">
                <h4 className="sv-bottom-title"> PHONE NUMBER</h4>
                <p>{catsInState?.phone_number}</p>
              </div>
            </div>

            <div className="sv-bottom-col ">
              <div className="sv-detail-container">
                <h4 className="sv-bottom-title">DIVISION</h4>
                <p>{catsInState?.full_name}</p>
              </div>

              <div className="sv-detail-container">
                <h4 className="sv-bottom-title"> HOUSEHOLDS MAPPED:</h4>
                <p> {catsInState?.number_of_households_mapped}</p>
              </div>
            </div>

            <div className="sv-bottom-col ">
              <div className="sv-detail-container">
                <h4 className="sv-bottom-title">STATUS</h4>
                <p
                  style={{
                    color: catsInState?.isApprovedByDO
                      ? Colors.darkGreen
                      : Colors.yellow,
                    fontWeight: "bold",
                  }}
                >
                  {catsInState?.isApprovedByDO ? "ACTIVE" : "INACTIVE"}
                </p>
              </div>

              <div className="sv-detail-container">
                <h4 className="sv-bottom-title">REFERRAL CODE</h4>
                <p style={{ color: Colors.textGray }}>
                  {catsInState?.referral_code}
                </p>
              </div>
            </div>
          </div>
          <div className="single-vouchers-bottom-right">
            <div className="sv-bottom-col ">
              <div className="sv-detail-container">
                <h4 className="sv-bottom-title"> Registered by </h4>
                <p>Not Provided</p>
                <p style={{ color: Colors.textGray }}>
                  DO {catsInState?.division} Division
                </p>
              </div>

              <div className="sv-detail-container">
                <h4 className="sv-bottom-title"> Approved by </h4>
                <p style={{ color: Colors.textGray }}>Not Provided</p>
                <p style={{ color: Colors.textGray }}>Program Officer</p>
              </div>
            </div>

            <div className="sv-bottom-col ">
              <div className="sv-detail-container">
                <h4 className="sv-bottom-title"> Registration time </h4>
                <p>
                  {moment(catsInState?.createdAt).format(
                    "DD MMMM YYYY hh:mm a"
                  )}
                </p>
              </div>

              <div className="sv-detail-container">
                <h4 className="sv-bottom-title"> Approved time </h4>
                <p>{calculateApprovedOn(catsInState?.date_of_registration)}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : null;
};

export default SingleCats;
