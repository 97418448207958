import { combineReducers } from "redux";
import adminReducer from "./adminReducer";
import jobReducer from "./jobReducer";
import driverReducer from "./driverReducer";
import spReducer from "./spReducer";
import voucherReducer from "./voucherReducer";
import customerReducer from "./customerReducer";
import dumpReducer from "./dumpReducer";
import notificationReducer from "./notificationReducer";
import catsReducer from "./catsReducer";
import divisionReducer from "./divisionReducer";
import dashboardReducer from "./dashboardReducer";

export default combineReducers({
  admin: adminReducer,
  jobs: jobReducer,
  driver: driverReducer,
  voucher: voucherReducer,
  dump: dumpReducer,
  customer: customerReducer,
  sp: spReducer,
  notification: notificationReducer,
  cats: catsReducer,
  division: divisionReducer,
  dashboard: dashboardReducer,
});
