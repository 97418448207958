import React, { useState, useEffect } from "react";
import {
  Button as AntDBtn,
  Modal,
  Input,
  Switch,
  Select,
  notification,
  Spin,
} from "antd";
import Colors from "../../../config/Colors";
// Redux
import { useSelector, useDispatch } from "react-redux";
import * as spActions from "../../../redux/actions/spActions";
import makeID from "../../../redux/utils/makeID";
const { Option } = Select;

const validSPTypes = ["Gulper", "Emptier"];

const AddSpModal = ({ visible, setVisible }) => {
  // Redux
  const dispatch = useDispatch();
  const spStatusMessages = useSelector((state) => state.sp.statusMessages);
  // const person = useSelector((state) => state.admin);
  const loading = useSelector((state) => state.sp.loading);
  const [createStatus, setCreateStatus] = useState("");
  const divisionID = useSelector((state) => state.admin?.officer?.divisionID);
  // const

  // State
  const [isActive, setIsActive] = useState(false);
  const [fullName, setFullName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [orgName, setOrgName] = useState("");
  const [licensePlate, setLicensePlate] = useState("");
  const [workAddress, setWorkAddress] = useState("");
  const [password, setPassword] = useState("");
  const [isApprovedForVoucher, setIsApprovedForVoucher] = useState(false);
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const defaultSpType = "Select Type";
  const [spType, setSpType] = useState(defaultSpType);

  const handleAddCats = () => {
    if (
      !fullName ||
      !phoneNumber ||
      !orgName ||
      !licensePlate ||
      !workAddress ||
      !password ||
      !spType
    ) {
      notification.warn({
        message: "Incomplete Input!",
        description: `Please provide all details`,
        onClick: () => {},
        duration: 2,
      });
      return;
    }

    if (password !== passwordConfirm) {
      notification.warn({
        message: "Passwords do not match!",
        description: `Please provide matching passwords`,
        onClick: () => {},
        duration: 2,
      });
      return;
    }

    const spObj = {
      full_name: fullName,
      phone_number: phoneNumber,
      password: passwordConfirm,
      work_address: workAddress,
      type: spType,
      isApprovedByDO: isApprovedForVoucher,
      ...(divisionID && {
        org: {
          name: orgName,
          division: divisionID,
          plate: licensePlate,
        },
      }),
    };

    const createStatusID = makeID(8);
    setCreateStatus(createStatusID);

    dispatch(spActions.addSP(spObj, createStatusID));
  };

  useEffect(() => {
    if (!createStatus) return;

    const result = spStatusMessages.find((it) => it.statusID === createStatus);

    if (result?.success) {
      const successMessage = "Service Provider saved";
      notification.success({
        message: "Update",
        description: successMessage,
        onClick: () => {},
        duration: 2,
      });
    } else {
      const errorMessage = result?.message || "An unknown error occurred";
      notification.warn({
        message: "An Error Occurred",
        description: errorMessage,
        onClick: () => {},
        duration: 2,
      });
    }

    setCreateStatus("");
  }, [spStatusMessages]);

  return (
    <Modal
      className="add-sp-modal-container"
      visible={visible}
      title={
        <b style={{ color: Colors.textGray }}>ADD A NEW SERVICE PROVIDER</b>
      }
      onOk={handleAddCats}
      onCancel={() => setVisible(false)}
      footer={[
        <AntDBtn key="logout" onClick={() => setVisible(false)}>
          Cancel
        </AntDBtn>,
        <AntDBtn
          key="logout"
          type="primary"
          onClick={handleAddCats}
          className="logout-modal-no"
        >
          Add
        </AntDBtn>,
      ]}
    >
      <Spin spinning={loading}>
        <div className="add-sp-modal">
          <div className="add-sp-modal-left">
            <div style={{ marginBottom: "30px" }}>
              <p style={{ color: Colors.darkText }}>Full Name:</p>
              <Input
                type="text"
                value={fullName}
                onChange={(val) => setFullName(val.target.value)}
                placeholder="Enter Name"
              />
            </div>

            <div style={{ marginBottom: "30px" }}>
              <p style={{ color: Colors.darkText }}>Phone number:</p>
              <Input
                type="text"
                value={phoneNumber}
                onChange={(val) => setPhoneNumber(val.target.value)}
                placeholder="Will be used for login"
              />
            </div>

            <div style={{ marginBottom: "30px" }}>
              <p style={{ color: Colors.darkText }}>Service Provider Type:</p>
              <Select
                style={{ width: "100%" }}
                onChange={(val) => setSpType(val)}
                value={spType}
              >
                {validSPTypes.map((opt) => (
                  <Option value={opt} key={opt}>
                    {opt}
                  </Option>
                ))}
              </Select>
            </div>

            <div style={{ marginBottom: "30px" }}>
              <p style={{ color: Colors.darkText }}>Organisation Name:</p>
              <Input
                type="text"
                value={orgName}
                onChange={(val) => setOrgName(val.target.value)}
                placeholder="Enter Name"
              />
            </div>

            <div style={{ marginBottom: "30px" }}>
              <div>
                <p
                  style={{
                    color: Colors.darkText,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "5px",
                  }}
                >
                  <span>Approved for Voucher Jobs:</span>{" "}
                  <Switch
                    onChange={(val) => setIsApprovedForVoucher(val)}
                    checked={isApprovedForVoucher}
                  />
                </p>
              </div>

              <small>If not approved, they won't see voucher jobs</small>
            </div>
          </div>

          <div className="add-sp-modal-right">
            <div style={{ marginBottom: "30px" }}>
              <p style={{ color: Colors.darkText }}>License Plate:</p>
              <Input
                type="text"
                value={licensePlate}
                onChange={(val) => setLicensePlate(val.target.value)}
                placeholder="Enter Plate"
              />
            </div>

            <div style={{ marginBottom: "30px" }}>
              <p style={{ color: Colors.darkText }}>Work Address:</p>
              <Input
                type="text"
                value={workAddress}
                onChange={(val) => setWorkAddress(val.target.value)}
                placeholder="Enter Address"
              />
            </div>

            <div style={{ marginBottom: "30px" }}>
              <p style={{ color: Colors.darkText }}>Password:</p>
              <Input
                type="text"
                value={password}
                onChange={(val) => setPassword(val.target.value)}
                placeholder="Enter password"
              />
            </div>

            <div style={{ marginBottom: "30px" }}>
              <p style={{ color: Colors.darkText }}>Confirm Password:</p>
              <Input
                type="text"
                value={passwordConfirm}
                onChange={(val) => setPasswordConfirm(val.target.value)}
                placeholder="Confirms password"
              />
            </div>

            <div style={{ marginBottom: "30px" }}>
              <div>
                <p
                  style={{
                    color: Colors.darkText,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "5px",
                  }}
                >
                  <span>Active:</span>{" "}
                  <Switch
                    onChange={(val) => setIsActive(val)}
                    checked={isActive}
                  />
                </p>
              </div>

              <small>If inactive, they will not be able to login</small>
            </div>
          </div>
        </div>
      </Spin>
    </Modal>
  );
};

export default AddSpModal;
