import React, { useState, useEffect, useRef } from "react";
import { Colors } from "../../../config";
import Button from "../../../components/Button";
import defaultData from "./defaultData";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { Table, Space } from "antd";
import { CSVLink } from "react-csv";
import ApproveAllPaymentsModal from "../VoucherModals/ApproveAllPaymentsModal";

// Redux
import { useSelector, useDispatch } from "react-redux";
import * as divisionActions from "../../../redux/actions/divisionActions";
import * as voucherActions from "../../../redux/actions/voucherActions";
import makeID from "../../../redux/utils/makeID";
import configureForCSV from "../../../redux/utils/configureForCSV";

import moment from "moment";

const { Column } = Table;

// const voucherStatuses = {
//   0: "Pending",
//   1: "Approved",
//   2: "Denied",
//   3: "Dispatched",
//   4: "SP Completed Job",
//   5: "Cats Approval",
//   6: "Do Job approval",
//   7: "Deactivated",
//   10: "Completed",
// };

const voucherStatuses = {
  0: "Awaiting Approval",
  1: "Ready to Dispatch to SP",
  2: "Declined",
  3: "Dispatched",
  4: "Awaiting CAT's Approval",
  5: "Awaiting DO Approval",
  6: "Ready for Payment",
  7: "Deactivated",
  10: "Paid",
};

const jobStatuses = {
  0: "Scheduled",
  1: "In Progress",
  2: "Declined",
  3: "Completed",
};

const voucherStatusColors = {
  0: Colors.black,
  1: Colors.darkGreen,
  2: Colors.actionRed,
  3: Colors.statPurple,
  4: Colors.yellow,
  5: Colors.darkGreen,
  6: Colors.darkMaroon,
  7: Colors.hrGray,
  10: Colors.weyonLightBlue,
};

const Payments = ({ isDO, history }) => {
  // State
  const [showAllApprovalModal, setShowAllApprovalModal] = useState(false);
  // Redux
  const dispatch = useDispatch();
  const csvDownloaderRef = useRef(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [csvDataForDownload, setCsvDataForDownload] = useState([]);
  const vouchersInState = useSelector((state) => state.voucher.vouchers);
  const officerInState = useSelector((state) => state.admin.officer);
  const allDivisions = useSelector((state) => state.division.divisions);
  const overview = useSelector((state) => state.voucher.overview);

  const getDOPayments = () => {};

  const vouchersForPayment = isDO
    ? vouchersInState.filter((voucher) => {
        const officerDivision = allDivisions.find(
          (d) => d._id === officerInState?.divisionID
        );
        if (
          voucher.status === 6 &&
          voucher.jobStatus === 1 &&
          voucher.validForDivision === officerDivision?.name
        ) {
          return voucher;
        }
      })
    : vouchersInState.filter(
        (voucher) => voucher.status === 6 && voucher.jobStatus === 1
      );

  const loading = useSelector((state) => state.voucher.loading);

  const insertCommas = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const configureEntitiesForCSV = async () => {
    const configFields = [
      { delete: "_id" },
      { delete: "updatedAt" },
      { delete: "catsDeviceID" },
      {
        new: "Date Created",
        old: "createdAt",
        mutation: (val) => moment(val).format("DD/MM/YYYY, hh:mm a"),
      },
      { new: "Voucher ID", old: "voucherID" },
      { old: "voucherReadableID", new: "Voucher Readable ID" },
      { old: "voucherProgram", new: "Voucher Program" },
      { old: "beneficiaryAmount", new: "Beneficiary Amount" },
      {
        old: "status",
        new: "Voucher Status",
        mutation: (val) => voucherStatuses[val],
      },
      { old: "beneficiary", new: "Beneficiary" },
      { old: "beneficiaryNumber", new: "Beneficiary Number" },
      { old: "householdID", new: "Household ID" },
      { old: "facilityType", new: "Facility Type" },
      { old: "lat", new: "Latitude" },
      { old: "lng", new: "Longitude" },
      { old: "address", new: "Address" },
      { old: "generatedBy", new: "Generated By" },
      { old: "catsPhoneNumber", new: "CATS Phone Number" },
      { old: "catsName", new: "CATS Name" },

      {
        old: "reasonForEligibility",
        new: "Reason For Eligibility",
        mutation: (val) => val?.join(",\n"),
      },

      {
        old: "noOfExtraBarrelsRequested",
        new: "Number of Extra Barrels",
        mutation: (val) => val || 0,
      },

      {
        old: "catsApproved",
        new: "Approved by CATS",
        mutation: (val) => (val ? "Yes" : "No"),
      },
      { old: "paid", new: "Paid", mutation: (val) => (val ? "Yes" : "No") },
      { old: "catsApprovalRemarks", new: "CATS Approval Remarks" },
      { old: "officerName", new: "Officer Name" },
      {
        old: "officerApproved",
        new: "Officer Approved",
        mutation: (val) => (val ? "Yes" : "No"),
      },
      { old: "officerApprovalRemarks", new: "Officer Approval Remarks" },

      { old: "spType", new: "Service Provider Type" },
      { old: "validForDivision", new: "Division" },
      { old: "value", new: "Value" },
      {
        old: "reasonForDenial",
        new: "Reason For Denial",
        mutation: (val) => val || "None given",
      },
      {
        old: "jobCompletedOn",
        new: "Job Completed On",
        mutation: (val) =>
          val ? moment(val).format("DD/MM/YYYY, hh:mm a") : "Not completed",
      },
      {
        old: "expiryDate",
        new: "Expiry Date",
        mutation: (val) =>
          `${moment(val)}` !== "Invalid date"
            ? moment(val).format("DD/MM/YYYY hh:mm a")
            : val,
      },
      { old: "approvedBy", new: "Approved By" },
      {
        old: "approvedOn",
        new: "Approved On",
        mutation: (val) =>
          `${moment(val)}` !== "Invalid date"
            ? moment(val).format("DD/MM/YYYY hh:mm a")
            : val,
      },
      {
        old: "redeemedOn",
        new: "Redeemed On",
        mutation: (val) =>
          `${moment(val)}` !== "Invalid date"
            ? moment(val).format("DD/MM/YYYY hh:mm a")
            : val,
      },

      {
        old: "serviceProvider",
        new: "Service Provider",
        mutation: (val) => val || "None Yet",
      },
      { delete: "jobID" },

      {
        old: "jobStatus",
        new: "Job Status",
        mutation: (val) => jobStatuses[val],
      },
    ];

    await configureForCSV(vouchersForPayment, configFields)
      .then((res) => setCsvDataForDownload(res))
      .then(() => csvDownloaderRef.current.click());
  };

  const handleTime = (val) => {
    const date = new Date(val);
    const dateIsInvalid = `${date}` === "Invalid Date";

    return dateIsInvalid
      ? false
      : moment(new Date(val)).format("MM/DD/YYYY hh:mm");
  };

  const openSingleVoucher = (vchr) => {
    dispatch(voucherActions.putVoucherInState(vchr));
    const newPath = `${history.location.pathname}?vchID=${vchr._id}`;
    history.push(newPath);
  };

  useEffect(() => {
    dispatch(voucherActions.getAllVouchers());
  }, []);

  console.log("vouchersInState are ", vouchersInState);
  return (
    <React.Fragment>
      <div className="voucher-settings-card">
        <div style={{ marginBottom: "1rem", display: "flex" }}>
          <Button color={Colors.darkGreen} onClick={configureEntitiesForCSV}>
            <span style={{ color: Colors.white }}>Export to CSV</span>
          </Button>

          <div style={{ display: "none" }}>
            <CSVLink
              filename={`WEYONJE VOUCHER PAYMENTS SUMMARY(${moment().format(
                "DD-MM-YYYY"
              )}).csv`}
              hidden
              data={csvDataForDownload}
            >
              <span ref={csvDownloaderRef}>Downloader</span>
            </CSVLink>
          </div>

          {isDO ? null : (
            <div style={{ marginLeft: "30px" }}>
              <ApproveAllPaymentsModal
                isDO={isDO}
                vouchers={vouchersForPayment}
              />
            </div>
          )}
        </div>

        <div className="sps-table vouchers-table">
          <Table
            rowKey={(record) => record._id}
            loading={loading}
            dataSource={vouchersForPayment}
            key={Math.random()}
            pagination={{
              defaultPageSize: 5,
              showSizeChanger: true,
              pageSizeOptions: ["5", "8", "10", "20", "30"],
            }}
          >
            <Column
              title={<b>VOUCHER</b>}
              dataIndex="voucherID"
              key={Math.random()}
              width={"160px"}
            />

            <Column
              title={<b>BENEFICIARY NAME</b>}
              // dataIndex="beneficiary.name"
              key={Math.random()}
              width={"160px"}
              render={(text, record) => (
                <Space size="middle" key={Math.random()}>
                  <div className="sp-table-end">
                    <div className="sp-table-end-label">
                      {record.beneficiary}
                    </div>
                  </div>
                </Space>
              )}
            />
            <Column
              title={<b>BENEFICIARY Phone</b>}
              // dataIndex="phoneNumber"
              key={Math.random()}
              width={"160px"}
              render={(text, record) => (
                <Space size="middle" key={Math.random()}>
                  <div className="sp-table-end">
                    <div className="sp-table-end-label">
                      {record.beneficiaryNumber}
                    </div>
                  </div>
                </Space>
              )}
            />
            <Column
              title={<b>HOUSEHOLD ID</b>}
              // dataIndex="noOfDumps"
              key={Math.random()}
              width={"10px"}
              render={(text, record) => (
                <Space size="middle" key={Math.random()}>
                  <div className="sp-table-end">
                    <div className="sp-table-end-label">
                      {record.householdID}
                    </div>
                  </div>
                </Space>
              )}
            />
            <Column
              title={<b>DIVISION</b>}
              dataIndex="validForDivision"
              key={Math.random()}
            />

            <Column
              title={<b>VALUE</b>}
              // dataIndex="type"value
              key={Math.random()}
              width={"140px"}
              render={(text, record) => (
                <Space size="middle" key={Math.random()}>
                  <div className="sp-table-end">
                    <div className="sp-table-end-label">UGX {record.value}</div>
                  </div>
                </Space>
              )}
            />

            <Column
              title={<b>CATS NAME</b>}
              dataIndex="generatedBy"
              value
              key={(record) => `${record._id}6`}
              width={"140px"}
            />

            <Column
              title={<b>SERVICE PROVIDER</b>}
              // dataIndex="type"value
              key={Math.random()}
              width={"140px"}
              render={(text, record) => (
                <Space size="middle" key={Math.random()}>
                  <div className="sp-table-end">
                    <div className="sp-table-end-label">
                      {record.serviceProvider || "None Yet"}
                    </div>
                  </div>
                </Space>
              )}
            />

            <Column
              title={<b>DATE REDEEMED</b>}
              dataIndex="redeemedOn"
              key={Math.random()}
              render={(val) => handleTime(val)}
            />

            <Column
              title={<b>CREATED AT</b>}
              dataIndex="createdAt"
              key={Math.random()}
              render={(val) => handleTime(val)}
            />

            <Column
              title={<b>EXPIRES IN</b>}
              dataIndex="expiryDate"
              render={(val) => {
                const returnVal = handleTime(val) || val;
                return returnVal;
              }}
              key={Math.random()}
            />

            <Column
              title={<b>STATUS</b>}
              key={Math.random()}
              render={(text, record) => (
                <Space size="middle" key={Math.random()}>
                  <div
                    className="sp-table-end"
                    style={{ justifyContent: "flex-start" }}
                  >
                    <div
                      className="sp-table-end-label"
                      style={{
                        color: voucherStatusColors[record.status],
                        marginRight: "5px",
                      }}
                    >
                      {voucherStatuses[record.status]}
                    </div>
                    {voucherStatuses[record.status] === voucherStatuses[3] ? (
                      <div className="sp-table-end-icon">
                        <AiOutlineInfoCircle
                          size={20}
                          color={Colors.darkGreen}
                        />
                      </div>
                    ) : null}
                  </div>
                </Space>
              )}
            />

            <Column
              title={<b>ACTION</b>}
              key={Math.random()}
              width={"160px"}
              render={(record) => (
                <Space
                  size="middle"
                  key={Math.random()}
                  style={{ minWidth: "100px" }}
                >
                  <Button
                    color={Colors.darkGreen}
                    onClick={() => openSingleVoucher(record)}
                  >
                    <p
                      style={{
                        color: Colors.white,
                        margin: "0px",
                        minWidth: "100px",
                      }}
                    >
                      View
                    </p>
                  </Button>
                </Space>
              )}
            />
          </Table>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Payments;
