import deepClone from "lodash.clonedeep";

/**
 * @param {Array} array  An array of objects to be processed for the conversion
 * @param {Array} fields An array of fields in the format [ { new:"", old:"", mutation:()=>{}, delete:"" } ]
 * @returns Array with the mutated output
 */

const configure = async (array, fields) => {
  array = deepClone(array);
  let arrayToReturn = [];
  let x;
  for (x = 0; x < array.length; x++) {
    let currentObj = array[x];
    if (!currentObj) return;
    // currentObj = deepClone(currentObj);
    fields.forEach((field) => {
      if (field.new && field.old) {
        delete Object.assign(currentObj, {
          [field.new]: currentObj[field.old],
        })[field.old];
      }

      if (field.delete) {
        delete currentObj[field.delete];
      }

      if (field.mutation && field.new && field.useParent) {
        currentObj[field.new] = field.mutation(currentObj);
      } else if (field.mutation && field.new) {
        currentObj[field.new] = field.mutation(currentObj[field.new]);
      }
    });
    array[x] = currentObj;
  }
  return array;
};

export default configure;
