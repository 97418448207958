export default class statusMessage {
  constructor({ subject, action, message }) {
    this.message = message
      ? message
      : action.payload.message
      ? action.payload.message
      : `${subject} operation attempted.`;
    this.success = !!action.payload.success;
    this.timeStamp = new Date();
    this.seen = false;
    this.subject = subject;
    this.error =
      !action.payload.success && action.payload.message
        ? action.payload.message
        : action.payload.error && typeof action.payload.error === "string"
        ? action.payload.error
        : !action.payload.success
        ? "An unknown error occurred."
        : false;

    if (action.statusID) {
      this.statusID = action.statusID;
    }
  }
}
