import * as dashboardTypes from '../types/dashboardTypes';
import { dashboardEndpoints } from '../endpoints';
import * as fetchFunctionsLegacy from '../utils/fetchFunctionsLegacy';

export const getJobsData =
  ({ division }, statusID) =>
  async (dispatch, getState) => {
    dispatch({
      type: dashboardTypes.LOADING_DASHBOARD_DATA,
    });

    const { token } = getState().admin;

    const url =
      division !== undefined && division !== ''
        ? `${dashboardEndpoints.getJobsData}?division=${division}`
        : dashboardEndpoints.getJobsData;

    const result = await fetchFunctionsLegacy.getData(url, token);

    dispatch({
      type: dashboardTypes.GET_JOBS_DATA,
      payload: result,
      statusID,
    });
  };

export const getDumpsData = (statusID) => async (dispatch, getState) => {
  dispatch({
    type: dashboardTypes.LOADING_DASHBOARD_DATA,
  });

  const { token } = getState().admin;

  const result = await fetchFunctionsLegacy.getData(
    dashboardEndpoints.getDumpsData,
    token
  );

  // Sort by month
  const byMonth = {
    January: result.data?.filter((it) => it.month === 'January') || [],
    February: result.data?.filter((it) => it.month === 'February') || [],
    March: result.data?.filter((it) => it.month === 'March') || [],
    April: result.data?.filter((it) => it.month === 'April') || [],
    May: result.data?.filter((it) => it.month === 'May') || [],
    June: result.data?.filter((it) => it.month === 'June') || [],
    July: result.data?.filter((it) => it.month === 'July') || [],
    August: result.data?.filter((it) => it.month === 'August') || [],
    September: result.data?.filter((it) => it.month === 'September') || [],
    October: result.data?.filter((it) => it.month === 'October') || [],
    November: result.data?.filter((it) => it.month === 'November') || [],
    December: result.data?.filter((it) => it.month === 'November') || [],
  };

  const payload = {
    ...result,
    data: result?.data ? { byMonth, data: result.data } : {},
  };

  dispatch({
    type: dashboardTypes.GET_DUMPS_DATA,
    payload,
    statusID,
  });
};

export const getSPCustomerData =
  ({ division }, statusID) =>
  async (dispatch, getState) => {
    dispatch({
      type: dashboardTypes.LOADING_DASHBOARD_DATA,
    });

    const { token } = getState().admin;

    const url =
      division !== undefined && division !== ''
        ? `${dashboardEndpoints.getCustomerSPData}?division=${division}`
        : dashboardEndpoints.getCustomerSPData;

    const result = await fetchFunctionsLegacy.getData(url, token);

    dispatch({
      type: dashboardTypes.GET_SP_CUSTOMER_DATA,
      payload: result,
      statusID,
    });
  };
