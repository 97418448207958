import React, { useState, useEffect } from "react";
import TouchableOpacity from "./TouchableOpacity";
import { MdCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md";
import { Colors } from "../config";

const Checkbox = ({ children, checked, onCheck, size }) => {
  return (
    <TouchableOpacity clicked={onCheck}>
      <div className="checkbox-container">
        <div className="checkbox-checkbox">
          {checked ? (
            <MdCheckBox size={size || 20} color={Colors.darkGreen} />
          ) : (
            <MdCheckBoxOutlineBlank
              size={size || 20}
              color={Colors.darkGreen}
            />
          )}
        </div>
        <div className={checked ? "checkbox-label-active" : "checkbox-label"}>
          {children}
        </div>
      </div>
    </TouchableOpacity>
  );
};

export default Checkbox;
