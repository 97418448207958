import React, { useState, useEffect } from 'react';
import { Colors } from '../../../../config';
import VoucherChart from '../../VoucherChart';
import { HiOutlineTicket } from 'react-icons/hi';
import { CustomIconTypes, CustomIcon } from '../../../../components/CustomIcon';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import AllVouchers from '../AllVouchers';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import * as voucherActions from '../../../../redux/actions/voucherActions';
import makeID from '../../../../redux/utils/makeID';

import { notification } from 'antd';
import styles from './style.module.css';

const defaultDataSet = [
  {
    label: 'Approved',
    number: 89,
    color: Colors.darkGreen,
  },
  {
    label: 'Redeemed',
    number: 43,
    color: Colors.yellow,
  },
  {
    label: 'Pending',
    number: 74,
    color: Colors.hrGray,
  },
  {
    label: 'Declined',
    number: 32,
    color: Colors.actionRed,
  },
];

const OverView = ({ history, isDO, spCounts }) => {
  // Redux
  const dispatch = useDispatch();
  const voucherStatusMessages = useSelector(
    (state) => state.voucher.statusMessages
  );
  const DOInState = useSelector((state) => state.admin.officer);
  const [approveStatusID, setApproveStatusID] = useState('');
  const [overviewDataSet, setOverviewDataSet] = useState(defaultDataSet);
  const [overviewDataSet2, setOverviewDataSet2] = useState([]);
  const [divisions, setDivisions] = useState([]);
  const [overviewDataSet2Title, setOverviewDataSet2Title] = useState(
    isDO ? 'Allocation' : 'Payments'
  );
  const [showProgramsDropdown, setShowProgramsDropdown] = useState(false);
  const vouchersInState = useSelector((state) => state.voucher.vouchers);
  const vouchersOverviewInState = useSelector(
    (state) => state.voucher.overview
  );
  const activeProgramInState = useSelector(
    (state) => state.voucher.activeProgram
  );
  const [activeProgram, setActiveProgram] = useState(activeProgramInState);

  const [programName, setProgramName] = useState('');
  const [getVouchersStatus, setGetVouchersStatus] = useState('');
  const [getOverviewStatus, setGetOverviewStatus] = useState('');
  const loading = useSelector((state) => state.voucher.loading);
  const actualLoading = loading && !!getVouchersStatus;
  const [denyStatusID, setDenyStatusID] = useState('');

  const getVouchersOverview = () => {
    const statusID = makeID(8);
    setGetOverviewStatus(statusID);
    dispatch(voucherActions.getVouchersOverview(statusID));
  };

  const handleOverviewReceived = (val) => {
    if (!val) return;
    let overviews = [];
    let overviewDataSet2 = [];

    // const activeProgram = val?.find((v) => v.isActive === false);

    setProgramName(activeProgram?.programName || '');

    const gulperCompleted = spCounts?.gulperCount || 0;
    const emptierCompleted = spCounts?.emptierCount || 0;
    const completedVouchers = +gulperCompleted + +emptierCompleted;
    let totalVouchers = activeProgram.totalVouchersAvailable;

    if (!isDO) {
      const actualOverview = activeProgram;

      let approvedVouchers = activeProgram?.approvedVouchers;
      let declinedVouchers =
        vouchersInState.filter(
          (v) =>
            v.status === 2 && actualOverview.programName === v.voucherProgram
        )?.length || 0;
      let pendingVouchers =
        vouchersInState.filter(
          (v) =>
            v.status === 0 && actualOverview.programName === v.voucherProgram
        )?.length || 0;
      let redeemedVouchers = 0;
      let vouchersLeft = +totalVouchers - +approvedVouchers;

      if (!actualOverview?.divisions?.length) return false;
      actualOverview.divisions.forEach((div) => {
        declinedVouchers = Number(div.declinedVouchers) + declinedVouchers;

        redeemedVouchers = Number(div.redeemedVouchers) + redeemedVouchers;
      });

      overviews = [
        {
          label: 'Total Vouchers Allocated',
          number: totalVouchers,
          color: Colors.darkBackground,
        },
        {
          label: 'Total Vouchers Approved',
          number: approvedVouchers,
          color: Colors.darkGreen,
        },
        {
          label: 'Total Vouchers  Left',
          number: vouchersLeft,
          color: Colors.statPurple,
        },
        {
          label: 'Total Vouchers Pending',
          number: pendingVouchers,
          color: Colors.hrGray,
        },
        // {
        //   label: "Redeemed",
        //   number: redeemedVouchers,
        //   color: Colors.yellow,
        // },
        {
          label: 'Total Vouchers Declined',
          number: declinedVouchers,
          color: Colors.actionRed,
        },

        {
          label: 'Total No of jobs completed by Emptiers',
          number: emptierCompleted,
          color: Colors.weyonLightBlue,
        },
        {
          label: 'Total No of jobs completed by Gulpers',
          number: gulperCompleted,
          color: Colors.yellow,
        },
      ];
    } else {
      if (!activeProgram?.divisions?.length) return;

      const divisionData =
        activeProgram?.divisions?.find(
          (div) => div.divisionID === DOInState.divisionID
        ) ?? [];

      let approvedVouchers = 0;
      let declinedVouchers = 0;

      totalVouchers = 0;

      activeProgram.divisions.forEach((div) => {
        totalVouchers += div.maxVouchers;
        declinedVouchers += div.declinedVouchers;
        approvedVouchers += div.approvedVouchers;
      });

      const { divisionName } = divisionData;

      overviews = isDO
        ? [
            {
              label: 'Completed Vouchers',
              number: completedVouchers,
              color: Colors.hrGray,
            },
            {
              label: 'Emptier Completed',
              number: emptierCompleted,
              color: Colors.weyonLightBlue,
            },
            {
              label: 'Gulper Completed',
              number: gulperCompleted,
              color: Colors.yellow,
            },
          ]
        : [
            {
              label: 'Total Vouchers',
              number: totalVouchers,
              color: Colors.hrGray,
            },
            {
              label: 'Approved',
              number: approvedVouchers,
              color: Colors.darkGreen,
            },

            {
              label: 'Declined',
              number: declinedVouchers,
              color: Colors.actionRed,
            },
            {
              label: 'Emptier Completed',
              number: emptierCompleted,
              color: Colors.weyonLightBlue,
            },
            {
              label: 'Gulper Completed',
              number: gulperCompleted,
              color: Colors.yellow,
            },
          ];

      overviewDataSet2 = [
        {
          label: (
            <span>
              Total Vouchers <br />
              Allocated for {divisionName}
            </span>
          ),
          number: divisionData.maxVouchers,
          color: Colors.weyonLightBlue,
        },
        {
          label: 'Approved',
          number: divisionData.approvedVouchers,
          color: Colors.darkGreen,
        },
        {
          label: 'Declined',
          number: divisionData.declinedVouchers,
          color: Colors.actionRed,
        },
        {
          label: 'Vouchers left',
          number:
            Number(divisionData.maxVouchers) -
            Number(divisionData.approvedVouchers),
          color: Colors.hrGray,
        },
      ];
    }

    setOverviewDataSet2(overviewDataSet2);
    setOverviewDataSet(overviews);
  };

  const dataSource = vouchersInState;
  const [filteredData, setFilteredData] = useState(dataSource || []);

  const handleSetProgram = (p) => {
    dispatch(voucherActions.setActiveProgram(p));
    setShowProgramsDropdown(false);
  };

  const handleDivisions = (d) => {
    if (!d?.length) return;

    if (!activeProgram?.divisions?.length) return;
    const divisionData = activeProgram?.divisions.map((division) => {
      return {
        name: division.divisionName,
        data: [
          {
            label: 'Vouchers Approved',
            number: division.approvedVouchers,
            color: Colors.darkGreen,
          },
          {
            label: 'Vouchers left',
            number:
              Number(division.maxVouchers) - Number(division.approvedVouchers),
            color: Colors.hrGray,
          },
        ],
      };
    });

    setDivisions(divisionData);
  };

  useEffect(() => {
    getVouchersOverview();
  }, []);

  useEffect(() => {
    if (
      !getVouchersStatus &&
      !getOverviewStatus &&
      !approveStatusID &&
      !denyStatusID
    )
      return;
    const vouchersResult = voucherStatusMessages.find(
      (it) => it.statusID === getVouchersStatus
    );
    const overviewResult = voucherStatusMessages.find(
      (it) => it.statusID === getVouchersStatus
    );

    if (getVouchersStatus && vouchersResult) {
      setFilteredData(dataSource);
      setGetVouchersStatus('');
    }

    if (getOverviewStatus && overviewResult) {
      handleOverviewReceived(vouchersOverviewInState);
      setGetOverviewStatus('');
    }

    if (approveStatusID) {
      const approvalResult = voucherStatusMessages.find(
        (d) => d.statusID === approveStatusID
      );

      if (!approvalResult) return;
      if (approvalResult.success) {
        notification.success({
          message: 'Update',
          description: 'Voucher has been approved',
          onClick: () => {},
          duration: 2,
          onClose: () => {},
        });
      } else {
        notification.error({
          message: 'Error!',
          description: 'Voucher has not been updated. Please try again',
          onClick: () => {},
          duration: 2,
        });
      }

      if (approveStatusID) setApproveStatusID('');
    }

    if (denyStatusID) {
      const result = voucherStatusMessages.find(
        (d) => d.statusID === denyStatusID
      );

      if (!result) return;
      if (result.success) {
        notification.success({
          message: 'Update',
          description: 'Voucher has been denied',
          onClick: () => {},
          duration: 2,
        });
      } else {
        notification.error({
          message: 'Error!',
          description: 'Voucher has not been updated. Please try again',
          onClick: () => {},
          duration: 2,
        });
      }
    }
  }, [voucherStatusMessages]);

  useEffect(() => {
    handleOverviewReceived(vouchersOverviewInState);
    handleDivisions(vouchersOverviewInState);
  }, [vouchersOverviewInState, spCounts, activeProgramInState]);

  useEffect(() => {
    if (!activeProgramInState) return;
    setActiveProgram(activeProgramInState);
  }, [activeProgramInState]);

  return (
    <React.Fragment>
      <div className='voucher-chart-title' style={{ marginTop: '20px' }}>
        <div className={styles.programDropdownContainer}>
          <h2
            className={styles.programName}
            onClick={() => setShowProgramsDropdown(!showProgramsDropdown)}
          >
            {programName}{' '}
            {!showProgramsDropdown ? <FiChevronDown /> : <FiChevronUp />}
          </h2>
          {showProgramsDropdown ? (
            <div className={styles.programDropdown}>
              {vouchersOverviewInState.map((p) => (
                <p
                  className={styles.programOption}
                  onClick={() => handleSetProgram(p)}
                >
                  {p.programName}
                </p>
              ))}
            </div>
          ) : null}
        </div>
      </div>
      <div className='vouchers-chart-container'>
        <div className='vouchers-chart-container-left'>
          <VoucherChart
            dataset={overviewDataSet}
            size={150}
            title='Vouchers'
            icon={<HiOutlineTicket size={40} />}
          />
        </div>

        <div className='vouchers-chart-container-right'>
          {/* {isDO ? a : null} */}
          {isDO ? (
            <VoucherChart
              dataset={overviewDataSet2}
              size={150}
              title={overviewDataSet2Title}
              icon={<CustomIcon size={2.5} type={CustomIconTypes.card} />}
            />
          ) : divisions?.length ? (
            divisions.map((d) => {
              return (
                <VoucherChart
                  dataset={d.data}
                  size={100}
                  title={d.name}
                  icon={<CustomIcon size={2.5} type={CustomIconTypes.card} />}
                />
              );
            })
          ) : null}
        </div>
      </div>

      <AllVouchers history={history} isDO={isDO} />
    </React.Fragment>
  );
};

export default OverView;
