const endpoints = (base, prefix, apiVersion) => {
  const defaultEndpoint = "entreprenuer";
  const altEndpoint = "entreprenuers";
  const authEndpoint = "auth";
  const spEndpoint = "sp";
  // {{prodAPI}}/api/entreprenuers/all/1
  return {
    get: `${base}/${prefix}/${defaultEndpoint}`,
    getAll: `${base}/${prefix}/${altEndpoint}/all/1`,
    register: `${base}/${authEndpoint}/${defaultEndpoint}/register`,
    getNearest: `${base}/${prefix}/${spEndpoint}/nearest`,
    getSp: (id) => `${base}/${prefix}/${defaultEndpoint}/${id}`,
    delete: (id) => `${base}/${prefix}/${defaultEndpoint}/${id}`,
    update: (id) => `${base}/${prefix}/${defaultEndpoint}/${id}`,
    getAllVoucherSPs: (id) =>
      `${base}/${prefix}/${altEndpoint}/all/division/${id}`,
    // "/entreprenuers/all/division/:id
  };
};
// /entreprenuer/:id
export default endpoints;
// https://api.weyonje.com/auth/entreprenuer/register
// https://api.weyonje.com/api/auth/entreprenuer/register
