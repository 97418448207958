import React, { useState, useEffect } from "react";
import { Colors } from "../../../../config";
import BottomBars from "../Components/BottomBars";
import CircleLabel from "../Components/CircleLabel";

const BottomOverview = ({
  labels = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  data,
  plantsVol,
  leftLoading,
  rightLoading,
}) => {
  if (!data) {
    data = [
      {
        label: "Central",
        data: [4, 4, 10, 22, 2, 13, 14, 14, 22, 12, 31, 14],
      },
      {
        label: "Kawempe",
        data: [2, 10, 5, 2, 20, 30, 45, 10, 12, 2, 3, 18],
      },
      {
        label: "Makindye",
        data: [4, 12, 8, 10, 5, 8, 23, 15, 22, 13, 6, 40],
      },
      {
        label: "Nakawa",
        data: [41, 22, 18, 1, 15, 18, 13, 25, 12, 23, 26, 17],
      },
      {
        label: "Rubaga",
        data: [30, 18, 1, 18, 18, 13, 25, 12, 23, 26, 17, 10],
      },
    ];
  }

  if (!plantsVol) {
    plantsVol = {
      bugolobi: 55,
      lubigi: 10,
    };
  }

  const tension = 0.3;
  const borderRadius = 10;
  const tripsData = {
    name: "OVERVIEW",
    number: 1,
    background: Colors.yellow,
    gradColor: "rgba(235, 241, 226, #)",
    lineColor: Colors.darkGreen,
    chartTitle: "Trend of emptying trips by division",
    statCardColor: Colors.statCardGreen,
    labels: labels,
    datasets: [
      {
        label: "Central",
        backgroundColor: Colors.actionRed,
        borderColor: Colors.actionRed,
        data: data.find((it) => it.label === "Central").data,
        tension,
        borderRadius,
        borderDash: [],
        borderDashOffset: 0.0,
      },
      {
        label: "Kawempe",
        backgroundColor: Colors.yellow,
        borderColor: Colors.yellow,
        data: data.find((it) => it.label === "Kawempe").data,
        tension,
        borderRadius,
        borderDash: [],
        borderDashOffset: 0.0,
      },
      {
        label: "Makindye",
        backgroundColor: Colors.statPurple,
        borderColor: Colors.statPurple,
        data: data.find((it) => it.label === "Makindye").data,
        tension,
        borderRadius,
        borderDash: [],
        borderDashOffset: 0.0,
      },
      {
        label: "Nakawa",
        backgroundColor: Colors.weyonLightBlue,
        borderColor: Colors.weyonLightBlue,
        data: data.find((it) => it.label === "Nakawa").data,
        tension,
        borderRadius,
        borderDash: [],
        borderDashOffset: 0.0,
      },
      {
        label: "Rubaga",
        backgroundColor: `${Colors.barChartGreen}80`,
        borderColor: `${Colors.barChartGreen}80`,
        data: data.find((it) => it.label === "Rubaga").data,
        tension,
        borderRadius,
        borderDash: [],
        borderDashOffset: 0.0,
      },
    ],
  };

  const plantsData = {
    name: "OVERVIEW",
    number: 1,
    background: Colors.yellow,
    gradColor: "rgba(235, 241, 226, #)",
    lineColor: Colors.darkGreen,
    chartTitle: "Dumping Volume at Sewage plants",
    statCardColor: Colors.statCardGreen,
    labels: [""],
    datasets: [
      {
        label: "Bugolobi",
        backgroundColor: Colors.weyonLightBlue,
        borderColor: Colors.weyonLightBlue,
        data: [plantsVol.bugolobi],
        tension,
        borderRadius,
        borderDash: [],
        borderDashOffset: 0.0,
      },
      {
        label: "Lubigi",
        backgroundColor: Colors.yellow,
        borderColor: Colors.yellow,
        data: [plantsVol.lubigi],
        tension,
        borderRadius,
        borderDash: [],
        borderDashOffset: 0.0,
      },
    ],
  };
  return (
    <div className="bottom-overview-container">
      <div className="bottom-overview-left">
        <BottomBars
          title={tripsData.chartTitle}
          labels={tripsData.labels}
          datasets={tripsData.datasets}
          gradColor={tripsData.gradColor}
          lineColor={tripsData.lineColor}
          loading={leftLoading}
        />
        <div className="bottom-bars-labels">
          {tripsData.datasets.map((it) => (
            <CircleLabel
              label={it.label}
              color={it.backgroundColor}
              key={it.label}
            />
          ))}
        </div>
      </div>
      <div className="bottom-overview-right">
        <BottomBars
          title={plantsData.chartTitle}
          labels={plantsData.labels}
          datasets={plantsData.datasets}
          gradColor={plantsData.gradColor}
          lineColor={plantsData.lineColor}
          small
          loading={rightLoading}
        />
        <div className="bottom-bars-labels">
          {plantsData.datasets.map((it) => (
            <CircleLabel
              label={it.label}
              color={it.backgroundColor}
              key={it.label}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default BottomOverview;
