// /api/v1/divisions
export default (base, prefix, apiVersion) => {
  const defaultEndpoint = 'division';

  return {
    get: `${base}/${prefix}/${apiVersion}/${defaultEndpoint}`, // /:id
    getAll: `${base}/${prefix}/${apiVersion}/${defaultEndpoint}`,
    add: `${base}/${prefix}/${apiVersion}/${defaultEndpoint}`,
  };
};
