import * as driverTypes from "../types/driverTypes";
import StatusMessage from "../models/statusMessage";

const initialState = {
  drivers: [],
  availableDrivers: [],
  loading: false,
  driver: {},
  token: "",
  statusMessages: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case driverTypes.GET_DRIVER:
      return {
        ...state,
        loading: false,
        driver: action.payload || state.driver,
        statusMessages: [
          ...state.statusMessages,
          new StatusMessage({ subject: driverTypes.GET_DRIVER, action }),
        ],
      };

    case driverTypes.GET_ALL_DRIVERS:
      return {
        ...state,
        loading: false,
        drivers: action.payload || state.drivers,
        statusMessages: [
          ...state.statusMessages,
          new StatusMessage({ subject: driverTypes.GET_ALL_DRIVERS, action }),
        ],
      };

    case driverTypes.GET_AVAILABLE_DRIVERS:
      return {
        ...state,
        loading: false,
        availableDrivers: action.payload.data || [],
        statusMessages: [
          ...state.statusMessages,
          new StatusMessage({
            subject: driverTypes.GET_AVAILABLE_DRIVERS,
            action,
          }),
        ],
      };

    case driverTypes.LOADING_DRIVERS:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}
