import React, { useState, useEffect } from "react";
import { Colors } from "../../../config";
import Button from "../../../components/Button";

import defaultData from "./defaultData";
import { AiOutlineSearch } from "react-icons/ai";
import { Select, Table, Space } from "antd";
import AddCATModal from "./../VoucherModals/AddCATModal";

// Redux
import { useSelector, useDispatch } from "react-redux";
import * as catsActions from "../../../redux/actions/catsActions";
import * as utils from "../../../redux/utils/componentUtils";

import makeID from "../../../redux/utils/makeID";
import moment from "moment";

const { Option } = Select;
const { Column } = Table;

const CATsVHTs = ({ history }) => {
  const [activeTab, setActiveTab] = useState(1);
  const [showAddCatModal, setShowAddCatModal] = useState(false);
  const dispatch = useDispatch();
  const catsInState = useSelector((state) => state.cats.cats);
  const catsLoading = useSelector((state) => state.cats.loading);
  const [filteredData, setFilteredData] = useState(catsInState);
  const [searchText, setSearchText] = useState("");

  const handleSearch = (text) => {
    const result = utils.search(
      text,
      catsInState,
      ["full_name", "referral_code", "phone_number", "cats_id"],
      "_id"
    );

    setFilteredData(result);
  };

  useEffect(() => {
    if (searchText.length) {
      handleSearch(searchText);
    } else {
      setFilteredData(catsInState);
    }
  }, [searchText]);

  const openSingleCats = (cats) => {
    dispatch(catsActions.putCatInState(cats));
    const newPath = `${history.location.pathname}?catsID=${cats._id}`;
    history.push(newPath);
  };

  useEffect(() => {
    dispatch(catsActions.getAllCats());
  }, []);

  return (
    <React.Fragment>
      <AddCATModal visible={showAddCatModal} setVisible={setShowAddCatModal} />
      <div className="voucher-settings-card">
        <div style={{ marginBottom: "1rem", display: "flex" }}>
          <Button color={Colors.darkGreen}>
            <span style={{ color: Colors.white }}>Export to CSV</span>
          </Button>
          <div style={{ marginLeft: "40px" }}>
            <Button
              color={Colors.darkGreen}
              onClick={() => setShowAddCatModal(true)}
            >
              <span style={{ color: Colors.white }}>ADD CATs/VHTs</span>
            </Button>
          </div>

          <div className="job-search-container">
            <div className="job-search-input-wrapper">
              <input
                placeholder="Search CAT's name, referral code, phone number"
                value={searchText}
                onChange={(e) => setSearchText(utils.getInputVal(e))}
              />
            </div>
            <div className="job-search-icon">
              <AiOutlineSearch color={Colors.textGray} size={20} />
            </div>
          </div>
        </div>

        <div className="sps-table vouchers-table">
          <Table
            rowKey={(record) => record._id}
            loading={catsLoading}
            // searchableProps={{ fuzzySearch: true }}
            dataSource={filteredData}
            key={Math.random()}
            pagination={{
              defaultPageSize: 5,
              showSizeChanger: true,
              pageSizeOptions: ["5", "8", "10", "20", "30"],
            }}
          >
            <Column
              title={<b>CAT/VHT NAME</b>}
              dataIndex="full_name"
              key={Math.random()}
              width={"160px"}
            />

            <Column
              title={<b>CAT/VHT ID</b>}
              dataIndex="cats_id"
              key={Math.random()}
              width={"100px"}
            />
            <Column
              title={<b>PHONE NUMBER</b>}
              dataIndex="phone_number"
              key={Math.random()}
              width={"160px"}
            />

            <Column
              title={<b>REFERRAL CODE</b>}
              dataIndex="referral_code"
              key={Math.random()}
              width={"160px"}
            />

            <Column
              title={<b>DIVISION</b>}
              dataIndex="division"
              width={"160px"}
              key={Math.random()}
            />

            <Column
              title={<b>HOUSEHOLDS MAPPED</b>}
              dataIndex="number_of_households_mapped"
              key={Math.random()}
              width={"140px"}
            />

            <Column
              title={<b>DATE OF REGISTRATION</b>}
              dataIndex="cat"
              render={(val) => moment(val).format("DD/MM/YYYY hh:mm a")}
            />

            <Column
              title={<b>STATUS</b>}
              dataIndex="isApprovedByDO"
              render={(val) => (val ? "Active" : "Inactive")}
              key={Math.random()}
            />

            <Column
              title={<b>ACTION</b>}
              key={Math.random()}
              render={(text, record) => (
                <Space size="middle" key={Math.random()}>
                  <Button
                    color={Colors.darkGreen}
                    onClick={() => openSingleCats(record)}
                  >
                    <p
                      style={{
                        color: Colors.white,
                        margin: "0px",
                        minWidth: "100px",
                      }}
                    >
                      View
                    </p>
                  </Button>
                </Space>
              )}
            />
          </Table>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CATsVHTs;

/**
 {
    "isApprovedByDO": true,
    "number_of_households_mapped": 0,
    "_id": "60787bfc9e65b9748a6201e4",
    "phone_number": "0774707118",
    "full_name": "Brian Ochan - Test",
    "division": "Nakawa",
    "date_of_registration": "2021-04-15T17:46:36.536Z",
    "referral_code": "BWwmL695og",
    "createdAt": "2021-04-15T17:46:36.538Z",
    "updatedAt": "2021-04-21T07:13:00.922Z",
    "cats_id": 1,
    "__v": 0
}
 */
