// CRUD Operations
export const CREATE_SP = "CREATE_SP";
export const UPDATE_SP = "UPDATE_SP";
export const DELETE_SP = "DELETE_SP";
export const GET_SP = "GET_SP";
export const GET_ALL_SPS = "GET_ALL_SPS";
export const GET_NEAREST_SP = "GET_NEAREST_SP";
export const ADD_SP = "ADD_SP";
export const PUT_SP_IN_STATE = "PUT_SP_IN_STATE";

// Misc
export const LOADING_SPS = "LOADING_SPS";
