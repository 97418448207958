import React, { useState, useEffect } from "react";
import { Colors } from "./../config";
import { ImSpinner2 } from "react-icons/im";

const spinner = <div></div>;

const Button = ({
  onClick,
  children,
  color,
  type,
  extraClassName,
  disabled,
  loading,
  style = {},
}) => {
  const handleClick = () => {
    if (loading || disabled) {
      return;
    }
    if (!onClick) return;
    onClick();
  };
  return (
    <button
      type={type || "button"}
      className={`custom-button ${extraClassName}`}
      onClick={handleClick}
      style={{
        ...style,
        background:
          loading || disabled ? Colors.disabledGray : color || Colors.white,
        cursor: !disabled ? "pointer" : "not-allowed",
      }}
    >
      {children}
      {loading ? (
        <ImSpinner2
          className="custom-loading-spinner"
          color={Colors.white}
          size={20}
        />
      ) : null}
    </button>
  );
};

export default Button;
