import React, { useState, useEffect, useRef } from 'react';
import Button from './../../components/Button';
import { AiOutlineSearch } from 'react-icons/ai';
import { CSVLink } from 'react-csv';
import { Select, Table, Space } from 'antd';
import { RiCalendarTodoFill } from 'react-icons/ri';
import CustomDateBtn from '../../components/CustomDateBtn';

import { Colors } from './../../config';
import * as utils from './../../redux/utils/componentUtils';
import defaultData from './defaultData';
import TouchableOpacity from './../../components/TouchableOpacity';
import configureForCSV from './../../redux/utils/configureForCSV';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import * as dumpActions from './../../redux/actions/dumpActions';
import makeID from './../../redux/utils/makeID';
import moment from 'moment';

const { Option } = Select;
const { Column } = Table;

/**
​​​
​​​


 */

const Dumps = ({ isDO }) => {
  const csvDownloaderRef = useRef(null);
  const [csvDataForDownload, setCsvDataForDownload] = useState([]);

  // Redux
  const dispatch = useDispatch();
  const dumpStatusMessages = useSelector((state) => state.dump.statusMessages);
  const dumpsInState = useSelector((state) => state.dump.dumps);
  const [getDumpStatus, setGetDumpStatus] = useState('');
  const loading = useSelector((state) => state.dump.loading);
  const doDivision = useSelector((state) => state.admin.officer?.divisionID);

  const dataSource = isDO
    ? dumpsInState?.filter((dump) => dump.division === doDivision)
    : dumpsInState;

  const [facilityType, setFacilityType] = useState('All');
  const [filteredData, setFilteredData] = useState(dataSource);
  const [searchText, setSearchText] = useState('');

  const getDumps = () => {
    const statusID = makeID(8);
    setGetDumpStatus(statusID);
    dispatch(dumpActions.getAllDumps(statusID));
  };

  const handleSearch = (text) => {
    const result = utils.search(
      text,
      dataSource,
      ['full_name', 'phone_number', 'location', 'sp_type'],
      'dump_id'
    );

    setFilteredData(result);
  };

  const configureEntitiesForCSV = async () => {
    const configFields = [
      { delete: '_id' },

      {
        new: 'Date',
        old: 'date',
        mutation: (val) => moment(val).format('DD/MM/YYYY, hh:mm a'),
      },
      { new: 'Dump ID', old: 'dump_id' },
      { old: 'volume', new: 'Volume' },
      {
        old: 'full_name',
        new: 'Full Name',
      },
      { old: 'phone_number', new: 'Phone Number' },

      {
        old: 'jobs',
        new: 'Number of Jobs',
        mutation: (val) => val?.length || 'Not provided',
      },

      { old: 'sp_type', new: 'Service Provider Type' },

      {
        new: 'Dumping Site',
        old: 'location',
      },
      {
        new: 'Verification Status',
        old: 'verification_status',
      },

      {
        new: 'Verified By',
        old: 'verified_by',
      },

      {
        new: 'Latitude',
        old: 'lat',
      },
      {
        new: 'Longitude',
        old: 'long',
      },
    ];

    await configureForCSV(dumpsInState, configFields)
      .then((res) => setCsvDataForDownload(res))
      .then(() => csvDownloaderRef.current.click());
  };

  useEffect(() => {
    if (searchText.length) {
      handleSearch(searchText);
    } else {
      setFilteredData(dataSource);
    }
  }, [searchText]);

  useEffect(() => {
    getDumps();
  }, []);

  useEffect(() => {}, [dataSource]);

  useEffect(() => {
    if (!getDumpStatus) return;

    const result = dumpStatusMessages.find(
      (it) => it.statusID === getDumpStatus
    );
    setFilteredData(dataSource);
  }, [dumpStatusMessages]);

  // console.log("Dumps are", dumpsInState);

  return (
    <div className='sps'>
      <div className='dumps-top'>
        <h3>DUMPS</h3>
      </div>
      <div className='dumps-bottom'>
        <div className='dumps-search-row'>
          <div className='dumps-select-container'>
            <p className='dumps-select-label'>Dumping Location</p>
            <Select
              showSearch
              style={{ width: '100%' }}
              // placeholder={}
              optionFilterProp='Status'
              onChange={(val) => setFacilityType(val)}
              value={facilityType}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value='Bugolobi'>Bugolobi</Option>
              <Option value='Lubigi'>Lubigi</Option>
            </Select>
          </div>

          <div className='dumps-select-container'>
            <p className='dumps-select-label'>Type of Service Provider</p>
            <Select
              showSearch
              style={{ width: '100%' }}
              // placeholder={}
              optionFilterProp='Status'
              onChange={(val) => setFacilityType(val)}
              value={facilityType}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value='Gulper'>Gulper</Option>
              <Option value='Emptier'>Emptier</Option>
              <Option value='Others'>Others</Option>
            </Select>
          </div>

          <div className='dashboard-home-dispatch-button'>
            <CustomDateBtn noMargin />
          </div>
        </div>

        <div style={{ marginBottom: '1rem' }} className='dumps-export-row'>
          <div style={{ marginBottom: '1rem' }}>
            <Button color={Colors.darkGreen} onClick={configureEntitiesForCSV}>
              <span style={{ color: Colors.white }}>Export to CSV</span>
            </Button>
          </div>

          <div style={{ display: 'none' }}>
            <CSVLink
              filename={`WEYONJE DUMPS SUMMARY(${moment().format(
                'DD-MM-YYYY'
              )}).csv`}
              hidden
              data={csvDataForDownload}
            >
              <span ref={csvDownloaderRef}>Downloader</span>
            </CSVLink>
          </div>

          <div className='dump-search-container'>
            <div className='dump-search-input-wrapper'>
              <input
                placeholder='Search full name, phone number, sp type'
                value={searchText}
                onChange={(e) => setSearchText(utils.getInputVal(e))}
              />
            </div>
            <div className='dump-search-icon'>
              <AiOutlineSearch color={Colors.textGray} size={20} />
            </div>
          </div>
        </div>

        <div className='dumps-table'>
          <Table
            rowKey={(record) => record._id}
            loading={loading}
            // searchable={true}
            // searchableProps={{ fuzzySearch: true }}
            dataSource={filteredData}
            key={Math.random()}
            pagination={{
              defaultPageSize: 5,
              showSizeChanger: true,
              pageSizeOptions: ['5', '8', '10', '20', '30'],
            }}
          >
            <Column
              title={<b>Date</b>}
              // dataIndex="date"
              key={Math.random()}
              render={(text, record) => (
                <Space size='middle' key={Math.random()}>
                  <div className='dump-table-end-label'>
                    {moment(record.date).format('DD/MM/YYYY')}
                  </div>
                </Space>
              )}
            />

            <Column
              title={<b>Dump id</b>}
              dataIndex='dump_id'
              key={Math.random()}
            />

            <Column
              title={<b>sp Name</b>}
              dataIndex='full_name'
              key={Math.random()}
            />
            <Column
              title={<b>sp Number</b>}
              dataIndex='phone_number'
              key={Math.random()}
            />

            <Column
              title={<b>sp type</b>}
              dataIndex='sp_type'
              key={Math.random()}
            />

            <Column
              title={<b>Dump Location</b>}
              dataIndex='location'
              key={Math.random()}
            />
            <Column
              title={<b>Volume</b>}
              key={Math.random()}
              render={(text, record) => (
                <Space size='middle' key={Math.random()}>
                  <div className='dump-table-end-label'>
                    {record.volume} m<sup>3</sup>
                  </div>
                </Space>
              )}
            />

            <Column
              title={<b>longitude</b>}
              dataIndex='long'
              key={Math.random()}
            />

            <Column
              title={<b>Latitude</b>}
              dataIndex='lat'
              key={Math.random()}
            />
          </Table>
        </div>
      </div>
    </div>
  );
};

export default Dumps;
