import React, { useState, useEffect } from 'react';

import { Spin } from 'antd';

import { Colors } from './../../config';

import Chart from './../../components/Chart';
import MapChart from './MapChart/MapChart';
import * as jobActions from './../../redux/actions/jobActions';
import * as dashboardActions from '../../redux/actions/dashboardActions';
import { insertCommas } from '../../redux/utils/componentUtils';

// Bottom tabs
import BottomOverview from './BottomTabSections/Tabs/BottomOverview';
import BottomDumping from './BottomTabSections/Tabs/BottomDumping';
import BottomEmptying from './BottomTabSections/Tabs/BottomEmptying';
import BottomMapView from './BottomTabSections/Tabs/BottomMapView';

import { useDispatch, useSelector } from 'react-redux';
import CustomDateBtn from '../../components/CustomDateBtn';

const Home = () => {
  const dispatch = useDispatch();
  const dashboardState = useSelector((state) => state.dashboard);
  const dumpsLoading = !dashboardState.dumpsData;
  const jobsDataLoading = !dashboardState.jobsData;

  const [activeTab, setActiveTab] = useState(1);
  const userInState = useSelector((state) => state.admin);

  const userRole = userInState.role;
  const doDivision = userInState.divisionName;

  const [dateFilter, setDateFilter] = useState({});

  // Statistics
  const [dumpingBySP, setDumpingBySP] = useState([
    2, 10, 5, 2, 20, 30, 45, 10, 12, 2, 3, 18,
  ]);
  const [totalRevenue, setTotalRevenue] = useState(
    <Spin>
      <span>Loading...</span>
    </Spin>
  );
  const [totalVolDumped, setTotalVolDumped] = useState(
    <Spin>
      <span>Loading...</span>
    </Spin>
  );
  const [totalDumpingTrips, setTotalDumpingTrips] = useState(
    <Spin>
      <span>Loading...</span>
    </Spin>
  );
  const [totalJobCount, setTotalJobCount] = useState(
    <Spin>
      <span>Loading...</span>
    </Spin>
  );
  const [totalDumpedVol, setTotalDumpedVol] = useState(
    <Spin>
      <span>Loading...</span>
    </Spin>
  );
  const [dumpTripTotal, setDumpTripTotal] = useState(
    <Spin>
      <span>Loading...</span>
    </Spin>
  );
  const [volAtBugolobi, setVolAtBugolobi] = useState(
    <Spin>
      <span>Loading...</span>
    </Spin>
  );
  const [volAtLubigi, setVolAtLubigi] = useState(
    <Spin>
      <span>Loading...</span>
    </Spin>
  );

  const [dumpingByDivision, setDumpingByDivision] = useState('');
  const [dumpingByPlant, setDumpingByPlant] = useState('');
  const [dumpingByPlantByMonth, setDumpingByPlantByMonth] = useState('');
  const [emptyingBySPType, setEmptyingBySPType] = useState('');
  const [emptyingByDivision, setEmptyingByDivision] = useState('');
  const [emptyingStatCardData, setEmptyingStatCardData] = useState('');
  const [totalDumpingByMonth, setTotalDumpingByMonth] = useState('');
  const [dumpingBySPType, setDumpingBySPType] = useState('');
  const [mapDumpByDivision, setMapDumpByDivision] = useState([
    { name: 'NAKAWA', dumpVal: 200 },
    { name: 'KAWEMPE', dumpVal: 125 },
    { name: 'RUBAGA', dumpVal: 129 },
    { name: 'CENTRAL', dumpVal: 119 },
    { name: 'MAKINDYE', dumpVal: 112 },
  ]);

  const selectRegion = (data) => console.log('Selecting ', data);

  const changeTab = (tab) => {
    setActiveTab(tab.number);
  };

  const tabs = {
    1: {
      name: 'OVERVIEW',
      number: 1,
      loading: dumpsLoading,
      background: Colors.yellow,
      gradColor: 'rgba(235, 241, 226, #)',
      lineColor: Colors.darkGreen,
      chartTitle: 'Trend of dumping volumes by month',
      statCardColor: Colors.statCardGreen,
      labels: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ],
      datasets: [
        {
          label: 'Total Volume Dumped',
          backgroundColor: Colors.palestGreen,
          borderColor: Colors.darkGreen,
          data: dumpingBySP,
          tension: 0.3,
        },
      ],
      barChartSets: {
        1: [
          {
            label: 'Trend of dumping trips per division by KCCA Drivers',
            backgroundColor: Colors.palestGreen,
            borderColor: Colors.darkGreen,
            data: [2, 10, 5, 2, 20, 30, 45, 10, 12, 2, 3, 18],
            tension: 0.3,
          },
        ],
        2: [
          {
            label: 'Total Volume Dumped',
            backgroundColor: Colors.palestGreen,
            borderColor: Colors.darkGreen,
            data: [2, 10, 5, 2, 20, 30, 45, 10, 12, 2, 3, 18],
            tension: 0.3,
          },
        ],
      },
      statCards: [
        { title: 'total revenue', stat: totalRevenue, statUnit: 'UGX' },
        {
          title: 'total volume dumped',
          stat: totalVolDumped,
          statUnit: <sup>m3</sup>,
        },
        { title: 'Jobs Completed', stat: totalJobCount, statUnit: '' },
        {
          title: 'dumping trips by KCCA',
          stat: totalDumpingTrips,
          statUnit: '',
        },
      ],

      bottom: (
        <BottomOverview
          data={dumpingByDivision}
          plantsVol={dumpingByPlant}
          leftLoading={dumpsLoading}
          rightLoading={dumpsLoading}
        />
      ),
    },
    2: {
      name: 'EMPTYING',
      number: 2,
      loading: jobsDataLoading,
      background: Colors.darkBackground,
      statCardColor: Colors.statCardBlue,
      chartTitle: 'Emptying trips by service provider type',
      labels: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ],
      datasets: [
        {
          label: 'Gulpers',
          backgroundColor: Colors.palestGreen,
          borderColor: Colors.darkGreen,
          data: emptyingBySPType?.gulpers || [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          ],
          tension: 0.3,
        },
        {
          label: 'Emptiers',
          backgroundColor: Colors.actionRed,
          borderColor: Colors.actionRed,
          data: emptyingBySPType?.emptiers || [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          ],
          tension: 0.3,
        },
        {
          label: 'KCCA Trucks',
          backgroundColor: Colors.weyonLightBlue,
          borderColor: Colors.weyonLightBlue,
          data: emptyingBySPType?.kccaTrucks || [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          ],
          tension: 0.3,
        },
        {
          label: 'Others',
          backgroundColor: Colors.textGray,
          borderColor: Colors.textGray,
          data: emptyingBySPType?.others || [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          ],
          tension: 0.3,
        },
      ],
      statCards: [
        // { title: "Total Customers", stat: "16", statUnit: "" },
        {
          title: 'Call Centre Referrals',
          stat: emptyingStatCardData?.callCenterReferrals,
          statUnit: '',
        },
        {
          title: 'Service Provider Contacts',
          stat: emptyingStatCardData?.spContacts,

          // || (
          //   <Spin>
          //     <span>Loading...</span>
          //   </Spin>
          // ),
          statUnit: '',
        },
        {
          title: 'Emptying Trips',
          stat: emptyingStatCardData?.emptyingTrips,

          // || (
          //   <Spin>
          //     <span>Loading...</span>
          //   </Spin>
          // ),
          statUnit: '',
        },
      ],

      // bottom: <BottomEmptying />,
      bottom: (
        <BottomEmptying
          data={emptyingByDivision}
          plantsVol={dumpingByPlant}
          leftLoading={jobsDataLoading}
          rightLoading={dumpsLoading}
        />
      ),
    },
    3: {
      name: 'DUMPING',
      number: 3,
      loading: dumpsLoading,
      background: Colors.blue,
      gradColor: 'rgba(245, 246, 249, #)',
      lineColor: Colors.statPurple,
      chartTitle: 'Number of dumping trips',
      statCardColor: Colors.statCardPurple,

      labels: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ],
      datasets: [
        {
          label: 'Total number of trips made',
          backgroundColor: Colors.statPurple,
          borderColor: Colors.statPurple,
          data: totalDumpingByMonth || [
            12, 15, 11, 13, 10, 8, 9, 10, 12, 10, 9, 7,
          ],
          tension: 0.3,
        },
      ],
      statCards: [
        {
          title: 'Volume dumped',
          stat: totalDumpedVol,
          statUnit: <sup>m3</sup>,
        },
        { title: 'Dumping trips', stat: dumpTripTotal, statUnit: '' },
        {
          title: 'Volume at Bugolobi',
          stat: volAtBugolobi,
          statUnit: <sup>m3</sup>,
        },
        {
          title: 'Volume at Lubigi',
          stat: volAtLubigi,
          statUnit: <sup>m3</sup>,
        },
      ],
      bottom: (
        <BottomDumping
          dataLeft={dumpingByPlantByMonth}
          dataRight={dumpingBySPType}
          leftLoading={dumpsLoading}
          rightLoading={dumpsLoading}
        />
      ),
    },
    4: {
      name: 'MAP VIEW',
      number: 4,
      background: Colors.darkGreen,
      map: {
        1: (
          <MapChart
            byDivision={mapDumpByDivision}
            loading={dumpsLoading}
            selectRegion={selectRegion}
            title='Dumping trips per division'
          />
        ),
        2: (
          <MapChart
            selectRegion={selectRegion}
            useParish
            title='Emptying trips per parish'
          />
        ),
      },
      bottom: <BottomMapView />,
    },
  };

  useEffect(() => {
    dispatch(jobActions.getJobsSummary());
    dispatch(dashboardActions.getJobsData({ division: doDivision }));
    dispatch(dashboardActions.getDumpsData());
    dispatch(dashboardActions.getSPCustomerData({ division: doDivision }));
  }, []);

  useEffect(() => {
    const reducer = (previousValue, currentValue, key) =>
      previousValue[key]
        ? Number(previousValue[key]) + Number(currentValue[key])
        : !Number.isNaN(previousValue)
        ? Number(previousValue) + Number(currentValue[key])
        : 0;

    // Dumping By SP
    if (dashboardState?.dumpsData?.byMonth) {
      const dumpingBySpByMonth = Object.keys(
        dashboardState?.dumpsData?.byMonth
      ).map((month) =>
        dashboardState?.dumpsData?.byMonth[month].reduce((a, b) =>
          reducer(a, b, 'totalVolumeDumped')
        )
      );

      const totalDumpingBySp = insertCommas(
        dashboardState?.dumpsData?.data.reduce((a, b) =>
          reducer(a, b, 'totalVolumeDumped')
        )
      );

      const totalDumpingTripCount = insertCommas(
        dashboardState?.dumpsData?.data.reduce((a, b) =>
          reducer(a, b, 'totalNumberOfDumpingTrips')
        )
      );

      const dumpingByMonth = Object.keys(
        dashboardState?.dumpsData?.byMonth
      ).map((month) =>
        dashboardState?.dumpsData?.byMonth[month].reduce((a, b) =>
          reducer(a, b, 'totalNumberOfDumpingTrips')
        )
      );

      const sortedByDivision = [
        {
          label: 'Central',
          data: Object.keys(dashboardState?.dumpsData?.byMonth).map((month) =>
            dashboardState?.dumpsData?.byMonth[month].reduce((a, b) =>
              reducer(a, b, 'totalNumberOfDumpingTripsFromCentral')
            )
          ),
        },
        {
          label: 'Kawempe',
          data: Object.keys(dashboardState?.dumpsData?.byMonth).map((month) =>
            dashboardState?.dumpsData?.byMonth[month].reduce((a, b) =>
              reducer(a, b, 'totalNumberOfDumpingTripsFromKawempe')
            )
          ),
        },
        {
          label: 'Makindye',
          data: Object.keys(dashboardState?.dumpsData?.byMonth).map((month) =>
            dashboardState?.dumpsData?.byMonth[month].reduce((a, b) =>
              reducer(a, b, 'totalNumberOfDumpingTripsFromMakindye')
            )
          ),
        },
        {
          label: 'Nakawa',
          data: Object.keys(dashboardState?.dumpsData?.byMonth).map((month) =>
            dashboardState?.dumpsData?.byMonth[month].reduce((a, b) =>
              reducer(a, b, 'totalNumberOfDumpingTripsFromNakawa')
            )
          ),
        },
        {
          label: 'Rubaga',
          data: Object.keys(dashboardState?.dumpsData?.byMonth).map((month) =>
            dashboardState?.dumpsData?.byMonth[month].reduce((a, b) =>
              reducer(a, b, 'totalNumberOfDumpingTripsFromRubaga')
            )
          ),
        },
        {
          label: 'Outside Kampala',
          data: Object.keys(dashboardState?.dumpsData?.byMonth).map((month) =>
            dashboardState?.dumpsData?.byMonth[month].reduce((a, b) =>
              reducer(a, b, 'totalNumberOfDumpingTripsFromOutsideKampala')
            )
          ),
        },
      ];

      const tripsByDivForMap = [
        {
          name: 'NAKAWA',
          dumpVal: dashboardState?.dumpsData?.data.reduce((a, b) =>
            reducer(a, b, 'totalNumberOfDumpingTripsFromNakawa')
          ),
        },
        {
          name: 'KAWEMPE',
          dumpVal: dashboardState?.dumpsData?.data.reduce((a, b) =>
            reducer(a, b, 'totalNumberOfDumpingTripsFromKawempe')
          ),
        },
        {
          name: 'RUBAGA',
          dumpVal: dashboardState?.dumpsData?.data.reduce((a, b) =>
            reducer(a, b, 'totalNumberOfDumpingTripsFromRubaga')
          ),
        },
        {
          name: 'CENTRAL',
          dumpVal: dashboardState?.dumpsData?.data.reduce((a, b) =>
            reducer(a, b, 'totalNumberOfDumpingTripsFromCentral')
          ),
        },
        {
          name: 'MAKINDYE',
          dumpVal: dashboardState?.dumpsData?.data.reduce((a, b) =>
            reducer(a, b, 'totalNumberOfDumpingTripsFromMakindye')
          ),
        },
      ];

      // totalNumberOfDumpingTripsByEmptiers: 7
      // totalNumberOfDumpingTripsByGulpers: 31
      // totalNumberOfDumpingTripsByKCCATrucks: 4
      // totalNumberOfDumpingTripsByOtherSP: 46

      // Dumping by service provider type

      const dumpingByPlantData = {
        bugolobi: dashboardState?.dumpsData?.data.reduce((a, b) =>
          reducer(a, b, 'totalVolumeDumpedAtBugolobi')
        ),
        lubigi: dashboardState?.dumpsData?.data.reduce((a, b) =>
          reducer(a, b, 'totalVolumeDumpedAtLubigi')
        ),
      };

      const plantByMonth = {
        bugolobi: Object.keys(dashboardState?.dumpsData?.byMonth).map((month) =>
          dashboardState?.dumpsData?.byMonth[month].reduce((a, b) =>
            reducer(a, b, 'totalVolumeDumpedAtBugolobi')
          )
        ),
        lubigi: Object.keys(dashboardState?.dumpsData?.byMonth).map((month) =>
          dashboardState?.dumpsData?.byMonth[month].reduce((a, b) =>
            reducer(a, b, 'totalVolumeDumpedAtLubigi')
          )
        ),
      };

      const dumpsTotal = insertCommas(
        dashboardState?.dumpsData?.data.reduce((a, b) =>
          reducer(a, b, 'totalVolumeDumped')
        )
      );

      const allDumpTrips = insertCommas(
        dashboardState?.dumpsData?.data.reduce((a, b) =>
          reducer(a, b, 'totalNumberOfDumpingTrips')
        )
      );

      const dumpBugolobi = insertCommas(
        dashboardState?.dumpsData?.data.reduce((a, b) =>
          reducer(a, b, 'totalVolumeDumpedAtBugolobi')
        )
      );

      const dumpLubigi = insertCommas(
        dashboardState?.dumpsData?.data.reduce((a, b) =>
          reducer(a, b, 'totalVolumeDumpedAtLubigi')
        )
      );

      const dumpBySpType = {
        bugolobi: [
          dashboardState?.dumpsData?.data.reduce((a, b) =>
            reducer(
              a,
              b,
              'totalNumberOfDumpingTripsFromOutsideKampalaToBugolobiDumpingSite'
            )
          ),

          dashboardState?.dumpsData?.data.reduce((a, b) =>
            reducer(
              a,
              b,
              'totalNumberOfDumpingTripsFromRubagaToBugolobiDumpingSite'
            )
          ),

          dashboardState?.dumpsData?.data.reduce((a, b) =>
            reducer(
              a,
              b,
              'totalNumberOfDumpingTripsFromNakawaToBugolobiDumpingSite'
            )
          ),
          dashboardState?.dumpsData?.data.reduce((a, b) =>
            reducer(
              a,
              b,
              'totalNumberOfDumpingTripsFromMakindyeToBugolobiDumpingSite'
            )
          ),
          dashboardState?.dumpsData?.data.reduce((a, b) =>
            reducer(
              a,
              b,
              'totalNumberOfDumpingTripsFromKawempeToBugolobiDumpingSite'
            )
          ),

          dashboardState?.dumpsData?.data.reduce((a, b) =>
            reducer(
              a,
              b,
              'totalNumberOfDumpingTripsFromCentralToBugolobiDumpingSite'
            )
          ),
        ],
        lubigi: [
          dashboardState?.dumpsData?.data.reduce((a, b) =>
            reducer(
              a,
              b,
              'totalNumberOfDumpingTripsFromOutsideKampalaToLubigiDumpingSite'
            )
          ),
          dashboardState?.dumpsData?.data.reduce((a, b) =>
            reducer(
              a,
              b,
              'totalNumberOfDumpingTripsFromRubagaToLubigiDumpingSite'
            )
          ),
          dashboardState?.dumpsData?.data.reduce((a, b) =>
            reducer(
              a,
              b,
              'totalNumberOfDumpingTripsFromNakawaToLubigiDumpingSite'
            )
          ),
          dashboardState?.dumpsData?.data.reduce((a, b) =>
            reducer(
              a,
              b,
              'totalNumberOfDumpingTripsFromMakindyeToLubigiDumpingSite'
            )
          ),
          dashboardState?.dumpsData?.data.reduce((a, b) =>
            reducer(
              a,
              b,
              'totalNumberOfDumpingTripsFromKawempeToLubigiDumpingSite'
            )
          ),
          dashboardState?.dumpsData?.data.reduce((a, b) =>
            reducer(
              a,
              b,
              'totalNumberOfDumpingTripsFromCentralToLubigiDumpingSite'
            )
          ),
        ],
      };

      setMapDumpByDivision(tripsByDivForMap);
      setDumpingBySPType(dumpBySpType);
      setVolAtLubigi(dumpLubigi);
      setVolAtBugolobi(dumpBugolobi);
      setDumpTripTotal(allDumpTrips);
      setTotalDumpedVol(dumpsTotal);
      setTotalDumpingByMonth(dumpingByMonth);
      setDumpingByPlant(dumpingByPlantData);
      setDumpingByPlantByMonth(plantByMonth);
      setDumpingByDivision(sortedByDivision);
      setTotalDumpingTrips(totalDumpingTripCount);
      setTotalVolDumped(totalDumpingBySp);
      setDumpingBySP(dumpingBySpByMonth);
    }

    if (dashboardState?.jobsData) {
      // Total revenue
      const result = dashboardState?.jobsData?.length
        ? insertCommas(
            dashboardState?.jobsData?.reduce((a, b) =>
              reducer(a, b, 'totalRevenue')
            )
          )
        : 0;

      const jobDataByMonth = {
        January:
          dashboardState?.jobsData?.filter((it) => it.month === 'January') ||
          [],
        February:
          dashboardState?.jobsData?.filter((it) => it.month === 'February') ||
          [],
        March:
          dashboardState?.jobsData?.filter((it) => it.month === 'March') || [],
        April:
          dashboardState?.jobsData?.filter((it) => it.month === 'April') || [],
        May: dashboardState?.jobsData?.filter((it) => it.month === 'May') || [],
        June:
          dashboardState?.jobsData?.filter((it) => it.month === 'June') || [],
        July:
          dashboardState?.jobsData?.filter((it) => it.month === 'July') || [],
        August:
          dashboardState?.jobsData?.filter((it) => it.month === 'August') || [],
        September:
          dashboardState?.jobsData?.filter((it) => it.month === 'September') ||
          [],
        October:
          dashboardState?.jobsData?.filter((it) => it.month === 'October') ||
          [],
        November:
          dashboardState?.jobsData?.filter((it) => it.month === 'November') ||
          [],
        December:
          dashboardState?.jobsData?.filter((it) => it.month === 'November') ||
          [],
      };

      const dumpingSpType = {
        gulpers: dashboardState?.jobsData?.length
          ? Object.keys(jobDataByMonth).map((month) =>
              jobDataByMonth[month].reduce((a, b) =>
                reducer(a, b, 'emptyingTripsByGulpers')
              )
            )
          : 0,
        emptiers: dashboardState?.jobsData?.length
          ? Object.keys(jobDataByMonth).map((month) =>
              jobDataByMonth[month].reduce((a, b) =>
                reducer(a, b, 'emptyingTripsByEmptiers')
              )
            )
          : 0,
        kccaTrucks: dashboardState?.jobsData?.length
          ? Object.keys(jobDataByMonth).map((month) =>
              jobDataByMonth[month].reduce((a, b) =>
                reducer(a, b, 'totalNumberOfEmptyingTripsByKCCATrucks')
              )
            )
          : 0,
        others: dashboardState?.jobsData?.length
          ? Object.keys(jobDataByMonth).map((month) =>
              jobDataByMonth[month].reduce((a, b) =>
                reducer(a, b, 'emptyingTripsByOther')
              )
            )
          : 0,
      };

      const emptyingSortedByDivision = [
        {
          label: 'Central',
          data: dashboardState?.jobsData?.length
            ? Object.keys(jobDataByMonth).map((month) =>
                jobDataByMonth[month].reduce((a, b) =>
                  reducer(a, b, 'empytingTripsFromCentral')
                )
              )
            : 0,
        },
        {
          label: 'Kawempe',
          data: dashboardState?.jobsData?.length
            ? Object.keys(jobDataByMonth).map((month) =>
                jobDataByMonth[month].reduce((a, b) =>
                  reducer(a, b, 'empytingTripsFromKawempe')
                )
              )
            : 0,
        },
        {
          label: 'Makindye',
          data: dashboardState?.jobsData?.length
            ? Object.keys(jobDataByMonth).map((month) =>
                jobDataByMonth[month].reduce((a, b) =>
                  reducer(a, b, 'empytingTripsFromMakindye')
                )
              )
            : 0,
        },
        {
          label: 'Nakawa',
          data: dashboardState?.jobsData?.length
            ? Object.keys(jobDataByMonth).map((month) =>
                jobDataByMonth[month].reduce((a, b) =>
                  reducer(a, b, 'empytingTripsFromNakawa')
                )
              )
            : 0,
        },
        {
          label: 'Rubaga',
          data: dashboardState?.jobsData?.length
            ? Object.keys(jobDataByMonth).map((month) =>
                jobDataByMonth[month].reduce((a, b) =>
                  reducer(a, b, 'empytingTripsFromRubaga')
                )
              )
            : 0,
        },
      ];

      const emptyingStatCards = {
        callCenterReferrals: dashboardState?.jobsData?.length
          ? dashboardState?.jobsData.reduce((a, b) =>
              reducer(a, b, 'callCenterReferals')
            )
          : 0,
        spContacts: dashboardState?.jobsData?.length
          ? dashboardState?.jobsData.reduce((a, b) =>
              reducer(a, b, 'serviceProviderContacts')
            )
          : 0,
        emptyingTrips: dashboardState?.jobsData?.length
          ? dashboardState?.jobsData.reduce((a, b) =>
              reducer(a, b, 'totalNumberOfEmptyingTrips')
            )
          : 0,
        // totalCustomers:"",
      };

      setEmptyingByDivision(emptyingSortedByDivision);
      setEmptyingStatCardData(emptyingStatCards);
      setEmptyingBySPType(dumpingSpType);
      setTotalRevenue(result);
    }

    if (dashboardState?.customerSPData) {
      // Total jobs completed
      const result = dashboardState?.customerSPData?.length
        ? insertCommas(
            dashboardState?.customerSPData.reduce((a, b) =>
              reducer(a, b, 'count')
            )
          )
        : 0;

      setTotalJobCount(result);
    }
  }, [dashboardState, dateFilter]);

  const klaDivisions = ['Nakawa', 'Kawempe', 'Rubaga', 'Central', 'Makindye'];

  return (
    <div className='dashboard-home'>
      <div className='dashboard-home-top'>
        <div className='dashboard-home-tabs'>
          {Object.keys(tabs).map((tabNum) => {
            // Filter out MAP view for non kla divisions
            if (
              (tabs[tabNum].name === 'MAP VIEW' ||
                tabs[tabNum].name === 'OVERVIEW' ||
                tabs[tabNum].name === 'DUMPING') &&
              !klaDivisions.includes(doDivision.divisionName) &&
              userRole !== 'admin'
            ) {
              return null;
            }
            return (
              <p
                className={`dashboard-home-tab ${
                  activeTab === tabs[tabNum].number
                    ? 'dashboard-home-tab-active'
                    : ''
                }`}
                onClick={() => changeTab(tabs[tabNum])}
              >
                {tabs[tabNum].name}
              </p>
            );
          })}
        </div>

        <CustomDateBtn
          onSubmit={setDateFilter}
          onClear={() => setDateFilter({})}
        />
      </div>
      <div className='dashboard-home-bottom'>
        {tabs[activeTab]?.map ? (
          <React.Fragment>
            <div className='dashboard-home-bottom-left-alt'>
              {React.cloneElement(tabs[activeTab]?.map[1])}
            </div>
            <div className='dashboard-home-bottom-right-alt'>
              {React.cloneElement(tabs[activeTab]?.map[2])}
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className='dashboard-home-bottom-left'>
              <Chart
                title={tabs[activeTab]?.chartTitle}
                labels={tabs[activeTab]?.labels}
                datasets={tabs[activeTab]?.datasets}
                gradColor={tabs[activeTab]?.gradColor}
                lineColor={tabs[activeTab]?.lineColor}
                loading={tabs[activeTab]?.loading}
              />
            </div>
            <div className='dashboard-home-bottom-right'>
              {tabs[activeTab].statCards?.length
                ? tabs[activeTab].statCards.map((card) => (
                    <div
                      className='dashboard-home-stat-card'
                      style={{ background: tabs[activeTab].statCardColor }}
                    >
                      <p className='dashboard-home-stat-card-title'>
                        {card.title}
                      </p>
                      <div className='dashboard-home-stat-container'>
                        <p className='dashboard-home-stat-card-stat'>
                          {card.stat}
                        </p>
                        <p className='dashboard-home-stat-card-stat-unit'>
                          {card.statUnit}
                        </p>
                      </div>
                    </div>
                  ))
                : ''}
            </div>
          </React.Fragment>
        )}
      </div>

      <div className='voucher-settings-card'>{tabs[activeTab].bottom}</div>
    </div>
  );
};

export default Home;
