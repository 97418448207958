// CRUD Operations
export const CREATE_NOTIFICATION = "CREATE_NOTIFICATION";
export const UPDATE_NOTIFICATION = "UPDATE_NOTIFICATION";
export const DELETE_NOTIFICATION = "DELETE_NOTIFICATION";
export const GET_NOTIFICATION = "GET_NOTIFICATION";
export const GET_ALL_NOTIFICATIONS = "GET_ALL_NOTIFICATIONS";

export const ADD_NOTIFICATION = "ADD_NOTIFICATION";
export const SEND_SP_NOTIFICATION = "SEND_SP_NOTIFICATION";

// Misc
export const LOADING_NOTIFICATIONS = "LOADING_NOTIFICATIONS";
