import React, { useState, useEffect } from "react";
import ApprovePaymentModal from "./../VoucherModals/UpdateVPModal";
// Redux
import { useDispatch, useSelector } from "react-redux";
import * as voucherActions from "../../../redux/actions/voucherActions";
import makeID from "../../../redux/utils/makeID";
import { Input, notification, Button } from "antd";
import TouchableOpacity from "./../../../components/TouchableOpacity";
import Colors from "../../../config/Colors";
import { CgChevronDown, CgChevronUp } from "react-icons/cg";
import CustomButton from "../../../components/Button";

const ApproveForPaymentButton = ({ voucher, showSingleVoucher }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [approvePaymentStatusID, setApprovePaymentStatusID] = useState("");
  const [officerRemarks, setOfficerRemarks] = useState("");
  const voucherStatusMessages = useSelector(
    (state) => state.voucher.statusMessages
  );
  const [showApprovePaymentModal, setShowApprovePaymentModal] = useState(false);
  const doID = useSelector((state) => state.admin.officer?._id);
  const vouchersLoading = useSelector((state) => state.voucher.loading);
  const dispatch = useDispatch();

  const handleVoucherPaymentApproval = (voucherID) => {
    const voucherDetails = {
      doID,
      // expiryDate,
      status: 6,
      // reasonForDenial: declineReason,
      // reasonForDeactivation,
      // catsRemarks,
      officerRemarks,
    };

    const statusID = makeID(8);
    setApprovePaymentStatusID(statusID);
    dispatch(
      voucherActions.approveVoucher(voucher._id, voucherDetails, statusID)
    );
    // approveVoucherForPayment
  };

  useEffect(() => {
    if (!approvePaymentStatusID) return;
    const result = voucherStatusMessages.find(
      (it) => it.statusID === approvePaymentStatusID
    );

    if (result?.success) {
      const successMessage = "Voucher has been approved for payment";
      notification.success({
        message: "Update",
        description: successMessage,
        onClick: () => {},
        duration: 2,
        onClose: () => {
          dispatch(voucherActions.getVoucher(voucher?._id));
          dispatch(voucherActions.getAllVouchers());
        },
      });
    } else {
      const failMessage = "Failed to approve voucher. Please try again";
      notification.error({
        message: "Update",
        description: failMessage,
        onClick: () => {},
        duration: 2,
      });
    }

    setApprovePaymentStatusID("");
  }, [voucherStatusMessages]);

  return (
    <>
      <ApprovePaymentModal
        visible={showApprovePaymentModal}
        loading={vouchersLoading}
        setVisible={() => setShowApprovePaymentModal(false)}
        onAction={handleVoucherPaymentApproval}
        actionBtnTxt={"Approve"}
        actionTxt={
          <div>
            <p>
              Please confirm that you want to approve payment for this voucher.
            </p>
            <div className="sv-bottom-detail-pair">
              <p className="sv-bottom-detail-left ">Remarks:</p>

              <p className="sv-bottom-detail-right ">
                <Input
                  type={"text"}
                  value={officerRemarks}
                  onChange={(val) => setOfficerRemarks(val.target.value)}
                  placeholder={"Input any remarks about this job"}
                  style={{ marginBottom: 20 }}
                />
              </p>
            </div>
          </div>
        }
        title={`Approve Voucher ${voucher.voucherReadableID} For Payment`}
      />

      {showSingleVoucher ? (
        <div className="ad-container">
          <CustomButton
            color={Colors.darkGreen}
            onClick={() => setShowDropdown(!showDropdown)}
          >
            <p
              style={{
                color: Colors.white,
                margin: "0px",
                minWidth: "80px",
              }}
            >
              View/Approve
            </p>

            {showDropdown ? (
              <CgChevronUp size={20} color={Colors.white} />
            ) : (
              <CgChevronDown size={20} color={Colors.white} />
            )}
          </CustomButton>

          {showDropdown ? (
            <div className="ad-dropdown">
              <TouchableOpacity
                clicked={() => showSingleVoucher(voucher)}
                altClass="ad-dropdown-item-br"
              >
                VIEW
              </TouchableOpacity>
              <TouchableOpacity
                clicked={() => setShowApprovePaymentModal(true)}
                altClass="ad-dropdown-item"
              >
                APPROVE FOR PAYMENT
              </TouchableOpacity>
            </div>
          ) : null}
        </div>
      ) : (
        <Button
          onClick={() => setShowApprovePaymentModal(true)}
          disabled={voucher.status !== 5}
        >
          Approve For Payment
        </Button>
      )}
    </>
  );
};

export default ApproveForPaymentButton;
