import React, { useState, useEffect } from "react";
import { Bar, Line } from "react-chartjs-2";
import { Spin } from "antd";
import { Colors } from "../config";

const Chart = ({
  title,
  labels,
  datasets,
  gradColor,
  lineColor,
  small,
  loading,
}) => {
  const [useLineChart, setUseLineChart] = useState(true);
  const chartIconSize = 25;
  const height = 50;
  const width = 100;

  const data = (canvas) => {
    const ctx = canvas.getContext("2d");

    let gradient;
    if (gradColor) {
      gradient = ctx.createLinearGradient(0, 0, 0, 400);
      gradient.addColorStop(0, gradColor.replace("#", 1));
      gradient.addColorStop(1, gradColor.replace("#", 0));
    }

    let gradientStroke;
    if (lineColor) {
      gradientStroke = ctx.createLinearGradient(500, 0, 100, 0);
      gradientStroke.addColorStop(0, lineColor || Colors.actionRed);
      gradientStroke.addColorStop(1, lineColor || Colors.actionRed);
    }

    let obj =
      datasets.length === 1
        ? {
            labels,
            datasets: [
              {
                ...datasets[0],
                backgroundColor: gradient,
                fill: "start",
                borderColor: gradientStroke,
                pointBorderColor: gradientStroke,
                pointBackgroundColor: gradientStroke,
                pointHoverBackgroundColor: gradientStroke,
                pointHoverBorderColor: gradientStroke,
              },
            ],
          }
        : { labels, datasets };

    return obj;
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      xAxes: {
        grid: {
          color: "rgba(0, 0, 0, 0)",
        },
      },

      yAxes: {
        grid: {
          color: "rgba(0, 0, 0, 0)",
        },
      },
    },
  };

  return (
    <div className="dashboard-chart">
      <h3>{title}</h3>
      <div className="dashboard-chart-icons"></div>
      <Spin spinning={loading}>
        <div className="dashboard-chart-container">
          <div
            className={
              small
                ? "dashboard-chart-wrapper-small"
                : "dashboard-chart-wrapper"
            }
          >
            {useLineChart ? (
              <Line
                data={data}
                width={width}
                height={height}
                options={options}
              />
            ) : (
              <Bar
                data={data}
                width={width}
                height={height}
                options={options}
              />
            )}
          </div>
        </div>
      </Spin>
    </div>
  );
};

export default Chart;
