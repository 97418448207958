import React, { useState, useEffect } from "react";
import Modal from "./../../components/Modal";
import CustomInput from "./../../components/CustomInput";
import TouchableOpacity from "./../../components/TouchableOpacity";
import Button from "./../../components/Button";
import { notification, Switch, Progress } from "antd";
import { Colors } from "../../config";
import { GrClose } from "react-icons/gr";
import OutlineInput from "./../../components/OutlineInput";
import defMap from "./../../assets/misc/map_default_2.png";
import { GoSearch } from "react-icons/go";
import DispatchMap from "./DispatchMap";

// Redux
import * as driverActions from "./../../redux/actions/driverActions";
import * as jobActions from "./../../redux/actions/jobActions";
import * as notificationActions from "./../../redux/actions/notificationActions";
import { useSelector, useDispatch } from "react-redux";
import makeID from "./../../redux/utils/makeID";

import { Select } from "antd";

const { Option } = Select;

const DispatchPopup = ({
  showPopup,
  hidePopup,
  jobDetails,
  voucherDetails,
}) => {
  // jobDetails if the job has been selected from the job list for the dispatch

  // Form output
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [address, setAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [isAutoDispatch, setIsAutoDispatch] = useState(false);

  // Form options
  const defaultToilet = "Toilet Facility";
  const [selectedFacilityOption, setSelectedFacilityOption] =
    useState(defaultToilet);
  const allOptions = [
    "Lined",
    "Unlined",
    "Lined pit latrine with slab",
    "Unlined pit latrine without slab",
    "Flush toilet connected to septic tank",
    "Unlined pit latrine with slab",
    "Composting toilet",
    "I don't know",
  ];

  const defaultServiceProvider = "Available Service Providers";
  const [serviceProviderName, setServiceProviderName] = useState(
    defaultServiceProvider
  );
  const [serviceProvider, setServiceProvider] = useState("");
  const handleSwitch = (e) => {
    setIsAutoDispatch(e);
  };

  // Redux
  const dispatch = useDispatch();
  const newJobInState = useSelector((state) => state.jobs.newJob);
  const availableDrivers = useSelector(
    (state) => state.driver.availableDrivers
  );
  const jobStatusMessages = useSelector((state) => state.jobs.statusMessages);
  const [createJobStatus, setCreateJobStatus] = useState("");
  const [requestSent, setRequestSent] = useState(false);
  const jobsLoading = useSelector((state) => state.jobs.loading);
  const loading = requestSent;
  const adminID = useSelector((state) => state.admin.id);
  const cannotSubmitYet =
    !customerName ||
    !phoneNumber ||
    !selectedFacilityOption ||
    selectedFacilityOption === defaultToilet ||
    !longitude ||
    !latitude ||
    (!isAutoDispatch && !serviceProvider);

  const handleAvailableSp = (spID) => {
    const chosenSP = availableDrivers.find((dr) => dr._id == spID);
    setServiceProviderName(chosenSP.full_name);
    setServiceProvider(chosenSP);
  };

  const handleToiletType = (val) => {
    setSelectedFacilityOption(val);
  };

  const receiveAddressFromMap = (address) => {
    if (!address) return;

    setAddress(address.address);
    setLongitude(address.long);
    setLatitude(address.lat);
  };

  const handleCreateDispatch = () => {
    let warnText = !customerName
      ? "Customer Name"
      : !phoneNumber
      ? "Customer Phone Number"
      : !selectedFacilityOption || selectedFacilityOption === defaultToilet
      ? "Facility Type"
      : !longitude || !latitude
      ? "location"
      : false;

    let altWarnMessage =
      "Either choose an available service provider or turn on auto dispatch instead.";

    if (warnText) {
      notification.warn({
        message: "Invalid Input!",
        description: `Please ${
          warnText === "location" ? "choose" : "provide"
        } a ${warnText} to proceed`,
        onClick: () => {},
        duration: 2,
      });
      if (isAutoDispatch) setIsAutoDispatch(false);
      return;
    }

    if (!isAutoDispatch && !serviceProvider) {
      notification.warn({
        message: "Validation Error!",
        description: altWarnMessage,
        onClick: () => {},
        duration: 2,
      });
      return;
    }

    const data = {
      full_name: customerName,
      phone_number: phoneNumber,
      address: address,
      facility_type: selectedFacilityOption,
      long: longitude || "930493",
      lat: latitude || "0238293",
      id: adminID,
      // id:"611af1a38e10d630c6a2f020",
      auto_dispatch: isAutoDispatch,
      //this.state.isVoucherJob,
      isVoucherJob: false,
    };

    setRequestSent(true);
    const statusID = makeID(8);
    setCreateJobStatus(statusID);
    dispatch(jobActions.addJob(data, statusID));
  };

  const findDrivers = () => dispatch(driverActions.getAvailableDrivers());

  useEffect(() => {
    if (!showPopup) return;
    // Fetch drivers immediately
    findDrivers();

    // Fetch drivers every 5 seconds after
    const findingDrivers = setInterval(() => {
      findDrivers();
    }, 5000);
    return () => {
      // Stop fetching drivers when the dispatch window is closed
      clearInterval(findingDrivers);
    };
  }, [showPopup]);

  useEffect(() => {
    if (!isAutoDispatch) return;
    handleCreateDispatch();
  }, [isAutoDispatch]);

  useEffect(() => {
    if (!createJobStatus) return;
    const result = jobStatusMessages.find(
      (it) => it.statusID === createJobStatus
    );
    if (result?.success) {
      const jobWasAutoDispatch = newJobInState?.auto_dispatch;

      if (jobWasAutoDispatch) {
        // Handle if auto dispatch. i.e find a service provider using the job
        const successMessage = "Job was created, finding a service provider...";
        notification.success({
          message: "Update",
          description: successMessage,
          onClick: () => {},
          duration: 2,
        });
      } else {
        const notificationData = {
          device_id: serviceProvider.device_id,
          job_id: newJobInState?._id,
          userId: serviceProvider._id,
          ...(voucherDetails && { voucherValue: voucherDetails.voucherValue }),
          ...(voucherDetails && {
            voucherNumber: voucherDetails.voucherNumber,
          }),
          ...(voucherDetails && { voucherDivision: voucherDetails.division }),
          ...(voucherDetails && {
            voucherBeneficiaryAmount: voucherDetails.voucherBeneficiaryAmount,
          }),
          ...(voucherDetails && { isVoucherJob: true }),
        };

        dispatch(notificationActions.sendSPNotification(notificationData));
        // Handle if not auto dispatch, i.e a service provider was selected
        // Inform the service provider

        const successMessage = `Job was created, Informing ${serviceProviderName} (SP)`;
        notification.success({
          message: "Update",
          description: successMessage,
          onClick: () => {},
          duration: 2,
          onClose: () => hidePopup(),
        });
      }
    } else {
      const errorMessage = result?.message || "An unknown error occurred";
      notification.warn({
        message: "An Error Occurred",
        description: errorMessage,
        onClick: () => {},
        duration: 2,
      });
    }

    setCreateJobStatus("");
  }, [jobStatusMessages]);

  return showPopup ? (
    <Modal>
      <div className="dp-popup">
        <div className="dp-popup-top">
          <div className="dp-popup-top-title">
            <h3>CREATE DISPATCH</h3>
          </div>
          <div className="dp-popup-top-icon">
            <TouchableOpacity clicked={hidePopup}>
              <GrClose size={20} color={Colors.textGray} />
            </TouchableOpacity>
          </div>
          {loading ? (
            <div className="dp-progress">
              <Progress
                percent={100}
                showInfo={false}
                status="active"
                strokeColor={{
                  "0%": "#108ee9",
                  "100%": "#87d068",
                }}
              />
            </div>
          ) : null}
        </div>
        <div className="dp-popup-bottom">
          <div className="dp-popup-bottom-left">
            <div className="dp-input-container">
              <OutlineInput
                placeHolder="Customer Name"
                label="Customer Name"
                value={customerName}
                receiveOutput={setCustomerName}
              />
            </div>

            <div className="dp-input-container">
              <OutlineInput
                placeHolder="Customer Phone Number"
                label="Customer Phone Number"
                value={phoneNumber}
                receiveOutput={setPhoneNumber}
              />
            </div>

            <div className="dp-input-container">
              <Select
                style={{ width: "100%" }}
                className="dp-input-container-select"
                onChange={handleToiletType}
                value={selectedFacilityOption}
                size="large"
              >
                {allOptions.map((val) => (
                  <Option value={val} key={val}>
                    {val}
                  </Option>
                ))}
              </Select>
            </div>

            <div className="dp-input-container">
              <Select
                style={{ width: "100%" }}
                className="dp-input-container-select"
                onChange={handleAvailableSp}
                value={serviceProviderName}
                size="large"
              >
                {availableDrivers.map((val) => (
                  <Option value={val?._id} key={val?._id}>
                    {val?.full_name} ({val?.type})
                  </Option>
                ))}
              </Select>
            </div>

            <p className="auto-dispatch-text">Auto Dispatch</p>
            <Switch onChange={handleSwitch} checked={isAutoDispatch} />

            <div className="dp-popup-buttons">
              <Button
                color={cannotSubmitYet ? Colors.hrGray : Colors.darkGreen}
                type="submit"
                onClick={handleCreateDispatch}
              >
                <span style={{ color: Colors.white }}>Create</span>
              </Button>

              <Button
                color={Colors.white}
                type="submit"
                extraClassName={"dp-white-btn"}
              >
                <span style={{ color: Colors.hrGray }}>Cancel</span>
              </Button>
            </div>
          </div>
          <div className="dp-popup-bottom-right">
            <div className="dp-popup-map">
              {/* <img src={defMap} /> */}
              <DispatchMap
                lat={latitude}
                long={longitude}
                returnAddress={receiveAddressFromMap}
                fromVoucher={false}
              />
              <div className="dp-map-input-container">
                <OutlineInput
                  placeHolder="Search Customer's location"
                  label="Search Customer's location"
                  adornment={
                    <div className="dp-adornment-container">
                      <GoSearch size={15} color={Colors.hrGray} />
                    </div>
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  ) : null;
};

export default DispatchPopup;
