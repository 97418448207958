import React, { useState, useEffect } from "react";
import { GeoSearchControl, GoogleProvider } from "leaflet-geosearch";
import "leaflet-geosearch/assets/css/leaflet.css";
import "leaflet/dist/leaflet.css";
// import 'alertifyjs/build/css/alertify.min.css';
// import 'alertifyjs/build/css/themes/semantic.min.css';
// import 'react-toggle-switch/dist/css/switch.min.css';

import Leaf from "leaflet";
import Pointer from "./../../assets/map_icons/pointer.png";
import { GOOGLE_MAPS_API_KEY } from "../../config";

const provider = new GoogleProvider({
  region: "ug",
  params: {
    key: GOOGLE_MAPS_API_KEY,
  },
});

let PointerIcon = Leaf.icon({
  iconUrl: Pointer,
  iconSize: [50, 50], // size of the icon
  iconAnchor: [22, 94], // point of the icon which will correspond to marker's location
  popupAnchor: [-3, -76], // point from which the popup should open relative to the iconAnchor
});

const searchControl = new GeoSearchControl({
  provider: provider,
  showMarker: true,
  style: "bar",
  marker: {
    // optional: Leaf.Marker    - default Leaf.Icon.Default
    icon: PointerIcon,
    draggable: true,
  },
  showPopup: false,
  popupFormat: ({ query, result }) => result.label, // optional: function    - default returns result label
  maxMarkers: 1, // optional: number      - default 1
  retainZoomLevel: false, // optional: true|false  - default false
  animateZoom: true, // optional: true|false  - default true
  autoClose: true, // optional: true|false  - default false
  searchLabel: "Enter address", // optional: string      - default 'Enter address'
  keepResult: true,
});

const DispatchMap = ({ long, lat, returnAddress, fromVoucher }) => {
  fromVoucher = !lat || !long;
  const renderMap = () => {
    let map;
    map = new Leaf.Map("innerMap");

    let osmUrl = "http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
    let osmAttrib = "Weyonjje";
    let osm = new Leaf.TileLayer(osmUrl, {
      minZoom: 7,
      zoomControl: false,
      attribution: osmAttrib,
    });
    map.setView(
      new Leaf.LatLng(lat || 0.30809999999999965, long || 32.59680000000029),
      14
    );
    map.addLayer(osm);

    // Only add search control if dispatch from call center and not voucher
    map.addControl(searchControl);
    if (!fromVoucher) {
    }

    // Show marker of location if voucher

    if (lat && long) {
      let marker = Leaf.marker([lat, long], { icon: PointerIcon });
      marker.addTo(map);
    }

    map.on("geosearch/showlocation", function (e) {
      const newAddress = {
        address: e.location.label,
        lat: e.location.y,
        long: e.location.x,
      };

      returnAddress(newAddress);
    });

    map.on("geosearch/marker/dragend", function (e) {
      const newAddress = {
        address: e.location.label,
        lat: e.location.y,
        long: e.location.x,
      };

      returnAddress(newAddress);
    });
  };

  useEffect(() => {
    renderMap();
  }, []);

  return (
    <div
      className="col-md-12 innerMap"
      style={{ height: "430px", marginBottom: "50px" }}
      id="innerMap"
    ></div>
  );
};

export default DispatchMap;
