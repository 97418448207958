import React, { useState, useEffect } from "react";

const MapChartLegend = ({
  maxColor,
  minColor,
  labels: { bottom, mid, top },
}) => {
  return (
    <div
      className="map-legend-container"
      style={{
        // background
        background: `linear-gradient(to left, ${maxColor}, ${minColor})`,
      }}
    >
      <div className="map-legend-left"></div>
      <div className="map-legend-labels">
        <p>{bottom}</p>
        <p>{bottom + mid}</p>
        <p>{top}</p>
      </div>
    </div>
  );
};

export default MapChartLegend;
