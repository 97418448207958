import React, { useState, useEffect, useRef } from 'react';
import { Colors } from '../../../config';
import Button from '../../../components/Button';
import { AiOutlineSearch } from 'react-icons/ai';
import ScheduleVoucher from './../ScheduleVoucher';
import { CSVLink } from 'react-csv';
import { MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { RiRefreshFill } from 'react-icons/ri';
import ApproveDeclineDropdown from '../VoucherModals/ApproveDeclineDropdown';
import ViewOrDispatchDropdown from './../VoucherModals/ViewOrDispatchDropdown';
import configureForCSV from '../../../redux/utils/configureForCSV';
import ApproveForPaymentButton from './../VoucherModals/ApproveForPaymentButton';
import ApproveAllVouchers from './../VoucherModals/ApproveAllVouchers';
import * as utils from '../../../redux/utils/componentUtils';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import * as voucherActions from '../../../redux/actions/voucherActions';
import makeID from '../../../redux/utils/makeID';
import moment from 'moment';

import { Table, Space, notification, Spin } from 'antd';

const { Column } = Table;

// const voucherStatuses = {
//   0: "Awaiting Approval",
//   1: "Ready to Dispatch to SP",
//   2: "Declined",
//   3: "Dispatched",
//   4: "Awaiting CAT's Approval",
//   5: "Awaiting DO Approval",
//   6: "Ready for Payment",
//   7: "Deactivated",
//   10: "Paid",
// };

const voucherStatuses = {
  0: 'Awaiting Approval',
  1: 'Ready to Dispatch to SP',
  2: 'Declined',
  3: 'Dispatched',
  4: "Awaiting CAT's Approval",
  5: 'Awaiting DO Approval',
  6: 'Ready for Payment',
  7: 'Deactivated',
  10: 'Paid',
};

const jobStatuses = {
  0: 'Scheduled',
  1: 'In Progress',
  2: 'Declined',
  3: 'Completed',
};

const voucherStatusColors = {
  0: Colors.black,
  1: Colors.darkGreen,
  2: Colors.actionRed,
  3: Colors.statPurple,
  4: Colors.yellow,
  5: Colors.darkGreen,
  6: Colors.darkMaroon,
  7: Colors.hrGray,
  10: Colors.weyonLightBlue,
};

const AllVouchers = ({ history, isDO }) => {
  // State
  const [warnScheduleVisible, setWarnScheduleVisible] = useState(false);
  const csvDownloaderRef = useRef(null);
  const [showApprovePaymentModal, setShowApprovePaymentModal] = useState(false);

  // Redux
  const dispatch = useDispatch();
  const voucherStatusMessages = useSelector(
    (state) => state.voucher.statusMessages
  );
  const vouchersLoading = useSelector((state) => state.voucher.loading);

  const [denyStatusID, setDenyStatusID] = useState('');
  const [approveStatusID, setApproveStatusID] = useState('');
  const [csvDataForDownload, setCsvDataForDownload] = useState([]);

  const vouchersInState = useSelector((state) => state.voucher.vouchers);

  const [getVouchersStatus, setGetVouchersStatus] = useState('');
  const loading = useSelector((state) => state.voucher.loading);
  const [selectedVouchers, setSelectedVouchers] = useState([]);
  const [selectedVoucherKeys, setSelectedVoucherKeys] = useState([]);

  const getVouchers = () => {
    const statusID = makeID(8);
    setGetVouchersStatus(statusID);
    dispatch(voucherActions.getAllVouchers(statusID));
  };

  const dataSource = vouchersInState;
  const [filteredData, setFilteredData] = useState(dataSource);
  const [searchText, setSearchText] = useState('');

  const handleSearch = (text) => {
    const result = utils.search(
      text,
      dataSource,
      [
        'beneficiary',
        'beneficiaryNumber',
        'generatedBy',
        'catsName',
        'serviceProvider',
      ],
      '_id'
    );

    setFilteredData(result);
  };

  const handleTime = (val) => {
    const date = new Date(val);
    const dateIsInvalid = `${date}` === 'Invalid Date';

    return dateIsInvalid
      ? false
      : moment(new Date(val)).format('MM/DD/YYYY hh:mm');
  };

  const handleVoucherApproval = (voucherDetails, voucherID) => {
    const statusID = makeID(8);
    setApproveStatusID(statusID);
    dispatch(
      voucherActions.approveVoucher(voucherID, voucherDetails, statusID)
    );
  };

  const handleDeclineConfirmed = (voucherDetails, voucherID) => {
    const statusID = makeID(8);
    setDenyStatusID(statusID);
    dispatch(voucherActions.denyVoucher(voucherID, voucherDetails, statusID));
  };

  const configureEntitiesForCSV = async () => {
    const configFields = [
      { delete: '_id' },
      { delete: 'updatedAt' },
      { delete: 'catsDeviceID' },
      {
        new: 'Date Created',
        old: 'createdAt',
        mutation: (val) => moment(val).format('DD/MM/YYYY, hh:mm a'),
      },
      { new: 'Voucher ID', old: 'voucherID' },
      { old: 'voucherReadableID', new: 'Voucher Readable ID' },
      { old: 'voucherProgram', new: 'Voucher Program' },
      { old: 'beneficiaryAmount', new: 'Beneficiary Amount' },
      {
        old: 'status',
        new: 'Voucher Status',
        mutation: (val) => voucherStatuses[val],
      },
      { old: 'beneficiary', new: 'Beneficiary' },
      { old: 'beneficiaryNumber', new: 'Beneficiary Number' },
      { old: 'householdID', new: 'Household ID' },
      { old: 'facilityType', new: 'Facility Type' },
      { old: 'lat', new: 'Latitude' },
      { old: 'lng', new: 'Longitude' },
      { old: 'address', new: 'Address' },
      { old: 'generatedBy', new: 'Generated By' },
      { old: 'catsPhoneNumber', new: 'CATS Phone Number' },
      { old: 'catsName', new: 'CATS Name' },

      {
        old: 'reasonForEligibility',
        new: 'Reason For Eligibility',
        mutation: (val) => val?.join(',\n'),
      },

      {
        old: 'noOfExtraBarrelsRequested',
        new: 'Number of Extra Barrels',
        mutation: (val) => val || 0,
      },

      {
        old: 'catsApproved',
        new: 'Approved by CATS',
        mutation: (val) => (val ? 'Yes' : 'No'),
      },
      { old: 'paid', new: 'Paid', mutation: (val) => (val ? 'Yes' : 'No') },
      { old: 'catsApprovalRemarks', new: 'CATS Approval Remarks' },
      { old: 'officerName', new: 'Officer Name' },
      {
        old: 'officerApproved',
        new: 'Officer Approved',
        mutation: (val) => (val ? 'Yes' : 'No'),
      },
      { old: 'officerApprovalRemarks', new: 'Officer Approval Remarks' },

      { old: 'spType', new: 'Service Provider Type' },
      { old: 'validForDivision', new: 'Division' },
      { old: 'value', new: 'Value' },
      {
        old: 'reasonForDenial',
        new: 'Reason For Denial',
        mutation: (val) => val || 'None given',
      },
      {
        old: 'jobCompletedOn',
        new: 'Job Completed On',
        mutation: (val) =>
          val ? moment(val).format('DD/MM/YYYY, hh:mm a') : 'Not completed',
      },
      {
        old: 'expiryDate',
        new: 'Expiry Date',
        mutation: (val) =>
          `${moment(val)}` !== 'Invalid date'
            ? moment(val).format('DD/MM/YYYY hh:mm a')
            : val,
      },
      { old: 'approvedBy', new: 'Approved By' },
      {
        old: 'approvedOn',
        new: 'Approved On',
        mutation: (val) =>
          `${moment(val)}` !== 'Invalid date'
            ? moment(val).format('DD/MM/YYYY hh:mm a')
            : val,
      },
      {
        old: 'redeemedOn',
        new: 'Redeemed On',
        mutation: (val) =>
          `${moment(val)}` !== 'Invalid date'
            ? moment(val).format('DD/MM/YYYY hh:mm a')
            : val,
      },

      {
        old: 'serviceProvider',
        new: 'Service Provider',
        mutation: (val) => val || 'None Yet',
      },
      { delete: 'jobID' },

      {
        old: 'jobStatus',
        new: 'Job Status',
        mutation: (val) => jobStatuses[val],
      },
    ];

    await configureForCSV(vouchersInState, configFields)
      .then((res) => setCsvDataForDownload(res))
      .then(() => csvDownloaderRef.current.click());
  };

  const handleSelectVoucherKey = (key) => {
    const isIncluded = selectedVoucherKeys.includes(key);
    if (isIncluded) {
      setSelectedVoucherKeys(selectedVoucherKeys.filter((it) => it !== key));
    } else {
      if (selectedVoucherKeys.length === 3) {
        notification.warn({
          message: 'Invalid Selection!',
          description:
            'You cannot select more than three vouchers at a time for scheduling.',
          onClick: () => {},
          duration: 2,
        });
        return;
      }
      setSelectedVoucherKeys([...selectedVoucherKeys, key]);
    }
  };

  const warnCantSelect = () => {
    notification.warn({
      message: 'Invalid Selection!',
      description:
        "You cannot select a voucher that does not have the 'Do Job approval' status.",
      onClick: () => {},
      duration: 2,
    });
  };
  const rowSelection = {
    selectedRowKeys: selectedVoucherKeys,
    // onChange: handleSelectVoucherKey,
    selections: [Table.SELECTION_NONE],
    columnTitle: <b>SCHEDULE</b>,
    hideSelectAll: true,
    renderCell: (checked, record, index, originNode) => {
      if (record.status !== 1) return <span onClick={warnCantSelect}>N/A</span>;
      if (checked) {
        return (
          <MdCheckBox
            color={Colors.blue}
            size={20}
            onClick={(e) => handleSelectVoucherKey(record._id)}
          />
        );
      } else {
        return (
          <MdCheckBoxOutlineBlank
            color={Colors.blue}
            size={20}
            onClick={(e) => handleSelectVoucherKey(record._id)}
          />
        );
      }
    },
  };

  const openSingleVoucher = (vchr) => {
    dispatch(voucherActions.putVoucherInState(vchr));
    const newPath = `${history.location.pathname}?vchID=${vchr._id}`;
    history.push(newPath);
  };

  useEffect(() => {
    getVouchers();
  }, []);

  useEffect(() => {
    if (searchText.length) {
      handleSearch(searchText);
    } else {
      setFilteredData(dataSource);
    }
  }, [searchText]);

  useEffect(() => {
    if (!getVouchersStatus && !denyStatusID && !approveStatusID) return;
    const vouchersResult = voucherStatusMessages.find(
      (it) => it.statusID === getVouchersStatus
    );

    if (getVouchersStatus && vouchersResult) {
      setGetVouchersStatus('');
    }

    if (approveStatusID) {
      const approvalResult = voucherStatusMessages.find(
        (d) => d.statusID === approveStatusID
      );

      if (!approvalResult) return;
      if (approvalResult.success) {
        notification.success({
          message: 'Update',
          description: 'Voucher has been approved',
          onClick: () => {},
          duration: 2,
          onClose: () => {
            getVouchers();
          },
        });
      } else {
        notification.error({
          message: 'Error!',
          description: 'Voucher has not been updated. Please try again',
          onClick: () => {
            getVouchers();
          },
          duration: 2,
        });
      }

      if (approveStatusID) setApproveStatusID('');
    }

    if (denyStatusID) {
      const result = voucherStatusMessages.find(
        (d) => d.statusID === denyStatusID
      );

      if (!result) return;
      if (result.success) {
        notification.success({
          message: 'Update',
          description: 'Voucher has been denied',
          onClick: () => {},
          duration: 2,
        });
      } else {
        notification.error({
          message: 'Error!',
          description: 'Voucher has not been updated. Please try again',
          onClick: () => {},
          duration: 2,
        });
      }
    }
  }, [voucherStatusMessages]);

  useEffect(() => {
    setFilteredData(dataSource);
  }, [dataSource]);

  return (
    <React.Fragment>
      <div className='voucher-settings-card'>
        <div className='voucher-search-container'>
          <div className='voucher-search-input-wrapper'>
            <input
              placeholder='Search beneficiary, cats name, service provider'
              value={searchText}
              onChange={(e) => setSearchText(utils.getInputVal(e))}
            />
          </div>
          <div className='voucher-search-icon'>
            <AiOutlineSearch color={Colors.textGray} size={20} />
          </div>
        </div>
        <div style={{ marginBottom: '1rem', display: 'flex' }}>
          <Button color={Colors.darkGreen} onClick={configureEntitiesForCSV}>
            <span style={{ color: Colors.white }}>Export to CSV</span>
          </Button>

          <div style={{ display: 'none' }}>
            <CSVLink
              filename={`WEYONJE VOUCHERS SUMMARY(${moment().format(
                'DD-MM-YYYY'
              )}).csv`}
              // headers={csvHeaders}
              hidden
              data={csvDataForDownload}
            >
              <span ref={csvDownloaderRef}>Downloader</span>
            </CSVLink>
          </div>

          <div style={{ marginLeft: '30px' }}>
            <ApproveAllVouchers isDO={isDO} />
          </div>

          <div
            style={{ marginLeft: '30px' }}
            onMouseLeave={() => {
              if (warnScheduleVisible) setWarnScheduleVisible(false);
            }}
          >
            <ScheduleVoucher vouchersList={selectedVoucherKeys} />
          </div>

          <div style={{ marginLeft: '30px' }}>
            <Button
              color={Colors.white}
              onClick={!vouchersLoading ? getVouchers : () => {}}
              extraClassName='voucher-spinner-btn'
            >
              {/* <SyncOutlined /> */}
              <RiRefreshFill
                className={vouchersLoading ? 'voucher-spinner' : ''}
                size={26}
                color={vouchersLoading ? Colors.actionRed : Colors.blue}
              />
            </Button>
          </div>
        </div>

        <div className='sps-table vouchers-table'>
          <Spin spinning={vouchersLoading}>
            <Table
              rowKey={(record) => record._id}
              loading={loading}
              dataSource={filteredData}
              rowSelection={rowSelection}
              pagination={{
                defaultPageSize: 5,
                showSizeChanger: true,
                pageSizeOptions: ['5', '8', '10', '20', '30'],
              }}
            >
              <Column
                title={<b>VOUCHER ID</b>}
                dataIndex='voucherReadableID'
                key={Math.random()}
                width={'160px'}
              />

              <Column
                title={<b>BENEFICIARY NAME</b>}
                // dataIndex="beneficiary.name"
                key={Math.random()}
                width={'160px'}
                render={(text, record) => (
                  <Space size='middle' key={Math.random()}>
                    <div className='sp-table-end'>
                      <div className='sp-table-end-label'>
                        {record.beneficiary}
                      </div>
                    </div>
                  </Space>
                )}
              />
              <Column
                title={<b>BENEFICIARY Phone</b>}
                // dataIndex="phoneNumber"
                key={Math.random()}
                width={'160px'}
                render={(text, record) => (
                  <Space size='middle' key={Math.random()}>
                    <div className='sp-table-end'>
                      <div className='sp-table-end-label'>
                        {record.beneficiaryNumber}
                      </div>
                    </div>
                  </Space>
                )}
              />
              <Column
                title={<b>SERVICE PROVIDER TYPE</b>}
                // dataIndex="noOfDumps"
                key={Math.random()}
                width={'10px'}
                render={(text, record) => (
                  <Space size='middle' key={Math.random()}>
                    <div className='sp-table-end'>
                      <div className='sp-table-end-label'>{record.spType}</div>
                    </div>
                  </Space>
                )}
              />
              <Column
                title={<b>DIVISION</b>}
                dataIndex='validForDivision'
                key={Math.random()}
                onFilter={(val, record) => record.validForDivision === val}
                filters={[
                  { text: 'Nakawa', value: 'Nakawa' },
                  { text: 'Central', value: 'Central' },
                  { text: 'Makindye', value: 'Makindye' },
                  { text: 'Rubaga', value: 'Rubaga' },
                  { text: 'Kawempe', value: 'Kawempe' },
                ]}
              />

              <Column
                title={<b>CATS NAME</b>}
                dataIndex='generatedBy'
                value
                key={(record) => `${record._id}6`}
                width={'140px'}
              />

              <Column
                title={<b>VALUE</b>}
                // dataIndex="type"value
                key={Math.random()}
                width={'140px'}
                render={(text, record) => (
                  <Space size='middle' key={Math.random()}>
                    <div className='sp-table-end'>
                      <div className='sp-table-end-label'>
                        UGX {record.value}
                      </div>
                    </div>
                  </Space>
                )}
              />

              <Column
                title={<b>Extra Barrels</b>}
                key={Math.random()}
                width={'160px'}
                dataIndex='noOfExtraBarrelsRequested'
                render={(text, record) => (
                  <Space size='middle' key={Math.random()}>
                    <div className='sp-table-end'>
                      <div className='sp-table-end-label'>
                        {record.noOfExtraBarrelsRequested || 'N/A'}
                      </div>
                    </div>
                  </Space>
                )}
              />

              <Column
                title={<b>CREATED AT</b>}
                dataIndex='createdAt'
                key={Math.random()}
                render={(val) => handleTime(val)}
              />

              <Column
                title={<b>EXPIRES IN</b>}
                dataIndex='expiryDate'
                render={(val) => {
                  const returnVal = handleTime(val) || val;
                  return returnVal;
                }}
                key={Math.random()}
              />

              <Column
                title={<b>SERVICE PROVIDER</b>}
                // dataIndex="type"value
                key={Math.random()}
                width={'140px'}
                render={(text, record) => (
                  <Space size='middle' key={Math.random()}>
                    <div className='sp-table-end'>
                      <div className='sp-table-end-label'>
                        {record.serviceProvider || 'None Yet'}
                      </div>
                    </div>
                  </Space>
                )}
              />

              <Column
                title={<b>STATUS</b>}
                key={Math.random()}
                render={(text, record) => (
                  <Space size='middle' key={Math.random()}>
                    <div
                      className='sp-table-end'
                      style={{ justifyContent: 'flex-start' }}
                    >
                      <div
                        className='sp-table-end-label'
                        style={{
                          color: voucherStatusColors[record.status],
                          marginRight: '5px',
                        }}
                      >
                        {voucherStatuses[record.status]}
                      </div>
                      {voucherStatuses[record.status] === voucherStatuses[3] ? (
                        <div className='sp-table-end-icon'>
                          <AiOutlineInfoCircle
                            size={20}
                            color={Colors.darkGreen}
                          />
                        </div>
                      ) : null}
                    </div>
                  </Space>
                )}
              />

              <Column
                title={<b>ACTION</b>}
                key={Math.random()}
                width={'160px'}
                render={(record) => (
                  <Space
                    size='middle'
                    key={Math.random()}
                    style={{ minWidth: '100px' }}
                  >
                    {record.status === 5 ? (
                      <ApproveForPaymentButton
                        voucher={record}
                        showSingleVoucher={openSingleVoucher}
                      />
                    ) : record.status === 0 ? (
                      <ApproveDeclineDropdown
                        onView={openSingleVoucher}
                        voucher={record}
                        onApprove={handleVoucherApproval}
                        onDecline={handleDeclineConfirmed}
                      />
                    ) : (
                      <ViewOrDispatchDropdown
                        voucher={record}
                        onView={openSingleVoucher}
                      />
                    )}
                  </Space>
                )}
              />
            </Table>
          </Spin>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AllVouchers;
