const endpoints = (base, prefix, apiVersion) => {
  const defaultEndpoint = "voucherProgram";
  const altEndpoint = "vouchers";
  const altEndpoint2 = "voucher";
  const officerEndpoint = "officer";

  return {
    get: `${base}/${prefix}/${apiVersion}/${officerEndpoint}/${altEndpoint2}`, // /:voucherID
    getAll: `${base}/${prefix}/${apiVersion}/${officerEndpoint}/${altEndpoint}`,
    getOverviewData: `${base}/${prefix}/${apiVersion}/${defaultEndpoint}`,
    createVoucherProgram: `${base}/${prefix}/${apiVersion}/${defaultEndpoint}`,
    recountVouchers: `${base}/${prefix}/${apiVersion}/${officerEndpoint}/recount`,
    updateVoucherProgram: (id) =>
      `${base}/${prefix}/${apiVersion}/${defaultEndpoint}/${id}`,
    // `api/v1/officer/voucher/actionVoucher/${thisApp.props.voucherDetails._id}`;
    actionVoucher: (id) =>
      `${base}/${prefix}/${apiVersion}/${officerEndpoint}/${altEndpoint2}/actionVoucher/${id}`, // /:voucherID
    deleteVoucher: (id) =>
      `${base}/${prefix}/${apiVersion}/${officerEndpoint}/${altEndpoint2}/${id}`, // /:voucherID
    editVoucher: (id) =>
      `${base}/${prefix}/${apiVersion}/${officerEndpoint}/${altEndpoint2}/${id}`, // /:voucherID
    deactivateVoucher: (id) =>
      `${base}/${prefix}/${apiVersion}/${altEndpoint2}/actionVoucher/${id}`,
    updateVoucher: `${base}/${prefix}/${apiVersion}/${officerEndpoint}/${altEndpoint2}/bulkUpdate`,
  };
};

export default endpoints;
