import React, { useState, useEffect } from 'react';
import { Button as AntDBtn, Modal, Input, notification, Spin } from 'antd';
import Colors from '../../../config/Colors';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { addDivision } from '../../../redux/actions/divisionActions';
import makeID from '../../../redux/utils/makeID';

const AddDivisionModal = ({ visible, setVisible }) => {
  // Redux
  const dispatch = useDispatch();
  const divisionStatusMessages = useSelector(
    (state) => state.division.statusMessages
  );
  const loading = useSelector((state) => state.voucher.loading);
  const [createStatus, setCreateStatus] = useState('');

  const [divisionName, setDivisionName] = useState('');
  const [maxVouchersPerMonth, setMaxVouchersPerMonth] = useState(0);

  const handleAddDivision = () => {
    if (!divisionName || maxVouchersPerMonth === 0) {
      notification.warn({
        message: 'Incomplete Input!',
        description: `Please provide the division / region name and maximum vouchers per month.`,
        onClick: () => {},
        duration: 2,
      });
      return;
    }
    const payload = {
      name: divisionName,
      maxVouchersPerMonth,
    };

    // return;
    const createStatusID = makeID(8);
    setCreateStatus(createStatusID);
    dispatch(addDivision(payload, createStatusID));
  };

  useEffect(() => {
    if (!createStatus) return;

    const result = divisionStatusMessages.find(
      (it) => it.statusID === createStatus
    );

    if (result?.success) {
      const successMessage = 'Division created successfully';
      notification.success({
        message: 'Update',
        description: successMessage,
        onClose: () => {
          setVisible(false);
        },
        duration: 2,
      });
    } else {
      const errorMessage = result?.error || 'An unknown error occurred';
      notification.warn({
        message: 'An Error Occurred',
        description: errorMessage,
        onClick: () => {},
        duration: 2,
      });
    }

    setCreateStatus('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [divisionStatusMessages]);

  return (
    <Modal
      visible={visible}
      title={<b style={{ color: Colors.textGray }}>ADD DIVISION / REGION</b>}
      onOk={handleAddDivision}
      className='add-vp-container'
      onCancel={() => setVisible(false)}
      footer={[
        <AntDBtn key='cancel' onClick={() => setVisible(false)}>
          Cancel
        </AntDBtn>,
        <AntDBtn
          key='add'
          type='primary'
          onClick={handleAddDivision}
          className='logout-modal-no'
        >
          Add
        </AntDBtn>,
      ]}
    >
      <Spin spinning={loading}>
        <div>
          <div className='add-vp-fields'>
            <p className='add-vp-key'>Division Name:</p>
            <Input
              type='text'
              className='add-vp-val'
              value={divisionName}
              onChange={(val) => setDivisionName(val.target.value)}
              placeholder='Enter division name'
            />
          </div>
          <div className='add-vp-fields'>
            <p className='add-vp-key'>Maximum vouchers per month:</p>
            <Input
              type='text'
              className='add-vp-val'
              value={maxVouchersPerMonth}
              onChange={(val) => setMaxVouchersPerMonth(val.target.value)}
              placeholder='Enter division name'
            />
          </div>
        </div>
      </Spin>
    </Modal>
  );
};

export default AddDivisionModal;
