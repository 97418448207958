import React, { useEffect, useState, useRef } from "react";
import * as d3 from "d3";
import "d3-selection-multi";
import divisions from "./../../../assets/geo_data/maps/divisions.json";
import parishes from "./../../../assets/geo_data/maps/parishes.json";
import { Spin } from "antd";

import { Colors } from "./../../../config";
import MapChartLegend from "./MapChartLegend";
import * as defaultVals from "./defaultVals";

const MapChart = ({
  mapJson,
  selectRegion,
  title,
  loading = false,
  byDivision,
  byParish,
}) => {
  const useParish = !byDivision;
  const [reloadKey, setReloadKey] = useState(Math.random());

  const mapToUse = useParish ? parishes : divisions;

  const mapRef = useRef(null);
  const tempStore = useRef(null);
  let chartHeight;
  const chartWidth = (chartHeight = 400);

  // Svg
  const canvasWidth = 0.75 * chartWidth;
  const canvasHeight = 0.75 * chartHeight;

  const activeRanking = !byDivision ? defaultVals.parishRanking : byDivision;

  const maxRankingColor = useParish ? "#2D6744" : "#566380";

  const findRankedColor = (name) => {
    const itemInArr = activeRanking.find((it) => it.name === name);
    const topVal = Math.max(...activeRanking.map((it) => it.dumpVal));

    const percentageRanking = Math.round((itemInArr.dumpVal / topVal) * 100);

    // const actualIndex = rankedVals.indexOf(itemInArr);
    const color = `${maxRankingColor}${defaultVals.hexTable[percentageRanking]}`;
    return color;
    // return rankedValsRankedVals[actualIndex];
  };

  const loadMap = () => {
    let centerMap = d3.geoCentroid(mapToUse);
    let scale = 30;
    let offset = [canvasWidth / 2, canvasHeight / 2];
    let mapProjection = d3
      .geoMercator()
      .scale(scale)
      .center(centerMap)
      .translate(offset);

    let mapPath = d3.geoPath().projection(mapProjection);

    // using the path determine the bounds of the current map and use
    // these to determine better values for the scale and translation
    let bounds = mapPath.bounds(mapToUse);
    let hScale = (scale * canvasWidth) / (bounds[1][0] - bounds[0][0]);
    let vScale = (scale * canvasHeight) / (bounds[1][1] - bounds[0][1]);
    scale = hScale < vScale ? hScale : vScale;
    offset = [
      canvasWidth - (bounds[0][0] + bounds[1][0]) / 2,
      canvasHeight - (bounds[0][1] + bounds[1][1]) / 2,
    ];

    // new projection
    mapProjection = d3
      .geoMercator()
      .center(centerMap)
      .scale(scale)
      .translate(offset);
    mapPath = mapPath.projection(mapProjection);

    const svgCanvas = d3
      .select(mapRef.current)
      .append("svg")
      .attr("width", canvasWidth)
      .attr("height", canvasHeight);

    svgCanvas
      .selectAll("path")
      .data(mapToUse.features)
      .enter()
      .append("path")
      .attr("d", (data) => {
        return mapPath(data);
      })
      .attr("stroke", "#ffffff")
      .attr("fill", (d) => {
        return findRankedColor(
          useParish ? d.properties.PNAME2014 : d.properties.SNAME2014
        );
      })
      .on("mouseover", function (d) {
        d3.select(this).style("fill", Colors.mapSelected);
      })
      .on("mouseout", function (d) {
        d3.select(this).style("fill", (dm) => {});

        d3.select(this).style("fill", (dm2) =>
          findRankedColor(
            useParish ? dm2.properties.PNAME2014 : dm2.properties.SNAME2014
          )
        );
      })
      .on("click", (d) => {
        // selectRegion(d.id);
        d3.select(this).enter((d2) => selectRegion(d2.properties.SNAME2014));
      })
      .append("title")
      .text((d) => {
        let itemValue = activeRanking.find(
          (it) =>
            it.name ===
            (useParish ? d.properties.PNAME2014 : d.properties.SNAME2014)
        );
        return `${
          useParish ? d.properties.PNAME2014 : d.properties.SNAME2014
        } - ${itemValue.dumpVal}`;
      });

    return () => {};
  };

  useEffect(() => {
    loadMap();
  }, []);

  useEffect(() => {
    setReloadKey(Math.random());
  }, [byDivision, byParish]);

  useEffect(() => {
    loadMap();
  }, [reloadKey]);

  return (
    <React.Fragment>
      <div className="map-chart-title">
        <h4>{title}</h4>
      </div>
      <Spin spinning={loading}>
        <div
          key={reloadKey}
          id="interactiveMap"
          style={{ ...styles.dataContainer, width: "100%" }}
          ref={mapRef}
        >
          <p ref={tempStore} style={styles.tempStore}></p>
          <MapChartLegend
            labels={{
              bottom: Math.min(...activeRanking.map((it) => it.dumpVal)),
              mid:
                (Math.max(...activeRanking.map((it) => it.dumpVal)) -
                  Math.min(...activeRanking.map((it) => it.dumpVal))) /
                2,
              top: Math.max(...activeRanking.map((it) => it.dumpVal)),
            }}
            maxColor={maxRankingColor}
            minColor="#ffffff"
          />
        </div>
      </Spin>
    </React.Fragment>
  );
};

const styles = {
  container: {},
  h3s: {
    color: "#fff",
  },

  dataContainer: {
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
  },

  fills: {
    purple: ["fill", "#8150c2"],
    blue: ["fill", "rgb(0, 0, 255)"],
  },
  tempStore: { display: "none" },
};

export default MapChart;
