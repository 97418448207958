import * as notificationTypes from "../types/notificationTypes";
import StatusMessage from "../models/statusMessage";

const initialState = {
  notifications: [],
  loading: false,
  notification: {},
  statusMessages: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case notificationTypes.GET_NOTIFICATION:
      return {
        ...state,
        loading: false,
        notification: action.payload || state.notification,
        statusMessages: [
          ...state.statusMessages,
          new StatusMessage({
            subject: notificationTypes.GET_NOTIFICATION,
            action,
          }),
        ],
      };

    case notificationTypes.SEND_SP_NOTIFICATION:
      return {
        ...state,
        loading: false,
        // notification: action.payload || state.notification,
        statusMessages: [
          ...state.statusMessages,
          new StatusMessage({
            subject: notificationTypes.GET_NOTIFICATION,
            action,
          }),
        ],
      };

    case notificationTypes.GET_ALL_NOTIFICATIONS:
      return {
        ...state,
        loading: false,
        notifications: action.payload || state.notifications,
        statusMessages: [
          ...state.statusMessages,
          new StatusMessage({
            subject: notificationTypes.GET_ALL_NOTIFICATIONS,
            action,
          }),
        ],
      };

    case notificationTypes.LOADING_NOTIFICATIONS:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}
