import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Colors } from "../config";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

const OutlineInput = ({
  placeholder,
  label,
  adornment,
  adornmentPosition,
  disabled,
  value,
  receiveOutput,
  type,
}) => {
  const classes = useStyles();
  const [inputVal, setInputVal] = useState(value || "");

  const allAdornments = (
    <InputAdornment position={adornmentPosition || "end"}>
      {adornment}
    </InputAdornment>
  );

  const handleInput = (e) => {
    if (disabled) return;
    const newVal = e.target.value;
    setInputVal(newVal);
    if (receiveOutput) receiveOutput(newVal);
  };
  return (
    <div style={{ background: Colors.white, borderRadius: "5px" }}>
      <TextField
        size="small"
        label={label}
        variant="outlined"
        placeholder={placeholder}
        onChange={handleInput}
        style={{ fontSize: "0.8em" }}
        // color={"#000000"}
        type={type || "text"}
        value={inputVal}
        fullWidth
        InputProps={
          adornment
            ? {
                endAdornment: allAdornments,
              }
            : null
        }
      />
    </div>
  );
};

export default OutlineInput;
