const endpoints = (base, prefix, apiVersion) => {
  const defaultEndpoint = "dashboard";
  return {
    getDumpsData: `${base}/${prefix}/${defaultEndpoint}/dumps`, // /:customerID
    getJobsData: `${base}/${prefix}/${defaultEndpoint}/jobs`,
    getCustomerSPData: `${base}/${prefix}/${defaultEndpoint}/jobs/customersAndSPRequests`,
  };
};

export default endpoints;
