import * as adminTypes from '../types/adminTypes';
import StatusMessage from '../models/statusMessage';

const initialState = {
  admins: [],
  loading: false,
  admin: {},
  name: '',
  id: '',
  officer: {},
  role: '',
  token: '',
  divisionName: '',
  statusMessages: [],
};

const adminReducer = (state = initialState, action) => {
  switch (action.type) {
    case adminTypes.LOGIN_ADMIN:
      return {
        ...state,
        loading: false,
        token: action.payload.token || state.token,
        divisionName: action.payload.division || state.divisionName || '',
        officer: action.isDO
          ? action.payload.officer || state.officer
          : state.officer,
        id: action.payload.id || state.id,
        name: action.payload.name || state.name,
        role: action.payload.role || '',
        statusMessages: [
          ...state.statusMessages,
          new StatusMessage({ subject: adminTypes.LOGIN_ADMIN, action }),
        ],
      };

    case adminTypes.GET_ADMIN:
      return {
        ...state,
        loading: false,
        admin: action.payload,
        statusMessages: [
          ...state.statusMessages,
          new StatusMessage({ subject: adminTypes.GET_ADMIN, action }),
        ],
      };

    case adminTypes.ADD_ADMIN:
      return {
        ...state,
        loading: false,
        admin: action.payload,
        statusMessages: [
          ...state.statusMessages,
          new StatusMessage({ subject: adminTypes.ADD_ADMIN, action }),
        ],
      };

    case adminTypes.GET_ALL_ADMINS:
      return {
        ...state,
        loading: false,
        admins: action.payload,
        statusMessages: [
          ...state.statusMessages,
          new StatusMessage({ subject: adminTypes.GET_ALL_ADMINS, action }),
        ],
      };

    case adminTypes.REQUEST_OTP:
      return {
        ...state,
        loading: false,
        statusMessages: [
          ...state.statusMessages,
          new StatusMessage({ subject: adminTypes.REQUEST_OTP, action }),
        ],
      };

    case adminTypes.VERIFY_OTP:
      return {
        ...state,
        loading: false,
        token: action.payload.token || state.token,
        // admin: action.payload.admin || state.admin,
        statusMessages: [
          ...state.statusMessages,
          new StatusMessage({ subject: adminTypes.VERIFY_OTP, action }),
        ],
      };

    case adminTypes.LOGOUT_ADMIN:
      return {
        ...initialState,
      };

    case adminTypes.LOADING_ADMINS:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};

export default adminReducer;
