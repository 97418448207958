export default {
  allMonths: {
    0: "Jan",
    1: "Feb",
    2: "Mar",
    3: "Apr",
    4: "May",
    5: "Jun",
    6: "Jul",
    7: "Aug",
    8: "Sep",
    9: "Oct",
    10: "Nov",
    11: "Dec",
  },
  jobs: [
    {
      jobID: "10022",
      customerName: "Arthur Richardson",
      location: "12b Bukoto Cres Lane",
      amount: "435000",
    },
    {
      jobID: "10022",
      customerName: "Arthur Richardson",
      location: "12b Bukoto Cres Lane",
      amount: "435000",
    },
    {
      jobID: "10022",
      customerName: "Arthur Richardson",
      location: "12b Bukoto Cres Lane",
      amount: "435000",
    },
    {
      jobID: "10022",
      customerName: "Arthur Richardson",
      location: "12b Bukoto Cres Lane",
      amount: "435000",
    },
    {
      jobID: "10022",
      customerName: "Arthur Richardson",
      location: "12b Bukoto Cres Lane",
      amount: "435000",
    },
    {
      jobID: "10022",
      customerName: "Arthur Richardson",
      location: "12b Bukoto Cres Lane",
      amount: "435000",
    },
    {
      jobID: "10022",
      customerName: "Arthur Richardson",
      location: "12b Bukoto Cres Lane",
      amount: "435000",
    },
    {
      jobID: "10022",
      customerName: "Arthur Richardson",
      location: "12b Bukoto Cres Lane",
      amount: "435000",
    },
  ],
};
