import React, { useState, useEffect } from "react";

const TouchableOpacity = ({ children, clicked, disabled, altClass }) => {
  const [buttonOpacity, setButtonOpacity] = useState(1);
  useEffect(() => {
    if (buttonOpacity < 1) {
      setTimeout(() => {
        setButtonOpacity(1);
      }, 100);
    }
  }, [buttonOpacity]);

  const clickHandler = (e) => {
    if (disabled) {
      e.preventDefault();
    }
    if (!disabled) {
      if (clicked) {
        clicked();
      }
      setButtonOpacity(0.5);
    }
  };

  return (
    <span
      className={
        altClass
          ? `${altClass} touchable-opacity-span-div`
          : "touchable-opacity-span-div"
      }
      style={{ opacity: buttonOpacity, cursor: "pointer" }}
      onClick={clickHandler}
    >
      {children}
    </span>
  );
};

export default TouchableOpacity;
