import * as spTypes from "../types/spTypes";
import { spEndpoints } from "../endpoints";
import * as fetchFunctions from "../utils/fetchFunctions";
import * as fetchFunctionsLegacy from "../utils/fetchFunctionsLegacy";

export const getAllSps = (statusID) => async (dispatch, getState) => {
  dispatch({
    type: spTypes.LOADING_SPS,
  });

  const { token } = getState().admin;

  const result = await fetchFunctionsLegacy.getData(spEndpoints.getAll, token);

  dispatch({
    type: spTypes.GET_ALL_SPS,
    payload: result,
    statusID,
  });
};

export const addSP = (spDetails, statusID) => async (dispatch, getState) => {
  dispatch({
    type: spTypes.LOADING_SPS,
  });

  const { token } = getState().admin;

  const result = await fetchFunctionsLegacy.postData(
    spEndpoints.register,
    spDetails,
    token
  );

  dispatch({
    type: spTypes.ADD_SP,
    payload: result,
    statusID,
  });
};

export const getNearestSP =
  (findDetails, statusID) => async (dispatch, getState) => {
    dispatch({
      type: spTypes.LOADING_SPS,
    });

    const { token } = getState().admin;

    const result = await fetchFunctionsLegacy.postData(
      spEndpoints.register,
      findDetails,
      token
    );

    dispatch({
      type: spTypes.ADD_SP,
      payload: result,
      statusID,
    });
  };

export const deleteSP = (spID, statusID) => async (dispatch, getState) => {
  dispatch({
    type: spTypes.LOADING_SPS,
  });

  const { token } = getState().admin;
  const result = await fetchFunctionsLegacy.deleteData(
    spEndpoints.delete(spID),
    token
  );

  dispatch({
    type: spTypes.DELETE_SP,
    payload: result,
    statusID,
  });
};

export const updateSP =
  (spID, updates, statusID) => async (dispatch, getState) => {
    dispatch({
      type: spTypes.LOADING_SPS,
    });

    const { token } = getState().admin;
    const result = await fetchFunctionsLegacy.putData(
      spEndpoints.update(spID),
      updates,
      token
    );

    console.log("Result is", result);

    dispatch({
      type: spTypes.UPDATE_SP,
      payload: result,
      statusID,
    });
  };

export const getSp = (spID, statusID) => async (dispatch, getState) => {
  dispatch({
    type: spTypes.LOADING_SPS,
  });

  const { token } = getState().admin;

  const result = await fetchFunctionsLegacy.getData(
    spEndpoints.getSp(spID),
    token
  );

  console.log("SP RESULT SI", result);
  dispatch({
    type: spTypes.GET_SP,
    payload: result,
    statusID,
  });
};

export const putSpInState = (sp) => async (dispatch, getState) => {
  dispatch({
    type: spTypes.PUT_SP_IN_STATE,
    payload: sp,
  });
};

export const getAllVoucherSPs = (statusID) => async (dispatch, getState) => {
  dispatch({
    type: spTypes.LOADING_SPS,
  });

  const { token } = getState().admin;
  const divisionID = "";

  const result = await fetchFunctionsLegacy.getData(
    spEndpoints.getAllVoucherSPs(divisionID),
    token
  );

  dispatch({
    type: spTypes.GET_ALL_SPS,
    payload: result,
    statusID,
  });
};
