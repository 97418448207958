import auth from "./authEndpoints";
import job from "./jobEndpoints";
import driver from "./driverEndpoints";
import sp from "./spEndpoints";
import customer from "./customerEndpoints";
import dump from "./dumpEndpoints";
import voucher from "./voucherEndpoints";
import notification from "./notificationEndpoints";
import cats from "./catsEndpoints";
import division from "./divisionEndpoints";
import dashboard from "./dashboardEndpoints";

const API_VERSION = "v1";
const API_PREFIX = "api";
const BASE_URL = "https://api.weyonje.com";
// const BASE_URL = "http://localhost:2083";
// const BASE_URL = "https://api.staging.weyonje.com";
// const BASE_URL = "https://api.testing.weyonje.com";

export const authEndpoints = { ...auth(BASE_URL, API_PREFIX, API_VERSION) };
export const jobEndpoints = { ...job(BASE_URL, API_PREFIX, API_VERSION) };
export const driverEndpoints = { ...driver(BASE_URL, API_PREFIX, API_VERSION) };
export const voucherEndpoints = {
  ...voucher(BASE_URL, API_PREFIX, API_VERSION),
};
export const dumpEndpoints = { ...dump(BASE_URL, API_PREFIX, API_VERSION) };
export const customerEndpoints = {
  ...customer(BASE_URL, API_PREFIX, API_VERSION),
};
export const spEndpoints = { ...sp(BASE_URL, API_PREFIX, API_VERSION) };
export const notificationEndpoints = {
  ...notification(BASE_URL, API_PREFIX, API_VERSION),
};
export const catsEndpoints = { ...cats(BASE_URL, API_PREFIX, API_VERSION) };
export const divisionEndpoints = {
  ...division(BASE_URL, API_PREFIX, API_VERSION),
};

export const dashboardEndpoints = {
  ...dashboard(BASE_URL, API_PREFIX, API_VERSION),
};
