// CRUD Operations
export const CREATE_DUMP = "CREATE_DUMP";
export const UPDATE_DUMP = "UPDATE_DUMP";
export const DELETE_DUMP = "DELETE_DUMP";
export const GET_DUMP = "GET_DUMP";
export const GET_ALL_DUMPS = "GET_ALL_DUMPS";

export const ADD_DUMP = "ADD_DUMP";

// Misc
export const LOADING_DUMPS = "LOADING_DUMPS";
