import React, { useState, useEffect } from "react";
import { Button as AntDBtn, Modal, Input, notification, Spin } from "antd";
import Colors from "../../../config/Colors";

// Redux
import { useSelector, useDispatch } from "react-redux";
import * as voucherActions from "../../../redux/actions/voucherActions";
import makeID from "../../../redux/utils/makeID";
import { insertCommas } from "../../../redux/utils/componentUtils";

const ApproveModal = ({ visible, setVisible, voucher }) => {
  // Redux
  const dispatch = useDispatch();
  const [voucherValue, setVoucherValue] = useState("");
  const vouchersOverviewInState = useSelector(
    (state) => state.voucher.overview
  );
  const activeProgramInState = useSelector(
    (state) => state.voucher.activeProgram
  );
  const [activeProgram, setActiveProgram] = useState(activeProgramInState);

  const voucherStatusMessages = useSelector(
    (state) => state.voucher.statusMessages
  );
  const vouchersLoading = useSelector((state) => state.voucher.loading);
  const [statusID, setStatusID] = useState("");

  const handleApproveConfirmed = () => {
    const updateID = makeID(8);
    setStatusID(updateID);
    dispatch(voucherActions.approveSinglePayment(voucher?._id, updateID));
  };

  useEffect(() => {
    if (!vouchersOverviewInState?.length) return;

    const actualValue = activeProgram.defaultVoucherValue;
    setVoucherValue(actualValue);
  }, [visible]);

  useEffect(() => {
    if (!statusID) return;

    const result = voucherStatusMessages.find((it) => it.statusID === statusID);
    if (result?.success) {
      const successMessage = "Voucher was marked as paid";
      notification.success({
        message: "Update",
        description: successMessage,
        onClick: () => {},
        duration: 2,
        onClose: () => {
          dispatch(voucherActions.getAllVouchers());
          setVisible(false);
        },
      });
      setStatusID("");

      return;
    } else {
      const failMessage = "Failed to mark voucher as paid. Please try again";
      notification.error({
        message: "Update",
        description: failMessage,
        onClick: () => {},
        duration: 2,
      });
      setStatusID("");
      return;
    }
  }, [voucherStatusMessages]);

  useEffect(() => {
    if (!activeProgramInState) return;
    setActiveProgram(activeProgramInState);
  }, [activeProgramInState]);

  return (
    <Modal
      visible={visible}
      title={<b style={{ color: Colors.textGray }}>APPROVE VOUCHER PAYMENT</b>}
      onOk={handleApproveConfirmed}
      onCancel={() => setVisible(false)}
      footer={[
        <AntDBtn
          key="logout"
          onClick={() => setVisible(false)}
          className="logout-modal-yes"
        >
          Cancel
        </AntDBtn>,
        <AntDBtn
          key="cancel"
          type="primary"
          onClick={handleApproveConfirmed}
          color={Colors.darkGreen}
          className="logout-modal-no"
        >
          Approve Payment
        </AntDBtn>,
      ]}
    >
      <Spin spinning={vouchersLoading}>
        <div style={{ marginBottom: "30px" }}>
          <p>
            Approve Voucher Payment for beneficiary {voucher.beneficiary} worth{" "}
            {insertCommas(voucherValue)}.
          </p>
        </div>
      </Spin>
    </Modal>
  );
};

export default ApproveModal;
