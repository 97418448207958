const catsEndpoints = (base, prefix, apiVersion) => {
  const defaultEndpoint = "cats";
  const altEndpoint = "officer";

  return {
    get: (id) => `${base}/${prefix}/${apiVersion}/${defaultEndpoint}/${id}`,
    getAll: (id) =>
      `${base}/${prefix}/${apiVersion}/${altEndpoint}/${id}/${defaultEndpoint}`,
    add: `${base}/${prefix}/${apiVersion}/${defaultEndpoint}`,
    update: (id) => `${base}/${prefix}/${apiVersion}/${defaultEndpoint}/${id}`,
    delete: (id) => `${base}/${prefix}/${apiVersion}/${defaultEndpoint}/${id}`,
  };
};

export default catsEndpoints;
