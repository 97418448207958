import React, { useState, useEffect } from "react";
import { Button as AntDBtn, Modal, Input } from "antd";
import Colors from "../../../config/Colors";
import Button from "../../../components/Button";
import OutlineInput from "../../../components/OutlineInput";
import TouchableOpacity from "../../../components/TouchableOpacity";
import { CgChevronDown, CgChevronUp } from "react-icons/cg";
import ApproveModal from "./ApproveModal";
import DeclineModal from "./DeclineModal";
import ReDispatchPopup from "./../../Jobs/ReDispatchPopup";

const ViewOrDispatchDropdown = ({ voucher, onView }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showDispatchModal, setShowDispatchModal] = useState(false);

  const handleViewClicked = () => {
    setShowDropdown(false);
    onView(voucher);
  };

  const handleDispatchClicked = () => {
    setShowDispatchModal(true);
    setShowDropdown(false);
  };

  const closeModal = () => {
    setShowDispatchModal(false);
    setShowApproveModal(false);
  };

  return (
    <>
      {voucher?.status === 6 || voucher?.status === 1 ? (
        <ReDispatchPopup
          voucherDetails={voucher}
          showPopup={showDispatchModal}
          hidePopup={() => setShowDispatchModal(false)}
        />
      ) : null}

      <div className="ad-container">
        <Button
          color={Colors.darkGreen}
          onClick={
            voucher?.status === 6 || voucher?.status === 1
              ? () => setShowDropdown(!showDropdown)
              : () => handleViewClicked()
          }
        >
          <p
            style={{
              color: Colors.white,
              margin: "0px",
              minWidth: "80px",
            }}
          >
            {voucher?.status === 6 || voucher?.status === 1
              ? "View/Dispatch"
              : "View"}
          </p>

          {voucher?.status === 6 || voucher?.status === 1 ? (
            <>
              {showDropdown ? (
                <CgChevronUp size={20} color={Colors.white} />
              ) : (
                <CgChevronDown size={20} color={Colors.white} />
              )}
            </>
          ) : null}
        </Button>

        {showDropdown ? (
          <div className="ad-dropdown">
            <TouchableOpacity
              clicked={handleViewClicked}
              altClass="ad-dropdown-item-br"
            >
              VIEW
            </TouchableOpacity>
            <TouchableOpacity
              clicked={handleDispatchClicked}
              altClass="ad-dropdown-item"
            >
              DISPATCH
            </TouchableOpacity>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default ViewOrDispatchDropdown;
