import * as catsTypes from "../types/catsTypes";
import { catsEndpoints } from "../endpoints";
import * as fetchFunctions from "../utils/fetchFunctions";
import * as fetchFunctionsLegacy from "../utils/fetchFunctionsLegacy";

export const getAllCats = (statusID) => async (dispatch, getState) => {
  dispatch({
    type: catsTypes.LOADING_CATS,
  });

  const { token, id } = getState().admin;

  const result = await fetchFunctions.getData(catsEndpoints.getAll(id), token);
  dispatch({
    type: catsTypes.GET_ALL_CATS,
    payload: result,
    statusID,
  });
};

export const getCats = (catsID, statusID) => async (dispatch, getState) => {
  dispatch({
    type: catsTypes.LOADING_CATS,
  });

  const { token } = getState().admin;

  const result = await fetchFunctions.getData(catsEndpoints.get(catsID), token);

  dispatch({
    type: catsTypes.GET_CATS,
    payload: result,
    statusID,
  });
};

export const putCatInState = (cat) => async (dispatch, getState) => {
  dispatch({
    type: catsTypes.PUT_CATS_IN_STATE,
    payload: cat,
  });
};

export const addCats = (catsDATA, statusID) => async (dispatch, getState) => {
  dispatch({
    type: catsTypes.LOADING_CATS,
  });

  const { token } = getState().admin;

  const result = await fetchFunctions.postData(
    catsEndpoints.add,
    catsDATA,
    token
  );

  dispatch({
    type: catsTypes.ADD_CATS,
    payload: result,
    statusID,
  });
};

export const deactivateCats =
  (catsID, statusID) => async (dispatch, getState) => {
    dispatch({
      type: catsTypes.LOADING_CATS,
    });

    const { token } = getState().admin;

    const result = await fetchFunctions.patchData(
      catsEndpoints.update(catsID),
      { isApprovedByDO: false },
      token
    );

    dispatch({
      type: catsTypes.DEACTIVATE_CATS,
      payload: result,
      statusID,
    });
  };

export const activateCats =
  (catsID, statusID) => async (dispatch, getState) => {
    dispatch({
      type: catsTypes.LOADING_CATS,
    });

    const { token } = getState().admin;

    const result = await fetchFunctions.patchData(
      catsEndpoints.update(catsID),
      { isApprovedByDO: true },
      token
    );

    dispatch({
      type: catsTypes.ACTIVATE_CATS,
      payload: result,
      statusID,
    });
  };

export const deleteCats = (catsID, statusID) => async (dispatch, getState) => {
  dispatch({
    type: catsTypes.LOADING_CATS,
  });

  const { token } = getState().admin;

  const result = await fetchFunctions.deleteData(
    catsEndpoints.delete(catsID),
    token
  );

  dispatch({
    type: catsTypes.DEACTIVATE_CATS,
    payload: result,
    statusID,
  });
};
