export const parishRanking = [
  { name: "BANDA", dumpVal: 20 },
  { name: "BUGOLOBI", dumpVal: 21 },
  { name: "BUKASA", dumpVal: 24 },
  { name: "BUKESA", dumpVal: 25 },
  { name: "BUKOTO I", dumpVal: 29 },
  { name: "BUKOTO II", dumpVal: 20 },
  { name: "BUSEGA", dumpVal: 21 },
  { name: "BUTABIKA", dumpVal: 26 },
  { name: "BUZIGA", dumpVal: 10 },
  { name: "BWAISE I", dumpVal: 5 },
  { name: "BWAISE II", dumpVal: 30 },
  { name: "BWAISE III", dumpVal: 40 },
  { name: "CIVIC CENTER", dumpVal: 15 },
  { name: "GGABA", dumpVal: 12 },
  { name: "INDUSTRIAL AREA", dumpVal: 14 },
  { name: "ITEK", dumpVal: 2 },
  { name: "KABALAGALA", dumpVal: 8 },
  { name: "KABOWA", dumpVal: 19 },
  { name: "KAGUGUBE", dumpVal: 8 },
  { name: "KAMWOKYA I", dumpVal: 35 },
  { name: "KAMWOKYA II", dumpVal: 22 },
  { name: "KANSANGA-MUYENGA", dumpVal: 16 },
  { name: "KANYANYA", dumpVal: 14 },
  { name: "KASUBI", dumpVal: 18 },
  { name: "KATWE I", dumpVal: 17 },
  { name: "KATWE II", dumpVal: 20 },
  { name: "KAWEMPE I", dumpVal: 22 },
  { name: "KAWEMPE II", dumpVal: 27 },
  { name: "KAZO", dumpVal: 23 },
  { name: "KIBULI", dumpVal: 31 },
  { name: "KIBUYE I", dumpVal: 13 },
  { name: "KIBUYE II", dumpVal: 33 },
  { name: "KIKAAYA", dumpVal: 36 },
  { name: "KISENYI  III", dumpVal: 17 },
  { name: "KISENYI I", dumpVal: 16 },
  { name: "KISENYI II", dumpVal: 19 },
  { name: "KISUGU", dumpVal: 14 },
  { name: "KIWATULE", dumpVal: 10 },
  { name: "KOLOLO I", dumpVal: 2 },
  { name: "KOLOLO II", dumpVal: 0 },
  { name: "KOLOLO III", dumpVal: 1 },
  { name: "KOLOLO IV", dumpVal: 13 },
  { name: "KOMAMBOGA", dumpVal: 16 },
  { name: "KYAMBOGO", dumpVal: 22 },
  { name: "KYANJA", dumpVal: 40 },
  { name: "KYEBANDO", dumpVal: 44 },
  { name: "LUBYA", dumpVal: 14 },
  { name: "LUKULI", dumpVal: 45 },
  { name: "LUNGUJJA", dumpVal: 45 },
  { name: "LUWAFU", dumpVal: 49 },
  { name: "LUZIRA", dumpVal: 10 },
  { name: "LUZIRA PRISONS", dumpVal: 20 },
  { name: "MAKERERE I", dumpVal: 20 },
  { name: "MAKERERE II", dumpVal: 27 },
  { name: "MAKERERE III", dumpVal: 26 },
  { name: "MAKERERE UNIVERSITY", dumpVal: 26 },
  { name: "MAKINDYE I", dumpVal: 36 },
  { name: "MAKINDYE II", dumpVal: 37 },
  { name: "MBUYA I", dumpVal: 46 },
  { name: "MBUYA II", dumpVal: 47 },
  { name: "MENGO", dumpVal: 40 },
  { name: "MPERERWE", dumpVal: 43 },
  { name: "MULAGO I", dumpVal: 42 },
  { name: "MULAGO II", dumpVal: 32 },
  { name: "MULAGO III", dumpVal: 47 },
  { name: "MUTUNDWE", dumpVal: 45 },
  { name: "MUTUNGO", dumpVal: 44 },
  { name: "NABISUNSA", dumpVal: 42 },
  { name: "NAGURU I", dumpVal: 42 },
  { name: "NAGURU II", dumpVal: 44 },
  { name: "NAJJANANKUBI I", dumpVal: 46 },
  { name: "NAJJANANKUBI II", dumpVal: 47 },
  { name: "NAKASERO I", dumpVal: 48 },
  { name: "NAKASERO II", dumpVal: 49 },
  { name: "NAKASERO III", dumpVal: 50 },
  { name: "NAKASERO IV", dumpVal: 41 },
  { name: "NAKAWA", dumpVal: 41 },
  { name: "NAKAWA INSTITUTION", dumpVal: 42 },
  { name: "NAKIVUBO-SHAURIYAKO", dumpVal: 48 },
  { name: "NAKULABYE", dumpVal: 45 },
  { name: "NAMIREMBE", dumpVal: 40 },
  { name: "NANKULABYE", dumpVal: 40 },
  { name: "NATETE", dumpVal: 41 },
  { name: "NDEEBA", dumpVal: 41 },
  { name: "NSAMBYA CENTRAL", dumpVal: 43 },
  { name: "NSAMBYA ESTATE", dumpVal: 42 },
  { name: "NSAMBYA RAILWAY", dumpVal: 41 },
  { name: "NTINDA", dumpVal: 50 },
  { name: "NTINDA POLICE BARRACKS", dumpVal: 47 },
  { name: "OLD KAMPALA", dumpVal: 45 },
  { name: "RUBAGA", dumpVal: 43 },
  { name: "SALAAMA", dumpVal: 42 },
  { name: "UPK", dumpVal: 40 },
  { name: "UPPER ESTATE", dumpVal: 41 },
  { name: "WABIGALO", dumpVal: 44 },
  { name: "WANDEGEYA", dumpVal: 30 },
];

export const divisionRanking = [
  { name: "NAKAWA", dumpVal: 200 },
  { name: "KAWEMPE", dumpVal: 125 },
  { name: "RUBAGA", dumpVal: 129 },
  { name: "CENTRAL", dumpVal: 119 },
  { name: "MAKINDYE", dumpVal: 112 },
];

export const hexTable = {
  100: "FF",
  99: "FC",
  98: "FA",
  97: "F7",
  96: "F5",
  95: "F2",
  94: "F0",
  93: "ED",
  92: "EB",
  91: "E8",
  90: "E6",
  89: "E3",
  88: "E0",
  87: "DE",
  86: "DB",
  85: "D9",
  84: "D6",
  83: "D4",
  82: "D1",
  81: "CF",
  80: "CC",
  79: "C9",
  78: "C7",
  77: "C4",
  76: "C2",
  75: "BF",
  74: "BD",
  73: "BA",
  72: "B8",
  71: "B5",
  70: "B3",
  69: "B0",
  68: "AD",
  67: "AB",
  66: "A8",
  65: "A6",
  64: "A3",
  63: "A1",
  62: "9E",
  61: "9C",
  60: "99",
  59: "96",
  58: "94",
  57: "91",
  56: "8F",
  55: "8C",
  54: "8A",
  53: "87",
  52: "85",
  51: "82",
  50: "80",
  49: "7D",
  48: "7A",
  47: "78",
  46: "75",
  45: "73",
  44: "70",
  43: "6E",
  42: "6B",
  41: "69",
  40: "66",
  39: "63",
  38: "61",
  37: "5E",
  36: "5C",
  35: "59",
  34: "57",
  33: "54",
  32: "52",
  31: "4F",
  30: "4D",
  29: "4A",
  28: "47",
  27: "45",
  26: "42",
  25: "40",
  24: "3D",
  23: "3B",
  22: "38",
  21: "36",
  20: "33",
  19: "30",
  18: "2E",
  17: "2B",
  16: "29",
  15: "26",
  14: "24",
  13: "21",
  12: "1F",
  11: "1C",
  10: "1A",
  9: "17",
  8: "14",
  7: "12",
  6: "0F",
  5: "0D",
  4: "0A",
  3: "08",
  2: "05",
  1: "03",
  0: "00",
};
