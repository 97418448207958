import React, { useState, useEffect } from "react";
import { Colors } from "../../../config";
import queryString from "query-string";
import { Button, Modal, Spin, notification, Popover } from "antd";
import moment from "moment";
import { DateTime } from "luxon";
import { BiBlock } from "react-icons/bi";
import ScheduleVoucher from "../ScheduleVoucher";
import VoucherField from "./VoucherField";
import ApproveForPaymentButton from "./../VoucherModals/ApproveForPaymentButton";
import ApproveAsPaidModal from "./../VoucherModals/ApprovePaymentModal";

// Redux
import { useSelector, useDispatch } from "react-redux";
import * as voucherActions from "../../../redux/actions/voucherActions";
import makeID from "../../../redux/utils/makeID";

// const voucherStatuses = {
//   0: "Pending",
//   1: "Approved",
//   2: "Denied",
//   3: "Dispatched",
//   4: "SP Completed Job",
//   5: "Cats Approval",
//   6: "Do Job approval",
//   7: "Deactivated",
//   10: "Completed",
// };

const voucherStatuses = {
  0: "Awaiting Approval",
  1: "Ready to Dispatch to SP",
  2: "Declined",
  3: "Dispatched",
  4: "Awaiting CAT's Approval",
  5: "Awaiting DO Approval",
  6: "Ready for Payment",
  7: "Deactivated",
  10: "Paid",
};

const voucherStatusColors = {
  0: Colors.black,
  1: Colors.darkGreen,
  2: Colors.actionRed,
  3: Colors.statPurple,
  4: Colors.yellow,
  5: Colors.darkGreen,
  6: Colors.darkMaroon,
  7: Colors.hrGray,
  10: Colors.weyonLightBlue,
};

const SingleVoucher = ({ history, isDO }) => {
  // State
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeactivateModal, setShowDeactivateModal] = useState(false);
  const [warnEdit, setWarnEdit] = useState(false);
  const [updateObject, setUpdateObject] = useState({});
  const [showApproveAsPaid, setShowAPproveAsPaid] = useState(false);
  const [showApprovePaymentModal, setShowApprovePaymentModal] = useState(false);

  const [editing, setEditing] = useState(false);

  // Redux
  const dispatch = useDispatch();
  const [deletingStatus, setDeletingStatus] = useState("");
  const [editingStatus, setEditingStatus] = useState("");
  const [deactivatingStatus, setDeactivatingStatus] = useState("");
  const voucherInState = useSelector((state) => state.voucher.voucher);
  const loading = useSelector((state) => state.voucher.loading);
  const editable = voucherInState?.status < 2;
  const voucherStatusMessages = useSelector(
    (state) => state.voucher.statusMessages
  );
  const vouchersLoading = useSelector((state) => state.voucher.loading);
  const isDenied = voucherInState?.status === 2;
  const insertCommas = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const expiryDateIsInvalid = voucherInState?.expiryDate
    ? `${new Date(voucherInState.expiryDate)}` === "Invalid Date"
    : true;

  const approvedOnIsInvalidDate = voucherInState?.approvedOn
    ? `${new Date(voucherInState.approvedOn)}` === "Invalid Date"
    : true;

  const calculateTimeLeft = (dt) => {
    // if a date is before today, that date - today will return false
    const dateIsBeforeToday = new Date() > new Date(dt);
    if (dateIsBeforeToday) return "Expired";

    const dateOfExpiry = DateTime.fromJSDate(new Date(dt));
    const result = dateOfExpiry.diffNow(["months", "days"]).toObject();

    const stringResult = `${!result?.months && !result?.days ? "No time" : ""}${
      result?.months ? Math.floor(result.months) + " months" : ""
    }${
      result?.days
        ? `${result?.months ? " and " : ""}` +
          Math.floor(result.days) +
          " days "
        : ""
    } left`;

    return stringResult;
  };

  const calculateApprovedOn = (dt) => {
    const dateIsInvalid = `${new Date(dt)}` === "Invalid Date";

    return dateIsInvalid
      ? dt
      : moment(voucherInState?.approvedOn).format("DD MMMM YYYY hh:mm a");
  };

  const goBackToVouchers = () => history.push(history.location.pathname);

  const handleFieldEdit = (val, required) => {
    const valExists = !!Object.values(val)[0];
    console.log("Object.values[0]", Object.values(val)[0]);
    if (!valExists && required) {
      notification.warn({
        message: "Warning!",
        description: "This field is required!",
        duration: 2,
      });
      return;
    } else {
      setUpdateObject({ ...updateObject, ...val });
    }
  };

  const finishEditing = () => {
    console.log("Edits are", updateObject);
    const statusID = makeID(8);
    setEditingStatus(statusID);
    dispatch(
      voucherActions.editVoucher(voucherInState?._id, updateObject, statusID)
    );
    setEditing(false);
  };

  useEffect(() => {
    const query = queryString.parse(history.location.search) || {};
    const vchID = query.vchID;
    const actualPath = history.location.pathname;

    if (!voucherInState) {
      // This endpoint is not yet created
      dispatch(voucherActions.getVoucher(vchID));

      // Temporary work around. Just go back
      // history.push(actualPath);
    }
  }, []);

  const handleDeleteVoucher = () => {
    const statusID = makeID(8);
    setDeletingStatus(statusID);
    dispatch(voucherActions.deleteVoucher(voucherInState?._id, statusID));
  };

  const handleDeactivateVoucher = () => {
    const statusID = makeID(8);
    setDeactivatingStatus(statusID);
    dispatch(voucherActions.deactivateVoucher(voucherInState?._id, statusID));
  };

  useEffect(() => {
    if (!deletingStatus && !deactivatingStatus && !editingStatus) return;
    if (deletingStatus) {
      const result = voucherStatusMessages.find(
        (it) => it.statusID === deletingStatus
      );
      if (result?.success) {
        const successMessage = "Voucher was deleted successfully";
        notification.success({
          message: "Update",
          description: successMessage,
          onClick: () => {},
          duration: 2,
          onClose: () => {
            dispatch(voucherActions.getAllVouchers());
          },
        });
        setDeletingStatus("");
        goBackToVouchers();
        return;
      } else {
        const failMessage = "Failed to delete voucher. Please try again";
        notification.error({
          message: "Update",
          description: failMessage,
          onClick: () => {},
          duration: 2,
        });
        setDeletingStatus("");
        return;
      }
    }
    if (deactivatingStatus) {
      const result = voucherStatusMessages.find(
        (it) => it.statusID === deactivatingStatus
      );
      if (result?.success) {
        const successMessage = "Voucher was deactivated successfully";
        notification.success({
          message: "Update",
          description: successMessage,
          onClick: () => {},
          duration: 2,
          onClose: () => {
            dispatch(voucherActions.getAllVouchers());
          },
        });
        setDeactivatingStatus("");
        goBackToVouchers();
        return;
      } else {
        const failMessage = "Failed to deactivate voucher. Please try again";
        notification.error({
          message: "Update",
          description: failMessage,
          onClick: () => {},
          duration: 2,
        });
        setDeactivatingStatus("");
        return;
      }
    }
    if (editingStatus) {
      const result = voucherStatusMessages.find(
        (it) => it.statusID === editingStatus
      );
      if (result?.success) {
        const successMessage = "Voucher was updated successfully";
        notification.success({
          message: "Update",
          description: successMessage,
          onClick: () => {},
          duration: 2,
          onClose: () => {
            dispatch(voucherActions.getVoucher(voucherInState?._id));
          },
        });
        setEditingStatus("");
        //   goBackToVouchers();
        return;
      } else {
        const failMessage = "Failed to update voucher. Please try again";
        notification.error({
          message: "Update",
          description: failMessage,
          onClick: () => {},
          duration: 2,
        });
        setEditingStatus("");
        return;
      }
    }
  }, [voucherStatusMessages]);

  return voucherInState?._id ? (
    <>
      {/* Delete Voucher */}

      <ApproveAsPaidModal
        visible={showApproveAsPaid}
        setVisible={setShowAPproveAsPaid}
        voucher={voucherInState}
      />

      <Modal
        confirmLoading={true}
        visible={showDeleteModal}
        title={
          <b style={{ color: Colors.textGray }}>
            Delete Voucher No {voucherInState?.voucherID}
          </b>
        }
        onOk={handleDeleteVoucher}
        onCancel={() => setShowDeleteModal(false)}
        footer={[
          <Button
            key="cancel"
            type="primary"
            onClick={() => setShowDeleteModal(false)}
            color={Colors.darkGreen}
            className="logout-modal-no"
          >
            No
          </Button>,
          <Button
            key="logout"
            danger
            onClick={handleDeleteVoucher}
            className="logout-modal-yes"
          >
            Yes
          </Button>,
        ]}
      >
        <Spin spinning={loading}>
          <p>
            Do you want to delete this voucher? This action is irreversible.
          </p>
        </Spin>
      </Modal>

      {/* Deactivate Voucher */}
      <Modal
        confirmLoading={true}
        visible={showDeactivateModal}
        title={
          <b style={{ color: Colors.textGray }}>
            Deactivate Voucher No {voucherInState?.voucherID}
          </b>
        }
        onOk={handleDeactivateVoucher}
        onCancel={() => setShowDeactivateModal(false)}
        footer={[
          <Button
            key="cancel"
            type="primary"
            onClick={() => setShowDeactivateModal(false)}
            color={Colors.darkGreen}
            className="logout-modal-no"
          >
            No
          </Button>,
          <Button
            key="logout"
            danger
            onClick={handleDeactivateVoucher}
            className="logout-modal-yes"
          >
            Yes
          </Button>,
        ]}
      >
        <Spin spinning={loading}>
          <p>Are you sure you want to deactivate this voucher?</p>
        </Spin>
      </Modal>

      <div className="single-voucher-container">
        <div className="single-vouchers-top">
          <div className="single-vouchers-left">
            <h3
              style={{ color: Colors.darkGreen, cursor: "pointer" }}
              onClick={goBackToVouchers}
            >
              <u>
                <b>Vouchers</b>
              </u>
            </h3>
            <span style={{ color: Colors.textGray }}>{">"}</span>
            <h3 style={{ color: Colors.textGray, marginLeft: "6px" }}>
              Voucher {voucherInState?.voucherID}
            </h3>
          </div>
          <div className="single-vouchers-right">
            <Popover
              content={
                <span>
                  Voucher can only be edited when it's status is Pending or
                  Approved.
                </span>
              }
              title={
                <div style={{ display: "flex", alignItems: "center" }}>
                  <BiBlock
                    size={20}
                    color={Colors.actionRed}
                    style={{ marginRight: "7px" }}
                  />
                  <h4 style={{ margin: "0px" }}>Not Allowed</h4>
                </div>
              }
              visible={warnEdit}
            >
              <div
                onMouseEnter={() => {
                  if (!editable) {
                    setWarnEdit(true);
                  }
                }}
                onMouseLeave={() => {
                  if (warnEdit) {
                    setWarnEdit(false);
                  }
                }}
              >
                <Button
                  key="1"
                  type={!editing ? "primary" : "danger"}
                  onClick={
                    !editing ? () => setEditing(true) : () => setEditing(false)
                  }
                  disabled={!editable || editing}
                >
                  Edit
                </Button>
              </div>
            </Popover>

            {!isDO ? (
              <Button
                key="1"
                type={"primary"}
                onClick={() => setShowAPproveAsPaid(true)}
              >
                Mark as Paid
              </Button>
            ) : null}
            {/* <Button
              key="2"
              danger
              onClick={() => {
                setShowDeactivateModal(true);
              }}
              disabled={expiryDateIsInvalid}
            >
              Deactivate
            </Button> */}

            <Button
              key="3"
              type="danger"
              onClick={() => setShowDeleteModal(true)}
            >
              Delete
            </Button>

            <ApproveForPaymentButton voucher={voucherInState} />

            <div style={{ marginLeft: "30px", display: "flex" }}>
              {/* Handling scheduling */}
              <ScheduleVoucher
                voucher={voucherInState}
                useAntD
                disabled={voucherInState?.status !== 1}
                status={
                  voucherStatuses[voucherInState?.status] ||
                  voucherInState?.status
                }
              />

              {/* Handling dispatch */}
              <ScheduleVoucher
                voucher={voucherInState}
                useAntD
                useDispatch
                disabled={voucherInState?.status !== 1}
                status={
                  voucherStatuses[voucherInState?.status] ||
                  voucherInState?.status
                }
              />
            </div>
          </div>
        </div>
        <Spin spinning={vouchersLoading}>
          <>
            <div className="single-vouchers-bottom">
              <div className="single-vouchers-bottom-left">
                <div className="sv-bottom-col ">
                  <div className="sv-detail-container">
                    <h4 className="sv-bottom-title"> Voucher ID</h4>
                    <p>{voucherInState?.voucherReadableID}</p>
                  </div>

                  <div className="sv-detail-container">
                    <div className="sv-bottom-detail-pair">
                      <h4 className="sv-bottom-title"> Value:</h4>
                      <p
                        className="sv-bottom-detail-right"
                        style={{ color: Colors.darkGreen, fontWeight: "bold" }}
                      >
                        UGX{" "}
                        {voucherInState?.beneficiaryAmount
                          ? insertCommas(voucherInState?.beneficiaryAmount)
                          : ""}
                      </p>
                    </div>
                  </div>

                  <div className="sv-detail-container">
                    <h4 className="sv-bottom-title"> Duration</h4>
                    <p>
                      {expiryDateIsInvalid
                        ? voucherInState?.expiryDate
                        : calculateTimeLeft(voucherInState?.expiryDate)}
                    </p>
                  </div>
                </div>

                <div className="sv-bottom-col ">
                  <div className="sv-detail-container">
                    <h4 className="sv-bottom-title"> CUSTOMER</h4>
                    <VoucherField
                      editing={editing}
                      voucher={voucherInState}
                      fieldName={"beneficiary"}
                      readableName={"Customer"}
                      onEdit={handleFieldEdit}
                      required
                      // selectOptions={}
                      inputType={"text"}
                      placeholder={"Input Beneficiary Name"}
                    />
                  </div>

                  <div className="sv-detail-container">
                    <VoucherField
                      editing={editing}
                      voucher={voucherInState}
                      fieldName={"address"}
                      readableName={"Location"}
                      onEdit={handleFieldEdit}
                      required
                      // selectOptions={}
                      inputType={"text"}
                      placeholder={"Input Beneficiary Address"}
                    />

                    <VoucherField
                      editing={editing}
                      voucher={voucherInState}
                      fieldName={"beneficiaryNumber"}
                      readableName={"Phone Number"}
                      onEdit={handleFieldEdit}
                      required
                      // selectOptions={}
                      inputType={"text"}
                      placeholder={"Input Beneficiary Number"}
                    />

                    <div className="sv-bottom-detail-pair">
                      <p className="sv-bottom-detail-left ">Division:</p>
                      <p className="sv-bottom-detail-right ">
                        {voucherInState.validForDivision}
                      </p>
                    </div>

                    <VoucherField
                      editing={editing}
                      voucher={voucherInState}
                      fieldName={"facilityType"}
                      readableName={"Facility"}
                      onEdit={handleFieldEdit}
                      required
                      selectOptions={[
                        "Lined Pit Latrine",
                        "Unlined Pit Latrine",
                        "Septic Tank",
                        "Flush Toilet",
                        "Composting Toilet",
                        "Others",
                      ]}
                      inputType={"text"}
                      placeholder={"Input Facility Type"}
                    />

                    <VoucherField
                      editing={editing}
                      voucher={voucherInState}
                      fieldName={"noOfExtraBarrelsRequested"}
                      readableName={"Extra barrels"}
                      onEdit={handleFieldEdit}
                      inputType={"number"}
                      placeholder={"Input Number or Barrels"}
                    />

                    {editing && (
                      <div>
                        <Button type="primary" onClick={finishEditing}>
                          Submit Edits
                        </Button>
                      </div>
                    )}
                  </div>
                </div>

                <div className="sv-bottom-col ">
                  <div className="sv-detail-container">
                    <h4 className="sv-bottom-title"> Service Provider</h4>
                    <p>{voucherInState?.serviceProvider || "None yet"}</p>
                    <p style={{ color: Colors.textGray }}>
                      CAT {voucherInState?.validForDivision} Division
                    </p>
                  </div>

                  <div className="sv-detail-container">
                    <h4 className="sv-bottom-title"> Status</h4>

                    <p
                      style={{
                        color:
                          voucherStatusColors[voucherInState?.status] ||
                          Colors.textGray,
                        textTransform: "uppercase",
                      }}
                    >
                      {voucherStatuses[voucherInState?.status] ||
                        voucherInState?.status}
                    </p>
                  </div>
                </div>
              </div>
              <div className="single-vouchers-bottom-right">
                <div className="sv-bottom-col ">
                  <div className="sv-detail-container">
                    <h4 className="sv-bottom-title"> Requested by </h4>
                    <p>{voucherInState?.catsName}</p>
                    <p style={{ color: Colors.textGray }}>
                      CAT {voucherInState?.validForDivision} Division
                    </p>
                  </div>

                  <div className="sv-detail-container">
                    <h4 className="sv-bottom-title"> Approved by </h4>
                    <p style={{ color: Colors.black }}>
                      {voucherInState?.officerApproved
                        ? voucherInState?.approvedBy
                        : "Not Approved"}
                    </p>
                    <p style={{ color: Colors.textGray }}>
                      DO {voucherInState?.validForDivision} Division
                    </p>
                  </div>
                </div>

                <div className="sv-bottom-col ">
                  <div className="sv-detail-container">
                    <h4 className="sv-bottom-title"> Requested time </h4>
                    <p>
                      {moment(voucherInState?.createdAt).format(
                        "DD MMMM YYYY hh:mm a"
                      )}
                    </p>
                  </div>

                  <div className="sv-detail-container">
                    <h4 className="sv-bottom-title"> Approved time </h4>
                    <p>{calculateApprovedOn(voucherInState?.approvedOn)}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="single-vouchers-bottom">
              <div className="single-vouchers-bottom-left">
                <div className="sv-detail-container">
                  <h4 className="sv-bottom-title">
                    {" "}
                    Reasons for Voucher Request
                  </h4>
                  <ul style={{ marginLeft: "20px" }}>
                    {voucherInState?.reasonForEligibility ? (
                      voucherInState.reasonForEligibility.map(
                        (reason, index) => (
                          <li
                            style={{ listStyleType: "circle" }}
                            key={`wey_${index}_`}
                          >
                            {reason}
                          </li>
                        )
                      )
                    ) : (
                      <li style={{ listStyleType: "circle" }}>None given</li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </>
        </Spin>
      </div>
    </>
  ) : null;
};

export default SingleVoucher;

/**
 * {
   
    "status": 2,
    "paid": false,
    
    
    "voucherReadableID": "Denied",
    "generatedBy": "Nalubega Kasifah",


    "catsDeviceID": "No Device ID",
    "catsPhoneNumber": "0754198656",

    "catsName": "Nalubega Kasifah",

    "catsApproved": false,

    
    "officerName": "Viola Matanda",
    "officerApproved": false,
    "officerApprovalRemarks": "",
    "beneficiary": "Mllro swarikl",
    "beneficiaryNumber": "0754010864",
    "householdID": "No",
    "spType": "Emptier",
    "validForDivision": "Central",
    "value": 0,
    "expiryDate": "Pending approval",
    "approvedBy": "Viola Matanda",
    "approvedOn": "Pending approval",
    "redeemedOn": "Not redeemed ",
    "reasonForDenial": "",
    "facilityType": "Pit Latrine",
    "lat": 0.3207336,
    "lng": 32.5760889,
    "address": "No Location Chosen",
    "serviceProvider": null,
    "jobID": "Not dispatched yet",
    "jobStatus": 0,
    "reasonForEligibility": [
        "Limited penetration of sanitation value chain actors",
        "Low priority is given to household and community sanitation"
    ],
    "voucherProgram": "Weyonje Voucher Program 4",
    "beneficiaryAmount": 30000
}
 */
