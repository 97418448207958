const authEndpoints = (base, prefix, apiVersion) => {
  const defaultEndpoint = "auth";
  const userType = "admin";
  return {
    getOTP: `${base}/${defaultEndpoint}/request`, // /:phone_number
    verifyOTP: `${base}/${defaultEndpoint}/verify`, // /:phone_number/:code
    get: `${base}/${defaultEndpoint}/search`,
    getAll: `${base}/${defaultEndpoint}/all`,
    update: `${base}/${defaultEndpoint}/update`,
    delete: `${base}/${defaultEndpoint}/delete`,
    add: `${base}/${defaultEndpoint}/create`,
    login: `${base}/${defaultEndpoint}/${userType}/login`, // POST
  };
};

export default authEndpoints;
