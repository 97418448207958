// CRUD Operations
export const CREATE_CATS = "CREATE_CATS";
export const UPDATE_CATS = "UPDATE_CATS";
export const DELETE_CATS = "DELETE_CATS";
export const GET_CATS = "GET_CATS";
export const GET_ALL_CATS = "GET_ALL_CATS";
export const PUT_CATS_IN_STATE = "PUT_CATS_IN_STATE";
export const DEACTIVATE_CATS = "DEACTIVATE_CATS";
export const ACTIVATE_CATS = "ACTIVATE_CATS";

export const ADD_CATS = "ADD_CATS";

// Misc
export const LOADING_CATS = "LOADING_CATS";
