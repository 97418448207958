import * as jobTypes from '../types/jobTypes';
import {
  jobEndpoints,
  voucherEndpoints,
  divisionEndpoints,
} from '../endpoints';
import * as fetchFunctions from '../utils/fetchFunctions';
import * as fetchFunctionsLegacy from '../utils/fetchFunctionsLegacy';

export const getAllJobs = (statusID) => async (dispatch, getState) => {
  dispatch({
    type: jobTypes.LOADING_JOBS,
  });

  const { token, officer } = getState().admin;

  let result = await fetchFunctionsLegacy.getData(jobEndpoints.getAll, token);

  if (result?.data?.data) {
    result.data.data = result.data.data.sort(
      (a, b) =>
        new Date(b.date_of_registration) - new Date(a.date_of_registration)
    );
  }

  // Only return the jobs for a particular division if a DO is logged in
  if (officer?.divisionID) {
    const allDivisionsResult = await fetchFunctions.getData(
      divisionEndpoints.getAll,
      token
    );

    const allDivisions = allDivisionsResult?.data || [];
    const matchingDivision = allDivisions.find(
      (d) => d._id === officer?.divisionID
    )?.name;

    const filteredJobs = result?.data?.data.filter(
      (j) =>
        `${j.division}`.toLowerCase() === `${matchingDivision}`.toLowerCase()
    );

    result.data.data = filteredJobs;
  }

  dispatch({
    type: jobTypes.GET_ALL_JOBS,
    payload: result,
    statusID,
  });
};

export const getJobsWithQuery =
  (query, statusID) => async (dispatch, getState) => {
    dispatch({
      type: jobTypes.LOADING_JOBS,
    });

    const { token } = getState().admin;

    const result = await fetchFunctionsLegacy.getData(
      `${jobEndpoints.getAll}?${query}`,
      token
    );

    dispatch({
      type: jobTypes.GET_ALL_JOBS,
      payload: result,
      statusID,
    });
  };

export const getScheduledJobs = (statusID) => async (dispatch, getState) => {
  dispatch({
    type: jobTypes.LOADING_JOBS,
  });

  const { token } = getState().admin;

  const result = await fetchFunctionsLegacy.getData(
    jobEndpoints.getScheduled,
    token
  );

  dispatch({
    type: jobTypes.GET_SCHEDULED_JOBS,
    payload: result,
    statusID,
  });
};

export const getJobsSummary = (statusID) => async (dispatch, getState) => {
  dispatch({
    type: jobTypes.LOADING_JOBS,
  });

  const { token } = getState().admin;

  const result = await fetchFunctionsLegacy.getData(
    jobEndpoints.summary,
    token
  );

  dispatch({
    type: jobTypes.GET_JOB_SUMMARY,
    payload: result,
    statusID,
  });
};

export const getPendingJobs = (statusID) => async (dispatch, getState) => {
  dispatch({
    type: jobTypes.LOADING_JOBS,
  });

  const { token } = getState().admin;

  const result = await fetchFunctionsLegacy.getData(
    jobEndpoints.getPending,
    token
  );

  dispatch({
    type: jobTypes.GET_PENDING_JOBS,
    payload: result,
    statusID,
  });
};

export const getInProgressJobs = (statusID) => async (dispatch, getState) => {
  dispatch({
    type: jobTypes.LOADING_JOBS,
  });

  const { token } = getState().admin;

  const result = await fetchFunctionsLegacy.getData(
    jobEndpoints.getInProgress,
    token
  );

  dispatch({
    type: jobTypes.GET_JOBS_IN_PROGRESS,
    payload: result,
    statusID,
  });
};

export const getCompletedJobs = (statusID) => async (dispatch, getState) => {
  dispatch({
    type: jobTypes.LOADING_JOBS,
  });

  const { token } = getState().admin;

  const result = await fetchFunctionsLegacy.getData(
    jobEndpoints.getCompleted,
    token
  );

  dispatch({
    type: jobTypes.GET_COMPLETED_JOBS,
    payload: result,
    statusID,
  });
};

export const addJob = (jobData, statusID) => async (dispatch, getState) => {
  try {
    dispatch({
      type: jobTypes.LOADING_JOBS,
    });

    const { token } = getState().admin;
    const isVoucherJob = jobData?.isVoucherJob;

    const result = await fetchFunctionsLegacy.postData(
      jobEndpoints.add,
      jobData,
      token
    );

    dispatch({
      type: jobTypes.ADD_JOB,
      payload: result,
      statusID,
    });
  } catch (error) {
    dispatch({
      type: jobTypes.ADD_JOB,
      payload: { success: false },
      statusID,
    });
  }
};

export const deleteJob = (jobID, statusID) => async (dispatch, getState) => {
  try {
    dispatch({
      type: jobTypes.LOADING_JOBS,
    });

    const { token } = getState().admin;

    const result = await fetchFunctionsLegacy.deleteData(
      `${jobEndpoints.delete}/${jobID}`,
      token
    );

    dispatch({
      type: jobTypes.DELETE_JOB,
      payload: result,
      statusID,
    });
  } catch (error) {
    dispatch({
      type: jobTypes.DELETE_JOB,
      payload: { success: false },
      statusID,
    });
  }
};

export const updateJob =
  (jobData, jobID, statusID) => async (dispatch, getState) => {
    try {
      dispatch({
        type: jobTypes.LOADING_JOBS,
      });

      const { token } = getState().admin;

      const result = await fetchFunctionsLegacy.putData(
        `${jobEndpoints.update}/${jobID}`,
        jobData,
        token
      );

      dispatch({
        type: jobTypes.UPDATE_JOB,
        payload: result,
        statusID,
      });
    } catch (error) {
      dispatch({
        type: jobTypes.UPDATE_JOB,
        payload: { success: false },
        statusID,
      });
    }
  };

export const scheduleJobs =
  (spID, voucherDetails, time, statusID) => async (dispatch, getState) => {
    try {
      dispatch({
        type: jobTypes.LOADING_JOBS,
      });

      let jobsSuccess = [];

      const { token, id, officer } = getState().admin;

      let x;

      for (x = 0; x < voucherDetails.length; x++) {
        let currentVoucher = voucherDetails[x];

        if (!currentVoucher) return;

        const jobCreationResult = await fetchFunctionsLegacy.postData(
          jobEndpoints.add,
          currentVoucher.jobData,
          token
        );

        const newJob = jobCreationResult?.data?.data;

        // Accept job on behalf of the SP
        const acceptResult = await fetchFunctionsLegacy.putData(
          jobEndpoints.acceptJobForSp(newJob._id),
          { entreprenuer_id: spID },
          token
        );

        // Add a check here to make sure the job actually went through

        // Schedule job on behalf of the SP
        const scheduleResult = await fetchFunctionsLegacy.putData(
          jobEndpoints.schedule(newJob._id),
          { date_time: new Date(time).getTime() },
          token
        );

        // Action the voucher here to transition it to the next stage
        const dispatchVoucherDetails = {
          doID: id,
          status: 3,
          spID: spID,
          jobID: newJob._id,
        };

        const result = await fetchFunctions.patchData(
          voucherEndpoints.actionVoucher(currentVoucher.voucherID),
          dispatchVoucherDetails,
          token
        );

        jobsSuccess.push(scheduleResult.success);
      }

      const actualSuccess = jobsSuccess.reduce((a, b) => a + b);

      let finalResult = {
        message:
          actualSuccess < jobsSuccess.length && actualSuccess > 0
            ? 'Some of the jobs were scheduled, but others failed. Please try again.'
            : actualSuccess === jobsSuccess.length
            ? 'All vouchers have been scheduled'
            : 'No vouchers were scheduled successfully. Please try again',
        success: actualSuccess > 0,
      };

      dispatch({
        type: jobTypes.SCHEDULE_JOB,
        payload: finalResult,
        statusID,
      });
    } catch (error) {
      dispatch({
        type: jobTypes.SCHEDULE_JOB,
        payload: { success: false },
        statusID,
      });
    }
  };

export const reScheduleJob =
  (spID, jobID, time, statusID) => async (dispatch, getState) => {
    try {
      dispatch({
        type: jobTypes.LOADING_JOBS,
      });

      const { token } = getState().admin;

      // Accept job on behalf of the SP
      const acceptResult = await fetchFunctionsLegacy.putData(
        jobEndpoints.acceptJobForSp(jobID),
        { entreprenuer_id: spID },
        token
      );
      // Add a check here to make sure the job actually went through

      // Schedule job on behalf of the SP
      const scheduleResult = await fetchFunctionsLegacy.putData(
        jobEndpoints.schedule(jobID),
        { date_time: new Date(time).getTime() },
        token
      );

      let finalResult = {
        message: scheduleResult?.success
          ? 'Job has been scheduled'
          : 'Job was not scheduled successfully. Please try again',
        success: scheduleResult?.success,
      };

      dispatch({
        type: jobTypes.RE_SCHEDULE_JOB,
        payload: finalResult,
        statusID,
      });
    } catch (error) {
      dispatch({
        type: jobTypes.RE_SCHEDULE_JOB,
        payload: { success: false },
        statusID,
      });
    }
  };
