import * as driverTypes from "../types/driverTypes";
import { driverEndpoints } from "../endpoints";
import * as fetchFunctions from "../utils/fetchFunctions";
import * as fetchFunctionsLegacy from "../utils/fetchFunctionsLegacy";

export const getAvailableDrivers = (statusID) => async (dispatch, getState) => {
  dispatch({
    type: driverTypes.LOADING_DRIVERS,
  });

  const { token } = getState().admin;

  const result = await fetchFunctionsLegacy.getData(
    driverEndpoints.getAvailable,
    token
  );

  dispatch({
    type: driverTypes.GET_AVAILABLE_DRIVERS,
    payload: result,
    statusID,
  });
};
