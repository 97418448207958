import React, { useState, useEffect } from 'react';
import Button from './../../components/Button';
import { RiCalendarTodoFill } from 'react-icons/ri';
import { AiOutlinePlus } from 'react-icons/ai';
import { FiChevronDown } from 'react-icons/fi';

import { Colors } from './../../config';
import defaultData from './defaultData';
import JobCard from './JobCard';
import DispatchPopup from './DispatchPopup';
import { notification } from 'antd';
import noResultImg from './../../assets/misc/weyon_no_results.svg';
import { ImSpinner2 } from 'react-icons/im';

// MAPS
import GoogleMapReact from 'google-map-react';
import { GOOGLE_MAPS_API_KEY } from './../../config';
import parishesGeo from './../../assets/geo_data/maps/parishes.geojson';
import MapJobMarker from './MapJobMarker';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import makeID from './../../redux/utils/makeID';
import * as jobActions from './../../redux/actions/jobActions';

// Icons
import customerInProgress from '../../assets/map_icons/customerInProgress.png';
import customerPending from '../../assets/map_icons/customerPending.png';
import customerScheduled from '../../assets/map_icons/customerScheduled.png';
import customerCompleted from '../../assets/map_icons/customerCompleted.png';

const LiveTracking = () => {
  // Redux
  const dispatch = useDispatch();
  const [statusID, setStatusID] = useState('');
  const jobStatusMessages = useSelector((state) => state.jobs.statusMessages);
  const loading = useSelector((state) => state.jobs.loading);
  const reduxJobs = useSelector((state) => state.jobs);
  const pendingJobs = useSelector((state) => state.jobs.pendingJobs);
  const completedJobs = useSelector((state) => state.jobs.completedJobs);
  const inProgressJobs = useSelector((state) => state.jobs.inProgressJobs);
  const scheduledJobs = useSelector((state) => state.jobs.scheduledJobs);
  const doDivision = useSelector((state) => state.admin);

  const klaDivisions = ['Nakawa', 'Kawempe', 'Rubaga', 'Central', 'Makindye'];

  const showMap = klaDivisions.includes(doDivision.divisionName);

  // State
  const [jobList, setJobList] = useState([]);

  // Maps
  const [mapCenter, setMapCenter] = useState({ lat: 0.324787, lng: 32.585583 });
  const [mapZoom, setMapZoom] = useState(11.8);
  const [activeJob, setActiveJob] = useState('');

  // State
  const [showPopup, setShowPopup] = useState(false);
  const today = new Date();
  const allMonths = defaultData.allMonths;
  const jobs = defaultData.jobs;

  const [activeTab, setActiveTab] = useState(1);
  const [showDropdown, setShowDropdown] = useState(false);

  const tabs = {
    1: {
      name: 'SCHEDULED',
      reduxObj: 'scheduledJobs',
      number: 1,
      background: Colors.yellow,
      icon: customerScheduled,
      jobsCount: scheduledJobs?.length || 0,
    },
    2: {
      name: 'PENDING',
      reduxObj: 'pendingJobs',
      number: 2,
      background: Colors.darkMaroon,
      icon: customerPending,
      jobsCount: pendingJobs?.length || 0,
    },
    3: {
      name: 'IN PROGRESS',
      reduxObj: 'inProgressJobs',
      number: 3,
      background: Colors.blue,
      icon: customerInProgress,
      jobsCount: inProgressJobs?.length || 0,
    },
    4: {
      name: 'COMPLETED',
      reduxObj: 'completedJobs',
      number: 4,
      background: Colors.darkGreen,
      icon: customerCompleted,
      jobsCount: completedJobs?.length || 0,
    },
  };

  const changeTab = (tab) => {
    setActiveTab(tab.number);

    // Fetch details about all jobs related to the selected tab
    const newStatusID = makeID(8);
    setStatusID(newStatusID);

    switch (tab.name) {
      case tabs[1].name: // Scheduled
        setActiveJob('');
        dispatch(jobActions.getScheduledJobs(newStatusID));
        return;

      case tabs[2].name: // Pending
        setActiveJob('');
        dispatch(jobActions.getPendingJobs(newStatusID));
        return;

      case tabs[3].name: // In Progress
        setActiveJob('');
        dispatch(jobActions.getInProgressJobs(newStatusID));
        return;

      case tabs[4].name: // Completed
        setActiveJob('');
        dispatch(jobActions.getCompletedJobs(newStatusID));
        return;

      default:
        return;
    }
  };

  const handleMapLoaded = ({ map, maps }) => {
    if (map) {
      let infoWindow = new maps.InfoWindow();

      // Input GeoJson object here
      map.data.loadGeoJson(parishesGeo);

      // Styling of parish borders
      map.data.setStyle({
        fillColor: Colors.transparentGreen,
        strokeWeight: 0.5,
        strokeColor: `${Colors.darkGreen}BF`, // 70% opacity
      });

      // Make borders thicker on hover
      map.data.addListener('mouseover', function (event) {
        map.data.revertStyle();
        map.data.overrideStyle(event.feature, {
          strokeWeight: 3,
          strokeColor: `${Colors.darkGreen}BF`, // 70% opacity
        });
      });

      // Display name of parish on click in an info Window
      map.data.addListener('click', function (event) {
        let place = event.feature.getProperty('PNAME2014');
        const position = event.feature.getGeometry().getAt(0).getAt(0);
        let textName = place; // combine place and magnitude, inserting additional text between them

        infoWindow.setContent(textName); // show the textName variable in the infoWindow
        infoWindow.setPosition(position); // anchor the infoWindow at the marker
        infoWindow.setOptions({ pixelOffset: new maps.Size(0, 0) });
        infoWindow.open(map);
      });

      // Reset the styling for the borders of a parish if it is not being hovered over
      map.data.addListener('mouseout', function (event) {
        infoWindow.close();
        map.data.revertStyle();
      });
    }
  };

  const showJobOnMap = (job) => {
    const { long, lat } = job;

    setActiveJob(job);
  };

  const getAllJobs = () => {
    dispatch(jobActions.getScheduledJobs());

    dispatch(jobActions.getPendingJobs());

    dispatch(jobActions.getInProgressJobs());

    dispatch(jobActions.getCompletedJobs());
  };

  useEffect(() => {
    if (!statusID) return;
    const currentTab = tabs[activeTab];
    const tabReduxObject = currentTab.reduxObj;
    const jobsInState = reduxJobs[tabReduxObject];

    const result = jobStatusMessages.find((msg) => msg.statusID === statusID);
    if (!result) return;

    if (result?.success) {
    } else {
      let msg = result.error;
      const defaultMsg = `Failed to get all ${currentTab.name.toLowerCase()} jobs. Please try again`;
      if (msg && msg.includes('An unknown error occurred')) msg = defaultMsg;
      notification.error({
        message: 'Oops!',
        description: msg || defaultMsg,
        onClick: () => {},
        duration: 2,
      });
    }

    // setJobList([...jobsInState, testJob, testJob2]);
    setJobList(jobsInState);
    setStatusID('');
  }, [jobStatusMessages]);

  useEffect(() => {
    // Populate the default tab when the page opens
    changeTab(tabs[1]);

    // Fetch all jobs every 5 seconds
    const gettingAllJobs = setInterval(() => {
      getAllJobs();
    }, 5000);
    return () => {
      // Stop fetching jobs when the tab is closed
      clearInterval(gettingAllJobs);
    };
  }, []);

  // useEffect(() => {
  //   if (!showPopup) return;
  //   // Fetch drivers immediately
  //   findDrivers();

  //   // Fetch drivers every 5 seconds after
  //   const findingDrivers = setInterval(() => {
  //     findDrivers();
  //   }, 5000);
  //   return () => {
  //     // Stop fetching drivers when the dispatch window is closed
  //     clearInterval(findingDrivers);
  //   };
  // }, [showPopup]);

  return (
    <div className='live-tracking'>
      <DispatchPopup
        showPopup={showPopup}
        hidePopup={() => setShowPopup(false)}
      />
      <div className='live-tracking-top'>
        <div className='live-tracking-top-date'>
          <div className='live-tracking-top-date-icon'>
            <RiCalendarTodoFill color={Colors.darkGreen} size={25} />
          </div>
          <div className='live-tracking-top-date-text'>
            Today, {today.getDate()} {allMonths[today.getMonth()]}{' '}
            {today.getFullYear()}
          </div>
        </div>

        <div className='live-tracking-tabs'>
          {Object.keys(tabs).map((tabNum) => (
            <p
              className={`live-tracking-tab ${
                activeTab === tabs[tabNum].number
                  ? 'live-tracking-tab-active'
                  : ''
              }`}
              style={{ position: 'relative' }}
              onClick={() => changeTab(tabs[tabNum])}
            >
              {tabs[tabNum].name} : {tabs[tabNum].jobsCount}
            </p>
          ))}
        </div>

        <div className='live-tracking-dispatch-button'>
          <Button color={Colors.darkGreen} onClick={() => setShowPopup(true)}>
            <AiOutlinePlus size={25} color={Colors.white} />
            <p className='live-tracking-dispatch-button-text'>
              Create Dispatch
            </p>
          </Button>
        </div>
      </div>
      <div className='live-tracking-bottom'>
        <div className='live-tracking-bottom-left'>
          <div
            className='live-tracking-bottom-left-title'
            style={{ background: tabs[activeTab].background }}
          >
            <p>{tabs[activeTab].name}</p>
            <p>
              {loading ? '...' : `${jobList.length || 0} trips`}
              <FiChevronDown
                size={20}
                color={Colors.white}
                onClick={() => setShowDropdown(!showDropdown)}
              />
            </p>
            {showDropdown ? (
              <div className='live-tracking-bottom-left-title-dropdown'>
                {Object.keys(tabs).map((tab) => (
                  <div
                    className='lt-dropdown-item'
                    onClick={() => {
                      changeTab(tabs[tab]);
                      setShowDropdown(false);
                    }}
                  >
                    {tabs[tab].name}
                  </div>
                ))}
              </div>
            ) : (
              ''
            )}
          </div>

          <div className='live-tracking-bottom-left-jobs-list'>
            {jobList?.length ? (
              jobList.map((job) => (
                <JobCard
                  job={job}
                  key={job._id}
                  onHover={showJobOnMap}
                  activeJob={activeJob}
                  currentColor={tabs[activeTab].background}
                />
              ))
            ) : (
              <div className='live-tracking-no-results-container'>
                <img
                  src={noResultImg}
                  className='live-tracking-no-results-img'
                />
                <p className='live-tracking-no-results-text'>
                  {loading ? (
                    <React.Fragment>
                      Loading
                      <br />
                      <ImSpinner2
                        className='live-tracking-spinner'
                        color={Colors.darkGreen}
                        size={20}
                      />
                    </React.Fragment>
                  ) : (
                    'No results...'
                  )}
                </p>
              </div>
            )}
          </div>
        </div>
        {showMap ? (
          <div className='live-tracking-bottom-right'>
            <div
              className='default-map-container'
              style={{ height: '500px', width: '500px' }}
            >
              <GoogleMapReact
                defaultCenter={mapCenter}
                defaultZoom={mapZoom}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={handleMapLoaded}
                bootstrapURLKeys={{ key: GOOGLE_MAPS_API_KEY }}
              >
                {activeJob ? (
                  <MapJobMarker
                    lat={activeJob.lat}
                    lng={activeJob.long}
                    text={activeJob.full_name}
                    icon={tabs[activeTab].icon}
                    key={activeJob._id}
                    color={tabs[activeTab].background}
                  />
                ) : null}
              </GoogleMapReact>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default LiveTracking;
