import React, { useState, useEffect } from "react";

import queryString from "query-string";
import Vouchers from "./Vouchers";
import SingleVoucher from "./SingleVoucher";
import SingleCats from "./SingleCats";

const VouchersMain = (props) => {
  const { history, location, isDO } = props;
  const [showSingleVoucher, setShowSingleVoucher] = useState(false);
  const [showSingleCats, setShowSingleCats] = useState(false);

  useEffect(() => {
    const actualPath = history.location.pathname;
    const query = queryString.parse(history.location.search) || {};
    const vchID = query.vchID;
    const catsID = query.catsID;
    const spID = query.sp;
    const queryExists = !!Object.keys(query).length;

    if (queryExists && !vchID && !catsID && !spID) {
      if (showSingleVoucher) setShowSingleVoucher(false);
      if (showSingleCats) setShowSingleCats(false);
      history.push(actualPath);
    } else if (queryExists && !!vchID) {
      setShowSingleVoucher(true);
    } else if (queryExists && !!catsID) {
      setShowSingleCats(true);
    } else if (showSingleVoucher || showSingleCats) {
      setShowSingleCats(false);
      setShowSingleVoucher(false);
    }
  }, [location]);

  return (
    <>
      {showSingleVoucher ? (
        <SingleVoucher history={history} isDO={isDO} />
      ) : showSingleCats ? (
        <SingleCats history={history} isDO={isDO} />
      ) : (
        <Vouchers history={history} isDO={isDO} />
      )}
    </>
  );
};

export default VouchersMain;
