import React, { useState, useEffect } from "react";
import * as utils from "./../redux/utils/componentUtils";

const CustomInput = ({
  value,
  receiveOutput,
  style,
  iconLeft,
  iconRight,
  required,
  type,
  placeHolder,
}) => {
  const [inputVal, setInputVal] = useState("");
  const styleObj = style ? style : {};
  const handleInput = (e) => {
    const actualInput = utils.getInputVal(e);
    // ;
    if (receiveOutput) {
      receiveOutput(actualInput);
      return;
    }
    setInputVal(actualInput);
  };

  useEffect(() => {
    setInputVal(value);
  }, [value]);

  return (
    <div
      className={
        value || inputVal
          ? "custom-input-container custom-input-container-active"
          : "custom-input-container"
      }
    >
      {iconLeft ? (
        <div className="custom-input-icon-left">
          {React.cloneElement(iconLeft)}
        </div>
      ) : (
        ""
      )}

      <div className="custom-input-input-wrapper">
        <input
          type={type || "text"}
          required={required || false}
          style={styleObj}
          className="custom-input"
          value={value || inputVal}
          onChange={handleInput}
          placeholder={placeHolder || ""}
        />
      </div>

      {iconRight ? (
        <div className="custom-input-icon-right">
          {React.cloneElement(iconRight)}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default CustomInput;
