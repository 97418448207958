import * as dashboardTypes from "../types/dashboardTypes";
import StatusMessage from "../models/statusMessage";

const initialState = {
  loading: false,
  jobsData: "",
  dumpsData: "",
  customerSPData: "",
  statusMessages: [],
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case dashboardTypes.GET_JOBS_DATA:
      return {
        ...state,
        loading: false,
        jobsData: action.payload.data || "",
        statusMessages: [
          ...state.statusMessages,
          new StatusMessage({ subject: dashboardTypes.GET_JOBS_DATA, action }),
        ],
      };

    case dashboardTypes.GET_SP_CUSTOMER_DATA:
      return {
        ...state,
        loading: false,
        customerSPData: action.payload.data || "",
        statusMessages: [
          ...state.statusMessages,
          new StatusMessage({
            subject: dashboardTypes.GET_SP_CUSTOMER_DATA,
            action,
          }),
        ],
      };

    case dashboardTypes.GET_DUMPS_DATA:
      return {
        ...state,
        loading: false,
        dumpsData: action.payload.data || "",
        statusMessages: [
          ...state.statusMessages,
          new StatusMessage({
            subject: dashboardTypes.GET_DUMPS_DATA,
            action,
          }),
        ],
      };

    case dashboardTypes.LOADING_DASHBOARD_DATA:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};

export default dashboardReducer;
