import React, { useState, useEffect } from "react";

// Id generator
import makeID from "./../redux/utils/makeID";

// Available icon imports
import { ReactComponent as Radio } from "./../assets/icons/radio.svg";
import { ReactComponent as Card } from "./../assets/icons/card.svg";

// Available icon types
export const CustomIconTypes = {
  radio: <Radio />,
  card: <Card />,
};

// Icon Component
export const CustomIcon = ({
  type,
  size,
  margin,
  color,
  clicked,
  altClass,
}) => {
  // Opacity for click responsiveness
  const [opacity, setOpacity] = useState(1);

  // Function to make a unique id for each CustomIcon component
  // ID is used when assigning classes that alter the fill / color of the icon
  const classNameG = `custom-icon-${makeID(8)}`;

  const handleClick = () => {
    if (clicked) {
      clicked();
    }
    setOpacity(0.5);
  };

  // Handle click opacity responsiveness
  useEffect(() => {
    if (opacity < 1) {
      setTimeout(() => {
        setOpacity(1);
      }, 100);
    }
  }, [opacity]);
  return (
    <div
      className={`custom-icon-container ${altClass || ""}`}
      onClick={handleClick}
    >
      <style>
        {`
        .${classNameG} g{ fill: ${color} ; fill-opacity:${opacity};}
        `}
      </style>
      {type &&
        React.cloneElement(type, {
          className: classNameG,
          style: {
            width: size ? `${size}rem` : "3rem",
            height: size ? `${size}rem` : "3rem",
            marginTop: margin || "0.3rem",
          },
        })}
    </div>
  );
};
