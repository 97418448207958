import React, { useState, useEffect } from "react";
import { Colors } from "../../../config";
import queryString from "query-string";
import { Button, Modal, Spin, notification, Popover } from "antd";
import moment from "moment";
import { BsStarFill } from "react-icons/bs";
import AssignDivisionModal from "./AssignDivisionModal";

// Redux
import { useSelector, useDispatch } from "react-redux";
import * as spActions from "../../../redux/actions/spActions";
import * as divisionActions from "../../../redux/actions/divisionActions";
import makeID from "../../../redux/utils/makeID";

const SingleVoucher = ({ history, isDO, onDelete }) => {
  // State
  const [showDeactivateModal, setShowDeactivateModal] = useState(false);
  const [showAssigning, setShowAssigning] = useState(false);
  const [deactivatingStatus, setDeactivatingStatus] = useState(false);
  const [activatingStatus, setActivatingStatus] = useState(false);

  // Redux
  const dispatch = useDispatch();
  const [assigningStatus, setAssigningStatus] = useState("");
  const spInState = useSelector((state) => state.sp.sp);
  const divisionsInState = useSelector((state) => state.division.divisions);
  const loading = useSelector((state) => state.sp.loading);
  const spStatusMessages = useSelector((state) => state.sp.statusMessages);
  const spsLoading = useSelector((state) => state.sp.loading);
  const insertCommas = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const goBackToSps = () => history.push(history.location.pathname);

  useEffect(() => {
    const query = queryString.parse(history.location.search) || {};
    const spID = query.sp;
    const actualPath = history.location.pathname;

    if (!spInState) {
      // This endpoint is not yet updated
      dispatch(spActions.getSp(spID));
      dispatch(divisionActions.getAllDivisions());
    }
  }, []);

  const handleDeleteSp = () => {
    if (!onDelete) return;
    onDelete(spInState);
  };

  const handleDeactivateSp = () => {
    const statusID = makeID(8);
    setDeactivatingStatus(statusID);
    dispatch(
      spActions.updateSP(spInState?._id, { isVoucherSP: false }, statusID)
    );
  };

  const handleActivateSP = () => {
    const statusID = makeID(8);
    setActivatingStatus(statusID);
    dispatch(
      spActions.updateSP(spInState?._id, { isVoucherSP: true }, statusID)
    );
  };

  const getDivision = () => {
    if (!divisionsInState?.length || !spInState?.org?.division)
      return "None Yet";
    return divisionsInState.find((it) => it._id === spInState?.org?.division)
      ?.name;
  };

  useEffect(() => {
    if (!deactivatingStatus && !activatingStatus && !assigningStatus) return;
    if (deactivatingStatus) {
      const result = spStatusMessages.find(
        (it) => it.statusID === deactivatingStatus
      );
      if (result?.success) {
        const successMessage = "Service Provider was deactivated successfully";
        notification.success({
          message: "Update",
          description: successMessage,
          onClick: () => {},
          duration: 2,
          onClose: () => {
            dispatch(spActions.getSp(spInState?._id));
          },
        });
        setDeactivatingStatus("");
        setShowDeactivateModal(false);

        return;
      } else {
        const failMessage =
          "Failed to deactivate service provider. Please try again";
        notification.error({
          message: "Update",
          description: failMessage,
          onClick: () => {},
          duration: 2,
        });
        setDeactivatingStatus("");
        setShowDeactivateModal(false);
        return;
      }
    }
    if (activatingStatus) {
      const result = spStatusMessages.find(
        (it) => it.statusID === activatingStatus
      );
      if (result?.success) {
        const successMessage =
          "Service Provider was activated for voucher programs";
        notification.success({
          message: "Update",
          description: successMessage,
          onClick: () => {},
          duration: 2,
        });
        setActivatingStatus("");
        setShowDeactivateModal(false);
        return;
      } else {
        const failMessage =
          "Failed to activate service provider for voucher programs. Please try again";
        notification.error({
          message: "Update",
          description: failMessage,
          onClick: () => {},
          duration: 2,
        });
        setActivatingStatus("");
        setShowDeactivateModal(false);
        return;
      }
    }
    if (assigningStatus) {
      const result = spStatusMessages.find(
        (it) => it.statusID === assigningStatus
      );
      if (result?.success) {
        const successMessage =
          "Service Provider has successfully been assigned to a division";
        notification.success({
          message: "Update",
          description: successMessage,
          onClick: () => {},
          duration: 2,
          onClose: () => {
            dispatch(spActions.getSp(spInState?._id));
          },
        });
        setAssigningStatus("");
        setShowAssigning(false);
        //   goBackToSps();
        return;
      } else {
        const failMessage =
          "Failed to assign a division to service provider. Please try again";
        notification.error({
          message: "Update",
          description: failMessage,
          onClick: () => {},
          duration: 2,
        });
        setAssigningStatus("");
        setShowAssigning(false);
        return;
      }
    }
  }, [spStatusMessages]);

  console.log("divisionsInState is ", divisionsInState);

  return spInState?._id ? (
    <>
      <AssignDivisionModal
        visible={showAssigning}
        setVisible={setShowAssigning}
        sp={spInState}
      />
      {/* Deactivate Voucher */}
      <Modal
        confirmLoading={true}
        visible={showDeactivateModal}
        title={
          <b style={{ color: Colors.textGray }}>
            {spInState?.isVoucherSP ? "Deactivate" : "Activate"} Service
            Provider
          </b>
        }
        onOk={handleDeactivateSp}
        onCancel={() => setShowDeactivateModal(false)}
        footer={[
          <Button
            key="cancel"
            type="primary"
            onClick={() => setShowDeactivateModal(false)}
            color={Colors.darkGreen}
            className="logout-modal-no"
          >
            No
          </Button>,
          <Button
            key="logout"
            danger
            onClick={
              spInState?.isVoucherSP ? handleDeactivateSp : handleActivateSP
            }
            className="logout-modal-yes"
          >
            Yes
          </Button>,
        ]}
      >
        <Spin spinning={loading}>
          <p>
            Are you sure you want to{" "}
            {spInState?.isVoucherSP ? "deactivate" : "activate"} this service
            provider? They will
            {spInState?.isVoucherSP ? " no longer" : " now"} be able to do
            voucher jobs.
          </p>
        </Spin>
      </Modal>

      <div className="single-voucher-container">
        <div className="single-vouchers-top">
          <div className="single-vouchers-left">
            <h3
              style={{ color: Colors.darkGreen, cursor: "pointer" }}
              onClick={goBackToSps}
            >
              <u>
                <b>Service Providers</b>
              </u>
            </h3>
            <span style={{ color: Colors.textGray }}>{">"}</span>
            <h3 style={{ color: Colors.textGray, marginLeft: "6px" }}>
              SP {spInState?.ent_id}
            </h3>
          </div>
          <div className="single-vouchers-right">
            <Button
              key="2"
              danger
              onClick={() => {
                setShowDeactivateModal(true);
              }}
              disabled={isDO}
            >
              {spInState?.isVoucherSP ? "Deactivate" : "Activate"}
            </Button>

            <Button
              key="3"
              type="danger"
              disabled={isDO}
              onClick={handleDeleteSp}
            >
              Delete
            </Button>

            <Button
              key="3"
              type="primary"
              disabled={isDO}
              onClick={() => setShowAssigning(true)}
            >
              Assign to Division
            </Button>
          </div>
        </div>
        <Spin spinning={spsLoading}>
          <>
            <div className="single-vouchers-bottom">
              <div className="single-vouchers-bottom-left">
                <div className="sv-bottom-col ">
                  <div className="sv-detail-container">
                    <h4 className="sv-bottom-title"> SERVICE PROVIDER TYPE</h4>
                    <p>{spInState?.type}</p>
                  </div>

                  <div className="sv-detail-container">
                    <div className="sv-bottom-detail-pair">
                      <h4 className="sv-bottom-title"> CAN DO VOUCHERS:</h4>
                      <p
                        className="sv-bottom-detail-right"
                        style={{
                          color: spInState?.isVoucherSP
                            ? Colors.darkGreen
                            : Colors.actionRed,
                          fontWeight: "bold",
                        }}
                      >
                        {spInState?.isVoucherSP ? "YES" : "NO"}
                      </p>
                    </div>
                  </div>

                  <div className="sv-detail-container">
                    <h4 className="sv-bottom-title"> Rating</h4>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      {[...Array(spInState?.rating?.current || 1).keys()].map(
                        (it) => (
                          <BsStarFill color={Colors.gold} size={17} key={it} />
                        )
                      )}
                    </div>
                  </div>
                </div>

                <div className="sv-bottom-col ">
                  <div className="sv-detail-container">
                    <h4 className="sv-bottom-title"> PERSONAL DETAILS</h4>
                    <div className="sv-bottom-detail-pair">
                      <p className="sv-bottom-detail-left ">Full Name:</p>
                      <p className="sv-bottom-detail-right ">
                        {spInState?.full_name}
                      </p>
                    </div>
                  </div>

                  <div className="sv-detail-container">
                    <div className="sv-bottom-detail-pair">
                      <p className="sv-bottom-detail-left ">Phone Number:</p>
                      <p className="sv-bottom-detail-right ">
                        {spInState?.phone_number}
                      </p>
                    </div>

                    <div className="sv-bottom-detail-pair">
                      <p className="sv-bottom-detail-left ">Work Address:</p>
                      <p className="sv-bottom-detail-right ">
                        {spInState?.work_address || "None given"}
                      </p>
                    </div>

                    <div className="sv-bottom-detail-pair">
                      <p className="sv-bottom-detail-left ">Number Plate:</p>
                      <p className="sv-bottom-detail-right ">
                        {spInState?.org?.plate || "None given"}
                      </p>
                    </div>

                    <div className="sv-bottom-detail-pair">
                      <p className="sv-bottom-detail-left ">
                        Date of Registration:
                      </p>
                      <p className="sv-bottom-detail-right ">
                        {spInState?.date_of_registration
                          ? moment(spInState?.date_of_registration).format(
                              "DD/MM/YYYY"
                            )
                          : "Not Found"}
                      </p>
                    </div>

                    <div className="sv-bottom-detail-pair">
                      <p className="sv-bottom-detail-left ">Last Seen:</p>
                      <p className="sv-bottom-detail-right ">
                        {spInState?.last_seen
                          ? moment(spInState?.last_seen).format("DD/MM/YYYY")
                          : "Not Found"}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="sv-bottom-col ">
                  <div className="sv-detail-container">
                    <h4 className="sv-bottom-title"> Division</h4>
                    <p>{getDivision()}</p>
                  </div>

                  <div className="sv-detail-container">
                    <h4 className="sv-bottom-title"> Organisation</h4>

                    <p>{spInState?.org?.name || "None"}</p>
                  </div>
                </div>
              </div>
            </div>
          </>
        </Spin>
      </div>
    </>
  ) : null;
};

export default SingleVoucher;

/**
 * {


 
    approved: { type: Boolean, default: false },
   
    status: { type: Number, enum: [0, 1, 2, 3, 4, 5], default: 0 },
    
    org: {
      name: { type: String },
      division: { type: Schema.Types.ObjectId, ref: "division" },
      plate: { type: String },
    },
  


   
}
 */
