import React, { useState, useEffect } from "react";
import { Modal, Button, Popconfirm, notification } from "antd";
import moment from "moment";
import { Colors } from "./../../config";

// Redux
import { useSelector, useDispatch } from "react-redux";
import * as jobActions from "./../../redux/actions/jobActions";
import makeID from "./../../redux/utils/makeID";

const JobModal = ({
  visible,
  setVisible,
  job,
  history,
  handleRedoDispatch,
  handleReschedule,
}) => {
  // Redux
  const dispatch = useDispatch();
  const jobStatusMessages = useSelector((state) => state.jobs.statusMessages);
  const loading = useSelector((state) => state.jobs.loading);
  const [deleteStatusID, setDeleteStatusID] = useState("");

  // State
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const handleClose = () => {
    setShowDeletePopup(false);
    setVisible(false);
  };

  const handleDeleteJob = () => {
    setShowDeletePopup(false);
    const statusID = makeID(8);
    setDeleteStatusID(statusID);
    dispatch(jobActions.deleteJob(job._id, statusID));
  };

  const getSource = (record) => {
    let val = "None Given";
    if (record.source?.from) {
      val = record.source?.from;
    } else if (record?.source) {
      val = record?.source;
    } else if (record.referral_code !== "No referral code used") {
      val = "CAT's";
    } else if (record.isVoucherJob) {
      val = "CAT's";
    }
    return val;
  };

  const statusValues = {
    0: "Scheduled",
    1: "In Progress",
    2: "Declined",
    3: "Completed",
  };

  const jobNeedsRedo = job?.status === 1 || job?.status === 0 ? true : false;
  const buttons = jobNeedsRedo
    ? [
        <Button key="back" onClick={handleClose}>
          Close
        </Button>,

        <Popconfirm
          title={
            <span>
              Are you sure you want to delete this job?
              <br /> This action is irreversible.
            </span>
          }
          onConfirm={handleDeleteJob}
          onCancel={() => setShowDeletePopup(false)}
          okText="Yes"
          cancelText="No"
          visible={visible && showDeletePopup}
        >
          <Button
            loading={loading}
            key="danger"
            type="danger"
            onClick={() => setShowDeletePopup(true)}
          >
            Delete Job
          </Button>
        </Popconfirm>,
        <Button
          key="primary"
          type="primary"
          style={{ background: Colors.darkGreen }}
          onClick={handleRedoDispatch}
        >
          Redo Dispatch
        </Button>,
        <Button key="primary" type="primary" onClick={handleReschedule}>
          Schedule Job
        </Button>,
      ]
    : [
        <Popconfirm
          title={
            <span>
              Are you sure you want to delete this job?
              <br /> This action is irreversible.
            </span>
          }
          onConfirm={handleDeleteJob}
          onCancel={() => setShowDeletePopup(false)}
          okText="Yes"
          cancelText="No"
          visible={showDeletePopup}
        >
          <Button
            key="danger"
            type="danger"
            onClick={() => setShowDeletePopup(true)}
          >
            Delete Job
          </Button>
        </Popconfirm>,
        <Button key="back" onClick={handleClose}>
          Close
        </Button>,
      ];

  useEffect(() => {
    if (!deleteStatusID) return;

    const result = jobStatusMessages.find(
      (it) => it.statusID === deleteStatusID
    );

    if (result?.success) {
      const successMessage = "Job was deleted successfully";
      notification.success({
        message: "Update",
        description: successMessage,
        onClick: () => {},
        duration: 2,
        onClose: () => {
          dispatch(jobActions.getAllJobs());
          handleClose();
        },
      });
    } else {
      const failMessage = "Failed to delete job. Please try again";
      notification.error({
        message: "Update",
        description: failMessage,
        onClick: () => {},
        duration: 2,
      });
    }

    setDeleteStatusID("");
  }, [jobStatusMessages]);

  useEffect(() => {
    if (!visible) return;
  }, [visible]);
  return visible ? (
    <Modal
      title={<b>Job ID: {job?.job_id}</b>}
      visible={visible}
      footer={buttons}
      onCancel={handleClose}
    >
      <div className="job-modal">
        <h3>Job Details</h3>
        <div className="job-card-pair">
          <div className="job-card-key">Job ID:</div>
          <div className="job-card-value">{job?.job_id}</div>
        </div>

        <div className="job-card-pair">
          <div className="job-card-key">Date Created:</div>
          <div className="job-card-value">
            {job?.date_of_registration
              ? moment(job.date_of_registration).format("DD/MM/YYYY, HH:mm")
              : "None provided"}
          </div>
        </div>

        <div className="job-card-pair">
          <div className="job-card-key">Source:</div>
          <div className="job-card-value">{getSource(job)}</div>
        </div>

        <div className="job-card-pair">
          <div className="job-card-key">Facility Type:</div>
          <div className="job-card-value">
            {job?.facility_type || "None provided"}
          </div>
        </div>

        <div className="job-card-pair">
          <div className="job-card-key">Status:</div>
          <div className="job-card-value">
            {job?.status ? statusValues[job?.status] : "None provided"}
          </div>
        </div>

        <h3>Customer Details</h3>
        <div className="job-card-pair">
          <div className="job-card-key">Full Name:</div>
          <div className="job-card-value">{job?.full_name}</div>
        </div>

        <div className="job-card-pair">
          <div className="job-card-key">Phone Number:</div>
          <div className="job-card-value">{job?.phone_number}</div>
        </div>

        <div className="job-card-pair">
          <div className="job-card-key">Location:</div>
          <div className="job-card-value">
            {job?.address || job?.location || "None provided"}
          </div>
        </div>

        <div className="job-card-pair">
          <div className="job-card-key">Longitude / Latitude:</div>
          <div className="job-card-value">
            {job?.long ? `${job?.long} / ${job?.lat}` : "None provided"}
          </div>
        </div>

        <div className="job-card-pair">
          <div className="job-card-key">Division:</div>
          <div className="job-card-value">
            {job?.division || "None provided"}
          </div>
        </div>

        <div className="job-card-pair">
          <div className="job-card-key">Parish:</div>
          <div className="job-card-value">{job?.parish || "None provided"}</div>
        </div>

        <h3>Service Provider Details</h3>
        <div className="job-card-pair">
          <div className="job-card-key">Full Name:</div>
          <div className="job-card-value">
            {job?.service_provider || "None Yet"}
          </div>
        </div>

        <div className="job-card-pair">
          <div className="job-card-key">Phone Number:</div>
          <div className="job-card-value">
            {job?.service_provider_number || "None Yet"}
          </div>
        </div>

        <div className="job-card-pair">
          <div className="job-card-key">Amount paid:</div>
          <div className="job-card-value">
            {job?.income_received || "None Yet"}
          </div>
        </div>

        {job?.referral_code ? (
          <>
            <h3>Voucher Details</h3>
            <div className="job-card-pair">
              <div className="job-card-key">Referral Code:</div>
              <div className="job-card-value">
                {job?.referral_code || "None Yet"}
              </div>
            </div>
          </>
        ) : null}
      </div>
    </Modal>
  ) : null;
};

export default JobModal;
