// CRUD Operations
export const CREATE_CUSTOMER = "CREATE_CUSTOMER";
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER";
export const DELETE_CUSTOMER = "DELETE_CUSTOMER";
export const GET_CUSTOMER = "GET_CUSTOMER";
export const GET_ALL_CUSTOMERS = "GET_ALL_CUSTOMERS";

export const ADD_CUSTOMER = "ADD_CUSTOMER";

// Misc
export const LOADING_CUSTOMERS = "LOADING_CUSTOMERS";
