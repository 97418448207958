import moment from "moment";
const jobEndpoints = (base, prefix, apiVersion) => {
  const defaultEndpoint = "jobs";
  const altEndpoint = "job";
  // http://localhost:2083/api/job
  // Get all jobs from the last two months
  const twenty18 = moment("2018");
  const today = moment();
  return {
    get: `${base}/${prefix}/${defaultEndpoint}`,
    getAll: `${base}/${prefix}/${defaultEndpoint}?records=1&limit=15000&from=2018&to=2023`, // Can accept queries
    getPending: `${base}/${prefix}/${defaultEndpoint}/pending`,
    getScheduled: `${base}/${prefix}/${defaultEndpoint}/scheduled`,
    getInProgress: `${base}/${prefix}/${defaultEndpoint}/completed`,
    getCompleted: `${base}/${prefix}/${defaultEndpoint}/inprogress`,
    update: `${base}/${prefix}/${altEndpoint}`, // /:jobID
    delete: `${base}/${prefix}/${altEndpoint}`, // /:jobID
    add: `${base}/${prefix}/${altEndpoint}`,
    acceptJobForSp: (id) => `${base}/${prefix}/${altEndpoint}/${id}`,
    schedule: (id) => `${base}/${prefix}/${altEndpoint}/schedule/${id}`,
    summary: `${base}/${prefix}/${defaultEndpoint}/summary`,
    // {{url}}/api/job/schedule/id
    queries: {
      page: "records",
      status: "status",
      dateFrom: "from",
      dateTo: "to",
      facilityType: "facility_type",
      pageLimit: "limit",
      // "api/jobs?records=" + page + "&status=" + this.state.status + "&from=" + this.state.from + "&to=" + this.state.to + "&facility_type=" + this.state.facility_type + "&limit=" + sizePerPage
    },
  };
};

// "api/jobs?records=1" + "&status=" + this.state.status + "&from=" + this.state.from + "&to=" + this.state.to + "&facility_type=" + this.state.facility_type + "&limit=" + this.state.totalDataSize;

export default jobEndpoints;
